import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of, retry, tap, throwError } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { Profile } from './../models';
import { AuthConfig } from './authentication.config';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  constructor(private http: HttpClient, private authConfig: AuthConfig) {}
  useLocal = false;
  apiURL = this.authConfig.apiConfig.profileUri + '/api';

  getMe(): Observable<Profile> {
    return this.http.get<Profile>(this.apiURL + '/users/me').pipe(
      map((p: Profile) => {
        var u: Profile = {
          id: p.id,
          email: p.email,
          firstName: p.firstName,
          lastName: p.lastName,
          credentials: p.credentials,
        };
        return u;
      }),
      retry(1),
      catchError(this.httpError.bind(this))
    );
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(msg);
  }
}
