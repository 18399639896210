import { InjectionToken } from '@angular/core';

import { IEnvironmentConfig } from './environment.interface';

/**
 * @const ENVIRONMENT
 * Injection token for the environment interface to be provided by the applications.
 */
export const ENVIRONMENT: InjectionToken<IEnvironmentConfig> =
  new InjectionToken('ENVIRONMENT');
