import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-form-flex',
  template: `
    <div
      class="content"
      [fxLayout]="props.fxLayout"

      fxLayout.xs="column"
      fxFlexFill
    >
      <formly-field *ngFor="let f of field.fieldGroup" [field]="f"  [formlyAttributes]="field">
      </formly-field>
    </div>
  `,
  styles: [`
    .content {
      min-width: 300px;
      /*height: 400px;*/
      font: normal normal normal 16px/22px;
    }
  `]
})
export class FlexLayoutType extends FieldType {
}
