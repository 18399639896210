import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Guid, SearchDialogComponent } from '@forms/common';
import { catchError, delay, Observable, startWith, timer } from 'rxjs';
import { AccountMinimalView } from '@accounting/common';
import { filter, map } from 'rxjs/operators';
import { CaseFacade } from '../../../../services/case.facade';
import { Actions, ofType } from '@ngrx/effects';
import {
  CaseApiActionTypes,
  createCaseFailure,
  createCaseSuccess,
} from '../../../../actions/case-api.actions';
import { CallAction, CallForm, CallType, CreateCallDTO } from '@nfr/common';
import { provideComponentStore } from '@ngrx/component-store';
import { CallDialogStages, NewCallStore } from './new-call.store';
import { faker } from '@faker-js/faker';
import { ConfirmDialogComponent } from '@autobot/shared';

@UntilDestroy()
@Component({
  selector: 'nfr-new-call',
  templateUrl: './new-call.component.html',
  styleUrls: ['./new-call.component.scss'],
  providers: [provideComponentStore(NewCallStore)],
})
export class NewCallDialog implements OnInit {
  vm: any;
  CallStages = CallDialogStages;
  errorMessage: string = '';
  needsParticipant: boolean;
  filteredAccounts: Observable<any[]>;
  form: FormGroup;
  callDTO: CreateCallDTO;
  callFormData: CallForm;

  constructor(
    private dialogRef: MatDialogRef<NewCallDialog>,
    public store: NewCallStore,
    private dialog: MatDialog,
    private facade: CaseFacade,
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    const accounts = this.prepareAccounts(data.accounts);
    this.store.loadAccounts(accounts);

    this.setup();
  }

  setup() {
    this.store.vm$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.vm = data;
    });

    this.form = this.fb.group({
      id: [Guid.newGuid()],
      phoneSystemId: ['', Validators.required],
      newCase: [true],
      account: ['', Validators.required],
      employee: this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        role: ['employee'],
        employeeId: [''],
        isAvailable: [false],
        isCaller: [false],
      }),
      supervisor: this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        role: ['supervisor'],
        employeeId: [''],
        isAvailable: [false],
        isCaller: [false],
      }),
      participants: this.fb.array([]),
      eligibilityData: this.fb.group({
        employeeOrContractor: ['', Validators.required],
        workRelatedInjury: ['', Validators.required],
      }),
      props: this.fb.group({}),
      startTime: [new Date()],
    });
    this.form.valueChanges.subscribe((data) => {
      if (
        data.employee?.isAvailable == false &&
        data.supervisor?.isAvailable == false
      ) {
        this.needsParticipant = true;
      } else {
        this.needsParticipant = false;
      }
    });

    this.filteredAccounts = this.accountsCtrl.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name
          ? this.filterAccounts(name as string)
          : this.vm.accounts.slice();
      })
    );
  }

  prepareAccounts(accounts) {
    const noContracts = accounts.filter((o) => o.accountType != 'contract');

    const result = noContracts.map((e) => {
      const parent = accounts.filter((p) => p.id == e.parentId)[0];
      const accountTypeFormat = this.formatAccountType(e.accountType);
      return {
        ...e,
        parent,
        accountTypeFormat,
      };
    });
    return result;
  }

  formatAccountType(type) {
    if (type == 'sub') return 'Sub-Account';
    if (type == 'account') return 'Account';
  }

  get formValue() {
    return this.form.value;
  }
  get employee() {
    return this.form.get('employee') as FormGroup;
  }
  get supervisor() {
    return this.form.get('supervisor') as FormGroup;
  }
  get phoneSystemId() {
    return this.form.get('phoneSystemId') as FormControl;
  }
  get accountsCtrl() {
    return this.form.get('account') as FormControl;
  }
  get newCase() {
    return this.form.get('newCase') as FormControl;
  }
  get participants() {
    return this.form.get('participants') as FormArray;
  }

  ngOnInit(): void {
    if (this.vm.config.fakeParticipants) {
      this.addParticipant({
        firstName: faker.name.firstName(),
        lastName: faker.name.lastName(),
        isCaller: true,
        role: 'employee',
      });
      this.addParticipant({
        firstName: faker.name.firstName(),
        lastName: faker.name.lastName(),
        isCaller: false,
        role: 'supervisor',
      });
    } else {
      this.addParticipant({ isCaller: true, role: 'employee' });
      this.addParticipant({ isCaller: false, role: 'supervisor' });
    }
  }

  displayFn(acc: AccountMinimalView): string {
    const newDisplay = acc.name; //+ ' ' + (acc.accountType == 'account') ? 'Account' : 'Sub-Account'

    return acc && newDisplay ? newDisplay : '';
  }

  addParticipant(data?: any) {
    const frm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      role: ['employee'],
      employeeId: [''],
      isCaller: [false],
    });

    if (data) frm.patchValue(data);

    this.participants.push(frm);
  }
  removeParticipant(i: number) {
    this.participants.removeAt(i);
  }
  searchUsers() {
    this.dialog
      .open(SearchDialogComponent, { width: '50%' })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res) {
          this.addParticipant({
            firstName: res.firstName,
            lastName: res.lastName,
            employeeId: res.employeeId,
          });
        }
      });
  }

  selectCase(e) {
    console.log('Selected:', e);
  }

  cancel() {
    this.dialogRef.close();
  }
  close(choice: any) {
    this.dialogRef.close(choice);
  }

  save(data: any) {
    if (this.form.valid) {
      const call = {
        ...data,
        receivedBy: this.vm.user.email,
        result: 'NewCase',
      };

      this.dialogRef.close(call);
    }
  }
  isSomeoneAvailable() {}
  createCase() {
    const form = this.form.value;
    const account = form.account;
    const agent = this.vm.user.email;
    const user=this.vm.user;
    const participants = this.scrapeParticipants();

    if (this.needsParticipant) {
      return;
    }

    this.store.startCaseCreation();
    this.dialogRef.updateSize('600px', null);

    const call: CallForm = {
      id: form.id,
      account,
      phoneSystemId: form.phoneSystemId,
      participants,
      newCase: form.newCase,
      receivedBy: this.vm.user.email,
      action: CallAction.NewCase,
      callType: CallType.Inbound,
      props: {
        employee: form.employee,
        supervisor: form.supervisor,
      },
      data: form.data,
      startTime: form.startTime,
    };

    this.store.updateSelectedAccount(account);
    this.store.updateCallData(call);
    this.store.updateMessage(`Creating new case for ${account.name}`);

    this.facade.createNewCase(call, account, agent,user);
    this.actions$
      .pipe(
        delay((Math.random() + 2) * 1000),
        ofType(createCaseSuccess, createCaseFailure),
        untilDestroyed(this)
      )
      .subscribe((res) => {
        this.store.updateProcessing(false);

        if (res.type == CaseApiActionTypes.CREATE_CASE_FAILURE) {
          this.errorMessage = res.error;
          this.store.updateCallDialogStage(CallDialogStages.err);
          this.store.updateMessage('Error creating case');
        } else {
          this.store.updateCaseData(res.case);
          const id = res?.case.id;
          const dto = this.generateCallDTO(call, account, id, 'NewCase');
          this.facade.createCall(dto);
          this.store.updateMessage(`Case Creation Complete`);
          const updatedWithCaseId = { ...call, caseId: id };
          this.callDTO = dto;
          this.callFormData = updatedWithCaseId;
          this.confirmation();
        }
      });
  }

  continueToCase(data) {
    this.dialogRef.close(data);
  }
  confirmation() {
    this.store.updateCallDialogStage(CallDialogStages.confirmation);
  }
  processValue(isProcessing) {
    return isProcessing ? 50 : 100;
  }
  private scrapeParticipants() {
    const frm = this.formValue;
    const participants = [];

    if (frm.employee?.isAvailable) participants.push(frm.employee);
    if (frm.supervisor?.isAvailable) participants.push(frm.supervisor);

    return participants;
  }
  private generateCallDTO(call, account, caseId, action) {
    let callDTO: CreateCallDTO = {
      id: call.id,
      accountId: account.id,
      caseId,
      phoneSystemId: call.phoneSystemId,
      participants: call.participants,
      newCase: call.newCase,
      callType: call.callType,
      receivedBy: call.receivedBy,
      action,
      props: call.props,
      data: call.data,
      startTime: call.startTime,
    };

    return callDTO;
  }

  private filterAccounts(value: string): AccountMinimalView[] {
    const filterValue = value.toLowerCase();
    return this.vm.accounts.filter(
      (state) =>
        state.displayName.toLowerCase().includes(filterValue) ||
        state.skillNumber.toLowerCase().includes(filterValue)
    );
  }

  eligibilityQuestionChange() {
    if (
      this.formValue.eligibilityData.employeeOrContractor === 'contractor' ||
      this.formValue.eligibilityData.workRelatedInjury === 'N'
    ) {
      const data = {
        message:
          'READ: I’m very sorry but we are unable to proceed with the call. Please reach out to your supervisor for next steps. If this is an emergency, please call 911.',
        acceptButtonText: 'FINISH',
        cancelButtonText: 'CANCEL',
      };

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '700px',
        data,
      });

      dialogRef
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          if (result) {
            this.saveCall();
          } else {
            this.form.get('eligibilityData.employeeOrContractor').setValue('');
            this.form.get('eligibilityData.workRelatedInjury').setValue('');
          }
        });
    }
  }

  saveCall() {
    this.store.startCaseCreation();
    const form = this.form.value;
    const account = form.account;

    const call: CallForm = {
      id: form.id,
      account,
      phoneSystemId: form.phoneSystemId,
      participants: form.participants,
      newCase: form.newCase,
      receivedBy: this.vm.user.email,
      action: CallAction.NewCase,
      callType: CallType.Inbound,
      props: {
        employee: form.employee,
        supervisor: form.supervisor,
      },
      data: form.eligibilityData,
      startTime: form.startTime,
    };

    this.store.updateSelectedAccount(account);
    this.store.updateCallData(call);

    const dto = this.generateCallDTO(call, account, null, CallAction.NewCase);
    this.facade.createCall(dto);

    this.dialogRef.close();
  }
}

function randDelay() {
  return timer((Math.random() + 2) * 1000);
}
