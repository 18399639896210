import {createActionGroup, props} from "@ngrx/store";

export const UserActions = createActionGroup({
  source: 'Content Home',
  events: {
    'Add Favorite': props<{ id: string }>(),
    'Toggle Favorite': props<{ id: string }>(),
    'Remove Favorite': props<{ id: string }>(),
    'Hide Content': props<{ id: string }>(),
    'Update Props': props<{ data: any}>()
  },
})

