import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import {
  AdvancedPanelWrapperComponent,
  CollapsePanelWrapperComponent,
  FormlyTooltipWrapper,
  HeaderCollapsePanelWrapperComponent,
  PanelWrapperComponent,
  StandardExpansionPanelWrapperComponent,
  FormlyPasteControlWrapper
} from './wrappers';
import {
  AutocompleteTypeComponent,
  dragDropType,
  FlexLayoutType,
  FormlyFieldNoWrapServiceTabs,
  FormlyFieldStepper,
  FormlyFieldTabs,
  MatFormlyFieldFile,
  noBlockingTabs,
  noWrapTabType,
  PhoneMaskTypeComponent,
  SSNMaskTypeComponent,
  repeatType,
  selectableMatSelect,
  textAreaAutoResizeType,
} from './types';
import { ConfigurableFormlyInput } from './types/configurable-formly-input';
import { FormlySelectedWrapper } from './wrappers/formly-selected-wrapper';
import { dragDropHorizontalType } from './types/drag-drop-horizontal';
import { radioyesno } from './types/yesno-radio-type';
import { radiogroup } from './types/group-radio-type';
import { popoverRepeatType } from './types/popover-repeat-type.component';
import { FormlyWrapperAddons } from './wrappers/addons.wrapper';
import { radioThreeChoice } from './types/radio-three';
import { multiCheckboxChoice } from './types/mat-checkbox-list';
import { radioSelectedProtocols } from './types/selected-protocols';
import { FormlyFieldServiceTabs } from './types/material-tabs-service-type';
import { formlyLabel } from './types/formly-label';
import { formlyDynamicLabel } from './types/formly-dynamiclabel';
import { formlyCustomLink } from './types/custom-link';
import { CustomButtonType } from './types/custom-button';
import { formlyInfo } from './types/formly-info';
import {
  CustomValidationService,
  dateFormatValidationMessage,
  dateFormatValidator,
  dateFutureValidationMessage,
  dateofinjuryValidationMessage,
  dateofinjuryValidator,
  PhoneSystemIdValidationService,
  futureDateValidator,
  futureDateValidatorAsync,
  maxLengthValidationMessage,
  maxValidationMessage,
  minLengthValidationMessage,
  minValidationMessage,
  validateTriage,
} from './validation.messages';
import { CustomDatePickerType } from './types/custom-date-picker-type';
import { addonsExtension, tooltipExtension, pasteControlExtension } from './extensions';

export function registerFormlyValidation(
  customVal: CustomValidationService,
  caseApiservice: PhoneSystemIdValidationService
): any {
  return {
    validationMessages: [
      { name: 'required', message: 'This field is required' },
      { name: 'minLength', message: minLengthValidationMessage },
      { name: 'maxLength', message: maxLengthValidationMessage },
      { name: 'min', message: minValidationMessage },
      { name: 'max', message: maxValidationMessage },
      { name: 'future-date', message: dateFutureValidationMessage },
      { name: 'dateValidator', message: dateFormatValidationMessage },
      {
        name: 'parental-consent',
        message: customVal.parentalConsentValidationMessage,
      },
      {
        name: 'dateofdeath-validation',
        message: customVal.dateOfDeathValidationMessage,
      },
      {
        name: 'dateofinjury-validation',
        message: dateofinjuryValidationMessage,
      },
      {
        name: 'dateofinjuryreport-validation',
        message: customVal.dateOfInjuryReportValidationMessage,
      },
      {
        name: 'dateofmanagernotified-validation',
        message: customVal.dateOfManagerNotifiedValidationMessage,
      },
      {
        name: 'phonesystemid-validation',
        message: caseApiservice.phoneSystemIdValidationMessage,
      },
      {
        name: 'phonesystemid-length-validation',
        message: caseApiservice.phoneSystemIdLengthValidationMessage,
      },
    ],
    validators: [
      {
        name: 'future-date',
        validation: futureDateValidator,
      },
      {
        name: 'dateValidator',
        validation: dateFormatValidator,
      },
      {
        name: 'parental-consent',
        validation: customVal.isParentalConsentRequired,
      },
      {
        name: 'validation-triage',
        validation: validateTriage,
      },
      {
        name: 'dateofinjury-validation',
        validation: dateofinjuryValidator,
      },
      {
        name: 'dateofdeath-validation',
        validation: customVal.dateOfDeathValidation,
      },
      {
        name: 'dateofinjuryreport-validation',
        validation: customVal.dateOfInjuryReportValidation,
      },
      {
        name: 'dateofmanagernotified-validation',
        validation: customVal.dateOfManagerNotifiedValidation,
      },
      {
        name: 'phonesystemid-validation',
        validation: caseApiservice.phoneSystemIdValidator,
      },
      {
        name: 'phonesystemid-length-validation',
        message: caseApiservice.phoneSystemIdLengthValidationMessage,
      },
    ],
  };
}

@NgModule({
  imports: [
    FormlyModule.forRoot({
      wrappers: [
        { name: 'panel', component: PanelWrapperComponent },
        { name: 'collapse-panel', component: CollapsePanelWrapperComponent },
        {
          name: 'collapse-header-panel',
          component: HeaderCollapsePanelWrapperComponent,
        },
        { name: 'advanced-panel', component: AdvancedPanelWrapperComponent },
        { name: 'selected-wrapper', component: FormlySelectedWrapper },
        {
          name: 'expansion-panel',
          component: StandardExpansionPanelWrapperComponent,
        },
        { name: 'addons', component: FormlyWrapperAddons },
        { name: 'tooltip', component: FormlyTooltipWrapper },
        { name: 'pasteFromField', component: FormlyPasteControlWrapper },
      ],
      types: [
        textAreaAutoResizeType,
        repeatType,
        popoverRepeatType,
        dragDropType,
        selectableMatSelect,
        dragDropHorizontalType,
        radioyesno,
        noWrapTabType,
        radioThreeChoice,
        multiCheckboxChoice,
        radioSelectedProtocols,
        noBlockingTabs,
        radiogroup,
        formlyInfo,
        formlyLabel,
        formlyDynamicLabel,
        
        formlyCustomLink,
        {
          name: 'custom-date-picker',
          component: CustomDatePickerType,
          wrappers: ['form-field'],
          defaultOptions: {
            validators: {
              'future-date': futureDateValidator,
              dateValidator: dateFormatValidator,
            },
          },
        },
        {
          name: 'button',
          component: CustomButtonType,
          defaultOptions: {
            templateOptions: {
              btnType: 'default',
              type: 'button',
            },
          },
        },
        {
          name: 'autocomplete',
          component: AutocompleteTypeComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'phone',
          component: PhoneMaskTypeComponent,
          wrappers: ['form-field'],
          defaultOptions: {
            templateOptions: {
              type: 'phone',
            },
          },
        },
        {
          name: 'ssnumber',
          component: SSNMaskTypeComponent,
          wrappers: ['form-field'],
          defaultOptions: {
            templateOptions: {
              type: 'ssnumber',
            },
          },
        },
        {
          name: 'text',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              attributes: {
                autocomplete: 'no',
              },
            },
          },
        },

        {
          name: 'mat-file',
          component: MatFormlyFieldFile,
          wrappers: ['form-field'],
        },
        { name: 'flex-layout', component: FlexLayoutType },
        { name: 'stepper', component: FormlyFieldStepper },
        { name: 'info-tabs', component: FormlyFieldServiceTabs },
        { name: 'tabs', component: FormlyFieldTabs },
        { name: 'select-input', component: ConfigurableFormlyInput },
      ],
      extensions: [
        //  { name: 'addons', extension: { onPopulate: addonsExtension } },
        { name: 'tooltip', extension: { onPopulate: tooltipExtension } },
        { name: 'pasteFromField', extension: { onPopulate: pasteControlExtension } },
      ],
      extras: { immutable: true },
    }),
  ],
  declarations: [],
  exports: [],
  providers: [
    CustomValidationService,
    PhoneSystemIdValidationService,
    // { provide: FORMLY_CONFIG, multi: true, useFactory: registerTranslateExtension, deps: [TranslateService, TranslateParser] },
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerFormlyValidation,
      deps: [CustomValidationService, PhoneSystemIdValidationService],
    },
  ],
})
export class AutobotFormlyModule {
  constructor(@Optional() @SkipSelf() parentModule: AutobotFormlyModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only!'
      );
    }
  }

  static forRoot(config?: any): ModuleWithProviders<AutobotFormlyModule> {
    return {
      ngModule: AutobotFormlyModule,
    };
  }
}
