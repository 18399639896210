import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable, Subscription, of, take, timer } from 'rxjs';
import {
  ProviderApiService,
  ProviderMinimalView
} from '@autobot/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'auto-provider-search',
  templateUrl: './auto-provider-search.component.html',
  styleUrls: ['./auto-provider-search.component.scss'],
})
export class AutoProviderSearchComponent implements OnInit {
  panelOpenState = false;
  providerSearchForm: FormGroup;
  providers$: Observable<ProviderMinimalView[]> = of([]);
  showMaxLengthValidation: boolean = false;
  apiCallNeeded: boolean = false;
  loading: boolean = true;
  dataSubscription: Subscription;
  isProviderExpanded$ = new BehaviorSubject<boolean>(true);

  @Input() formGroup: FormGroup<any[]>;
  @Input() caseData: any;

  intakeValues = {
    locationCode: '',
    locationFullAdddress: '',
    empFullHomeAddress: '',
  };
  isLabelChangeEnabled = false;
  dynamicLabel = {
    labelEmployee: 'Employee',
  };

  constructor(private service: ProviderApiService,
    private fb: FormBuilder, private cdr: ChangeDetectorRef,
    private store: Store) { }

  ngOnInit(): void {
    this.providerSearchForm = this.formGroup;
    this.showMsgOnRankingField(1, 4, this.providerSearchForm.controls.provider.get('providerRanking').value);
    this.loadProviders();
    this.providers$.subscribe((data) => {
      if (data && data.length > 0) {
        this.isProviderExpanded$.next(true);
      }
      else { this.isProviderExpanded$.next(false); }
    });

    this.setFormValuesFromCase();
    this.providerSearchForm.get(['provider', 'providerRanking'])?.valueChanges.subscribe((value: any) => {
      this.showMsgOnRankingField(1, 4, value);
    });

    this.dataSubscription = timer(1000).pipe(take(1)).subscribe(() => {
      this.loading = false; // After 1 second, set loading to false
      this.cdr.detectChanges(); // Trigger change detection
    });
  }

  showMsgOnRankingField(minLength: number, maxLength, controlValue: number) {
    const inputLength = (controlValue?.toString().length) || 0;
    if (controlValue?.toString() != '') {
      this.showMaxLengthValidation = (inputLength < minLength || inputLength > maxLength);
    }
    else {
      this.showMaxLengthValidation = false;
    }
  }

  loadProviders() {
    if (this.caseData) {
      const code = this.caseData.model.intake.location.work?.code;
      const accountId = this.caseData.accountId;
      if (code && accountId) {
        this.apiCallNeeded = true;
        this.providers$ = this.service.getProviderByLocation(accountId, code);

      } else {
        this.providers$ = of(null);

      }
      this.isLabelChangeEnabled = this.caseData?.calls[0]?.data?.account?.props?.isLabelChangeEnabled;
      if (this.isLabelChangeEnabled) {
        this.dynamicLabel.labelEmployee = 'Associate';
       
      } else {
        this.dynamicLabel.labelEmployee = 'Employee';
      }
    }
  }

  get requiredValidator() {
    return Validators.required;
  }

  get providerTypeError() {
    const formControl = this.providerSearchForm?.get([
      'provider',
      'providerType',
    ]);
    return formControl ? formControl.errors : null;
  }

  get providerNameError() {
    const formControl = this.providerSearchForm?.get([
      'provider',
      'providerName',
    ]);
    return formControl ? formControl.errors : null;
  }

  get providerAddressError() {
    const formControl = this.providerSearchForm?.get([
      'provider',
      'providerAddress',
    ]);
    return formControl ? formControl.errors : null;
  }

  get faxError() {
    const formControl = this.providerSearchForm?.get([
      'provider',
      'providerFax',
    ]);
    return formControl ? formControl.errors : null;
  }

  get phoneError() {
    const formControl = this.providerSearchForm?.get([
      'provider',
      'providerPhone',
    ]);
    return formControl ? formControl.errors : null;
  }

  applyProvider(provider: any) {
    const providerForm = this.providerSearchForm.get('provider') as FormGroup;
    if (providerForm) {
      providerForm.patchValue({
        providerType: provider.providerType,
        providerRanking: provider.ranking,
        hoursOperations: provider.hoursOfOperation,
        facilityName: provider.facilityName,
        providerAddress: provider.fullAddress,
        providerName: provider.providerName,
        providerPhone: provider.phone,
      });
      this.cdr.detectChanges(); // Manually trigger change detection if necessary
    }
  }

  clearProvider() {
    this.providerSearchForm.controls.provider
      .get('providerType')
      .setValue('');
    this.providerSearchForm.controls.provider
      .get('providerRanking')
      .setValue('');
    this.providerSearchForm.controls.provider
      .get('hoursOperations')
      .setValue('');
    this.providerSearchForm.controls.provider
      .get('facilityName')
      .setValue('');
    this.providerSearchForm.controls.provider
      .get('providerAddress')
      .setValue('');
    this.providerSearchForm.controls.provider
      .get('providerName')
      .setValue('');
    this.providerSearchForm.controls.provider
      .get('providerPhone')
      .setValue('');
    this.providerSearchForm.controls.provider
      .get('providerFax')
      .setValue('');
    this.providerSearchForm.controls.provider
      .get('otherReferrals')
      .setValue('');
    this.cdr.detectChanges(); // Manually trigger change detection if necessary
  }

  setFormValuesFromCase() {
    if (this.caseData) {
      const workLocation = this.caseData.model.intake?.location?.work;
      const empHomeAddress =
        this.caseData.model.intake?.employee?.address?.home;
      this.intakeValues.locationCode = workLocation?.code;
      this.intakeValues.locationFullAdddress = [
        workLocation?.address1,
        workLocation?.address2,
        workLocation?.city,
        workLocation?.state,
        workLocation?.zip,
      ]
        .filter(Boolean)
        .join(', ');
      this.intakeValues.empFullHomeAddress = [
        empHomeAddress?.address1,
        empHomeAddress?.address2,
        empHomeAddress?.city,
        empHomeAddress?.state,
        empHomeAddress?.zip,
      ]
        .filter(Boolean)
        .join(', ');
    }
  }

  ngAfterContentChecked() {
    if (this.providerSearchForm.disabled) {
      this.formGroup.disable({ onlySelf: true, emitEvent: false });
    }
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
