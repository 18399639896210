import {Component, OnInit} from '@angular/core';



@Component({
  selector: 'confirm-dialog',
  template: `
    <h1 mat-dialog-title>Confirm Exit</h1>
    <div mat-dialog-content>
      <p>Are you sure you wish to return to the starting area?</p>

    </div>
    <div mat-dialog-actions>
      <button mat-raised-button [mat-dialog-close]="false">No Thanks</button>
      <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
    </div>

  `
})
export class ConfirmDialog implements OnInit {
  ngOnInit(): void {
  }

}
