import { Account } from "@accounting/common";
import {createAction, props} from "@ngrx/store";


export enum AccountEditorActionTypes {
  INIT = '[Account/Editor Page] Init',
  SAVE_ACCOUNT = '[Account/Editor Page] Save Account',
  SAVE_ACCOUNT_SUCCESS = '[Account/Editor Page] Save Account Success',
  SAVE_ACCOUNT_FAILURE = '[Account/Editor Page] Save Account Failure',
  EXPORT_ACCOUNT_CLICKED = '[Account/Editor Page] Export Account Clicked',
}
export const init = createAction(AccountEditorActionTypes.INIT,  props<{account: Account}>());
export const saveAccount = createAction(AccountEditorActionTypes.SAVE_ACCOUNT, props<{account: Account}>());
export const saveAccountSuccess = createAction(AccountEditorActionTypes.SAVE_ACCOUNT_SUCCESS);
export const saveAccountFailure = createAction(AccountEditorActionTypes.SAVE_ACCOUNT_FAILURE,props<{err: any}>());
export const exportAccountClicked = createAction(AccountEditorActionTypes.EXPORT_ACCOUNT_CLICKED, props<{account: Account}>());
