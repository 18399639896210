import { IForm, Template } from '@forms/common';
import { createReducer, on } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CustomFormActions } from '../actions';
import * as fromCase from './case.reducer';

export interface State {
  triage?: TriageState;
  planofCare?: PlanOfCareState;
  followUp?: FollowUpState;
  selectedProtocol: SelectedProtocol;

  loaded: boolean;
  loading: boolean;
  error?: any;
  model?: any;
  saved: boolean;
  saving: boolean;
}

export interface FormState {
  page: string;
  template?: Template;

  iForm?: IForm;
  datasets?: { [key: string]: any };
  fields: FormlyFieldConfig[];
  triageInitialModel?: any;
  triageModel?: any;
}

export interface SelectedProtocol {
  Id?: string;
  Name?: string;
  Code?: string;
}
export interface TriageState {
  subjectiveAssmtFormInfo: FormState;
  triageInitialModel?: any;
  triageModel?: any;
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export interface PlanOfCareState {
  planOfCareInitialModel?: any;
  planOfCareModel?: any;
  loaded: boolean;
  loading: boolean;
  error?: any;
}
export interface FollowUpState {
  followUpFormInfo: FormState;
  followUpInitialModel?: any;
  followUpModel?: any;
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialCutomState: State = {
  //triage: null,
  triage: {
    triageModel: fromCase?.initialState?.model?.triage,
    subjectiveAssmtFormInfo: null,
    loaded: false,
    loading: false,
  },
  planofCare: null,
  selectedProtocol: undefined,

  model: null,
  //notes: fromCase.initialState.notes,
  // protocols: fromCase.initialState.protocols,
  // calls: fromCase.initialState.calls,
  // casesearch: fromCase.initialState.casesearch,
  // events: fromCase.initialState.events,
  // status: fromCase.initialState.status,
  loaded: false,
  loading: false,
  saved: false,
  saving: false,
};

//#region reducers
export const reducer = createReducer(
  initialCutomState,
  on(CustomFormActions.loadFormFromCustomPage, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(CustomFormActions.loadFormFromCustomPageSuccess, (state, action) => ({
    ...state,
    triage: {
      ...state.triage,
      subjectiveAssmtFormInfo: {
        ...state.triage?.subjectiveAssmtFormInfo,
        template: action.template,
        iForm: action.form,
        datasets: action.datasets,
        fields: action.fields,
        // initialModel: action.data,
        // model: action?.data?.triage?.subjectiveAssessmt,
      },
    },
    initialModel: action.data,
    model: {
      ...state.model,
      ...action.data,
    },
    loaded: true,
    loading: false,
  })),
  // on(CustomFormActions.loadFormFromCustomPageSuccess, (state, action) => ({
  //   ...state,
  //   triage: {
  //     ...state.triage,
  //     subjectiveAssmtFormInfo: {
  //       ...state.triage?.subjectiveAssmtFormInfo,
  //       template: action.template,
  //       iForm: action.form,
  //       datasets: action.datasets,
  //       fields: action.fields,
  //       // initialModel: action.data,
  //       // model: action?.data?.triage?.subjectiveAssessmt,
  //     },
  //     triageModel: action?.data?.triage,
  //     // model: action?.data?.triage,
  //     // subjectiveAssmtFormInfo1:  action.page==="test" ? {} : null,
  //   },
  //   loaded: true,
  //   loading: false,
  // })),
  on(CustomFormActions.loadFormFromCustomPageFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.err,
  })),
  // on(CustomFormActions.updateCustomModel, (state, action) => ({
  //   ...state,
  //   model: {
  //     ...state.model,
  //     triage: action?.data?.triage ?? state.triage,
  //     subjectiveAssmtFormInfo: {
  //       ...state.triage?.subjectiveAssmtFormInfo,
  //       model: action?.data?.triage?.history ?? state.model.triage?.history,
  //     },
  //   },
  // }))
  on(CustomFormActions.updateCustomModel, (state, action) => ({
    ...state,
    model: {
      ...state.model,
      [action.key]: action.data,
    },
  }))
  // on(CustomFormActions.updateCustomModel, (state, action) => ({
  //   ...state,
  //   model: {
  //     ...state.model,
  //     ...action.data,
  //   },
  // }))

  // on(CustomFormActions.updateCustomModel, (state, action) => ({
  //   ...state,
  //   model: action.data,
  //   triage: action?.data?.triage ?? state.triage,
  //   subjectiveAssmtFormInfo: {
  //     ...state.triage?.subjectiveAssmtFormInfo,
  //     model:
  //       action?.data?.triage?.subjectiveAssessmt ??
  //       state.triage?.subjectiveAssmtFormInfo?.model,
  //   },
  // }))
);
//#endregion
on(CustomFormActions.exitPage, (state) => ({ ...initialCutomState }));
