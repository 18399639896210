export interface Lookup {
  id: string;
  name: string;
  src: string;
  type: any;
  sourceType: SourceType;
  props?: { [key: string]: any }
  updated: Date;
}

export enum SourceType {
  file,
  url
}
