import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { DashboardService } from '../services/dashboard.service';
import { DashboardActions, DashboardApiActions } from '../actions';
import { AccountApiService } from '@accounting/common';
import { RouterActions } from '@autobot/core';

@Injectable()
export class DashboardEffects {
  loadAccountsTree$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadAccountsTree),
      switchMap(() =>
        this.accountsApi.getMinimal().pipe(
          // tap((accountsTree) =>
          //   console.log('Fetched accounts minimal tree:', accountsTree)
          // ),
          map((accountsTree) =>
            DashboardApiActions.loadAccountsTreeSuccess({
              accounts: accountsTree,
            })
          ),
          catchError((error: unknown) =>
            of(DashboardApiActions.loadAccountsTreeFailure({ error }))
          )
        )
      )
    );
  });

  loadDraftCases$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadDraftCases),
      concatMap((action) => {
        const caseStatus = 'Draft';
        return this.service
          .loadCases(caseStatus)
          .pipe(
            // tap((draftCases) =>
            //   console.log('Fetched draft cases:', draftCases)
            // ),
            map((draftCases) =>
              DashboardApiActions.loadDraftCasesSuccess({ draftCases })
            )
          );
      }),
      catchError((error: unknown) =>
        of(DashboardApiActions.loadDraftCasesFailure({ error }))
      )
    );
  });

  loadCompletedCases$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadCompletedCases),
      concatMap((action) => {
        const caseStatus = 'Completed';
        return this.service
          .loadCases(caseStatus)
          .pipe(
            // tap((completedCases) =>
            //   console.log('Fetched completedCases cases:', completedCases)
            // ),
            map((completedCases) =>
              DashboardApiActions.loadCompletedCasesSuccess({ completedCases })
            )
          );
      }),
      catchError((error: unknown) =>
        of(DashboardApiActions.loadCompletedCasesFailure({ error }))
      )
    );
  });

  loadUnAssignedCases$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadUnAssignedCases),
      switchMap(() =>
        this.service.loadUnAssignedCases().pipe(
          // tap((unAssignedCases) =>
          //   console.log('Fetched unassigned cases:', unAssignedCases)
          // ),
          map((unAssignedCases) =>
            DashboardApiActions.loadUnAssignedCasesSuccess({
              unAssignedCases: unAssignedCases,
            })
          ),
          catchError((error: unknown) =>
            of(DashboardApiActions.loadUnAssignedCasesFailure({ error }))
          )
        )
      )
    );
  });

  loadUnAssignedFollowUps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadUnAssignedFollowUps),
      switchMap(() =>
        this.service.loadUnAssignedFollowUps().pipe(
          // tap((followups) =>
          //   console.log('Fetched unassigned followups:', followups)
          // ),
          map((followups) =>
            DashboardApiActions.loadUnAssignedFollowUpSuccess({
              unAssignedFollowUps: followups,
            })
          ),
          catchError((error: unknown) =>
            of(DashboardApiActions.loadUnAssignedFollowUpFailure({ error }))
          )
        )
      )
    );
  });

  loadAssignedFollowUps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadAssignedFollowUps),
      switchMap(() =>
        this.service.loadAssignedFollowUps().pipe(
          // tap((followups) =>
          //   console.log('Fetched assigned followups:', followups)
          // ),
          map((followups) =>
            DashboardApiActions.loadAssignedFollowUpSuccess({
              assignedFollowUps: followups,
            })
          ),
          catchError((error: unknown) =>
            of(DashboardApiActions.loadAssignedFollowUpFailure({ error }))
          )
        )
      )
    );
  });

  assignCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardApiActions.assignCaseAndNavigate),
      switchMap(({ data, navigateTo }) =>
        this.service.assignCase(data).pipe(
          exhaustMap((_) => [
            DashboardApiActions.assignCaseSuccess(),
            RouterActions.go({ to: navigateTo }),
          ]),
          catchError((error: unknown) =>
            of(DashboardApiActions.assignCaseFailure({ error }))
          )
        )
      )
    )
  );

  assigneFollowUp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardApiActions.assignFollowUp),
      mergeMap(({ data }) =>
        this.service.assignCase(data).pipe(
          mergeMap(() => [
            DashboardApiActions.assignFollowUpSuccess(),
            DashboardActions.loadUnAssignedFollowUps(),
          ]),
          catchError((error: unknown) =>
            of(DashboardApiActions.assignFollowUpFailure({ error }))
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private accountsApi: AccountApiService,
    private service: DashboardService
  ) { }
}
