import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManagerSearchComponent } from './manager-search.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {LetModule} from "@ngrx/component";

@NgModule({
  declarations: [ManagerSearchComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    LetModule
  ],
  exports: [ManagerSearchComponent],
})
export class ManagerSearchModule {}
