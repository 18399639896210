import { Injectable } from '@angular/core';
import { Area, IForm, Tab, Template } from '@forms/common';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import {
  exitEditor,
  loadCustomForm,
  loadForm,
  selectArea,
  selectTab,
  updateModel,
} from '../actions/editor.actions';
import {
  getCombinedMenu,
  getCurrentIForm,
  getFormAreas,
  getFormTabs,
  getFormlyFields,
  getIsViewOnlyMode,
  getModel,
  getNextForm,
  getPrevForm,
  getSelectedAreaIndex,
  getSelectedTabIndex,
  getTemplate,
  isFormLoaded,
  isFormLoading,
} from '../selectors';

@Injectable({ providedIn: 'root' })
export class EditorFacade {
  isLoaded$: Observable<boolean> = this.store.select(isFormLoaded);
  isLoading$: Observable<boolean> = this.store.select(isFormLoading);
  selectedTabIndex$: Observable<number> =
    this.store.select(getSelectedTabIndex);
  selectedAreaIndex$: Observable<number> =
    this.store.select(getSelectedAreaIndex);
  tabs$: Observable<Tab[]> = this.store.select(getFormTabs);
  model$: Observable<any> = this.store.select(getModel);
  fields$: Observable<FormlyFieldConfig[]> = this.store.select(getFormlyFields);
  template$: Observable<Template> = this.store.select(getTemplate);
  areas$: Observable<Area[]> = this.store.select(getFormAreas);
  nextForm$: Observable<IForm> = this.store.select(getNextForm);

  prevForm$: Observable<IForm> = this.store.select(getPrevForm);
  iForm$: Observable<IForm> = this.store.select(getCurrentIForm);
  combinedMenu$: Observable<any[]> = this.store.select(getCombinedMenu);
  isViewOnly$: Observable<boolean> = this.store.select(getIsViewOnlyMode);
  constructor(private store: Store) {}

  selectTab(index: number) {
    this.store.dispatch(selectTab({ selectedTabIndex: index }));
  }
  selectArea(index: number) {
    this.store.dispatch(selectArea({ selectedAreaIndex: index }));
  }

  loadForm(templateId: string): void {
    this.store.dispatch(loadForm({ templateId }));
  }
  loadCustomForm(key: string): void {
    this.store.dispatch(loadCustomForm({ key }));
  }
  updateModel(data: any) {
    this.store.dispatch(updateModel({ data }));
  }

  exitEditor() {
    this.store.dispatch(exitEditor());
  }
}
