import { IForm, Template } from '@forms/common';
import { createReducer, on } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormEditorActions } from '../actions';

export const editorFeatureKey = 'form-editor';

export interface State {
  newCase: boolean;
  caseId?: string;
  template?: Template;

  iForm?: IForm;
  datasets?: { [key: string]: any };
  fields: FormlyFieldConfig[];
  initialModel?: any;
  model?: any;
  loaded: boolean;
  loading: boolean;
  saved: boolean;
  saving: boolean;
  selectedTabIndex: number;
  selectedAreaIndex: number;
  lastSaved?: Date;
  lastUpdate: Date;
  error?: any;
}

export const initialState: State = {
  newCase: true,
  caseId: undefined,
  template: undefined,
  iForm: undefined,
  datasets: undefined,
  fields: [],
  initialModel: undefined,
  model: undefined,
  loaded: false,
  loading: false,
  saved: false,
  saving: false,
  selectedTabIndex: 0,
  selectedAreaIndex: 0,
  lastSaved: undefined,
  lastUpdate: new Date(),
  error: undefined,
};

export const reducer = createReducer(
  initialState,
  on(FormEditorActions.loadForm, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(FormEditorActions.loadFormSuccess, (state, action) => ({
    ...state,
    template: action.template,
    iForm: action.form,
    datasets: action.datasets,
    fields: action.fields,
    initialModel: action.data,
    model: action.data,
    loaded: true,
    loading: false,
  })),
  on(FormEditorActions.loadFormFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.err,
  })),
  on(FormEditorActions.loadCustomForm, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(FormEditorActions.loadCustomFormSuccess, (state, action) => ({
    ...state,
    iForm: action.form,
    loaded: true,
    loading: false,
  })),
  on(FormEditorActions.loadCustomFormSuccess, (state, action) => ({
    ...state,

    initialModel: action.data,
    model: action.data,
    iForm: action.form,
    loaded: true,
    loading: false,
  })),
  on(FormEditorActions.updateModel, (state, action) => ({
    ...state,
    model: action.data,
  })),
  on(FormEditorActions.selectTab, (state, action) => ({
    ...state,
    selectedTabIndex: action.selectedTabIndex,
    selectedAreaIndex: 0,
  })),
  on(FormEditorActions.selectArea, (state, action) => ({
    ...state,
    selectedAreaIndex: action.selectedAreaIndex,
  })),

  on(FormEditorActions.enableDisableSubMenu, (state, action) => ({
    ...state,
    iForm: {
      ...state.iForm,
      menuItems: action?.subMenu ?? state.iForm.menuItems,
    },
  })),
  on(FormEditorActions.exitEditor, (state) => ({ ...initialState }))
);
