import { Action, createReducer, on } from '@ngrx/store';

import { AccountEditorActions } from '../actions';
import { Account } from '@accounting/common';

export const editorFeatureKey = 'editor';

export interface State {
  saving: boolean;
  saved: boolean;
  initialAccount?: Account;
  currentAccount?: Account;
  isDraft?: boolean;
}

export const initialState: State = {
  saving: false,
  saved: false,
};

export const reducer = createReducer(
  initialState,
  on(AccountEditorActions.init, (state, action) => ({
    ...state,
    saved: true,
    initialAccount: action.account,
    currentAccount: action.account,
    isDraft: action.account.props?.['isDraft'] ?? false,
  })),
  on(AccountEditorActions.saveAccount, (state) => ({
    ...state,
    saving: true,
    saved: false,
  })),
  on(AccountEditorActions.saveAccountSuccess, (state) => ({
    ...state,
    saving: false,
    saved: true,
  }))
);
