import { createAction, props } from '@ngrx/store';
export enum AccountListActionTypes {
  SELECT_ACCOUNT = '[Account List Page] Select Account',
  REFRESH_LIST = '[Account List Page] Load Accounts',
}
export const refreshList = createAction(AccountListActionTypes.REFRESH_LIST);
export const selectAccount = createAction(
  AccountListActionTypes.SELECT_ACCOUNT,
  props<{ id: string }>()
);
