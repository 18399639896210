import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-dialog',
  template: `
    <h2 mat-dialog-title *ngIf="title">{{ title }}</h2>
    <mat-dialog-content class="mat-typography">
    <div>
      <mat-icon *ngIf="data.showIcon">info</mat-icon>
      <span [innerHTML]="message">{{ message }}</span> 
    </div>     
    </mat-dialog-content>
    <div mat-dialog-actions fxLayoutAlign="end center">
      <button
        mat-raised-button
        *ngIf="showCancelButton"
        [mat-dialog-close]="false"
        cdkFocusInitial
      >
        {{ cancelButtonText }}
      </button>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">
        {{ acceptButtonText }}
      </button>
    </div>
  `,
  styles: [``],
})
export class ConfirmDialogComponent {
  showCancelButton = true;
  showIcon = false;
  title!: string;
  message!: string;
  acceptButtonText!: string;
  cancelButtonText!: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.title !== undefined) this.title = data.title;
    if (data.message !== undefined) this.message = data.message;
    if (data.acceptButtonText !== undefined)
      this.acceptButtonText = data.acceptButtonText;
    if (data.cancelButtonText !== undefined)
      this.cancelButtonText = data.cancelButtonText;
    if (data.showCancelButton !== undefined)
      this.showCancelButton = data.showCancelButton;
    if (data.showIcon !== undefined) this.showIcon = data.showIcon;
  }
}
