import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-standard-mat-expansion-panel',
  template: `


    <mat-expansion-panel #panel class="mat-elevation-z0" hideToggle="true" [formlyAttributes]="field">
      <mat-expansion-panel-header>
        <mat-panel-title class="title">
          <mat-icon  style="margin-right: 14px;" >{{panel.expanded? 'remove' : 'add'}}</mat-icon>
          <a>{{ props.label }}</a>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container #fieldComponent></ng-container>
    </mat-expansion-panel>


  `,
  styles: [`
    .title {
      margin-left: 0.3rem !important;
      margin-top: 0.5rem!important;
      margin-bottom: 0.5rem!important;

      font-size: 24px;
      font-weight: 500;


    }
  `]
})
export class StandardExpansionPanelWrapperComponent extends FieldWrapper {
}



