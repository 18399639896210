import { Injectable } from '@angular/core';
import { Tab, subTemplate } from '@forms/common';
import { FollowUpStatus, FollowUpTab } from '@nfr/common';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import {
  getFollowUpDialogFields,
  getFollowUps,
  init,
  loadFollowUpForms,
  onFollowUpTabSelect,
  saveFollowUp,
  selectFollowUpFormlyTab,
  updateFollowUpModel,
} from '../actions/followup.actions';
import {
  getFollowUpDatasets,
  getFollowUpFormlyFields,
  getFollowUpFormlySelectedTabIndex,
  getFollowUpFormlyTabs,
  getFollowUpModel,
  getFollowUpPageTabs,
  getFormlyDilogFields,
  getIsViewOnlyForm,
  getPrevRecomendation,
  getSelectedFollowUpTab,
  getSelectedFollowUpTabIndex,
  isCompleteFollowUpButtonVisible,
  isFollowUpFormLoaded,
  isFollowUpFormLoading,
  selectCaseDetailModel,
  selectFollowUpTemplates,
} from '../selectors/';
import { template } from 'lodash';
@Injectable({ providedIn: 'root' })
export class FollowUpFacade {
  constructor(private store: Store) {}

  //#region  Variables
  isLoaded$: Observable<boolean> = this.store.select(isFollowUpFormLoaded);
  isLoading$: Observable<boolean> = this.store.select(isFollowUpFormLoading);

  caseDetailModel$: Observable<any> = this.store.select(selectCaseDetailModel);
  followUpDetailModel$: Observable<any> = this.store.select(getFollowUpModel);
  // caseDetailFormFields$: Observable<FormlyFieldConfig[]> = this.store.select(getCaseDetailFormlyFields);

  getFollowupTemplates$: Observable<subTemplate[]> = this.store.select(
    selectFollowUpTemplates
  );
  followUpTabs$: Observable<FollowUpTab[]> =
    this.store.select(getFollowUpPageTabs);
  selectedTab$: Observable<FollowUpTab> = this.store.select(
    getSelectedFollowUpTab
  );
  formFields$: Observable<FormlyFieldConfig[]> = this.store.select(
    getFollowUpFormlyFields
  );
  followUpFormlyTabs$: Observable<Tab[]> = this.store.select(
    getFollowUpFormlyTabs
  );
  dialogFelds$: Observable<FormlyFieldConfig[]> =
    this.store.select(getFormlyDilogFields);
  followUpFormlySelectedTabIndex$: Observable<number> = this.store.select(
    getFollowUpFormlySelectedTabIndex
  );
  // selectedAreaIndex$: Observable<number> = this.store.select(getFollowUpFormlySelectedAreaIndex);
  // combinedMenu$: Observable<any[]> = this.store.select(getFormlyCombinedMenu);
  // areas$: Observable<Area[]> = this.store.select(getFormlyAreas)
  getSelectedFollowUpTabIndex$: Observable<number> = this.store.select(
    getSelectedFollowUpTabIndex
  );
  isCompleteButtonVisible$: Observable<boolean> = this.store.select(
    isCompleteFollowUpButtonVisible
  );
  isViewOnly$: Observable<boolean> = this.store.select(getIsViewOnlyForm);
  getPrevRecomendation$: Observable<string> =
    this.store.select(getPrevRecomendation);
  getDispositionDataset$: Observable<any> =
    this.store.select(getFollowUpDatasets);

  //#endregion

  //#region  Methods

  //#endregion
  init() {
    this.store.dispatch(init());
  }
  // loadCaseDetail(templateKey: string): void {
  //   this.store.dispatch(loadCaseDetail({templateKey}));
  // }
  selectFollowUpTab(selectedTabIndex: number, data?: any): void {
    this.store.dispatch(onFollowUpTabSelect({ selectedTabIndex, data }));
  }
  //Load follow up by case id
  getFollowUps() {
    return this.store.dispatch(getFollowUps({}));
  }

  selectFormlyTab(index: number) {
    this.store.dispatch(selectFollowUpFormlyTab({ selectedTabIndex: index }));
  }
  ///Load Follow Up by followUp id
  getFollowUpById(id: string): void {
    //this.store.dispatch(loadCase({ id }));
  }
  saveFollowUp(status: FollowUpStatus = FollowUpStatus.Draft): void {
    this.store.dispatch(saveFollowUp({ status }));
  }

  loadFollowUpTemplates(ids: any): void {
    this.store.dispatch(loadFollowUpForms(ids));
  }
  updateFollowUpModel(model: any) {
    this.store.dispatch(updateFollowUpModel({ data: model }));
  }
  loadDialogfield(): void {
    this.store.dispatch(getFollowUpDialogFields());
  }
  clickMe() {
    alert('facade clicked');
  }
}
