import { createSelector } from '@ngrx/store';
import { selectAccountFeatureState } from './account.selectors';

export const selectEditorState = createSelector(
  selectAccountFeatureState,
  (state) => state.editor
);

export const editorSaving = createSelector(
  selectEditorState,
  (state) => state.saving
);
export const editorSaved = createSelector(
  selectEditorState,
  (state) => state.saved
);
