import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Template } from '@forms/common';




export enum TemplatesApiActionTypes {

  LOAD_TEMPLATES = '[Template/API] Load Templates',
  LOAD_TEMPLATES_SUCCESS = '[Template/API] Load Templates Success',
  LOAD_TEMPLATES_FAILURE = '[Template/API] Load Templates Failure',

  CREATE_TEMPLATE = '[Template/API] Add Template',
  CREATE_TEMPLATE_SUCCESS = '[Template/API] Add Template Success',
  CREATE_TEMPLATE_FAILURE = '[Template/API] Add Template Failure',

  CLONE_TEMPLATE = '[Template/API] Clone Template',
  CLONE_TEMPLATE_SUCCESS = '[Template/API] Clone Template Success',
  CLONE_TEMPLATE_FAILURE = '[Template/API] Clone Template Failure',


  CREATE_TEMPLATE_DRAFT ='[Template/API] Create Template Draft',
  CREATE_TEMPLATE_DRAFT_SUCCESS ='[Template/API] Create Template Draft Success',
  ADD_TEMPLATES_SUCCESS = '[Template/API] Add Templates Success',

  UPDATE_TEMPLATE = '[Template/API] Update Template',
  UPDATE_TEMPLATE_SUCCESS = '[Template/API] Update Template Success',
  UPDATE_TEMPLATE_FAILURE = '[Template/API] Update Template Failure',

  DELETE_TEMPLATE = '[Template/API] Delete Template',
  DELETE_TEMPLATE_SUCCESS = '[Template/API] Delete Template Success',
  DELETE_TEMPLATE_FAILURE = '[Template/API] Delete Template Failure',
}



export const loadTemplates = createAction(TemplatesApiActionTypes.LOAD_TEMPLATES)
export const loadTemplatesSuccess = createAction(TemplatesApiActionTypes.LOAD_TEMPLATES_SUCCESS,props<{ templates: Template[] }>());
export const loadTemplatesFailure = createAction(TemplatesApiActionTypes.LOAD_TEMPLATES_FAILURE,props<{err: any}>());


export const createTemplate = createAction(TemplatesApiActionTypes.CREATE_TEMPLATE, props<{template: Template}>());
export const createTemplateSuccess = createAction(TemplatesApiActionTypes.CREATE_TEMPLATE_SUCCESS,props<{ template: Template }>());
export const createTemplateFailure = createAction(TemplatesApiActionTypes.CREATE_TEMPLATE_FAILURE,props<{err:any}>());


export const cloneTemplate = createAction(TemplatesApiActionTypes.CLONE_TEMPLATE, props<{template: Template}>());
export const cloneTemplateSuccess = createAction(TemplatesApiActionTypes.CLONE_TEMPLATE_SUCCESS,props<{ template: Template }>());
export const cloneTemplateFailure = createAction(TemplatesApiActionTypes.CLONE_TEMPLATE_FAILURE,props<{err:any}>());


export const createTemplateDraft = createAction(TemplatesApiActionTypes.CREATE_TEMPLATE_DRAFT,props<{ id: string }>());
export const createTemplateDraftSuccess = createAction(TemplatesApiActionTypes.CREATE_TEMPLATE_SUCCESS,props<{ template: Template }>());

export const addTemplatesSuccess = createAction(TemplatesApiActionTypes.ADD_TEMPLATES_SUCCESS, props<{ templates: Template[] }>());

export const updateTemplate = createAction(TemplatesApiActionTypes.UPDATE_TEMPLATE, props<{template: Template}>());
export const updateTemplateSuccess = createAction(TemplatesApiActionTypes.UPDATE_TEMPLATE_SUCCESS, props<{ template: Update<Template> }>());
export const updateTemplateFailure = createAction(TemplatesApiActionTypes.UPDATE_TEMPLATE_FAILURE, props<{err: any}>());


export const deleteTemplate = createAction(TemplatesApiActionTypes.DELETE_TEMPLATE, props<{ id: string}>());
export const deleteTemplateSuccess = createAction(TemplatesApiActionTypes.DELETE_TEMPLATE_SUCCESS, props<{ id: string }>());
export const deleteTemplateFailure = createAction(TemplatesApiActionTypes.DELETE_TEMPLATE_FAILURE, props<{err: any}>());


export const updateTemplates = createAction('[Template/API] Update Templates', props<{ templates: Update<Template>[] }>());
export const deleteTemplates = createAction('[Template/API] Delete Templates',props<{ ids: string[] }>());
export const clearTemplates = createAction('[Template/API] Clear Templates');

export const upsertTemplate = createAction('[Template/API] Upsert Template',props<{ template: Template }>());
export const upsertTemplates = createAction('[Template/API] Upsert Templates',props<{ templates: Template[] }>());
