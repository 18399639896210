import {Component, Input} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'configurable-field-input',
  template: `

   <mat-form-field appearance="fill" class="w-100"
  >
     <mat-label>{{props.label}}</mat-label>
     <input matInput [formControl]="formControl" [formlyAttributes]="field"  [readonly]="props.readonly" >
   </mat-form-field>
 `,
  styles:[
    `.selected {
      outline: 4px dashed darkorange;
      background: transparent;
    }`]
})
export class ConfigurableFormlyInput extends FieldType<FieldTypeConfig> {

}
