import {createReducer, createSelector, on} from '@ngrx/store';
import {
  closeSidenav,
  hideHeaderNav,
  openSidenav,
  showHeaderNav,
  toggleHeaderNav,
  toggleSidenav
} from "./layout.actions";




export const layoutFeatureKey = 'layout';

export interface State {
  showSidenav: boolean;
  showHeaderNav: boolean;
}

const initialState: State = {
  showSidenav: true,
  showHeaderNav: true


};

export const reducer = createReducer(
  initialState,
  // Even thought the `state` is unused, it helps infer the return type
  on(toggleSidenav, state => ({...state,showSidenav: !state.showSidenav})),
  on(closeSidenav, state => ({...state, showSidenav: false })),
  on(openSidenav, state => ({...state, showSidenav: true })),
  on(showHeaderNav, state => ({...state, showHeaderNav: true})),
  on(hideHeaderNav, state => ({...state, showHeaderNav: false})),
  on(toggleHeaderNav, state => ({...state, showHeaderNav: !state.showHeaderNav}))
);

export const selectShowSidenav = (state: State) => state.showSidenav;
export const selectShowHeaderNav = (state: State) => state.showHeaderNav;


