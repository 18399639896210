import { Template } from "@forms/common";
import {createAction, props} from "@ngrx/store";


export enum TemplateCreatorPageActions {
  SAVE_TEMPLATE_CLICKED = '[Template/Creator Page] Save Template Clicked',
  EXPORT_TEMPLATE_CLICKED = '[Template/Creator Page] Export Template Clicked',
  BATCH_IMPORT_CLICKED = '[Template/Creator Page] Batch Import Clicked'
}
export const saveTemplateClicked = createAction(TemplateCreatorPageActions.SAVE_TEMPLATE_CLICKED, props<{template: Template}>());
export const exportTemplateClicked = createAction(TemplateCreatorPageActions.EXPORT_TEMPLATE_CLICKED, props<{template: Template}>());
export const batchImportClicked = createAction(TemplateCreatorPageActions.BATCH_IMPORT_CLICKED)
