import * as fromCase from './case.reducer';
import * as fromCustomForm from './custom-form.reducer';
import * as fromDesktop from './desktop.reducer';
import * as fromEditor from './editor.reducer';
import * as followUp from './followup.reducer';
import * as fromProtocol from './protocol.reducer';
import * as fromLookUp from './lookup.reducer';

export interface SessionState {
  lookUp: fromLookUp.State;
  case: fromCase.State;
  editor: fromEditor.State;
  desktop: fromDesktop.State;
  protocol: fromProtocol.State;
  customFormState: fromCustomForm.State;
  followUpState: followUp.State;
}

export const sessionFeatureReducersMap = {
  case: fromCase.reducer,
  editor: fromEditor.reducer,
  desktop: fromDesktop.reducer,
  lookUp: fromLookUp.reducer,
  protocol: fromProtocol.reducer,
  customFormState: fromCustomForm.reducer,
  followUpState: followUp.reducer,
};
