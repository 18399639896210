/* tslint:disable:typedef */

import {FormArray} from "@angular/forms";
import * as moment from 'moment';
export interface SelectionItem {
  getElement: () => Element;
  isActive: () => boolean;
  setActive: () => void;
  setInactive: () => void;
}


/**
 * Moves an item in a FormArray to another position.
 * @param formArray FormArray instance in which to move the item.
 * @param fromIndex Starting index of the item.
 * @param toIndex Index to which he item should be moved.
 */
export function moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
  const dir = toIndex > fromIndex ? 1 : -1;

  const from = fromIndex;
  const to = toIndex;

  const temp = formArray.at(from);
  for (let i = from; i * dir < to * dir; i = i + dir) {
    const current = formArray.at(i + dir);
    formArray.setControl(i, current);
  }
  formArray.setControl(to, temp);
}



export function allowWhen(decider$: Observable<boolean>) {
  return function<T>(source$: Observable<T>): Observable<T> {
    return source$.pipe(
      withLatestFrom(decider$),
      filter(([value, decider]) => decider),
      map(([value]) => value),
    )
  }
}
export class DaraUtils {



  public static getDaysArray(start:Date, end:Date) {
    const result = [];
    // tslint:disable-next-line:no-shadowed-variable
    for (const arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)){
      arr.push(new Date(dt));
      result.push(new Date(dt));
    }
    return result;
  }

  /**
   * Search in string
   *
   * @param value
   * @param searchText
   * @returns {any}
   */
  public static searchInString(value:string, searchText:string): any {
    return value.toLowerCase().includes(searchText);
  }

  /**
   * Generate a unique GUID
   *
   * @returns {string}
   */
  public static generateGUID(): string {
    function S4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  }

  /**
   * Toggle in array
   *
   * @param item
   * @param array
   */
  public static toggleInArray(item:any, array:any[]): void {
    if (array.indexOf(item) === -1) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  /**
   * Handleize
   *
   * @param text
   * @returns {string}
   */
  public static handleize(text:string): string {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from landing of text
      .replace(/-+$/, '');            // Trim - from end of text
  }
}
export function getPropertiesByKey(object:any, key:string) {

  const paths: string[] = [
  ];

  iterate(
    object,
    '');

  return paths;

  /**
   * Single object iteration. Accumulates to an outer 'paths' array.
   */
  function iterate(object:any, path:string) {
    let chainedPath;

    for (const property in object) {
      if (object.hasOwnProperty(property)) {

        chainedPath =
          path.length > 0 ?
            path + '.' + property :
            path + property;

        if (typeof object[property] == 'object') {

          iterate(
            object[property],
            chainedPath);
        } else if (
          property === key ||
          key.length === 0) {

          paths.push(
            chainedPath);
        }
      }
    }

    return paths;
  }
}

export  class Guid {
  // tslint:disable-next-line:typedef
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8 );
      return v.toString(16);
    });
  }
}

// tslint:disable-next-line:max-line-length
export const randomColor = () => 'rgb(' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ')';

export function getDirtyValues(form: any) {
  const dirtyValues = {};

  Object.keys(form.controls)
    .forEach(key => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] =  getDirtyValues(currentControl);
        }
        else {
          dirtyValues[key] = currentControl.value;
        }
      }
    });

  return dirtyValues;
}

function isDebug(){
  return true;
}



export class DebugData {
  constructor(desc: string, value: any) {
    this.desc = desc;
    this.value = value;
  }

  desc: string;
  value: any;
}

export function logGroup(groupName: string, data: DebugData[]){

  if (isDebug()){
    console.group(groupName);
    data.forEach((d) => {
      console.log(d.desc + ':' , d.value);
    });
    console.groupEnd();
  }

}

import * as _ from 'lodash';
import {Observable} from "rxjs";
import {filter, map, withLatestFrom} from "rxjs/operators";

const cssRule =
  'color: rgb(249, 162, 34);' +
  'font-size: 60px;' +
  'font-weight: bold;' +
  'text-shadow: 1px 1px 5px rgb(249, 162, 34);' +
  'filter: dropshadow(color=rgb(249, 162, 34), offx=1, offy=1);';

export function shouldLog(){
  return true;
}

export function CustLog(message: string, data: any){
  if (shouldLog()){
    const val = JSON.parse(JSON.stringify(data));
    console.log(message, data);
  }

}

/**
   * https://medium.com/@srajas02/merge-two-objects-deeply-nested-in-javascript-8e8515b4f8d3
   * This function will accept the two objects as arguments and return the object of deeply
   * merged with nested properties.
   * @param {object} targetObject objects containing the properties to be merged with source.
   * @param {object} sourceObject objects containing the properties you want to apply.
   * @return {object} return the deeply merged objects
   */
export function deepMergeObject(targetObject: any, sourceObject: any) {
  let momentLib = moment;
  let replacer = function (key, value) {
    try {
      if (key == 'dob'||key=='dateManagerNotified') {
        if (value instanceof Date) {
          const formatedDate = value;
          formatedDate.setHours(0, 0, 0, 0);
          return momentLib(formatedDate).format('YYYY-MM-DDTHH:MM:SS');
        } else if (value && value != '') {
          const formatedDate = new Date(value);
          formatedDate.setHours(0, 0, 0, 0);
          return momentLib(formatedDate).format('YYYY-MM-DDTHH:MM:SS');
        }
      }
      return value;
    } catch (e) {
      return value;
    }
  };
    // clone the source and target objects to avoid the mutation
    const copyTargetObject = JSON.parse(JSON.stringify(targetObject,replacer));
    const copySourceObject = JSON.parse(JSON.stringify(sourceObject,replacer));
  // Iterating through all the keys of source object
  Object.keys(copySourceObject).forEach((key) => {
    if (
      typeof copySourceObject[key] === 'object' &&
      !Array.isArray(copySourceObject[key])
    ) {
      // If property has nested object, call the function recursively
      copyTargetObject[key] = this.deepMergeObject(
        copyTargetObject[key],
        copySourceObject[key]
      );
    } else {
      // else merge the object source to target
      copyTargetObject[key] = copySourceObject[key];
    }
  });

  return copyTargetObject;
}


