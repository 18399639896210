import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule} from "@angular/material/form-field";
import { MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { ReactiveFormsModule} from "@angular/forms";
import { MatTableModule} from "@angular/material/table";
import { MatPaginatorModule} from "@angular/material/paginator";
import { MatInputModule} from "@angular/material/input";
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon'
import { SimilarCaseSearchComponent } from './similar-case-search.component';

@NgModule({
  declarations: [SimilarCaseSearchComponent],
  imports: [CommonModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule, MatInputModule, MatRadioModule],
  exports: [SimilarCaseSearchComponent]
})
export class SimilarCaseSearchModule {}
