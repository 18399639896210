import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-advanced-panel',
  template: `
    <mat-expansion-panel  class="mat-elevation-z0" hideToggle="true" [expanded]="true">
      <mat-expansion-panel-header>
        <div class="w-100">
          <mat-panel-title>
            <span class="h5">{{ props.label }}</span>
          </mat-panel-title>

        </div>

      </mat-expansion-panel-header>
      <div fxLayout="row" class="w-100">
        <div fxFlex="25" class="p-1" style="border-right: 1px solid lightgray">
          {{props.description}}
        </div>
        <div fxFlex="75" fxFill class="pl-4">
          <ng-container #fieldComponent></ng-container>
        </div>
      </div>

    </mat-expansion-panel>
  `,
})
export class AdvancedPanelWrapperComponent extends FieldWrapper {
}
