import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-selected',
  template: `
    <div  [class.selected]="props?.['selectedRow']">
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
  styles:[
    `.selected {
      outline: 4px dashed darkorange;
      background: transparent;
    }`
  ]
})
export class FormlySelectedWrapper extends FieldWrapper {



}
