import { Component, Input, Output } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'formly-multi-checkbox-list',
  template: `
    <div
      class="bool-choice-container dbgOuter"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div name="multichckboxlist" ngDefaultControl class="dbgCont">
        <mat-checkbox
          class="dbgCheck"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [formControlName]="opt.value"
          *ngFor="
            let opt of props.options | formlySelectOptions : field | async;
            let i = index
          "
          [value]="opt.value"
          >{{ opt.label }}
        </mat-checkbox>
      </div>
    </div>
  `,
  styles: [
    `
      .bool-choice-container {
        margin: 1%;
      }
      .selected {
        outline: 4px dashed darkorange;
        background: transparent;
      }
      .dbgOuter {
        border: solid 1px #888;
        border-radius: 4px;
        padding: 3px 8px 0px 14px;
        width: auto;
        margin: 0 auto;
        font-size: 10.5pt;
      }
      .dbgCont {
        display: inline-block;
        height: auto;
        margin-left: 6px;
      }
      /* Base for label styling */
      .dbgCheck:not(:checked),
      .dbgCheck:checked {
        /* position: absolute;
            left: -9999px; */
      }
      .dbgCheck:not(:checked) + label,
      .dbgCheck:checked + label {
        display: inline-block;
        position: relative;
        padding-left: 25px;
        cursor: pointer;
      }

      /* checkbox aspect */
      .dbgCheck:not(:checked) + label:before,
      .dbgCheck:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 1px;
        width: 17px;
        height: 17px;
        border: 1px solid #aaa;
        background: #f8f8f8;
        border-radius: 3px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
      }
      /* checkmark aspect */
      .dbgCheck:not(:checked) + label:after,
      .dbgCheck:checked + label:after {
        content: '✔';
        position: absolute;
        top: 2px;
        left: 5px;
        font-size: 14px;
        color: #09ad7e;
        transition: all 0.2s;
      }
      /* checked mark aspect changes */
      .dbgCheck:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
      }
      .dbgCheck:checked + label:after {
        opacity: 1;
        transform: scale(1);
      }
      /* disabled checkbox */
      .dbgCheck:disabled:not(:checked) + label:before,
      .dbgCheck:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
      .dbgCheck:disabled:checked + label:after {
        color: #999;
      }
      .dbgCheck:disabled + label {
        color: #aaa;
      }
      /* accessibility */
      .dbgCheck:checked:focus + label:before,
      .dbgCheck:not(:checked):focus + label:before {
        border: 1px dotted blue;
      }

      .dbgCheck {
        display: inline-block;
        width: 150px;
        height: 24px;
        margin: 0.5em;
      }
    `,
  ],
})
export class MatCheckBoxListType extends FieldType<FieldTypeConfig> {
  @Output() seleted: any[];
  constructor(public dialog: MatDialog) {
    super();
  }

  loadSelectedProtocol(selected: any) {
    let cc = this.props.options;
  }

  openDialog() {
    this.dialog.open(MatCheckBoxListType, {
      data: {
        animal: ' ',
      },
    });
  }

  addMore() {
    this.openDialog();
  }
}

export const multiCheckboxChoice = {
  name: 'multi-checkbox-list',
  component: MatCheckBoxListType,
};
