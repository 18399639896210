import { Action, createReducer, on } from '@ngrx/store';

import {TemplateEditorActions} from "../actions";
import {Template} from "@forms/common";


export const editorFeatureKey = 'editor';

export interface State {
  saving: boolean;
  saved: boolean;
  initialTemplate?: Template;
  currentTemplate?: Template;
  isDraft?: boolean
}

export const initialState: State = {
  saving: false,
  saved: false
};

export const reducer = createReducer(
  initialState,
  on(TemplateEditorActions.init, (state,action) => ({
    ...state,
    saved: true,
    initialTemplate: action.template,
    currentTemplate: action.template,
    isDraft: action.template.props?.isDraft ?? false
  })),
  on(TemplateEditorActions.saveTemplate, (state) => ({...state, saving: true, saved: false})),
  on(TemplateEditorActions.saveTemplateSuccess, (state) => ({...state, saving: false, saved: true})),
);
