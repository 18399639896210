import * as fromEditor from '../reducers/account-editor.reducer';
import * as fromAccounts from '../reducers/account-entity.reducer';
export interface AccountState {
  accounts: fromAccounts.State;
  editor: fromEditor.State;
}

export const accountsFeatureReducersMap = {
  accounts: fromAccounts.reducer,
  editor: fromEditor.reducer,
};
