import { IForm, Template } from '@forms/common';
import { createAction, props } from '@ngrx/store';

import { FormlyFieldConfig } from '@ngx-formly/core';

export enum EditorActionTypes {
  INIT = '[Editor] Init',
  LOAD_FORM = '[Editor] Load Form',
  LOAD_FORM_SUCCESS = '[Editor] Load Form Success',
  LOAD_FORM_FAILURE = '[Editor] Load Form Failure',
  LOAD_CUSTOM_FORM = '[Editor] Load Custom Form',
  LOAD_CUSTOM_FORM_SUCCESS = '[Editor] Load Custom Form Success',
  LOAD_CUSTOM_FORM_FAILURE = '[Editor] Load Custom Form Failure',
  UPDATE_MODEL = '[Editor] Update Model',
  UPDATE_FORM_STATE = '[Editor] Update Form State',

  SELECT_FORM = '[Editor] Select Form',
  EXIT_EDITOR = '[Editor] Exit Editor',
  SELECT_TAB = '[Editor] Select Tab',
  SELECT_AREA = '[Editor] Select Area',

  ENABLE_DISABLE_SUBMENU = '[Editor] Enable Disable Sub menu',
}

export const init = createAction(EditorActionTypes.INIT);
export const loadForm = createAction(
  EditorActionTypes.LOAD_FORM,
  props<{ templateId: string }>()
);
export const loadFormSuccess = createAction(
  EditorActionTypes.LOAD_FORM_SUCCESS,
  props<{
    template: Template;
    form: IForm;
    datasets: any;
    fields: FormlyFieldConfig[];
    data?: any;
  }>()
);
export const loadFormFailure = createAction(
  EditorActionTypes.LOAD_FORM_FAILURE,
  props<{ err: any }>()
);

export const loadCustomForm = createAction(
  EditorActionTypes.LOAD_CUSTOM_FORM,
  props<{ key: string }>()
);
export const loadCustomFormSuccess = createAction(
  EditorActionTypes.LOAD_CUSTOM_FORM_SUCCESS,
  props<{
    form: IForm;
    datasets: any;
    data?: any;
  }>()
);

export const loadCustomFormFailure = createAction(
  EditorActionTypes.LOAD_CUSTOM_FORM_FAILURE,
  props<{ err: any }>()
);

export const selectTab = createAction(
  EditorActionTypes.SELECT_TAB,
  props<{ selectedTabIndex: number }>()
);
export const selectArea = createAction(
  EditorActionTypes.SELECT_AREA,
  props<{ selectedAreaIndex: number }>()
);
export const updateModel = createAction(
  EditorActionTypes.UPDATE_MODEL,
  props<{ data: any }>()
);
export const updateFormState = createAction(
  EditorActionTypes.UPDATE_FORM_STATE,
  props<{ formState: any }>()
);
export const exitEditor = createAction(EditorActionTypes.EXIT_EDITOR);

export const selectForm = createAction(
  EditorActionTypes.SELECT_FORM,
  props<{ templateId: string }>()
);
export const enableDisableSubMenu = createAction(
  EditorActionTypes.ENABLE_DISABLE_SUBMENU,
  props<{
    form: IForm;
    data?: any;
    subMenu?: any;
  }>()
);
