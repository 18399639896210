import { Component, OnInit } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable, debounceTime, distinctUntilChanged, map } from 'rxjs';
// import { SelectedDisposition } from '@nfr/common';
// import { selectSelectedDisposition } from '@nfr/data/session';

@Component({
  selector: 'provider-search',
  template: `
    <auto-provider-search
      [caseData]="caseData"
      [formGroup]="formGroup"
      ></auto-provider-search>
  `,
  styles: [
    `
      .error {
        font-size: 75%;
        position: absolute;
        margin-top: 8px;
      }
    `,
  ],
})
export class FormlyAutoProviderSearchComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit {
  case$: any;
  formGroup: FormGroup;
  //selectedDisposition$: Observable<SelectedDisposition>;
  caseData: any;
  SelectedDisposition = {
    SelfCare: 'SelfCare',
    TeleMedicine: 'TeleMedicine',
    Other: 'Other'
  }
  CaseType = {
    Undefined: 'Undefined',
    NurseTriage: 'NurseTriage',
    FNOL: 'FNOL',
    FNOLReportOnly: 'FNOLReportOnly',
    Emergency: 'Emergency',
    Death: 'Death',
  }

  constructor(private store: Store) {
    super();
    this.case$ = this.store.pipe(map((data: any) => data?.session?.case));
    //this.selectedDisposition$ = this.store.pipe(select(selectSelectedDisposition));
  }

  ngOnInit() {
    this.case$.subscribe((data) => {
      this.caseData = data;
    });
    this.formGroup = this.createFormGroup();
  }

  createFormGroup() {
    const fb = new FormBuilder();

    let providerForm = fb.group({
      provider: fb.group({
        providerType: [''],
        providerRanking: [''],
        hoursOperations: [''],
        facilityName: [''],
        providerName: [''],
        providerAddress: ['',],
        providerPhone: ['', [Validators.minLength(10)]],
        providerFax: ['', [Validators.minLength(10)]],
        otherReferrals: [''],
        providerSelected: [''],
        whyNot: new FormControl({ value: '', disabled: true }),
        otherReason: new FormControl({ value: '', disabled: true }),
        agreementInformation: [''],
        medicalProvider: [''],
        startTime: [new Date()],
      }),
    });

    providerForm.patchValue(this.formControl.value);
    providerForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(
      (data) => {
        this.formControl.setValue(data);
      }
    );

    return providerForm;
  }

  ngAfterContentChecked() {
    if (this.form.disabled) this.formGroup.disable({ onlySelf: true, emitEvent: false })
  }
}
