import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'confirm-ov-disposition',
  template: `
  <button mat-icon-button class="close-button" mat-dialog-close="false">
    <mat-icon class="close-icon" color="black">close</mat-icon>
  </button>
  <h2 mat-dialog-title fxLayoutAlign="center center">Attention</h2>
  <mat-dialog-content class="mat-typography">
    <h3><b>To continue, please enter your reasons to select Office Visit instead of Self-Care or Telemedicine (when available).</b></h3>
  </mat-dialog-content>
    <div fxLayout="column" class="p-3" fxLayoutGap="2%" >
    <mat-card-content fxFlex="80">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px" >
              <mat-form-field  fxFlex="100">
              <mat-label>Select Reason</mat-label>
              <mat-select #reason formControlName="reason" class="minor-gap">
                  <mat-option value="Nurse judgement as explained in the 'Nursing Conclusions'">Nurse judgement as explained in the “Nursing Conclusions”</mat-option>
                  <mat-option value="Escalation criteria met">Escalation criteria met</mat-option>
                  <mat-option value="The patient needs a drug test or tetanus shot">The patient needs a drug test or tetanus shot</mat-option>
                  <mat-option *ngIf="isTelemedicineDispositionAvailable == true" value="Injury not supported by telemedicine vendor">Injury not supported by telemedicine vendor</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-card-content>    
    </div>
  <mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="end" style="text-align:center;width:100%">
    <button mat-raised-button color="primary" mat-dialog-close="false">
    Cancel
    </button>
    <button mat-raised-button color="primary" [disabled]="!(reason.value?.length>0)"  [mat-dialog-close]="reason.value">
    OK
    </button>
  </div>
</mat-dialog-actions>

  `,
  styles: [`

.close-button{
  float: right;
  top:-10px;
  right:-10px;
}

::ng-deep .icon-outside .close-button{
  float: right;
  top:-52px;
  right:-52px;
}

/* ::ng-deep .icon-outside .mat-dialog-container {
 overflow: unset
} */

  `]
})
export class ConfirmOVDispositionComponent {
  form: FormGroup;
  isTelemedicineDispositionAvailable: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.isTelemedicineDispositionAvailable !== undefined) this.isTelemedicineDispositionAvailable = data.isTelemedicineDispositionAvailable;

  }
}
