import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDashboard from '../reducers/dashboard.reducer';
import { DashboardState } from '../reducers/dashboard.reducer';
import * as lodash_ from 'lodash';
import * as moment_ from 'moment';
import { DaraUtils, formatCreatedBy } from '../services/helpers';
import { DashboardScope } from '@nfr/common';
import { selectIsTestUser, selectUserRoles } from '@autobot/authentication';

const _ = lodash_;
const moment = moment_;

export const selectDashboardState =
  createFeatureSelector<fromDashboard.DashboardState>(
    fromDashboard.dashboardFeatureKey
  );

export const isLoading = (state: DashboardState) => state.loading;

export const selectDashboardData = createSelector(
  selectDashboardState,
  (dash) => dash.data
);

export const selectDashboard = createSelector(
  selectDashboardState,
  (dash) => dash.dashboard
);

export const getDraftCases = createSelector(
  selectDashboardState,
  (state) => state.draftCases
);

export const getCompletedCases = createSelector(
  selectDashboardState,
  (state) => state.completedCases
);

export const getUnAssignedCases = createSelector(
  selectDashboardState,
  (state) => state.unAssignedCases
);

export const getUnAssignedFollowUps = createSelector(
  selectDashboardState,
  (state) => state.unAssignedFollowUps
);

export const getAssignedFollowUps = createSelector(
  selectDashboardState,
  (state) => state.assignedFollowUps
);

export const getAccounts = createSelector(
  selectDashboardState,
  (state) => state.accounts
);

export const getAccountsByFeatures = (filterKeys: string[]) =>
  createSelector(
    getAccounts,
    selectIsTestUser,
    (accounts: any[], isTestUser) => {
      if (filterKeys == undefined) {
        filterKeys = [];
      }
      var filteredAccounts = accounts.filter((account) =>
        filterKeys.some((feature) => account.featureNames?.includes(feature))
      );

      const testAccountFeatureName = 'TestAccount';
      return filteredAccounts.filter((account) =>
        isTestUser
          ? account.featureNames?.includes(testAccountFeatureName)
          : !account.featureNames?.includes(testAccountFeatureName)
      );
    }
  );

export const isDashboardLoaded = createSelector(
  selectDashboardState,
  (state) => state.loaded
);

export const getDashboardScope = createSelector(
  selectDashboardState,
  (dash) => dash.tableScope
);

export const getScopeDates = createSelector(
  getDashboardScope,
  (dash: DashboardScope) => {
    let result;
    let start;
    let end;
    switch (dash) {
      case DashboardScope.today:
        start = moment(new Date()).subtract(1, 'day');
        end = new Date();
        result = { start, end };

        break;
      case DashboardScope.week:
        start = moment(new Date()).subtract(7, 'day');
        end = new Date();
        result = { start, end };

        break;
      case DashboardScope.month:
        start = moment(new Date()).subtract(30, 'day');
        end = new Date();
        result = { start, end };
        break;
      case DashboardScope.all:
        start = moment(new Date()).subtract(999, 'day');
        end = new Date();
        result = { start, end };
        break;
    }

    return result;
  }
);

// FIRST: Filter Global by Date Range
// Currently 30days
export const getDashboardDataFilteredByScope = createSelector(
  selectDashboardData,
  getScopeDates,
  (sessions, dates) => {
    const { start, end } = dates;
    return sessions.filter((e) =>
      moment(new Date(e.date)).isBetween(start, end)
    );
  }
);

export const getDraftCasesTableData = createSelector(
  getDraftCases,
  getAccounts,
  selectUserRoles,
  (claims, accounts, userRoles) => {
    let item = claims.map((claim) => {
      const account = accounts.filter(
        (account) => account.id === claim.accountId
      )[0];

      const createdBy = claim.createdBy?.trim().toLowerCase();
      // Compare the standardized value to 'system system'
      const originatedBy = createdBy === 'system system' ? 'System' : claim.createdBy;

      return {
        ...claim,
        name: `${claim.claimant.firstName}  ${claim.claimant.lastName}`,
        account,
        parent: account?.hierarchy[0],
        accountName: `${account?.hierarchy[0]} - ${account?.name}`,
        caseRef: claim.caseRef,
        caseOrigin: claim.caseOrigin,
        caseType: claim.caseType,
        bodyPartInjured: claim.bodyPartInjured,
        createdBy: originatedBy,
        dateCreated: claim.created,
        dateCompleted: claim.dateCompleted,
        followupDueDate: claim.followupDueDate
      };
    });
    if (userRoles && userRoles.includes('TestSupervisor')) {
      return item.filter(
        (a) =>
          a.parent != undefined &&
          a.account.featureNames.includes('TestAccount')
      );
    } else {
      return item.filter((a) => a.parent != undefined);
    }
  }
);

export const getCompletedCasesTableData = createSelector(
  getCompletedCases,
  getAccounts,
  (claims, accounts) => {
    let item = claims.map((claim) => {
      const account = accounts.filter(
        (account) => account.id === claim.accountId
      )[0];

      const createdBy = claim.createdBy?.trim().toLowerCase();
      // Compare the standardized value to 'system system'
      const originatedBy = createdBy === 'system system' ? 'System' : claim.createdBy;

      return {
        ...claim,
        name: `${claim.claimant.firstName}  ${claim.claimant.lastName}`,
        account,
        parent: account?.hierarchy[0],
        accountName: `${account?.hierarchy[0]} - ${account?.name}`,
        caseRef: claim.caseRef,
        caseOrigin: claim.caseOrigin,
        caseType: claim.caseType,
        createdBy: originatedBy,
        dateCreated: claim.created,
        dateCompleted: claim.dateCompleted,
        lastFollowupDate: claim.lastFollowupDate
      };
    });
    return item.filter((a) => a.parent != undefined);
  }
);

export const getUnAssignedCasesTableData = createSelector(
  getUnAssignedCases,
  getAccounts,
  (claims, accounts) => {
    let item = claims.map((claim) => {
      const account = accounts.filter(
        (account) => account.id === claim.accountId
      )[0];

      return {
        ...claim,
        name: claim.patientName,
        account,
        parent: account?.hierarchy[0],
        accountName: `${account?.hierarchy[0]} - ${account?.name}`,
        createdBy: formatCreatedBy(claim.originatorFirstName, claim.originatorLastName),
        caseRef: claim.caseRef,
        caseOrigin: claim.caseOrigin,
        created: claim.created,
        assignDate: claim.assignDate,
        ageHours: claim.ageHours,
        mobilePhone: claim.mobilePhone,
        workPhone: claim.workPhone,
        supervisorName: claim.supervisorName,
        accidentDescription: claim.accidentDescription,
        bodyPartInjured: claim.bodyPartInjured,
        totalRecords: claim.totalRecords,
      };
    });
    return item.filter((a) => a.parent != undefined);
  }
);

export const selectAssignCaseSuccess = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.assignCaseSuccess
);

export const getUnAssignedFollowUpsTableData = createSelector(
  getUnAssignedFollowUps,
  getAccounts,
  (claims, accounts) => {
    let item = claims.map((claim) => {
      const account = accounts.filter(
        (account) => account.id === claim.accountId
      )[0];

      return {
        ...claim,
        name: claim.patientName,
        account,
        parent: account?.hierarchy[0],
        accountName: `${account?.hierarchy[0]} - ${account?.name}`,
        caseRef: claim.caseRef,
        status: claim.status,
        caseOrigin: claim.caseOrigin,
        dateCreated: claim.created,
        mobilePhone: claim.mobilePhone,
        workPhone: claim.workPhone,
        DueDatePassed: claim.DueDatePassed,
        supervisorName: claim.supervisorName,
        accidentDescription: claim.accidentDescription,
        bodyPartInjured: claim.bodyPartInjured,
        createdBy: formatCreatedBy(claim.originatorFirstName, claim.originatorLastName),
        dateCompleted: claim.dateCompleted,
        subjectiveAssessment: claim.subjectiveAssessment,
        followupDueDate: claim.followupDueDate,
      };
    });
    return item.filter((a) => a.parent != undefined);
  }
);

export const getAssignedFollowUpsTableData = createSelector(
  getAssignedFollowUps,
  getAccounts,
  (claims, accounts) => {
    let item = claims.map((claim) => {
      const account = accounts.filter(
        (account) => account.id === claim.accountId
      )[0];

      return {
        ...claim,
        name: claim.patientName,
        account,
        parent: account?.hierarchy[0],
        accountName: `${account?.hierarchy[0]} - ${account?.name}`,
        caseRef: claim.caseRef,
        id: claim.id,
        status: claim.status,
        caseOrigin: claim.caseOrigin,
        dateCreated: claim.created,
        createdBy: formatCreatedBy(claim.originatorFirstName, claim.originatorLastName),
        dateCompleted: claim.dateCompleted,
        followupDueDate: claim.followupDueDate,
        DueDateNotReached: claim.DueDateNotReached,
      };
    });
    return item.filter((a) => a.parent != undefined);
  }
);

export const getCaseInfoById = (caseId) =>
  createSelector(getUnAssignedCases, getAccounts, (claims, accounts) => {
    const claim = claims.find((claim) => claim.id === caseId);

    if (!claim) {
      return null;
    }

    const account = accounts.find((account) => account.id === claim.accountId);

    return {
      ...claim,
      account,
      parent: account?.hierarchy[0],
      accountName: `${account?.hierarchy[0]} - ${account?.name}`,
      caseOrigin: claim.caseOrigin,
      caseRef: claim.caseRef,
      date: claim.created,
      name: claim.patientName,
      mobilePhone: claim.mobilePhone,
      workPhone: claim.workPhone,
      supervisorName: claim.supervisorName,
      bodyPartInjured: claim.bodyPartInjured,
      accidentDescription: claim.accidentDescription,
    };
  });

export const getFollowUpCaseInfoById = (caseId) =>
  createSelector(getUnAssignedFollowUps, getAccounts, (claims, accounts) => {
    const claim = claims.find((claim) => claim.id === caseId);

    if (!claim) {
      return null;
    }

    const account = accounts.find((account) => account.id === claim.accountId);

    // Filter and format notes
    const clientAlertNotes = claim.notes
      .filter((note) => note.props.noteType === 'Client Alert')
      .sort(
        (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
      )
      .map((note, index) => ({
        author: note.author,
        date: moment(note.created).format('M/D/YY, h:mm A'),
        type: note.props.noteType,
        text: `Note ${index + 1} - ${note.text}`,
      }));

    return {
      ...claim,
      account,
      parent: account?.hierarchy[0],
      accountName: `${
        account?.parent?.name ? `${account?.parent?.name} - ` : ''
      }${account?.name}`,
      caseOrigin: claim.caseOrigin,
      caseRef: claim.caseRef,
      date: claim.date,
      name: claim.patientName,
      caseStatus: claim.status,
      caseType: claim.caseType,
      dispostion: claim.disposition,
      mobilePhone: claim.mobilePhone,
      workPhone: claim.workPhone,
      supervisorName: claim.supervisorName,
      bodyPartInjured: claim.bodyPartInjured,
      accidentDescription: claim.accidentDescription,
      subjectiveAssessment: claim.subjectiveAssessment,
      notes: clientAlertNotes || 'No notes available',
    };
  });

export const getDaysBetween = createSelector(
  selectDashboard,
  getScopeDates,
  (state, dates) => {
    const first = dates.start;
    const last = dates.end;

    return DaraUtils.getDaysArray(first, last);
  }
);
