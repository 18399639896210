<ng-container *ngIf="vm">
  <ng-container *ngIf="store.status$ | ngrxPush as status">
    <h2 mat-dialog-title>Call Initiation</h2>
    <mat-dialog-content>
      <ng-container *ngIf="status.stage === CallStages.init">
        <div fxLayout="column" class="p-3" fxLayoutGap="2%" [formGroup]="form">
          <mat-card class="mat-elevation-z0">
            <div fxLayout="row">
              <mat-card-header fxFlex="25">
                <mat-card-title>Account</mat-card-title>
                <mat-card-subtitle>Account Details</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content fxFlex="70">
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="14px"
                >
                  <mat-form-field fxFlex="50">
                    <mat-label>Select Account/Sub-Account </mat-label>
                    <input
                      matInput
                      aria-label="Account"
                      [matAutocomplete]="auto"
                      required="true"
                      matTooltipShowDelay="1000"
                      [formControl]="accountsCtrl"
                      placeholder="Please enter the employer’s name or Skill Number"
                    />
                    <button
                      *ngIf="accountsCtrl.value"
                      matSuffix
                      mat-icon-button
                      aria-label="Clear"
                      (click)="accountsCtrl.setValue('')"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      autoActiveFirstOption
                      [displayWith]="displayFn"
                    >
                      <mat-option
                        class="mat-custom-option"
                        *ngFor="let account of filteredAccounts | async"
                        [value]="account"
                      >
                        <div class="account-text">
                          {{ account.name }}
                          <span class="pl-2 account-type-text"
                            >({{ account.accountTypeFormat }})</span
                          >
                        </div>
                        <small>{{ account?.parent?.name }}</small>
                      </mat-option>
                    </mat-autocomplete>
                    <mat-hint *ngIf="accountsCtrl.value as accnt">
                      {{ accnt.parent.name }}
                    </mat-hint>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </div>
          </mat-card>
          <mat-card class="mat-elevation-z0">
            <div fxLayout="row">
              <mat-card-header fxFlex="25">
                <mat-card-title>Phone System ID</mat-card-title>
                <mat-card-subtitle
                  >Inbound Phone System \ ContactID</mat-card-subtitle
                >
              </mat-card-header>
              <mat-card-content fxFlex="70">
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="14px"
                >
                  <mat-form-field fxFlex="50">
                    <mat-label>Inbound Phone System ID</mat-label>
                    <input
                      #phoneSystem
                      maxlength="50"
                      matInput
                      placeholder="Inbound Phone System ID"
                      formControlName="phoneSystemId"
                    />
                    <mat-hint align="start"
                      >InContact Identification associated with call</mat-hint
                    >
                    <mat-hint align="end" *ngIf="phoneSystem.value?.length > 25"
                      >{{ phoneSystem.value?.length || 0 }}/50</mat-hint
                    >
                    <mat-error *ngIf="phoneSystemId.errors?.['required']"
                      >Phone System Id is required</mat-error
                    >
                  </mat-form-field>
                </div>
              </mat-card-content>
            </div>
          </mat-card>

          <mat-card class="mat-elevation-z0">
            <div fxLayout="row">
              <mat-card-header fxFlex="25">
                <mat-card-title>Injured Worker</mat-card-title>
                <mat-card-subtitle>Employee</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content fxFlex="70">
                <ng-container formGroupName="employee">
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="14px"
                  >
                    <mat-form-field class="w-100">
                      <mat-label>First Name</mat-label>
                      <input
                        #empFirstName
                        maxlength="50"
                        matInput
                        formControlName="firstName"
                      />
                      <mat-hint
                        align="end"
                        *ngIf="empFirstName.value?.length > 25"
                        >{{ empFirstName.value?.length || 0 }}/50</mat-hint
                      >
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label>Last Name</mat-label>
                      <input
                        #empLastName
                        maxlength="50"
                        matInput
                        formControlName="lastName"
                      />
                      <mat-hint
                        align="end"
                        *ngIf="empLastName.value?.length > 25"
                        >{{ empLastName.value?.length || 0 }}/50</mat-hint
                      >
                    </mat-form-field>
                    <mat-slide-toggle formControlName="isAvailable"
                      >Is Available?</mat-slide-toggle
                    >
                  </div>
                </ng-container>
              </mat-card-content>
            </div>
          </mat-card>
          <mat-card class="mat-elevation-z0">
            <div fxLayout="row">
              <mat-card-header fxFlex="25">
                <mat-card-title>Supervisor</mat-card-title>
                <mat-card-subtitle>Supervisor/Other</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content fxFlex="70">
                <ng-container formGroupName="supervisor">
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="14px"
                  >
                    <mat-form-field class="w-100">
                      <mat-label>First Name</mat-label>
                      <input
                        #supFirstName
                        maxlength="50"
                        matInput
                        formControlName="firstName"
                      />
                      <mat-hint
                        align="end"
                        *ngIf="supFirstName.value?.length > 25"
                        >{{ supFirstName.value?.length || 0 }}/50</mat-hint
                      >
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label>Last Name</mat-label>
                      <input
                        #supLastName
                        maxlength="50"
                        matInput
                        formControlName="lastName"
                      />
                      <mat-hint
                        align="end"
                        *ngIf="supLastName.value?.length > 25"
                        >{{ supLastName.value?.length || 0 }}/50</mat-hint
                      >
                    </mat-form-field>
                    <mat-slide-toggle formControlName="isAvailable"
                      >Is Available?</mat-slide-toggle
                    >
                  </div>
                </ng-container>
              </mat-card-content>
            </div>
          </mat-card>
          <mat-card class="mat-elevation-z0" *ngIf="needsParticipant">
            <div class="w-100" fxLayout="row" fxLayoutAlign="center">
              <span>At Least One Participant must be available</span>
            </div>
          </mat-card>
          <mat-card class="mat-elevation-z0">
            <div fxLayout="row">
              <mat-card-header fxFlex="25">
                <mat-card-title>Request Type</mat-card-title>
                <mat-card-subtitle>New or Existing Case</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content fxFlex="70">
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="14px"
                >
                  <mat-button-toggle-group
                    name="fontStyle"
                    aria-label="Font Style"
                    formControlName="newCase"
                  >
                    <mat-button-toggle [value]="true"
                      >New Case</mat-button-toggle
                    >
                    <mat-button-toggle [value]="false"
                      >Existing Case</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </mat-card-content>
            </div>
          </mat-card>
          <mat-card class="mat-elevation-z0">
            <div fxLayout="row">
              <mat-card-header fxFlex="25">
                <mat-card-title>Eligibility</mat-card-title>
                <mat-card-subtitle>New Call Eligibility</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content fxFlex="70">
                <ng-container
                  formGroupName="eligibilityData"
                  *ngIf="formValue.newCase; else caseSearch"
                >
                  <div fxLayout="column" fxLayoutGap="35px">
                    <div fxLayout="row">
                      <div fxFlex="50">
                        Is this a work-related injury or illness?
                      </div>
                      <div fxFlex="50">
                        <mat-radio-group
                          (change)="eligibilityQuestionChange()"
                          matTooltip="Hint: Did the injury happen at work?"
                          [matTooltipPosition]="'right'"
                          formControlName="workRelatedInjury"
                        >
                          <mat-radio-button
                            style="margin-right: 12px"
                            [value]="'Y'"
                            >Yes</mat-radio-button
                          >
                          <mat-radio-button [value]="'N'"
                            >No&nbsp;&nbsp;</mat-radio-button
                          >
                          <mat-radio-button [value]="'U'"
                            >Unknown</mat-radio-button
                          >
                        </mat-radio-group>
                      </div>
                    </div>
                    <div fxLayout="row">
                      <div fxFlex="50">Employee or Contractor?</div>
                      <div fxFlex="50">
                        <mat-radio-group
                          (change)="eligibilityQuestionChange()"
                          matTooltip="Hint: Name in the employee paycheck must match the account name"
                          [matTooltipPosition]="'right'"
                          formControlName="employeeOrContractor"
                        >
                          <mat-radio-button
                            style="margin-right: 12px"
                            [value]="'employee'"
                            >Employee</mat-radio-button
                          >
                          <mat-radio-button [value]="'contractor'"
                            >Contractor</mat-radio-button
                          >
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #caseSearch>
                  <div class="w-100" style="height: 450px">
                    <!-- <similar-call-case-search [accounts]="vm.accounts"
                                  [selectedAccount]="formValue.account"
                                  (loadSelectedRow)="selectCase($event)"></similar-call-case-search> -->
                  </div>
                </ng-template>
              </mat-card-content>
            </div>
          </mat-card>

          <ng-container *ngIf="false" formArrayName="participants">
            <div fxLayout="row" fxLayoutAlign="start start" class="w-100">
              <span class="col-title">Participants</span>
              <div fxFlex="70">
                <table
                  class="table table-borderless"
                  *ngIf="participants.controls.length > 0"
                >
                  <thead *ngIf="false">
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Role</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      *ngFor="
                        let participantForm of participants.controls;
                        let i = index;
                        let first = first
                      "
                      [formGroupName]="i"
                    >
                      <td>
                        <mat-form-field class="w-100">
                          <mat-label>First Name</mat-label>
                          <input matInput formControlName="firstName" />
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field class="w-100">
                          <mat-label>Last Name</mat-label>
                          <input matInput formControlName="lastName" />
                        </mat-form-field>
                      </td>

                      <td>
                        <mat-form-field class="w-100">
                          <mat-select appearance="fill" formControlName="role">
                            <mat-option value="employee">Employee</mat-option>
                            <mat-option value="supervisor"
                              >Supervisor</mat-option
                            >
                            <mat-option value="translator"
                              >Translator</mat-option
                            >
                            <mat-option value="other">Other</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </td>

                      <td style="vertical-align: middle">
                        <button
                          mat-icon-button
                          color="accent"
                          (click)="removeParticipant(i)"
                          *ngIf="!first"
                          [disabled]="first"
                        >
                          <mat-icon> delete</mat-icon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <button
                  *ngIf="!(store.config$ | ngrxPush)?.hideAddParticipantButton"
                  mat-raised-button
                  class="w-25 my-2"
                  color="primary"
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  <mat-icon class="mr-2">add</mat-icon>
                  <span>Participant</span>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="addParticipant()">
                    <span>Add Manually</span>
                  </button>
                  <button mat-menu-item (click)="searchUsers()">
                    <span>Add From Search</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="status.stage === CallStages.caseCreation">
        <div fxLayout="column" class="p-3" fxLayoutGap="1.5rem">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1.5rem">
            <mat-spinner
              mode="indeterminate"
              [value]="processValue(status.processing)"
            ></mat-spinner>
            <span>{{ status.message }}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="status.stage === CallStages.confirmation">
        <div
          fxLayout="column"
          class="p-3"
          fxLayoutGap="1.5rem"
          *ngIf="store.data$ | ngrxPush as data"
        >
          <div>
            Reference ID Created Successfully for
            <strong>{{ data.selectedAccount.name }}</strong>
          </div>
          <div>Please provide caller the following reference ID</div>
          <div>
            CaseRef #: <strong>{{ data.case.caseRef }}</strong>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="status.stage === CallStages.err">
        <div
          fxLayout="column"
          class="p-3"
          fxLayoutGap="1.5rem"
          *ngIf="store.data$ | ngrxPush as data"
        >
          <div class="mat-error">
            {{ errorMessage }}
          </div>
        </div>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
        <ng-container *ngIf="status.stage === CallStages.init">
          <button mat-raised-button mat-dialog-close (click)="cancel()">
            Cancel
          </button>
        </ng-container>

        <ng-container *ngIf="status.stage === CallStages.init">
          <ng-container *ngIf="newCase.value; else loadCase">
            <button
              mat-raised-button
              color="primary"
              [disabled]="!form.valid || needsParticipant"
              (click)="createCase()"
              matTooltip="Create a new case"
            >
              Continue
            </button>
          </ng-container>

          <ng-template #loadCase>
            <button
              mat-raised-button
              mat-dialog-close
              [disabled]="!form.valid"
              (click)="save(formValue)"
            >
              Load Case
            </button>
          </ng-template>
        </ng-container>
        <!-- <ng-container *ngIf="status.stage == CallStages.caseCreation">
      <button  mat-raised-button [disabled]="status.processing"  (click)="confirmation()">Confirm Case Creation</button>
    </ng-container> -->

        <ng-container *ngIf="status.stage === CallStages.confirmation">
          <button
            mat-raised-button
            color="primary"
            (click)="continueToCase(callFormData)"
          >
            OK
          </button>
        </ng-container>

        <ng-container *ngIf="status.stage === CallStages.err">
          <button mat-raised-button mat-dialog-close (click)="cancel()">
            Cancel
          </button>
        </ng-container>
      </div>
    </mat-dialog-actions>
  </ng-container>
</ng-container>
