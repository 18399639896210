import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";

import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import { selectApplicationVersion } from '@autobot/core';


@Component({
  selector: 'version-display',
  templateUrl: './version-display.component.html',
  styleUrls: ['./version-display.component.scss'],
})
export class VersionDisplayComponent implements OnInit {

  version$: Observable<{
    lastCommitMessage:string;
    lastCommitTime: string;
    fullSemver: string;
    branch: string;
  }|undefined> = this.store.select(selectApplicationVersion).pipe(

    map((v) => {
      if(v) {

        return {
          lastCommitAuthor: v.lastCommitAuthor,
          lastCommitMessage: v.lastCommitMessage,
          lastCommitTime: v.lastCommitTime,
          fullSemver: v.gitVersion.FullSemVer,
          branch: v.branch
        }



      }
      else return;
    })
  );
  displayedColumns = ['key','value']
  constructor(private store: Store) {}

  ngOnInit(): void {

  }
}
