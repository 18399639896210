import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of, retry, tap, throwError } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { IEnvironmentConfig } from '@autobot/core';
import { Customer, User, AppRole, AddUser } from '../models';
import { UserFakerService } from './user-faker.service';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private environment: IEnvironmentConfig,
    private faker: UserFakerService
  ) { }
  useLocal = false;
  apiURL = this.environment.apiConfig.managementUri + '/api';

  getAllFakes(): Observable<User[]> {
    return of(this.faker.users);
  }

  getAll(): Observable<User[]> {
    console.log('user api');
    return this.http
      .get<User[]>(this.apiURL + '/users')
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  getById(id: string): Observable<User> {
    const _url = `${this.apiURL} + '/users/' + ${id}`;
    return this.http
      .get<User>(_url)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  getMe(): Observable<User[]> {
    console.log('user api');
    return this.http
      .get<User[]>(this.apiURL + '/users/me')
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  addRole(userId: string, roleId: string) {
    const _url = `${this.apiURL}/users/${userId}/addrole`;
    return this.http
      .post<User>(_url, {
        userRole: {
          userId: userId,
          roleId: roleId
        }
      })
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  create(user: User) {
    var updAccount = {
      "user": {
        firstName: user.firstName,
        lastName: user.lastName,
        credentials: user.credentials,
        email: user.email,
        organizationId: user.organizationId,
        userRoles: user.userRoles,
        active: user.active
      }
    };
    return this.http
      .post<User>(this.apiURL + '/users', updAccount)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  update(id: string, user: User) {
    const _url = this.apiURL + '/users/' + id;
    var updAccount = {
      "userModel": {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        credentials: user.credentials,
        email: user.email,
        active: user.active,
        userRoles: user.userRoles
      }
    };
    return this.http
      .put<User>(_url, updAccount)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  delete(id: string): Observable<boolean> {
    const _url = this.apiURL + '/users/' + id;
    return this.http
      .delete<boolean>(_url)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(msg);
  }
}
