import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { User } from '@accounting/common';

export enum UsersApiActionTypes {
  LOAD_USERS = '[User/API] Load Users',
  LOAD_USERS_SUCCESS = '[User/API] Load Users Success',
  LOAD_USERS_FAILURE = '[User/API] Load Users Failure',

  CREATE_USER = '[User/API] Add User',
  CREATE_USER_SUCCESS = '[User/API] Add User Success',
  CREATE_USER_FAILURE = '[User/API] Add User Failure',

  CREATE_USER_DRAFT = '[User/API] Create User Draft',
  CREATE_USER_DRAFT_SUCCESS = '[User/API] Create User Draft Success',
  ADD_USERS_SUCCESS = '[User/API] Add Users Success',

  UPDATE_USER = '[User/API] Update User',
  UPDATE_USER_SUCCESS = '[User/API] Update User Success',
  UPDATE_USER_FAILURE = '[User/API] Update User Failure',

  DELETE_USER = '[User/API] Delete User',
  DELETE_USER_SUCCESS = '[User/API] Delete User Success',
  DELETE_USER_FAILURE = '[User/API] Delete User Failure',

  ADD_USER_ROLE = '[User/API] Add User Role',
  ADD_USER_ROLE_SUCCESS = '[User/API] Add User Role Success',
  ADD_USER_ROLE_FAILURE = '[User/API] Add User Role Failure',


}

export const loadUsers = createAction(UsersApiActionTypes.LOAD_USERS);
export const loadUsersSuccess = createAction(
  UsersApiActionTypes.LOAD_USERS_SUCCESS,
  props<{ users: User[] }>()
);
export const loadUsersFailure = createAction(
  UsersApiActionTypes.LOAD_USERS_FAILURE,
  props<{ err: any }>()
);

export const createUser = createAction(
  UsersApiActionTypes.CREATE_USER,
  props<{ user: User }>()
);
export const createUserSuccess = createAction(
  UsersApiActionTypes.CREATE_USER_SUCCESS,
  props<{ user: User }>()
);
export const createUserFailure = createAction(
  UsersApiActionTypes.CREATE_USER_FAILURE,
  props<{ err: any }>()
);

export const createUserDraft = createAction(
  UsersApiActionTypes.CREATE_USER_DRAFT,
  props<{ id: string }>()
);
export const createUserDraftSuccess = createAction(
  UsersApiActionTypes.CREATE_USER_SUCCESS,
  props<{ user: User }>()
);

export const addUsersSuccess = createAction(
  UsersApiActionTypes.ADD_USERS_SUCCESS,
  props<{ user: User[] }>()
);

export const updateUser = createAction(
  UsersApiActionTypes.UPDATE_USER,
  props<{ user: User }>()
);
export const updateUserSuccess = createAction(
  UsersApiActionTypes.UPDATE_USER_SUCCESS,
  props<{ user: Update<User> }>()
);
export const updateUserFailure = createAction(
  UsersApiActionTypes.UPDATE_USER_FAILURE,
  props<{ err: any }>()
);

export const deleteUser = createAction(
  UsersApiActionTypes.DELETE_USER,
  props<{ id: string }>()
);
export const deleteUserSuccess = createAction(
  UsersApiActionTypes.DELETE_USER_SUCCESS,
  props<{ id: string }>()
);
export const deleteUserFailure = createAction(
  UsersApiActionTypes.DELETE_USER_FAILURE,
  props<{ err: any }>()
);

export const addUserRole = createAction(
  UsersApiActionTypes.ADD_USER_ROLE,
  props<{ roleId: string, userId: string }>()
);
export const addUserRoleSuccess = createAction(
  UsersApiActionTypes.ADD_USER_ROLE_SUCCESS,
  props<{ userId: string }>()
);
export const addUserRoleFailure = createAction(
  UsersApiActionTypes.ADD_USER_ROLE_FAILURE,
  props<{ err: any }>()
);

