import { Component, Input } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import * as _ from 'lodash';


@Component({
  selector: 'formly-custom-link',
  template: `
    <div>
    <a
            matTooltip="{{ toolTip }}"
            [disabled]="
            dynamiURL === '' ||
            dynamiURL == null
            "
            mat-flat-button
            href="{{ dynamiURL }}"
            target="_blank"
            class="links"
            >{{ props.label }}</a
          >
     
    </div>
  `,
})

export class CustomLinkType extends FieldType<FieldTypeConfig> {

  get className() {
    return this.props.cssClassName || '';
  }
  get toolTip() {
    return this.props.description || '';
  }
  get dynamiURL() {
    let dynamicLink ='';
    if(this.props.dynamicLink)
    {

    
    var splittedLink = this.props.dynamicLink.split(",");
   
    if(splittedLink.length>0)
    {
      if(splittedLink.length>1)
    {
      if( splittedLink[1]=='model')
      {
        dynamicLink = _.get(this.model, splittedLink[0]);
      }
      else{
        dynamicLink = splittedLink[0];
      }
       
    }
    }
  }
   // return this.props.dynamicLink || '';
   return dynamicLink || '';
  }
}

export const formlyCustomLink = {
  name: 'formly-custom-link',
  component: CustomLinkType

};
