import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar',
    template: `<div class="flex">
        <p class="data">{{data.message}}</p>
        <hr>
        <p class="mb-0">{{data?.tooltip}}</p>
  </div>`
})
export class SnackbarComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<SnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) { }

}
