import { Injectable } from '@angular/core';
import {Store} from "@ngrx/store";
import {selectShowHeaderNav, selectShowSidenav} from "./layout.reducer";
import {closeSidenav, hideHeaderNav, openSidenav, showHeaderNav, toggleHeaderNav, toggleSidenav} from "./layout.actions";

@Injectable({
  providedIn: 'root'
})
export class LayoutFacadeService {
  showSideNav$ =  this.store.select(selectShowSidenav)
  showHeaderNav$ = this.store.select(selectShowHeaderNav)
  constructor(private store: Store) { }
  toggleSideNav(){
    this.store.dispatch(toggleSidenav())
  }
  closeSideNav(){
    this.store.dispatch(closeSidenav())
  }
  openSideNav(){
    this.store.dispatch(openSidenav())
  }

  showHeaderNav(){
    this.store.dispatch(showHeaderNav())
  }
  hideHeaderNav(){
    this.store.dispatch(hideHeaderNav())
  }
  toggleHeaderNav(){
    this.store.dispatch(toggleHeaderNav())
  }
}
