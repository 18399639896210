import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IEnvironmentConfig } from '@autobot/core';
import { Case } from '@nfr/common';
import { catchError, map, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  baseUrl: string;
 
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private environment: IEnvironmentConfig
  ) {
    this.baseUrl = environment.apiConfig.casesUri;
  }
  
  loadCases(caseStatus: string): Observable<any[]> {
    return this.http.get<any>(this.baseUrl + '/api/dashboard/cases', {
      headers: {
        Tenant: 'default',
      },
      params: {
        caseStatus: caseStatus,
      },
    }).pipe(
      map((d) => d.data)
    );
  }

  loadCasesByStatus(caseStatus: string): Observable<any[]> {
    return this.http.get<any>(this.baseUrl + '/api/dashboard/casesbystatus', {
      headers: {
        Tenant: 'default',
      },
      params: {
        caseStatus: caseStatus,
      },
    }).pipe(
      map((d) => d.data)
    );
  }
  

  loadUnAssignedCases(): Observable<any[]> {
    //TODO Adding typing for paged response
    return this.http
      .get<any>(this.baseUrl + '/api/dashboard/unassignedcases', {
        headers: {
          Tenant: 'default',
        },
      })
      .pipe(map((d) => d.data));
  }

  loadUnAssignedFollowUps(): Observable<any[]> {
    //TODO Adding typing for paged response
    return this.http
      .get<any>(this.baseUrl + '/api/dashboard/unassignedfollowups', {
        headers: {
          Tenant: 'default',
        },
      })
      .pipe(map((d) => d.data));
  }

  loadAssignedFollowUps(): Observable<any[]> {
    //TODO Adding typing for paged response
    return this.http
      .get<any>(this.baseUrl + '/api/dashboard/assignedfollowups', {
        headers: {
          Tenant: 'default',
        },
      })
      .pipe(map((d) => d.data));
  }

  loadDashboard(): Observable<any[]> {
    return of([true]);
  }

  loadPendingCases(): Observable<any[]> {
    return of([true]);
  }
  assignCase(data: any) {
    const options = {
      headers: {
        Tenant: 'default',
      },
    };
    return this.http
      .put<any>(this.baseUrl + '/api/dashboard/assigntome',  data,options)  
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }
  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(msg);
  }
}
