import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-popover-repeat-section',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index;" fxLayout="row" fxLayoutAlign="start center" >

      <formly-field  class="w-95" [field]="field"></formly-field>


      <div class="button-container">
        <button mat-icon-button color="warn" (click)="remove(i)"><mat-icon>delete</mat-icon></button>
        <button mat-icon-button matTooltip="Configure" (click)="onClick($event, i)"><mat-icon>rule_folder</mat-icon></button>
      </div>


         </div>
    <div style="margin:30px 0;">
      <button mat-icon-button color="accent" matTooltip="Add New Ruleset" (click)="add()"><mat-icon class="mr-2">add</mat-icon> Add Ruleset</button>
    </div>


  `,
  styles: [
    `
      .button-container {
        width: 15%;
        margin-left: 2%;
      }
      .w-95 {
        width: 95% !important;
      }

    `
  ]
})
export class PopoverRepeatTypeComponent extends FieldArrayType {
  onClick($event:any,index:number) {
    if (this.props.onClick) {
      this.props.onClick($event,index);
    }
  }
  rules(){

  }

}

export const popoverRepeatType = {
  name: 'popover-repeat',
  component: PopoverRepeatTypeComponent

};
