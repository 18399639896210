import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Content, selectRouteParams } from '@autobot/core';
import * as templatesReducer from '../reducers/templates.reducer';
import { TemplateState } from '../reducers';
import { MapContentProps, Template } from '@forms/common';

export const selectTemplateFeatureState =
  createFeatureSelector<TemplateState>('templates');
export const selectTemplateState = createSelector(
  selectTemplateFeatureState,
  (state) => state.templates
);
export const selectTemplateEntities = createSelector(
  selectTemplateState,
  templatesReducer.selectEntities
);

export const templatesLoaded = createSelector(
  selectTemplateState,
  (state) => state.loaded
);
export const templatesLoading = createSelector(
  selectTemplateState,
  (state) => state.loading
);

export const getSelectedTemplate = createSelector(
  selectTemplateState,
  (state) => {
    if (state.selectedTemplateId == undefined) return;
    return state.entities[state.selectedTemplateId] ?? state.entities[0];
  }
);

export const getAllTemplates = createSelector(
  selectTemplateState,
  templatesReducer.selectIds,
  templatesReducer.selectEntities,
  templatesReducer.selectAll
);

export const getTemplates = createSelector(
  getAllTemplates,
  (state: Template[]) => state
);

export const getTemplateListView = createSelector(getTemplates, (templates) => {
  const result: {
    id: string;
    name: string;
    updated: Date;
    props: { [key: string]: any };
  }[] = templates.map((t) => {
    return {
      id: t.id,
      name: t.name,
      updated: t.updated,
      props: t.props,
    };
  });
  return result;
});

export const selectSingleTemplate = (id: string) =>
  createSelector(selectTemplateEntities, (entities) => entities[id]);

export const getTemplateSelectionOptions = createSelector(
  getAllTemplates,
  (templates) =>
    templates.map((t) => {
      return {
        id: t.id,
        name: t.name,
        source: 'cloud',
        url: '',
        type: 'template',
      };
    })
);
export const getTemplateToEdit = createSelector(
  selectTemplateEntities,
  selectRouteParams,
  (templates, { templateId }) => templates[templateId]
);

export const getTemplatesAsContent = createSelector(
  getAllTemplates,
  (templates) =>
    templates.map((t) => {
      const { icon } = MapContentProps('Template');
      let ct = new Content(t, 'Template');
      if (!ct.owner) ct.owner = 'system';
      ct.props = { ...ct.props, icon };
      return ct;
    })
);

// export const getActiveTemplate = createSelector(
//   getSelectedTemplate,
//   getSelectedSupplement,
//   (template,changes) => {
//     if(changes == undefined) return template;
//     const _tabs: Tab[] = _.concat(template.tabs,changes.add.tabs,changes.update.tabs)
//     const _areas: Area[] = _.concat(template.areas, changes.add.areas, changes.update.areas)
//     const _fields: Field[] = _.concat(template.fields, changes.add.fields,changes.update.fields)
//
//
//
//
//     const tabs =  _
//       .chain(_tabs)
//       .orderBy(['seq','updated'],['asc', 'desc'])
//       .uniqBy('id')
//       .value()
//
//
//     const areas = _
//       .chain(_areas)
//       .orderBy(['seq','updated'],['asc', 'desc'])
//       .uniqBy('id')
//       .value()
//
//
//     const fields = _
//       .chain(_fields)
//       .orderBy(['seq','updated'],['asc', 'desc'])
//       .uniqBy('id')
//       .value()
//
//     return {
//       id: template.id,
//       name: template.name,
//
//       tabs,
//       areas,
//       fields,
//       updated: new Date()
//     }
//   }
// )
