import {createAction, props} from "@ngrx/store";

export enum TemplateListPageActionTypes {


  REFRESH_LIST = '[Template List Page/Header] Load Templates',
  START_NEW_TEMPLATE = '[Template List Page/Header] Start New Template Clicked',
  EXPORT_TEMPLATE = '[Template List Page/ContextMenu] Export Template',
  IMPORT_TEMPLATE = '[Template List Page/ContextMenu] Import Template',
  PREVIEW_TEMPLATE = '[Template List Page/ContextMenu] Preview Template',
  REMOVE_TEMPLATE = '[Template List Page/ContextMenu] Delete Template',
  SELECT_TEMPLATE = '[Template List Page/ContextMenu] Select Template',
  DOWNLOAD_TEMPLATE = '[Template List Page/ContextMenu] Download Template'


}

export const exportTemplate = createAction(TemplateListPageActionTypes.EXPORT_TEMPLATE, props<{id: string}>());
export const previewTemplate = createAction(TemplateListPageActionTypes.PREVIEW_TEMPLATE, props<{id: string}>());
export const removeTemplate = createAction(TemplateListPageActionTypes.REMOVE_TEMPLATE, props<{ id: string}>());
export const startNewTemplate = createAction(TemplateListPageActionTypes.START_NEW_TEMPLATE);
export const importTemplate = createAction(TemplateListPageActionTypes.IMPORT_TEMPLATE);
export const refreshList = createAction(TemplateListPageActionTypes.REFRESH_LIST);
export const selectTemplate = createAction(TemplateListPageActionTypes.SELECT_TEMPLATE, props<{ id: string}>());
export const downloadTemplate = createAction(TemplateListPageActionTypes.DOWNLOAD_TEMPLATE, props<{ id: string}>());
