import {EventEmitter, Injectable} from '@angular/core';
import {SiteTheme} from "../site-theme.model";




@Injectable({providedIn: 'root'})
export class ThemeStorage {
  static storageKey = 'theme-storage-current-name';
  constructor(private window: Window) {
  }
  onThemeUpdate: EventEmitter<SiteTheme> = new EventEmitter<SiteTheme>();

  storeTheme(theme: SiteTheme) {
    try {
      this.window.localStorage[ThemeStorage.storageKey] = theme.name;
      // eslint-disable-next-line no-empty
    } catch { }

    this.onThemeUpdate.emit(theme);
  }

  getStoredThemeName(): string | null {
    try {
      console.log(window.localStorage[ThemeStorage.storageKey] || 'conduent-theme')
      return window.localStorage[ThemeStorage.storageKey] || 'conduent-theme';
    } catch {
      return 'conduent-theme';
    }
  }

  clearStorage() {
    try {
      window.localStorage.removeItem(ThemeStorage.storageKey);
      // eslint-disable-next-line no-empty
    } catch { }
  }
}
