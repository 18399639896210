// export enum FollowUpMode {
//   New = 'New',
//   Edit = 'edit',
//   View = 'view'
// }
export enum FollowUpTabType {
  Detail="Detail",
  FollowUp = 'FollowUp'
}



export enum FollowUpStatus {
  New="New", //only for UI to identify save/update
  Draft = 'Draft',
  Complete = 'Complete'
}
export interface FollowUp {
  id: string;
  caseId: string;
  followUpNumber: Number;
  status: FollowUpStatus;
  data?: { [key: string]: any };
  metaData?:FollowUpMetaData
  createdBy?: string;
  updatedBy?: string;
  created?: Date;
  updated?: Date;
}

export interface FollowUpMetaData {
  isUpdateForms?: boolean;
}

export interface GetFollowUpsModel{
  id: string;
  caseId: string;
  followUpNumber: number;
  status: FollowUpStatus;
  data: { [key: string]: any };
  createdBy: string;
  updatedBy?: string;
  created: Date;
  updated?: Date;
}

export interface FollowUpTab{
  tabIndex:number;
  tabType:FollowUpTabType;
  tabDescription:string;
}