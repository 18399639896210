import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';


import {TemplateApiActions, TemplateListPageActions, TemplatePageActions} from "../actions";
import { Template } from '@forms/common';




export const templatesFeatureKey = 'templates';

export interface State extends EntityState<Template> {
  loaded: boolean;
  loading: boolean;
  ids: string[];
  selectedTemplateId?: string | null;
  error?: string | null; // last known error (if any)

}

export const adapter: EntityAdapter<Template> = createEntityAdapter<Template>({
  selectId: (model:Template) => model.id
});

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  ids: [],
  selectedTemplateId: null,

});

export const reducer = createReducer(
  initialState,
  on(TemplateApiActions.loadTemplates,(state,action) =>  ({...state, loading:true, loaded: false})),
  on(TemplateListPageActions.selectTemplate, (state,action) => ({...state, selectedTemplateId: action.id})),

  on(TemplateApiActions.loadTemplatesSuccess, (state, action) => adapter.setAll(action.templates, ({...state, loading: false, loaded: true}))),
  on(TemplateApiActions.updateTemplate,(state) => ({...state})),
  on(TemplateApiActions.createTemplateSuccess, (state,action) => adapter.addOne(action.template, ({...state}))),
  on(TemplateApiActions.cloneTemplateSuccess, (state,action) => adapter.addOne(action.template, ({...state}))),
  on(TemplateApiActions.deleteTemplateSuccess, (state,action) => adapter.removeOne(action.id, ({...state}))),

  on(TemplateApiActions.updateTemplateSuccess, (state,action) => adapter.updateOne(action.template, ({...state})))
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
