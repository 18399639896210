import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CoreEnvironment } from "../state/core.helpers";

@Injectable()
export class RoutingInterceptor implements HttpInterceptor {
    constructor(@Inject('CoreEnvironment') private environment: CoreEnvironment){}
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newHeaders = request.headers
            .set('x-environment', this.environment.environment);
        return next.handle(request.clone({ headers: newHeaders }));
    }
}
