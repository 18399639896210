import * as FileSaver from "file-saver";
import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class DownloadService {

  downloadJson(data:any, name?:string){

    const json = JSON.stringify(data,null,2);
    const blob = new Blob([json], {type: 'application/json'});

    const templateName = 'JSON_' + this.getDateString() + '.json';
    const fileName = name ?? templateName;
    console.log(blob, fileName);
    FileSaver.saveAs(blob, fileName);
  }


  getDateString() {
    const date = new Date();
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day =`${date.getDate()}`.padStart(2, '0');
    return `${year}${month}${day}`
  }
}
