import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { NxWelcomeComponent } from './nx-welcome.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarModule } from '@autobot/layout';
import { CORE_MODULES, PROVIDERS } from './app.dependencies';
import { LandingModule } from './pages/landing/landing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppResolver } from './app.resolver';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { LandingComponent } from './pages/landing/landing.component';
import { AppRoles, RoleGuard } from '@autobot/authentication';
import { UnAuthorizedComponent } from './pages/unauthorized/unauthorized.component';

@NgModule({
  declarations: [AppComponent, NxWelcomeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    LandingModule,
    NavbarModule,
    ...CORE_MODULES,
    RouterModule.forRoot(
      [
        {
          path: 'nfr',
          loadChildren: () =>
            import('@nfr/pages').then((m) => m.NfrPagesModule),
          data: {
            expectedRoles: [AppRoles.Nurse, AppRoles.Supervisor],
          },
          canActivate: [ RoleGuard],
          resolve: { dashboard: AppResolver },
        },
        {
          path: 'welcome',
          component: LandingComponent,
        },
        {
          path: 'unauthorized',
          component: UnAuthorizedComponent,
        },
        {
          path: '',
          redirectTo: 'nfr',
          pathMatch: 'full'
        },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64],
      }
    ),
  ],
  providers: [...PROVIDERS],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
