<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content class="mat-typography"   fxLayout="column" fxLayoutGap="24px">

  <div>
    <div fxLayout="row" class="p-3 action" tabindex="0"  *ngFor="let action of actions" (click)="action.command()" (keyup.enter)="action.command()">

      <div fxFlex="25" class="action_title mb-1">{{action.title}}</div>
      <div fxFlex="75" class="action_description">{{action.description}}</div>


    </div>

  </div>



</mat-dialog-content>

