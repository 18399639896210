import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewCallDialog } from './dialogs/new-call/new-call.component';
import { MaterialModule } from '@autobot/shared';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { UserSearchModule } from '@forms/common';
import { MatRadioModule } from '@angular/material/radio';
import { PushModule } from '@ngrx/component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SimilarCaseSearchModule } from '../similar-case-search/similar-case-search.module';


@NgModule({
  declarations: [NewCallDialog],
  imports: [
    CommonModule,
    MaterialModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    UserSearchModule,
    MatRadioModule,
    SimilarCaseSearchModule,
    PushModule,
  ],
  exports: [NewCallDialog],
})
export class CallModule {}
