<mat-form-field fxFlex="100" style="width: 100%">
  <mat-label>
    Search Employee by First Name, Last Name, Employee ID or Date of Birth (mm/dd/yyyy)
  </mat-label>

  <input
    matInput
    [matAutocomplete]="auto"
    [formControl]="employeeControl"
    autobotForceSelection
    (keyup)="onKeyup($event)"
  />
  <a
    *ngIf="employeeControl?.value"
    matSuffix
    aria-label="Clear"
    (click)="clear()"
  >
    <mat-icon style="font-size: 12px; height: 12px">close</mat-icon>
  </a>
  <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
    <mat-option *ngIf="(filter$ | async)?.length === 0" disabled>
      No items
    </mat-option>
    <mat-option
      *ngFor="let opt of filter$ | async"
      [value]="opt.label"
      (onSelectionChange)="onSelectionChange(opt)"
      [title]="opt.label"
    >
      {{ opt.label }}
    </mat-option>
  </mat-autocomplete>
 
</mat-form-field>
