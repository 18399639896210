
import {createAction, props} from "@ngrx/store";

import {Assembly} from "@forms/common";

export enum AssemblyActionTypes {

  LOAD_ASSEMBLY = '[Assembly] Load Assembly',
  LOAD_ASSEMBLY_SUCCESS = '[Assembly] Load Assembly Success',
  LOAD_ASSEMBLY_FAILURE = '[Assembly] Load Assembly Failure',
}

export const loadAssembly = createAction(AssemblyActionTypes.LOAD_ASSEMBLY, props<{ id: string }>())
export const loadAssemblySuccess = createAction(AssemblyActionTypes.LOAD_ASSEMBLY_SUCCESS, props<{assembly: Assembly}>())
export const loadAssemblyFailure = createAction(AssemblyActionTypes.LOAD_ASSEMBLY_FAILURE, props<{err: any}>())
