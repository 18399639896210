import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {EditorToolbarService} from "./editor-toolbar.service";


@UntilDestroy()
@Component({
  selector: 'forms-editor-toolbar',
  template: `
    <mat-toolbar color="primary">
      <ng-content></ng-content>
      <div class="flex-spacer"></div>
      <ng-container *ngIf="!minimal;else minimalTemplate">
        <div fxLayout="row">
          <button mat-icon-button matTooltip="Download" (click)="onExport.emit()"><mat-icon>file_download</mat-icon></button>
          <button mat-icon-button matTooltip="Save"  (click)="onSave.emit()"><mat-icon>save</mat-icon></button>

        </div>
      </ng-container>
      <ng-template #minimalTemplate>
        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
      </ng-template>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onExport.emit()">
          <mat-icon>file_download</mat-icon>
          <span>Download</span>
        </button>
        <button mat-menu-item (click)="onSave.emit()">
          <mat-icon>save</mat-icon>
          <span>Save</span>
        </button>
        <button mat-menu-item (click)="onExit.emit()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Exit</span>
        </button>
      </mat-menu>
    </mat-toolbar>
  `,
  styles: [
  ]
})
export class EditorToolbarComponent implements OnInit {
  @Output() onSave = new EventEmitter();
  @Output() onExport = new EventEmitter();
  @Output() onExit = new EventEmitter();

  @Input() minimal: boolean = false;

  constructor(public screen: EditorToolbarService) { }

  ngOnInit(): void {
    this.screen.isScreenSmall.pipe(untilDestroyed(this)).subscribe(
      (isSmall) => this.minimal = isSmall
    )
  }

}
