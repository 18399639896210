import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

Injectable()
export class TabService {
  _selected = new BehaviorSubject<number>(0)
  _showTabHeader = new BehaviorSubject<boolean>(true);
  showTabHeader = this._showTabHeader.asObservable();
  selected$ = this._selected.asObservable();


  setActive(idx){

  this._selected.next(idx);
 }
  toggleTabHeader(){
    const show = this._showTabHeader.getValue();
    this._showTabHeader.next(!show);
  }
}
