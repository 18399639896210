import {Component, Input} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import {DragDropFieldType} from "./drag-drop-type";

@Component({
  selector: 'selectable-mat-select',
  template: `

   <mat-form-field appearance="fill" class="w-100" >
     <mat-label>{{props.label}}</mat-label>
     <mat-select [formControl]="formControl" [formlyAttributes]="field" >


     </mat-select>
   </mat-form-field>
 `,
  styles:[
    `.selected {
      outline: 4px dashed darkorange;
      background: transparent;
    }`]
})
export class SelectableMatSelect extends FieldType<FieldTypeConfig> {

}

export const selectableMatSelect = {
  name: 'selectable-mat-select',
  component: SelectableMatSelect

};
