import { createAction, props } from '@ngrx/store';

import { Lookup } from '@forms/common';

export enum LookupActionTypes {
  LOAD_LOOKUP = '[Dashboard] Load Lookup',
  LOAD_LOOKUP_SUCCESS = '[Dashboard] Load Lookup Success',
  LOAD_LOOKUP_FAILURE = '[Dashboard] Load Lookup Failure',
}

export const loadLookup = createAction(LookupActionTypes.LOAD_LOOKUP);
export const loadLookupSuccess = createAction(
  LookupActionTypes.LOAD_LOOKUP_SUCCESS,
  props<{ looksups: any }>()
);
export const loadLookupFailure = createAction(
  LookupActionTypes.LOAD_LOOKUP_FAILURE,
  props<{ error: any }>()
);
