import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as accountsReducer from '../reducers/account-entity.reducer';
import { AccountState } from '../reducers';
import {
  Account,
  AccountDetailView,
  AccountViewMapper,
} from '@accounting/common';
import { selectRouteParams } from '@autobot/core';

export const selectAccountFeatureState =
  createFeatureSelector<AccountState>('accounts');

export const selectAccountState = createSelector(
  selectAccountFeatureState,
  (state) => state.accounts
);
export const selectAccountEntities = createSelector(
  selectAccountState,
  accountsReducer.selectEntities
);
export const getLastUpdateDate = createSelector(
  selectAccountState,
  (state) => state.lastUpdateDate || new Date('1970-01-01')
);
export const accountsLoaded = createSelector(
  selectAccountState,
  (state) => state.loaded
);
export const accountsLoading = createSelector(
  selectAccountState,
  (state) => state.loading
);

export const getSelectedAccount = createSelector(
  selectAccountState,
  (state) => state.entities[state.selectedAccountId] ?? state.entities[0]
);

export const getAllAccounts = createSelector(
  selectAccountState,
  accountsReducer.selectIds,
  accountsReducer.selectEntities,
  accountsReducer.selectAll
);

export const getContractAccountViews = createSelector(
  getAllAccounts,
  (accounts) => {
    return AccountViewMapper(accounts);
  }
);
export const getStandardAccountViews = createSelector(
  getAllAccounts,
  (accounts) => {
    return AccountViewMapper(accounts, 'account');
  }
);
export const getSubAccountViews = createSelector(getAllAccounts, (accounts) => {
  return AccountViewMapper(accounts, 'sub');
});

export const selectSingleAccount = (id) =>
  createSelector(selectAccountEntities, (entities) => entities[id]);

export const getSelectedAccountExpandedView = createSelector(
  getSelectedAccount,
  getAllAccounts,
  (single, accounts) => {
    if (single == undefined) return;
    const children = accounts.filter((a) => a.parentId == single.id);
    const parent = accounts.find((a) => a.id == single.parentId);

    return {
      ...single,
      children,
      parentAccount: parent,
    };
  }
);

export const getAccountMinimalView = createSelector(
  getAllAccounts,
  (accounts) => {
    return accounts.map((account) => {
      return {
        id: account.id,
        accountType: account.accountType,
        name: account.name,
        shortName: account.shortName,
        tenant: account.tenant,
        skillNumber: account.skillNumber,
        tin: account.tin,
        parentId: account.parentId,
      };
    });
  }
);

export const getSelectedAccountDetailView = createSelector(
  getSelectedAccount,
  (account) => {
    if (account == undefined) return;
    const acc: AccountDetailView = {
      id: account.id,
      accountType: account.accountType,
      name: account.name,
      shortName: account.shortName,
      tenant: account.tenant,
      skillNumber: account.skillNumber,
      phoneNumber: account.phoneNumber,
      tin: account.tin,
      parentId: account.parentId,
      configs: account.configs,
      props: account.props,
    };
    return acc;
  }
);

export const getAccountToEdit = createSelector(
  selectAccountEntities,
  selectRouteParams,
  (accounts, { accountId }) => accounts[accountId]
);
