import { enableProdMode, InjectionToken, PLATFORM_INITIALIZER, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { DefaultAuthSettings } from './environments/default.config';
import * as _ from 'lodash';
import { AuthConfig } from '@autobot/authentication';

if (environment.isProduction) {
  enableProdMode();
}
fetch(environment.configUrl + "/auth/" + environment.environment + "/",{
    method: "GET", mode: 'cors',headers: {'Content-Type': 'application/json' }
})
  .then(response => response.json())
  .then(json => {
    const authConfig = _.merge({}, DefaultAuthSettings, json as AuthConfig);
    const providers = [
      { provide: AuthConfig, useValue: authConfig },
    ]
    platformBrowserDynamic(providers)
      .bootstrapModule(AppModule, {
        defaultEncapsulation: ViewEncapsulation.Emulated,
      })
      .catch((err) => console.error(err));
  });
