import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-header-collapse-panel',
  template: `
   <mat-expansion-panel  class="mat-elevation-z0" hideToggle="true" [formlyAttributes]="field">
     <mat-expansion-panel-header>
       <div fxLayout="row" fxLayoutAlign="space-between center">
         <mat-panel-title>
           <a>{{ props.description }}</a>
         </mat-panel-title>
         <mat-panel-description>{{props.description}}</mat-panel-description>
         <ng-container #fieldComponent></ng-container>
       </div>

     </mat-expansion-panel-header>
   </mat-expansion-panel>





  `,
})
export class HeaderCollapsePanelWrapperComponent extends FieldWrapper {
}
