import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { accountsFeatureReducersMap } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { AccountApiEffects } from './effects';

import { AlertService } from '@forms/common';
import { AccountFacade, AccountFacadeBase } from './services/accounts.facade';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSnackBarModule,
    StoreModule.forFeature('accounts', accountsFeatureReducersMap),
    EffectsModule.forFeature([AccountApiEffects]),
  ],
  providers: [
    AlertService,
    { provide: AccountFacadeBase, useClass: AccountFacade }
  ],
})
export class AccountsStoreModule {}
