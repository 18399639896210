import { Store } from '@ngrx/store';
import { UserActions } from './actions/user.actions';
import {
  selectCurrentUser,
  selectUserDetails,
  selectUserFavorites,
} from './selectors/user.selectors';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class UserFacade {
  updateUser(user: { id: any; email: any; firstName: any; lastName: any; credentials: any; active: any; userRoles: any; }) {
    throw new Error('Method not implemented.');
  }
  loadUsers() {
    throw new Error('Method not implemented.');
  }
  user$ = this.store.select(selectUserDetails);
  currentUser$ = this.store.select(selectCurrentUser);
  favorites$: Observable<string[]> = this.store.select(selectUserFavorites);

  constructor(private store: Store) {}

  addFavorite(id: string): void {
    this.store.dispatch(UserActions.addFavorite({ id }));
  }

  updateProperties(data: any) {
    this.store.dispatch(UserActions.updateProps({ data }));
  }

  toggleFavorite(id: string): void {
    this.store.dispatch(UserActions.toggleFavorite({ id }));
  }
}
