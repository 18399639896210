import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-panel',
  template: `
    <mat-card style="overflow-y: auto;" [formlyAttributes]="field">


        <mat-card-title  class="h4 panel-title" *ngIf="props.showHeader">{{props.label}}</mat-card-title>



      <ng-container #fieldComponent></ng-container>
    </mat-card>
  `,
  styles:[`
    .panel-title {
          margin-left: 0.0rem !important;
          margin-top: 0.5rem!important;
          margin-bottom: 1.75rem !important;
          font: normal normal bold 18px/24px;
    }

  `]
})
export class PanelWrapperComponent extends FieldWrapper {

}
