import { Component, Input } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'formly-dynamiclabel',
  template: `
    <div [class]="className" [hidden]="to?.isLabelHidden">
      {{ to.label }}
    </div>
    <p>
      {{ dynamicName }}
    </p>
  `,
  styles: [
    `
      .headingBoldUnderline {
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 10px;
      }
      .headingUnderline {
        text-decoration: underline;
        margin-bottom: 10px;
      }
      .headingBold {
        font-weight: bold;
        margin-bottom: 10px;
      }
    `,
  ],
})
export class formlyDynamicLabelType extends FieldType<FieldTypeConfig> {
  get className() {
    return this.props.cssClassName || 'headingBold';
  }
  get dynamicName() {
    let modelVal = '';
    if (this.props.dynamicLabel) {
      var splittedLabel = this.props.dynamicLabel.split(',');
      var num = 0;
      var count = splittedLabel.length;

      for (num = 0; num < count; num++) {
        let labelValue = _.get(this.model, splittedLabel[num]);
        if (labelValue) {
          
          if (isValidDate(labelValue)) {
            const estFormattedDate = moment(labelValue)
            .format('MM/DD/yyy hh:mm A');
            labelValue = estFormattedDate;
          }
          modelVal = modelVal + labelValue + ' ';
        
      }
      }
    }

    return modelVal;
  }
}
function isValidDate(dateString) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if(dateString.length>=10)
  {
    return regex.test(dateString.substring(0,10));
  }
  else
  {
    return false;
  }
  
}

export const formlyDynamicLabel = {
  name: 'formly-dynamiclabel',
  component: formlyDynamicLabelType,
};
