import { Component } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FieldType } from '@ngx-formly/core';
import {RepeatTypeComponent} from "./repeat-type.component";

@Component({
  selector: 'formly-drag-drop',
  template: `
    <div class="formly-drag-drop"  (cdkDropListDropped)="drop($event)" cdkDropList>
      <formly-field *ngFor="let field of field.fieldGroup;" cdkDrag [field]="field">
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      </formly-field>
    </div>
  `,
  styles:[
    `
      .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }

      .example-box:last-child {
        border: none;
      }

      .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }

      .example-custom-placeholder {
        background: #ccc;
        border: dotted 3px #999;
        min-height: 60px;
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
    `
  ]
})
export class DragDropFieldType extends FieldType {
  drop(event: CdkDragDrop<string[]>) {
    // @ts-ignore
    moveItemInArray(this.field.fieldGroup, event.previousIndex, event.currentIndex);
  }
}


export const dragDropType = {
  name: 'drag-drop',
  component: DragDropFieldType

};
