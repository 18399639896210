import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { getProtocols } from './case.selectors';
import { selectSessionFeatureState } from './session.selectors';
import { getLookUpDatasets } from './lookup.selectors';

export const selectProtocolState = createSelector(
  selectSessionFeatureState,
  (state) => state.protocol
);
export const getProtocolMainModel = createSelector(
  selectProtocolState,
  (state) => state.model
);
export const getSelectedTemplate = createSelector(
  selectProtocolState,
  (state) => state.selectedProtocols
);

export const getSecondaryAssmtForms = createSelector(
  selectProtocolState,
  (state) => state?.secondaryAssmtForms
);
export const getSecondaryAssmtForms_Sorted = createSelector(
  selectProtocolState,
  getProtocols,
  (protocols, selectedProtocols) => {
    // return the protocols in same order as the selected protocols
    var sorted = _.sortBy(protocols?.secondaryAssmtForms, function (obj) {
      return _.findIndex(selectedProtocols, { id: obj.id });
    });
    return sorted;
  }
);

export const getInterventionAssmtForms_Sorted = createSelector(
  selectProtocolState,
  getProtocols,
  (protocols, selectedProtocols) => {
    // return the protocols in same order as the selected protocols
    return _.sortBy(protocols?.interventionAssmtForms, function (obj) {
      return _.findIndex(selectedProtocols, { id: obj.id });
    });
  }
);
//#region Subjective Assmt

export const getSubjectiveAssessmentModel = createSelector(
  selectProtocolState,
  (state) => state?.subjectiveAssmt?.model
);
export const getSubjectiveAssessmentTemplate = createSelector(
  selectProtocolState,
  (state) => state?.subjectiveAssmt?.template
);

export const getSubjectiveAssessmentIForm = createSelector(
  selectProtocolState,
  (state) => {
    return state?.subjectiveAssmt?.iForm;
  }
);

export const getSubjectiveAssessmentFormTabs = createSelector(
  selectProtocolState,
  (state) => {
    return state?.subjectiveAssmt?.template?.tabs;
  }
);

export const getSubjectiveAssessmentFormlyFields = createSelector(
  selectProtocolState,
  (state) => state?.subjectiveAssmt?.fields
);
//#endregion

//#region ABCD Protocol
export const getAbcdProtocolForms = createSelector(
  selectProtocolState,
  (state) => state?.abcdForms
);
export const getAbcdProtocolForms_Sorted = createSelector(
  selectProtocolState,
  getProtocols,
  (protocols, selectedProtocols) => {
    // return the protocols in same order as the selected protocols
    var sorted = _.sortBy(protocols?.abcdForms, function (obj) {
      return _.findIndex(selectedProtocols, { id: obj.id });
    });
    return sorted;
  }
);
export const getABCDFormByProtocolId = (protocolId: string) =>
  createSelector(
    selectProtocolState,
    getAbcdProtocolForms,
    (state, abcdForms) => {
      if (!abcdForms) return [];
      const forms = abcdForms.filter((o) => o.id == protocolId)[0];
      return forms.primaryForm;
    }
  );
export const selectABCDFormlyFieldsById = (
  protocolId: string,
  templateId: string
) =>
  createSelector(
    selectProtocolState,
    getABCDFormByProtocolId(protocolId),
    (state, template) => {
      if (!template) return [];
      // const forms = template.filter((o) => o.pro == t.id);
      // const fields = this.mapping.convertToNoWrapPropFields(
      //   form.template,
      //   datasets
      // );
    }
  );
// export const getABCDFormlyFields = (templateId: string) =>
//   createSelector(
//     selectProtocolState,
//     selectProductById(productId),
//     (state) => state.abcdForms
//   );

//#endregion
export const getProtocolExtendedProperties = createSelector(
  selectProtocolState,
  getLookUpDatasets,
  getProtocols,
  (protocols, datasets, selectedProtocols) => {
    let selectedProtos = _.filter(datasets["protocols"], p=>
    _.some(selectedProtocols, {"code":p.code}));
    return selectedProtos;
  }
);
export const isProtocolSaved = createSelector(
  selectProtocolState,
  (state) => state.saved
);
export const isProtocolSaving = createSelector(
  selectProtocolState,
  (state) => state.saving
);
export const isProtocolLoading = createSelector(
  selectProtocolState,
  (state) => state.loading
);
export const isProtocolFormLoaded = createSelector(
  selectProtocolState,
  (state) => state.loaded
);
