import {
    Directive,
    Input,
    Host,
    Self,
    AfterViewInit,
    OnDestroy,
  } from '@angular/core';
  import {
    MatAutocompleteTrigger,
    MatAutocomplete,
  } from '@angular/material/autocomplete';
  import { NgControl } from '@angular/forms';
  import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
  import { Subscription } from 'rxjs';

  @UntilDestroy()
  @Directive({
    selector: '[autobotForceSelection]',
  })
  export class AutocompleteSelectionDirective
    implements AfterViewInit, OnDestroy
  {
    @Input()
    matAutocomplete!: MatAutocomplete;

    constructor(
      @Host()
      @Self()
      private readonly autoCompleteTrigger: MatAutocompleteTrigger,
      private readonly ngControl: NgControl
    ) {}
    private panelClosingActionsSubscription: Subscription | null = null;
    ngAfterViewInit() {
      this.autoCompleteTrigger.panelClosingActions
        .pipe(untilDestroyed(this))
        .subscribe((e) => {
          if (!e || !e.source) {
            const selected = this.matAutocomplete.options
              .map((option) => option.value)
              .find((option) => option === this.ngControl.value);
            if (selected == null) {
              this.ngControl.control?.setValue('');
            }
          }
        });
    }

    ngOnDestroy(): void {
      this.unsubscribePanelClosingActions();
    }
    private unsubscribePanelClosingActions(): void {
      if (this.panelClosingActionsSubscription) {
        this.panelClosingActionsSubscription.unsubscribe();
        this.panelClosingActionsSubscription = null;
      }
    }
  }
