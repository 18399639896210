import { IForm, MedicalProtocol, Template } from '@forms/common';
import { createAction, props } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';

export enum ProtocolActionTypes {
  INIT = '[Protocol] Init',
  LOAD_PROTOCOL = '[Protocol] Load Protocol Form',
  LOAD_PROTOCOL_SUCCESS = '[Protocol] Load Protocol Form Success',
  LOAD_PROTOCOL_FAILURE = '[Protocol] Load Protocol Form Failure',

  LOAD_ABCDPROTOCOL = '[Protocol] Load ABCD Protocol Form',
  LOAD_ABCDPROTOCOL_SUCCESS = '[Protocol] Load ABCD Protocol Form Success',
  LOAD_ABCDPROTOCOL_FAILURE = '[Protocol] Load ABCD Protocol Form Failure',

  UPDATE_PROTOCOL_MODEL = '[Protocol] Update Protocol Model',
  LOAD_FORM_FROM_PROTOCOL = '[Protocol] Load Form From Protocol',

  LOAD_FORM_FROM_PROTOCOL_SUCCESS = '[Protocol] Load Form From Protocol Success',

  LOAD_FORM_FROM_PROTOCOL_FAILURE = '[Protocol] Load Form From Protocol Failure',

  EXIT_PROTOCOL = '[Editor] Exit Protocol',
}

export const init = createAction(ProtocolActionTypes.INIT);

export const loadProtocol = createAction(
  ProtocolActionTypes.LOAD_PROTOCOL,
  props<{ templateIds: string[]; protocolType: string }>()
);
export const loadProtocolSuccess = createAction(
  ProtocolActionTypes.LOAD_PROTOCOL_SUCCESS,
  props<{
    medicalProtocol: MedicalProtocol[];
    datasets: any;
    data?: any;
  }>()
);
export const loadProtocolFailure = createAction(
  ProtocolActionTypes.LOAD_PROTOCOL_FAILURE,
  props<{ err: any }>()
);

export const loadABCDProtocol = createAction(
  ProtocolActionTypes.LOAD_ABCDPROTOCOL,
  props<{ templateIds: string[]; protocolType: string }>()
);
export const loadABCDProtocolSuccess = createAction(
  ProtocolActionTypes.LOAD_ABCDPROTOCOL_SUCCESS,
  props<{
    medicalProtocol: MedicalProtocol[];
    datasets: any;
    data?: any;
  }>()
);
export const loadABCDProtocolFailure = createAction(
  ProtocolActionTypes.LOAD_ABCDPROTOCOL_FAILURE,
  props<{ err: any }>()
);

export const updateProtocolModel = createAction(
  ProtocolActionTypes.UPDATE_PROTOCOL_MODEL,
  props<{ data: any }>()
);

export const loadFormFromProtocol = createAction(
  ProtocolActionTypes.LOAD_FORM_FROM_PROTOCOL,
  props<{ templateId: string }>()
);
export const loadFormFromProtocolSuccess = createAction(
  ProtocolActionTypes.LOAD_FORM_FROM_PROTOCOL_SUCCESS,
  props<{
    template: Template;
    form: IForm;
    datasets: any;
    fields: FormlyFieldConfig[];
    data?: any;
  }>()
);
export const loadFormFromProtocolFailure = createAction(
  ProtocolActionTypes.LOAD_FORM_FROM_PROTOCOL_FAILURE,
  props<{ err: any }>()
);
export const exitProtocolPage = createAction(ProtocolActionTypes.EXIT_PROTOCOL);
