import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';

import {
  AccountFacade,
  accountsLoaded,
  getLastUpdateDate,
} from '@accounting/data';
import { Observable, forkJoin, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  first,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { CaseFacade, LookUpFacade } from '@nfr/data/session';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AppResolver implements Resolve<any> {
  baseUrl = 'http://localhost';
  routeParams: any;
  user: any;
  loading: boolean;

  constructor(
    private store: Store,
    private accountFacade: AccountFacade,
    public lookup: LookUpFacade,
    private stateFacade: CaseFacade
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    this.stateFacade.startNewSession();
    return this.loadAccounts();
  }
  loadAccounts() {
    return forkJoin([
      this.accountFacade.getLastUpdatedDate().pipe(take(1), catchError((error: unknown) => of(`Error occurred: ${error}`))),
      this.store.select(accountsLoaded).pipe(take(1), catchError((error: unknown) => of(`Error occurred: ${error}`))),
      this.store.select(getLastUpdateDate).pipe(take(1), catchError((error: unknown) => of(`Error occurred: ${error}`))),
    ]).pipe(
      switchMap((obj) => {
        const serverDate = new Date(obj[0]);
        const loaded = obj[1];
        const cachedDate = obj[2];
        if (!this.loading && (!loaded || serverDate > cachedDate)) {
          this.loading = true;
          this.accountFacade.loadAccounts();
        }
        return of(true);
      }),
      tap((_) => {
        this.lookup.loadLookUp();
      })
    );
  }
}
