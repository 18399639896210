import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteSelectionDirective } from './force-selection.directive';




@NgModule({
  declarations: [AutocompleteSelectionDirective],
  imports: [
    CommonModule
  ],
  exports: [AutocompleteSelectionDirective]
})
export class AutocompleteSelectionModule { }
