import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-collapse-panel',
  template: `


      <mat-expansion-panel #panel class="mat-elevation-z0" hideToggle="true" >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="mr-3" >{{panel.expanded? 'remove' : 'add'}}</mat-icon>
            <a>{{ props.label }}</a>
          </mat-panel-title>
         </mat-expansion-panel-header>
        <ng-container #fieldComponent></ng-container>
      </mat-expansion-panel>



  `,
  styles: [`
    .mat-expansion-panel {
      background: transparent !important;

    }
  `]
})
export class CollapsePanelWrapperComponent extends FieldWrapper {
}
