import { CallForm, CreateCallDTO } from '@nfr/common';
import { createAction, props } from '@ngrx/store';

export enum CallActionTypes {
  INIT = '[Call] Call Init',
  CALL_STARTED = '[Call] Call Started',
  CALL_START_FAILURE = '[Call] Call Start Failed',
  CREATE_CALL = '[Call] Create Call',
  CALL_ENDED = '[Call] Call Ended',
  CALL_DIALOG_CANCELLED = '[Call] Call Dialog Cancelled',
  PARTICIPANT_ADDED = '[Call] Add Participant',
  ACCOUNT_SELECTED = '[Call] Select Account',
  PHONE_SYSTEM_ID_SELECTED = '[Call] Phone System Id Selected',
  SEARCH_CASES_OPENED = '[Call] Search Cases Opened',
  CALL_MODE_SELECTED = '[Call] Call Mode Selected',
  CALL_ELIGIBILITY_UPDATED = '[Call] Call Eligibility Updated',
  CREATE_SEARCH_CALL = '[Call] Create Search Call',
}

export const initCall = createAction(CallActionTypes.INIT);
export const callStarted = createAction(
  CallActionTypes.CALL_STARTED,
  props<{ call: CallForm }>()
);
export const callStartFailure = createAction(
  CallActionTypes.CALL_START_FAILURE,
  props<{ err: any }>()
);
export const createCall = createAction(
  CallActionTypes.CREATE_CALL,
  props<{ call: CreateCallDTO }>()
);
//export const createSearchCall = createAction(CallActionTypes.CREATE_SEARCH_CALL, props<{call: CreateCaseSerchDTO}>());
export const callDialogCancelled = createAction(
  CallActionTypes.CALL_DIALOG_CANCELLED
);
export const callEnded = createAction(CallActionTypes.CALL_ENDED);
export const participantAdded = createAction(
  CallActionTypes.PARTICIPANT_ADDED,
  props<{ participant: any }>()
);
export const callModeSelected = createAction(
  CallActionTypes.CALL_MODE_SELECTED,
  props<{ newCase: boolean }>()
);
export const accountSelected = createAction(
  CallActionTypes.ACCOUNT_SELECTED,
  props<{ account: any }>()
);
export const phoneSystemIdSelected = createAction(
  CallActionTypes.PHONE_SYSTEM_ID_SELECTED,
  props<{ phoneSystemId: string }>()
);
export const searchCasesOpened = createAction(
  CallActionTypes.SEARCH_CASES_OPENED
);
export const callEligibilityUpdated = createAction(
  CallActionTypes.CALL_ELIGIBILITY_UPDATED,
  props<{ data: any }>()
);
