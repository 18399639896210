import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as userReducer from '../reducers/user-entity.reducer';
import { UserState } from '../reducers';
import { selectRouteParams } from '@autobot/core';

export const selectUserFeatureState =
  createFeatureSelector<UserState>('users');
export const selectUserState = createSelector(
  selectUserFeatureState,
  (state) => state.users
);
export const selectUserEntities = createSelector(
  selectUserState,
  userReducer.selectEntities
);

export const usersLoaded = createSelector(
  selectUserState,
  (state) => state.loaded
);
export const usersLoading = createSelector(
  selectUserState,
  (state) => state.loading
);

export const getSelectedUser = createSelector(
  selectUserState,
  (state) => state.entities[state.selectedUserId] ?? state.entities[0]
);

export const getAllUsers = createSelector(
  selectUserState,
  userReducer.selectIds,
  userReducer.selectEntities,
  userReducer.selectAll
);

export const selectSingleUser = (id) =>
  createSelector(selectUserEntities, (entities) => entities[id]);

export const getUserToEdit = createSelector(
  selectUserEntities,
  selectRouteParams,
  (users, { userId }) => users[userId]
);
