<div class="dialog-container">
  <h1 class="title" *ngIf="title">{{ title }}</h1>

  <div class="content" *ngIf="content" [innerHTML]="content"></div>
  <div class="content-comp-container" #contentContainer></div>

  <div class="btn-container">
    <button
      color="accent"
      *ngIf="cancelBtnText"
      mat-button
      mat-flat-button
      mat-accent
      [mat-dialog-close]="false"
      cdkFocusInitial
    >
      <span>{{ cancelBtnText }}</span>
    </button>

    <button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="true"
      *ngIf="confirmBtnText"
    >
      <span>{{ confirmBtnText }}</span>
    </button>
  </div>
</div>
