import { User, Customer, AppRole } from "@accounting/common";
import {createAction, props} from "@ngrx/store";


export enum ManagementEditorActionTypes {
  INIT_USER = '[User/Editor Page] Init',
  SAVE_USER = '[User/Editor Page] Save User',
  SAVE_USER_SUCCESS = '[User/Editor Page] Save User Success',
  SAVE_USER_FAILURE = '[User/Editor Page] Save User Failure',

  INIT_CUSTOMER = '[Customer/Editor Page] Init',
  SAVE_CUSTOMER = '[Customer/Editor Page] Save Customer',
  SAVE_CUSTOMER_SUCCESS = '[Customer/Editor Page] Save Customer Success',
  SAVE_CUSTOMER_FAILURE = '[Customer/Editor Page] Save Customer Failure',

  INIT_ROLE = '[Role/Editor Page] Init',
  SAVE_ROLE = '[Role/Editor Page] Save Role',
  SAVE_ROLE_SUCCESS = '[Role/Editor Page] Save Role Success',
  SAVE_ROLE_FAILURE = '[Role/Editor Page] Save Role Failure',
}
export const init_user = createAction(ManagementEditorActionTypes.INIT_USER,  props<{user: User}>());
export const saveUser = createAction(ManagementEditorActionTypes.SAVE_USER, props<{user: User}>());
export const saveUserSuccess = createAction(ManagementEditorActionTypes.SAVE_USER_SUCCESS);
export const saveUserFailure = createAction(ManagementEditorActionTypes.SAVE_USER_FAILURE,props<{err: any}>());

export const init_customer = createAction(ManagementEditorActionTypes.INIT_CUSTOMER,  props<{customer: Customer}>());
export const saveCustomer = createAction(ManagementEditorActionTypes.SAVE_CUSTOMER, props<{customer: Customer}>());
export const saveCustomerSuccess = createAction(ManagementEditorActionTypes.SAVE_CUSTOMER_SUCCESS);
export const saveCustomerFailure = createAction(ManagementEditorActionTypes.SAVE_CUSTOMER_FAILURE,props<{err: any}>());

export const init_role = createAction(ManagementEditorActionTypes.INIT_ROLE,  props<{role: AppRole}>());
export const saveRole = createAction(ManagementEditorActionTypes.SAVE_ROLE, props<{role: AppRole}>());
export const saveRoleSuccess = createAction(ManagementEditorActionTypes.SAVE_ROLE_SUCCESS);
export const saveRoleFailure = createAction(ManagementEditorActionTypes.SAVE_ROLE_FAILURE,props<{err: any}>());
