<h4 mat-dialog-title>Application Version</h4>
<mat-dialog-content>

  <ng-container *ngIf="version$|async as version">


     <mat-list>
       <mat-list-item>
         {{version.fullSemver}}
       </mat-list-item>
       <mat-list-item>
         {{version.lastCommitTime}}
       </mat-list-item>
       <mat-list-item>
         {{version.lastCommitMessage}}
       </mat-list-item>

     </mat-list>





  </ng-container>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
