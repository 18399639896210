import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';

import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from './router/router.state';

import { localStorageSync } from 'ngrx-store-localstorage';

import {
  settingsReducer,
  userReducer,
  UserState,
  versionReducer,
  VersionState,
} from './reducers';
import { SettingsState } from './models';

// TODO Move this function out of core project or at least parameterize it from config file.
export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  const keys = [
    'auth',
    'session',
    'settings',
    'user',
    'auth',
    'templates',
    'accounts',
  ];

  return localStorageSync({ keys, rehydrate: true })(reducer);
}

export const reducers: ActionReducerMap<AppState> = {
  version: versionReducer,
  user: userReducer,
  settings: settingsReducer,
  router: routerReducer,
};

export const metaReducers: MetaReducer<AppState>[] = [localStorageSyncReducer];

// if (!environment.production) {
//   if (!environment.test) {
//     metaReducers.unshift(debug);
//   }
// }

export const selectSettingsState =
  createFeatureSelector<SettingsState>('settings');

export const selectRouterState =
  createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectVersionState =
  createFeatureSelector<VersionState>('version');
export const getVersionState = createSelector(
  selectVersionState,
  (state) => state
);
export interface AppState {
  version: VersionState;
  user: UserState;
  settings: SettingsState;
  router: RouterReducerState<RouterStateUrl>;
}
