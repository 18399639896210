import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { MappingService } from '@forms/common';
import { catchError, first, forkJoin, map, of, switchMap } from 'rxjs';
import { ProtocolActions } from '../actions';
import {
  getProtocolMainModel,
  selectAssembly,
  selectModel,
} from '../selectors';
import { LookUpFacade } from '../services/lookup.facade';
import { getLookUpDatasets } from '../selectors/lookup.selectors';
import { TemplateApiService } from '@forms/data/templates';

@Injectable()
export class ProtocolEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: TemplateApiService,
    private lookup: LookUpFacade,
    private mapping: MappingService
  ) {}

  loadProtocolForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProtocolActions.loadProtocol),
      switchMap(({ templateIds, protocolType }) => {
        return this.service.loadProtocolDetails(templateIds, protocolType);
      }),
      concatLatestFrom(() => this.store.select(getLookUpDatasets)),
      concatLatestFrom(() => this.store.select(getProtocolMainModel)),
      map((_) => {
        const data = _[0][0];
        const datasetResult = _[0][1];
        const model = _[1];
        var mappedTemplate = this.mapping.mapMedicalProtocolTemplate1(
          data,
          datasetResult
        )[0];
        return ProtocolActions.loadProtocolSuccess({
          medicalProtocol: mappedTemplate,
          data: model,
          datasets: datasetResult,
        });
      }),
      catchError((error: unknown) => {
        return of(
          ProtocolActions.loadProtocolFailure({
            err: error,
          })
        );
      })
    );
  });

  loadABCDForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProtocolActions.loadABCDProtocol),
      switchMap(({ templateIds, protocolType }) => {
        return this.service.loadProtocolDetails(templateIds, protocolType);
      }),
      concatLatestFrom(() => this.lookup.lookUpDataSet$),
      concatLatestFrom(() => this.store.select(getProtocolMainModel)),
      map((_) => {
        const data = _[0][0];
        const datasetResult = _[0][1];
        const model = _[1];
        var mappedTemplate = this.mapping.mapMedicalProtocolTemplate1(
          data,
          datasetResult
        )[0];
        return ProtocolActions.loadABCDProtocolSuccess({
          medicalProtocol: mappedTemplate,
          data: model,
          datasets: datasetResult,
        });
      })
    );
  });

  loadFormFromAssembly$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProtocolActions.loadFormFromProtocol),
      switchMap(({ templateId }) => {
        return this.lookup.lookUpDataSet$.pipe(
          map((r) => {
            return {
              templateId,
              datasets: r,
            };
          })
        );
      }),
      concatLatestFrom((_) =>
        this.store.select(selectAssembly).pipe(
          map((r) => {
            const template = r.forms.filter(
              (e) => e.templateId == _.templateId
            )[0];

            return {
              template,
              datasets: _.datasets,
            };
          })
        )
      ),
      concatLatestFrom(() => this.store.select(selectModel)),
      map((_) => {
        const data = _[0][1];
        const model = _[1];
        const form = data.template;
        const datasets = data.datasets;

        const fields = this.mapping.convertToNoWrapPropFields(
          form.template,
          datasets
        );

        return ProtocolActions.loadFormFromProtocolSuccess({
          template: form.template,
          form,
          fields,
          data: model,
          datasets,
        });
      })
    );
  });
}
