import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import {SelectableMatSelect} from "./selectable-material-controls";

@Component({
  selector: 'formly-field-noblock-tabs',
  template: `
    <mat-tab-group>
      <mat-tab *ngFor="let tab of field.fieldGroup; let i = index; let last = last;"
               [label]="tab.props?.label ?? ''">
        <formly-field [field]="tab"></formly-field>

        <button *ngIf="last" class="btn btn-primary" [disabled]="!form.valid" type="submit">
          Submit
        </button>
      </mat-tab>
    </mat-tab-group>
  `,
})
export class NonblockingMaterialTabsType extends FieldType {
  isValid(field: FormlyFieldConfig): boolean {
    if(field)
    if (field.key) {
      if(field.formControl == undefined) return true;
      return field.formControl.valid
    }

    return field.fieldGroup
      ? field.fieldGroup.every((f) => this.isValid(f))
      : true;
  }
}


export const noBlockingTabs = {
  name: 'noblock-tabs',
  component: NonblockingMaterialTabsType

};
