import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {LetModule} from "@ngrx/component";
import { AddressVerificationComponent } from './address-verification.component';

@NgModule({
  declarations: [AddressVerificationComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    LetModule
  ],
  exports: [AddressVerificationComponent],
})
export class AddressVerificationModule {}
