import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material/form-field';
import {
  FieldTypeConfig,
  FormlyConfig,
  FormlyFieldConfig,
  FormlyFieldProps,
  ɵobserve as observe,
} from '@ngx-formly/core';
import { FormlyFieldDatepicker } from '@ngx-formly/material/datepicker';
import {
  MatCalendarCellClassFunction,
  MatDatepicker,
} from '@angular/material/datepicker';
import { ComponentType } from '@angular/cdk/overlay';

interface DatepickerProps extends FormlyFieldProps {
  datepickerOptions?: Partial<{
    touchUi: boolean;
    opened: boolean;
    disabled: boolean;
    startView: 'month' | 'year' | 'multi-year';
    datepickerTogglePosition: 'suffix' | 'prefix';
    calendarHeaderComponent: ComponentType<any>;
    filter: (date: any | null) => boolean;
    min: any;
    max: any;
    dateInput: (field: FieldTypeConfig<DatepickerProps>, event: any) => void;
    dateChange: (field: FieldTypeConfig<DatepickerProps>, event: any) => void;

    monthSelected: (
      field: FieldTypeConfig<DatepickerProps>,
      event: any,
      picker: MatDatepicker<any>
    ) => void;
    yearSelected: (
      field: FieldTypeConfig<DatepickerProps>,
      event: any,
      picker: MatDatepicker<any>
    ) => void;

    dateClass: MatCalendarCellClassFunction<any>;
    panelClass: string | string[];
    startAt: any | null;
  }>;
}

export interface FormlyDatepickerFieldConfig
  extends FormlyFieldConfig<DatepickerProps> {
  type: 'datepicker' | Type<FormlyFieldDatepicker>;
}

@Component({
  template: `
    <input
      matInput
      [id]="id"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="formControl"
      [matDatepicker]="picker"
      [matDatepickerFilter]="props.datepickerOptions.filter"
      [min]="props.datepickerOptions.min"
      [max]="props.datepickerOptions.max"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder"
      [tabindex]="props.tabindex"
      [readonly]="props.readonly"
      [required]="required"
      (dateInput)="props.datepickerOptions.dateInput(field, $event)"
      (dateChange)="props.datepickerOptions.dateChange(field, $event)"
    />
    <ng-template #dateToggle>
      <mat-datepicker-toggle
        (click)="detectChanges()"
        [disabled]="props.disabled"
        [for]="picker"
      ></mat-datepicker-toggle>
    </ng-template>
    <mat-datepicker
      #picker
      [dateClass]="props.datepickerOptions.dateClass"
      [disabled]="props.datepickerOptions.disabled"
      [opened]="props.datepickerOptions.opened"
      [panelClass]="props.datepickerOptions.panelClass"
      [startAt]="props.datepickerOptions.startAt"
      [startView]="props.datepickerOptions.startView"
      [touchUi]="props.datepickerOptions.touchUi"
      [calendarHeaderComponent]="
        props.datepickerOptions.calendarHeaderComponent
      "
      (monthSelected)="
        props.datepickerOptions.monthSelected(field, $event, picker)
      "
      (yearSelected)="
        props.datepickerOptions.yearSelected(field, $event, picker)
      "
      (opened)="props.datepickerOptions.opened = true"
      (closed)="props.datepickerOptions.opened = false"
    >
    </mat-datepicker>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDatePickerType
  extends FieldType<FieldTypeConfig<DatepickerProps>>
  implements AfterViewInit, OnDestroy
{
  @ViewChild(MatInput) formFieldControl!: MatInput;
  get dateMask() {
    return 'd0/M0/0000';
  }
  @ViewChild('dateToggle', { static: true })
  datepickerToggle!: TemplateRef<any>;

  override defaultOptions = {
    props: {
      datepickerOptions: {
        startView: 'month' as const,
        datepickerTogglePosition: 'suffix' as const,
        max:new Date(),
        disabled: false,
        opened: false,
        dateInput: () => {},
        dateChange: () => {},
        monthSelected: () => {},
        yearSelected: () => {},
      },
    },
  };
  private fieldErrorsObserver!: ReturnType<typeof observe>;

  constructor(private config: FormlyConfig, private cdRef: ChangeDetectorRef) {
    super();
  }

  detectChanges() {
    this.options.detectChanges?.(this.field);
  }

  ngAfterViewInit() {
    this.props[this.props.datepickerOptions.datepickerTogglePosition] =
      this.datepickerToggle;
    observe<boolean>(
      this.field,
      ['props', 'datepickerOptions', 'opened'],
      () => {
        this.cdRef.detectChanges();
      }
    );

    // temporary fix for https://github.com/angular/components/issues/16761
    if (this.config.getValidatorMessage('matDatepickerParse')) {
      this.fieldErrorsObserver = observe<any>(
        this.field,
        ['formControl', 'errors'],
        ({ currentValue }) => {
          if (
            currentValue &&
            currentValue.required &&
            currentValue.matDatepickerParse
          ) {
            const errors = Object.keys(currentValue)
              .sort((prop) => (prop === 'matDatepickerParse' ? -1 : 0))
              .reduce(
                (errors, prop) => ({ ...errors, [prop]: currentValue[prop] }),
                {}
              );

            this.fieldErrorsObserver?.setValue(errors);
          }
        }
      );
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.fieldErrorsObserver?.unsubscribe();
  }
}
