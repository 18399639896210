import { F } from "@angular/cdk/keycodes";
import { AuthConfig } from "@autobot/authentication";
import { IEnvironmentConfig } from "@autobot/core";
import { LogLevel } from "@azure/msal-browser";

export const DefaultConfig: IEnvironmentConfig = {
  displayName:"StrataNT",
  siteName: "Agent advance portal",
  production: false,
  apiConfig: {
    accountsUri: "https://clinical-api-dev.services.conduent.com/accounts",
    casesUri: "https://clinical-api-dev.services.conduent.com/cases",
    formsUri: "https://clinical-api-dev.services.conduent.com/forms",
    managementUri: "https://clinical-api-dev.services.conduent.com/management",
  },
  telemetry:{
    connectionString:"InstrumentationKey=266ab0d6-1084-463d-b912-6431cd52f782;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=cc577ba0-81ce-4fe1-8777-e8c9ad9c50fe"
  },
  ngrx: {
    hydrate: ["users", "auth", "forms", "tabs", "areas"],
  },
  isServed: false,
  withError: false,
  smartyStreetKey:'106663747334600194',
};

export const DefaultAuthSettings: AuthConfig = {
  auth: {
    clientId: "3d6d7d6a-7ab4-47ca-bcb0-589af2af5eb5",
    redirectUri: "/",
    postLogoutRedirectUri: "/welcome",
    navigateToLoginRequestUrl: false
  },
  b2cPolicies: {
    names: {
      signUpSignIn: "B2C_1A_NFR_signin",
      resetPassword: "B2C_1A_PASSWORDRESET",
      editProfile: "B2C_1A_PROFILEEDIT",
    },
    authorities: {
      signUpSignIn: {
        authority: "https://cccsclinicalservice.b2clogin.com/cccsclinicalservice.onmicrosoft.com/B2C_1A_NFR_signin",
      },
      resetPassword: {
        authority: "https://cccsclinicalservice.b2clogin.com/cccsclinicalservice.onmicrosoft.com/B2C_1A_PASSWORDRESET",
      },
      editProfile: {
        authority: "https://cccsclinicalservice.b2clogin.com/cccsclinicalservice.onmicrosoft.com/B2C_1A_PROFILEEDIT",
      },
    },
    authorityDomain: "cccsclinicalservice.b2clogin.com",
  },
  guard: {
    interactionType: "redirect",
    authRequest: {
      redirectUri: "/",
      scopes: ["https://cccsclinicalservice.onmicrosoft.com/cccs-apis/read",
        "https://cccsclinicalservice.onmicrosoft.com/cccs-apis/write"]
    },
    loginFailedRoute: "/login-failed"
  },
  apiConfig: {
    profileUri: "https://clinical-api-dev.services.conduent.com/management",
    scopes: [
      "https://cccsclinicalservice.onmicrosoft.com/cccs-apis/read",
      "https://cccsclinicalservice.onmicrosoft.com/cccs-apis/write",
    ]
  },
  interceptor: {
    interactionType: "redirect",
    protectedResourceMap: [
      [
        "https://clinical-api-dev.services.conduent.com/accounts",
        [
          "https://cccsclinicalservice.onmicrosoft.com/cccs-apis/read",
          "https://cccsclinicalservice.onmicrosoft.com/cccs-apis/write"
        ]
      ],
      [
        "https://clinical-api-dev.services.conduent.com/forms",
        [
          "https://cccsclinicalservice.onmicrosoft.com/cccs-apis/read",
          "https://cccsclinicalservice.onmicrosoft.com/cccs-apis/write"
        ]
      ],
      [
        "https://clinical-api-dev.services.conduent.com/cases",
        [
          "https://cccsclinicalservice.onmicrosoft.com/cccs-apis/read",
          "https://cccsclinicalservice.onmicrosoft.com/cccs-apis/write"
        ]
      ],
      [
        "https://clinical-api-dev.services.conduent.com/management",
        [
          "https://cccsclinicalservice.onmicrosoft.com/cccs-apis/read",
          "https://cccsclinicalservice.onmicrosoft.com/cccs-apis/write"
        ]
      ],
      ["https://graph.microsoft.com/v1.0/me", ["user.read"]]
    ]
  },
  isServed: false,
  withError: false,
  loggerOptions:{
    logLevel: LogLevel.Error,
    piiLoggingEnabled:false
  }
};
