<h2 mat-dialog-title>Search Employees</h2>
<mat-dialog-content class="mat-typography">
  <div class="p-0" fxLayout="column">
    <div class="example-container">
      <div class="example-loading-shade" *ngIf="loadingResults$ | async">
        <mat-spinner></mat-spinner>
      </div>

      <mat-form-field appearance="standard" class="w-50">
        <mat-label>Filter</mat-label>
        <input
          matInput
          placeholder="Ex. ium"
          #input
          [formControl]="searchControl"
        />
      </mat-form-field>

      <div class="example-table-container">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          matSortActive="lastName"
          matSortDisableClear
          matSortDirection="desc"
          class="rule-table"
        >
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>First Name</th>
            <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>Last Name</th>
            <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
          </ng-container>
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef>Company</th>
            <td mat-cell *matCellDef="let element">{{ element.company }}</td>
          </ng-container>
          <!-- Symbol Column -->
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef>City</th>
            <td mat-cell *matCellDef="let element">
              {{ element.address.city }}
            </td>
          </ng-container>
          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>State</th>
            <td mat-cell *matCellDef="let element">
              {{ element.address.state }}
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="select(row)"
            (dblclick)="loadSelectedUser(row)"
            [class.selected]="selected === row"
          ></tr>
        </table>
        <mat-paginator
          [length]="resultsLength$ | async"
          [pageSize]="30"
          aria-label="Select page of GitHub search results"
        ></mat-paginator>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button cdkFocusInitial>Ok</button>
</mat-dialog-actions>
