import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Update } from '@ngrx/entity';
import { AccountsApiActionTypes } from '../actions/account-api.actions';

import { AccountApiActions } from '../actions';
import {
  Account,
  AccountActivation,
  AccountApiService,
} from '@accounting/common';
import { AlertService } from '@forms/common';

@Injectable()
export class AccountApiEffects {
  ACCOUNT_ACTIONS_SUCCESS = [
    AccountApiActions.loadAccountsSuccess,
    AccountApiActions.createAccountSuccess,
    AccountApiActions.updateAccountSuccess,
    AccountApiActions.activateAccountSuccess
  ];

  ACCOUNT_ACTIONS_FAILURE = [
    AccountApiActions.loadAccountsFailure,
    AccountApiActions.createAccountFailure,
    AccountApiActions.updateAccountFailure,
    AccountApiActions.activateAccountFailure
  ];

  constructor(
    private actions$: Actions,
    private api: AccountApiService,
    private note: AlertService
  ) {}

  loadAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountApiActions.loadAccounts),
      switchMap(() =>
        this.api.getAll().pipe(
          map((accounts) => {
            return AccountApiActions.loadAccountsSuccess({ accounts });
          }),
          catchError((err: unknown) =>
            of(AccountApiActions.loadAccountsFailure({ err }))
          )
        )
      )
    );
  });

  loadAccountConfigs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountApiActions.loadAccountConfigs),
      switchMap(({ id }) =>
        this.api.getConfigs(id).pipe(
          map((configs) => {
            return AccountApiActions.loadAccountConfigsSuccess({
              id: id,
              configs: configs,
            });
          }),
          catchError((err: unknown) =>
            of(AccountApiActions.loadAccountConfigsFailure({ err }))
          )
        )
      )
    );
  });

  updateAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountApiActions.updateAccount),
      switchMap(({ account }) =>
        this.api.update(account.id, account).pipe(
          map((temp: Account) => {
            const account: Update<Account> = {
              id: temp.id,
              changes: temp,
            };
            return AccountApiActions.updateAccountSuccess({ account });
          }),
          catchError((err: unknown) =>
            of(AccountApiActions.updateAccountFailure({ err }))
          )
        )
      )
    );
  });

  createAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountApiActions.createAccount),
      switchMap(({ account }) =>
        this.api.create(account).pipe(
          map((account: Account) => {
            return AccountApiActions.createAccountSuccess({ account });
          }),
          catchError((err: unknown) =>
            of(AccountApiActions.createAccountFailure({ err }))
          )
        )
      )
    );
  });

  deleteAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountApiActions.deleteAccount),
      switchMap(({ id }) =>
        this.api.delete(id).pipe(
          map(() => {
            return AccountApiActions.deleteAccountSuccess({ id });
          }),
          catchError((err: unknown) =>
            of(AccountApiActions.createAccountFailure({ err }))
          )
        )
      )
    );
  });

  activateAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountApiActions.activateAccount),
      switchMap(({ account }) =>
        this.api.activateAccount(account.id, account).pipe(
          map((update: Account) => {
            const accntupd: Update<Account> = {
              id: update.id,
              changes: update,
            };
            return AccountApiActions.activateAccountSuccess({ account:accntupd });
          }),
          catchError((err: unknown) =>
            of(AccountApiActions.activateAccountFailure({ err }))
          )
        )
      )
    );
  });

  successNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(...this.ACCOUNT_ACTIONS_SUCCESS),

        tap((e) => this.note.success(this.customMessage(e.type) ?? 'Success'))
      ),
    { dispatch: false }
  );

  failedNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(...this.ACCOUNT_ACTIONS_FAILURE),
        tap((err) => this.note.error('Error'))
      ),
    { dispatch: false }
  );

  customMessage(val: string) {
    const result = Object.keys(AccountsApiActionTypes).find(
      (key) => AccountsApiActionTypes[key] === val
    );
    const findThis = result ?? '';
    const type = AccountsApiActionTypes[findThis] as AccountsApiActionTypes;

    switch (type) {
      case AccountsApiActionTypes.CREATE_ACCOUNT_SUCCESS:
        return 'Account Successfully Added';
        break;
      case AccountsApiActionTypes.CREATE_ACCOUNT_FAILURE:
        return 'Error adding Account';
        break;

      case AccountsApiActionTypes.UPDATE_ACCOUNT_SUCCESS:
        return 'Account Successfully Updated';
        break;
      case AccountsApiActionTypes.UPDATE_ACCOUNT_FAILURE:
        break;
      case AccountsApiActionTypes.DELETE_ACCOUNT_SUCCESS:
        return 'Account Successfully Deleted';
        break;
      case AccountsApiActionTypes.DELETE_ACCOUNT_FAILURE:
        break;
      case AccountsApiActionTypes.LOAD_ACCOUNTS_SUCCESS:
        return 'Loaded Accounts';
        break;
      case AccountsApiActionTypes.LOAD_ACCOUNTS_FAILURE:
        return 'Error Loading Accounts';
        break;
      default: {
        return;
      }
    }

    return '';
  }
}
