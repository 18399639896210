<mat-form-field fxFlex="100" style="width: 100%">
    <mat-label>
      Search Home Address
    </mat-label>
  
    <input
      matInput
      [matAutocomplete]="auto"
      [formControl]="addressControl"
      autobotForceSelection
      (keyup)="onKeyup($event)"
    />
    <a
      *ngIf="addressControl?.value"
      matSuffix
      aria-label="Clear"
      (click)="clear()"
    >
      <mat-icon style="font-size: 12px; height: 12px">close</mat-icon>
    </a>
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
     
      <mat-option
        *ngFor="let opt of lookupResults$ | async"
        [value]="opt.label"
        (onSelectionChange)="onSelectionChange(opt)"
        [title]="opt.label"
      >
        {{ opt.label }}
      </mat-option>
    </mat-autocomplete>
   
  </mat-form-field>
  