import { Injectable } from '@angular/core';
import { ComponentStore, OnStoreInit } from '@ngrx/component-store';
import { CallForm, Case } from '@nfr/common';
import { AccountMinimalView } from '@accounting/common';
import { FormBuilder } from '@angular/forms';
import { AuthenticationFacade } from '@autobot/authentication';

export enum CallDialogStages {
  err = -999,
  cancellation = -1,
  init,
  caseCreation,
  confirmation,
}

export interface NewCallDialogState {
  data: {
    call?: CallForm;
    selectedAccount?: AccountMinimalView;
    case?: Case;
  };
  accounts: any[];
  caseCreated?: Case;
  status: {
    stage: CallDialogStages;
    processing: boolean;
    message: string;
    log: string[];
  };
  config: {
    hideAddParticipantButton: boolean;
    fakeParticipants: boolean;
  };
  error?: any;
}

@Injectable({ providedIn: 'any' })
export class NewCallStore
  extends ComponentStore<NewCallDialogState>
  implements OnStoreInit
{
  constructor(
    private fb: FormBuilder,
    private readonly auth: AuthenticationFacade
  ) {
    super({
      accounts: [],
      data: {
        call: undefined,
        selectedAccount: undefined,
        case: undefined,
      },
      status: {
        stage: 0,
        processing: false,
        message: 'Init',
        log: [],
      },
      config: {
        hideAddParticipantButton: true,
        fakeParticipants: true,
      },
    });
  }
  readonly user$ = this.auth.profile$;

  readonly accounts$ = this.select(({ accounts }) => accounts);
  readonly config$ = this.select(({ config }) => config);
  readonly status$ = this.select(({ status }) => status);
  readonly data$ = this.select(({ data }) => data);
  readonly vm$ = this.select(
    this.user$,
    this.config$,
    this.accounts$,
    (user, config, accounts) => {
      return {
        user,
        config,
        accounts,
      };
    }
  );

  readonly updateCallData = this.updater((state, call: CallForm) => {
    return {
      ...state,
      data: {
        ...state.data,
        call,
      },
    };
  });
  readonly updateCaseData = this.updater((state, _case: Case) => {
    return {
      ...state,
      data: {
        ...state.data,
        case: _case,
      },
    };
  });
  readonly updateSelectedAccount = this.updater(
    (state, account: AccountMinimalView) => {
      return {
        ...state,
        data: {
          ...state.data,
          selectedAccount: account,
        },
      };
    }
  );
  readonly updateCallDialogStage = this.updater(
    (state, stage: CallDialogStages) => {
      return {
        ...state,
        status: {
          ...state.status,
          stage,
        },
      };
    }
  );
  readonly updateProcessing = this.updater((state, processing: boolean) => {
    return {
      ...state,
      status: {
        ...state.status,
        processing,
      },
    };
  });
  readonly updateMessage = this.updater((state, message: string) => {
    return {
      ...state,
      status: {
        ...state.status,
        message,
        log: [...state.status.log, message],
      },
    };
  });
  readonly updateLoaded = this.updater((state, loaded: boolean) => {
    return {
      ...state,
      loaded,
    };
  });

  readonly loadAccounts = this.updater(
    (state, accounts: AccountMinimalView[]) => {
      return {
        ...state,
        accounts,
      };
    }
  );
  ngrxOnStoreInit() {}

  startCaseCreation() {
    this.updateMessage('Creating New Case');
    this.updateCallDialogStage(CallDialogStages.caseCreation);
    this.updateProcessing(true);
  }
}
