
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { concatMap, filter, finalize, first, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { DashboardService } from "./dashboard.service";

import { DashboardActions } from "../actions";
import { DashboardState } from "../reducers/dashboard.reducer";
import { isDashboardLoaded } from "../selectors/dashboard.selectors";
import { AccountFacade, accountsLoaded } from "@accounting/data";


@Injectable({
  providedIn: 'root',
})
export class DashboardResolver implements Resolve<any> {
  baseUrl = 'http://localhost';
  routeParams: any;
  user: any;
  loading: boolean;

  constructor(
    private store: Store<DashboardState>,
    private accountFacade: AccountFacade
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;

    this.loadAccounts();
    return this.loadAccountsMinimalTree();
  }

  loadAccounts() {
    this.store
      .pipe(
        select(accountsLoaded),
        tap(loaded => {
          if (!this.loading && !loaded) {
            this.loading = true;
            this.accountFacade.loadAccounts()
          }
        }),
        filter(loaded => loaded),
        first()

      );
  }

  loadAccountsMinimalTree() {
    return this.store
      .pipe(
        select(isDashboardLoaded),
        tap(dashboardLoaded => {
          if (!this.loading && !dashboardLoaded) {
            this.loading = true;
            this.store.dispatch(DashboardActions.loadAccountsTree());
          }
        }),
        filter(dashboardLoaded => dashboardLoaded),
        first(),
        finalize(() => this.loading = false)
      );
  }

}
