import { AfterContentInit, Component, ElementRef, ChangeDetectionStrategy} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import {VisiblePipe} from "./visible.pipe";


@Component({
  selector: "formly-field-tabset",
  template: `
    <mat-tab-group animationDuration="0ms" class="dynamic-height">
      <mat-tab #tab
        *ngFor="let f of tabs; let index = index;"
        [disabled]="f?.templateOptions?.disabled || ( index>0 && !isValid(index-1) )">

        <ng-template mat-tab-label>
          <span>{{ f?.templateOptions?.label }}</span>
        </ng-template>

        <formly-field [field]="f"></formly-field>

      </mat-tab>
    </mat-tab-group>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFieldTabsetComponent extends FieldType {

  constructor() {
    super();
  }

  /**
   * Tabs
   */
  get tabs(): Array<any> {
    return new VisiblePipe().transform(this.field.fieldGroup, 'templateOptions');
  }

  /**
   * Loginc is Valid
   */
  isValid(index): boolean {
    return true;
    // console.log(index, this.field.formControl.valid)
    // return this.field.fieldGroup[index].formControl.valid;
  }
}
