import { createReducer, on } from '@ngrx/store';
import { LookUpActions } from '../actions';

export const lookUpFeatureKey = 'lookUp';

export interface State {
  lookups: any[];
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  lookups: [],
  loaded: false,
  loading:false
};

export const reducer = createReducer(
  initialState,
  on(LookUpActions.loadLookup, (state, action) =>({
    ...state,
    loaded:false,
    loading:true
  })),
  on(LookUpActions.loadLookupSuccess, (state, action) => ({
    ...state,
    lookups: action.looksups,
    loaded: true,
    loading:false
  })),
  on(LookUpActions.loadLookupFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.error,
  })),
);
