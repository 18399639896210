import { Injectable } from "@angular/core";
import { getLookUpDatasets, isLookUpLoaded } from "../selectors/lookup.selectors";
import * as fromLookup from '../reducers/lookup.reducer';
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { loadLookup } from "../actions/lookup.actions";

@Injectable({ providedIn: 'root' })
export class LookUpFacade {
  lookUpDataSet$: Observable<any[]> = this.store.select(getLookUpDatasets);
  lookUpLoaded$: Observable<boolean> = this.store.select(isLookUpLoaded);

  constructor(private store: Store<fromLookup.State>) {}

  loadLookUp(){
    this.store.dispatch(loadLookup());
  }
}
