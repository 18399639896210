import { IForm, MedicalProtocol, Template } from '@forms/common';
import { createAction, props } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';

export enum CustomActionTypes {
  INIT = '[Custom Form] Init',
  LOAD_FORM_CUSTOM = '[Custom] Load Form Template From Custom Page',
  LOAD_FORM_CUSTOM_SUCCESS = '[Custom] Load Form Template From Custom Page Success',
  LOAD_FORM_CUSTOM_FAILURE = '[Custom] Load Form Template From Custom Page Failure',
  UPDATE_CUSTOM_FORM_MODEL = '[Custom] Update Model',
  UPDATE_CUSTOM_FORM_STATE = '[Custom] Update Form State',

  LOAD_PROTOCOL = '[Custom] Load Protocol Form',
  LOAD_PROTOCOL_SUCCESS = '[Custom] Load Protocol Form Success',
  LOAD_PROTOCOL_FAILURE = '[Custom] Load Protocol Form Failure',

  VALIDATE_REDIRECT_TO_LINK = '[Custom] Validate Redirect To Link',
  VALIDATE_REDIRECT_TO_LINK_SUCCESS = '[Custom] Validate Redirect To Link Success',
  REDIRECT_TO_Relative_LINK = '[Custom]  Redirect To Relative Link Url',
  EXIT = '[Custom] Exit Custom Page',
}

export const init = createAction(CustomActionTypes.INIT);

export const loadFormFromCustomPage = createAction(
  CustomActionTypes.LOAD_FORM_CUSTOM,
  props<{ templateKey: string }>()
);
export const loadFormFromCustomPageSuccess = createAction(
  CustomActionTypes.LOAD_FORM_CUSTOM_SUCCESS,
  props<{
    template: Template;
    form: IForm;
    datasets: any;
    fields: FormlyFieldConfig[];
    data?: any;
  }>()
);
export const loadFormFromCustomPageFailure = createAction(
  CustomActionTypes.LOAD_FORM_CUSTOM_FAILURE,
  props<{ err: any }>()
);

export const updateCustomModel = createAction(
  CustomActionTypes.UPDATE_CUSTOM_FORM_MODEL,
  props<{ key: any; data: any }>()
);

//#region  Protocols
export const loadProtocol = createAction(
  CustomActionTypes.LOAD_PROTOCOL,
  props<{ templateIds: string[]; protocolType: string }>()
);
export const loadProtocolSuccess = createAction(
  CustomActionTypes.LOAD_PROTOCOL_SUCCESS,
  props<{
    medicalProtocol: MedicalProtocol[];
    datasets: any;
    data?: any;
  }>()
);
export const loadProtocolFailure = createAction(
  CustomActionTypes.LOAD_PROTOCOL_FAILURE,
  props<{ err: any }>()
);
//#endregion

export const validateRedirectToLink = createAction(
  CustomActionTypes.VALIDATE_REDIRECT_TO_LINK,
  props<{ selectedMenu: any; relativeTo: any }>()
);
export const validateRedirectToLinkSuccess = createAction(
  CustomActionTypes.VALIDATE_REDIRECT_TO_LINK_SUCCESS,
  props<{ callBackData: any }>()
);

export const redirectToRelativeLink = createAction(
  CustomActionTypes.REDIRECT_TO_Relative_LINK,
  props<{ selectedMenu: any }>()
);
export const exitPage = createAction(CustomActionTypes.EXIT);
