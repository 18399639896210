import { Store } from '@ngrx/store';
import * as fromDesktop from '../reducers/desktop.reducer';
import { Observable } from 'rxjs';
import {
  getDesktopNavs,
  getLeftNavStatus,
  isLeftNavHidden,
  isLeftNavOpen,
  isRightNavOpen,
  selectDesktopLoaded,
} from '../selectors';
import {
  exitDesktop,
  loadDesktop,
  setLeftNav,
  setRightNav,
} from '../actions/desktop.actions';
import { Injectable } from '@angular/core';
import { saveCaseAndExit } from '../actions/case.actions';

@Injectable({ providedIn: 'root' })
export class DesktopFacade {
  navs$: Observable<any[]> = this.store.select(getDesktopNavs);
  desktopLoaded$: Observable<boolean> = this.store.select(selectDesktopLoaded);
  rightNavOpen$: Observable<boolean> = this.store.select(isRightNavOpen);
  leftNavOpen$: Observable<boolean> = this.store.select(isLeftNavOpen);
  leftNavHidden$: Observable<boolean> = this.store.select(isLeftNavHidden);
  leftNavStatus$: Observable<string> = this.store.select(getLeftNavStatus);

  constructor(private store: Store<fromDesktop.State>) {}

  loadDesktop() {
    this.store.dispatch(loadDesktop());
  }

  exitDesktop(save: boolean) {
    if (save) {
      this.store.dispatch(saveCaseAndExit());
    } else {
      this.store.dispatch(exitDesktop());
    }
  }

  setRightNav(side: string, isOpen: boolean) {
    this.store.dispatch(setRightNav({ isOpen }));
  }

  setLeftNav(status: string) {
    this.store.dispatch(setLeftNav({ status }));
  }
}
