import {createSelector} from "@ngrx/store";
import {selectVersionState} from "../core.state";
import {VersionState} from "../reducers";

export const selectVersion = createSelector(
  selectVersionState,
  (state: VersionState) => state
);


export const isVersionLoaded = createSelector(
  selectVersionState,
  (state: VersionState) => state.loaded
);


export const isVersionLoading = createSelector(
  selectVersionState,
  (state: VersionState) => state.loading
);

export const selectApplicationVersion = createSelector(
  selectVersion,
  (state: VersionState) => state.info
);


export const isDevelopmentBuild = createSelector(
  selectApplicationVersion,
  state => (state?.gitVersion.BranchName !== 'master')

)


export const vitalVersionInfo = createSelector(
  selectVersion,
  state => {

    const v = state.info;
    console.log(state,v)
    if(!v)return
    return {
      Branch: v.gitVersion.BranchName,
      LastCommitAuthor: v.lastCommitAuthor,
      LastCommitTime: v.lastCommitTime,
      AssemblySemver: v.gitVersion.AssemblySemVer,
      FullSemver: v.gitVersion.FullSemVer,
      Semver: v.gitVersion.SemVer,
      LastCommitMessage: v.lastCommitMessage
    };




  }
)
