import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, Observable, retry, throwError } from 'rxjs';

import {Inject, Injectable} from "@angular/core";
import {IEnvironmentConfig} from "@autobot/core";
import { MedicalProtocol, Template } from "@forms/common";

@Injectable({ providedIn: 'root' })
export class TemplateApiService {
  constructor(private http: HttpClient,@Inject('ENVIRONMENT') private environment: IEnvironmentConfig) {
}

  apiURL = this.environment.apiConfig.formsUri + '/api';

  getAll(): Observable<Template[]> {
    return this.http
      .get<Template[]>(this.apiURL + '/templates')
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  getById(id: string): Observable<Template> {
    const _url = `${this.apiURL} + '/templates/' + ${id}`;

    return this.http
      .get<Template>(_url)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  create(template: Template) {
    return this.http
      .post<Template>(this.apiURL + '/templates', template)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  update(id: string, template: Template) {
    const _url = this.apiURL + '/templates/' + id;
    return this.http
      .put<Template>(_url, template)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  delete(id: string): Observable<boolean> {
    const _url = this.apiURL + '/templates/' + id;
    return this.http
      .delete<boolean>(_url)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }


  loadProtocol(id: string, protocolType: string): Observable<Template> {
    const _url = `${this.environment.apiConfig.formsUri}/api/templates/${id}`;
    return this.http
      .get<Template>(_url)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  loadProtocolDetails(
    templateIds: string[],
    protocolType: string
  ): Observable<MedicalProtocol[]> {
    const _url = `${this.environment.apiConfig.formsUri}/api/medicalprotocols/detail`;
    let params = new HttpParams();
    params = params.append('protocolIds', JSON.stringify(templateIds));
    const options = {
      params: { protocolIds: templateIds },
    };
    return this.http
      .get<MedicalProtocol[]>(_url, options)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(msg);
  }
}
