import { HttpClient } from '@angular/common/http';
import { catchError, Observable, retry, throwError } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { Call, IEnvironmentConfig } from '@autobot/core'
import { ConfigurationService } from '@autobot/core/services';

@Injectable({providedIn:'root'})
export class CaseCoreApiService {
  constructor(
       private configService: ConfigurationService,
       private http: HttpClient
  ) {
  }

  checkduplicatePhoneSystem(phoneSystemID: string): Observable<Call[]> {
    const environment:IEnvironmentConfig = this.configService.getConfig;
    const _url = `${environment.apiConfig?.casesUri}/api/calls/${phoneSystemID}`;

    return this.http
      .get<Call[]>(_url, {
        headers: {
          Tenant: 'default',
        },
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(msg);
  }
}
