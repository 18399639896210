import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTabsModule } from "@angular/material/tabs";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyAutoProviderSearchComponent } from './formly-auto-provider-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AutoProviderSearchModule } from '@autobot/shared';

@NgModule({
    declarations: [FormlyAutoProviderSearchComponent
    ],
    imports: [
        CommonModule, FormlyModule.forChild({
            types: [
                {
                    name: 'provider-search',
                    component: FormlyAutoProviderSearchComponent
                }
            ],
        }), ReactiveFormsModule, MatInputModule, MatIconModule, MatButtonModule, AutoProviderSearchModule, MatTabsModule
    ],
    exports: [
        FormlyAutoProviderSearchComponent
    ]
})
export class FormlyAutoProviderSearchModule { }