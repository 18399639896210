import { Component, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material/form-field';
import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { MaskService } from 'ngx-mask';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  template: `
  <div class="control-container">
    <input autocomplete="off"
      matInput
      [mask]="mask"
      [id]="id"
      [type]="type || 'text'"
      [readonly]="props.readonly"
      [required]="props.required ?? false"
      [errorStateMatcher]="errorStateMatcher"
      [tabIndex]="props.tabindex"
      [placeholder]="props.placeholder"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [hiddenInput]="true"
    />
    <span mat-icon-button matSuffix *ngIf="value && !disabled" aria-label="Clear" (click)="clear()">
      <mat-icon>close</mat-icon>
    </span>
</div>
  `,
  styles: [`
  .control-container {
    display: flex;
    align-items: baseline;
  }

  .control-container span:hover {
    cursor: pointer;
  }

  .control-container .mat-icon{
    font-size: 12px;
    height: 12px;
    width: 12px;
  }
`]
})

export class SSNMaskTypeComponent extends FieldType<FieldTypeConfig> {
  @ViewChild(MatInput) formFieldControl!: MatInput;
  get type() {
    return this.props.type ?? 'text';
  }
  get mask() {
    return this.props.mask ?? 'XXXXX0000';
  }
  get prefix() {
    const pre = this.props.prefix ?? '';
    return pre;
  }
  clear() {
    this.value = '';
    this.props.readonly = false;
  }
}
@Directive({
  selector: 'input[mask]',
})
export class SSNMaskDirective {
  constructor(
    private readonly elementRef: ElementRef,
    // private ngControl: NgControl,
    private readonly renderer: Renderer2,
    private readonly maskService: MaskService
  ) {}

  @HostListener('keydown', ['$event']) private onKeyDown(
    e: KeyboardEvent
  ): void {
    const el: HTMLInputElement = e.target as HTMLInputElement;
    const position = this.maskService.maskExpression.length;
    const key = e.key || e.keyCode;
    if (key === 'Backspace' || key == 'Delete') {
      el.value = '';
    }
    /*if (key === 'Backspace' || key === 8) {
    } else {
      if (position !== el.value.length) {
        el.setSelectionRange(position, position);
      }
    }*/
    if (position !== el.value.length) {
      el.setSelectionRange(position, position);
    }
  }
}
