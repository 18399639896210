import { Injectable } from '@angular/core';
import { faker } from '@faker-js/faker';
import * as _ from 'lodash-es';
import { Account, Location } from '../models/account';
import { Guid } from '@autobot/core';
@Injectable({
  providedIn: 'root',
})
export class AccountFakerService {
  contracts: Account[] = [];
  accounts: Account[] = [];
  constructor() {}
  randomAccountList(accounts: Account[], total?: number) {
    const amt = total ?? this.randomNumber(5, 10);
    return faker.helpers.arrayElements<Account>(accounts, amt);
  }
  randomNumber(min: number, max: number) {
    return faker.datatype.number({ min, max });
  }

  get randomContract() {
    return faker.helpers.arrayElement<Account>(this.contracts);
  }
  get randomAccount() {
    return faker.helpers.arrayElement<Account>(this.accounts);
  }

  createAccountList(): Account[] {
    /// Create random number of contract accounts
    this.contracts = this.createContractAccounts(5);
    this.accounts = this.createStandardAccounts(
      20,
      'account',
      this.randomContract
    );

    const subAccounts = this.createStandardAccounts(
      25,
      'sub',
      this.randomAccount
    );

    const result = _.concat(this.contracts, this.accounts, subAccounts);
    return result;
  }

  createContractAccounts(amount: number) {
    const parentAccounts = [];

    for (let i = 0; i < amount; i++) {
      const parent = this.createAccount('contract');
      parentAccounts.push(parent);
    }
    return parentAccounts;
  }
  createStandardAccounts(
    amount: number,
    accountType: string,
    parent?: Account
  ) {
    const accounts = [];

    for (let i = 0; i < amount; i++) {
      const account = this.createAccount(accountType, parent);
      accounts.push(account);
    }
    return accounts;
  }

  createAccount(accountType: string, parent?: Account): Account {
    const homeAddressState = faker.address.state();
    const companyName = faker.company.name();
    const shortName = companyName.trim().substring(0, 6);
    const address = {
      address1: faker.address.streetAddress(),
      city: faker.address.city(),
      state: homeAddressState,
      zip: faker.address.zipCodeByState(homeAddressState),
      country: faker.address.country(),
    };
    const locations = accountType !== 'contract' ? this.createLocations(3) : [];
    return {
      id: Guid.newGuid(),
      accountType: accountType,
      assemblyId: Guid.newGuid(),
      shortName,
      name: companyName,
      address: [
        address.address1,
        address.city,
        address.state,
        address.zip,
      ].join(','),
      tin: faker.datatype.number().toString(),
      phoneNumber: faker.phone.number(),
      startDate: faker.date.past(),
      endDate: faker.date.future(),
      skillNumber: faker.datatype.string(8),
      isDataIsolated: false,
      tenant: parent?.tenant ?? shortName,
      createdBy: 'system',
      updatedBy: faker.internet.email(),
      created: faker.date.past(),
      updated: new Date(),
      parentId: parent?.id,
      locations,
      active: true,
      props: {
        logo: faker.image.image(),
      },
      configs:{
        "test":{"instructions":"this is test"}
      }
    };
  }
  createLocations(amount: number): Location[] {
    const result = [];
    for (let i = 0; i < amount; i++) {
      const homeAddressState = faker.address.state();
      const location = {
        id: Guid.newGuid(),
        name: faker.company.bsAdjective(),
        address: faker.address.streetAddress(),
        city: faker.address.city(),
        state: homeAddressState,
        zip: faker.address.zipCodeByState(homeAddressState),
        country: faker.address.country(),
      };
      result.push(location);
    }

    return result;
  }
}
