import { Component, Input } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';


@Component({
  selector: 'formly-label',
  template: `
  <div [class]="className" [hidden]="to?.isLabelHidden">
  {{to.label}}
  </div>
 `,
 styles: [
  `
  .headingBoldUnderline {
      font-weight:bold;
      text-decoration: underline;
      margin-bottom:10px;
      margin-left:10px;
    }
  .headingUnderline {
      text-decoration: underline;
      margin-bottom:10px;
    }
   .headingBold {
      font-weight:bold;
      margin-bottom:10px;
      margin-left:10px;
    }
    .headingBold2 {
      font-weight:500px;
      margin-bottom:10px;
      margin-left:10px;
      font-size:20px
    }
    `,
],
})
export class FormlyLabelType extends FieldType<FieldTypeConfig> {

  get className() {
    return this.props.cssClassName || '';
  }
}

export const formlyLabel = {
  name: 'formly-label',
  component: FormlyLabelType

};
