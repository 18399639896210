import {Component} from '@angular/core';
import {FieldType, FieldTypeConfig, FormlyFieldConfig} from '@ngx-formly/core';
import {BehaviorSubject} from 'rxjs';
import {TabService} from "../services/tab.service";
import {UntilDestroy} from "@ngneat/until-destroy";


@UntilDestroy()
@Component({
  selector: 'formly-field-nowrap-service-tabs',
  template: `

    <mat-tab-group id="tab-group" [selectedIndex]="service.selected$|async" [class.header-less-tabs]="true" (selectedIndexChange)="onTabChange($event)">
      <mat-tab *ngFor="let tab of field.fieldGroup; let i = index; let last = last;"
               [label]="tab.props.label ?? ''">
        <formly-field [field]="tab"></formly-field>




      </mat-tab>
    </mat-tab-group>

  `,
  styles: [
    `

      .header-less-tabs .mat-tab-group .mat-tab-header { display: none; }
      #tab-group{
        height: 100%;
      }
      #tab-group mat-tab-body {
        flex-grow: 1;
      }

    `
  ]
})
export class FormlyFieldNoWrapServiceTabs extends FieldType<FieldTypeConfig> {
  currentIdx:number = 0;
  showHeader$ = this.service.showTabHeader;

  constructor(public service: TabService) {
    super();

  }
  selected = new BehaviorSubject<number>(0);

  onTabChange($event) {
    if($event !== this.currentIdx){
      this.selected.next($event)

      this.service.setActive($event);
      if (this.props.onTabChange) {
        return this.props.onTabChange($event);
      }
    }

  }

  isValid(field: FormlyFieldConfig): boolean {
    if(field)
      if (field.key) {
        if(field.formControl == undefined) return true;
        return field.formControl.valid
      }

    return field.fieldGroup
      ? field.fieldGroup.every((f) => this.isValid(f))
      : true;
  }
}
export const noWrapTabType = {
  name: 'nowrap-tabs',
  component: FormlyFieldNoWrapServiceTabs

};
