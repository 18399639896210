import { AbstractControl, FormGroup } from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';

export function FullRow(fields: FormlyFieldConfig[]) {
  return {
    fieldGroup: fields,
    fieldGroupClassName: 'display-flex flex-wrap row px-1 py-1',
  };
}


export function Row(fields: FormlyFieldConfig[]) {
  return {
    fieldGroup: fields,
    fieldGroupClassName: 'display-flex flex-wrap py-1',
  };
}

export function Column(fields: FormlyFieldConfig[]) {
  return {
    fieldGroup: fields,
    fieldGroupClassName: 'display-flex column',
  };
}


export interface TabType {
  label: string;
  fields: FormlyFieldConfig[];
}
export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
}
export interface AreaType {
  label: string;
  fields: FormlyFieldConfig[];
}

export function options(group:string, key:string, label = '', fields: any[] ) {
  if (!label) {
    label = key;
  }
  return  {
    key,
    fieldGroup: [
      ...fields
    ]

  };
}
export function toggle(key:string, label = '', defaultVal: boolean = false, isReq: boolean = false) {
  if (!label) {
    label = key;
  }
  return  {
    key,
    type: 'toggle',
    defaultValue: defaultVal,
    className: 'flex-grow-1',
    templateOptions: {
      label,
      description: label,
      required: isReq
    },

  };
}
export function input(key:string, label = '', defaultValue: any = null, isReq: boolean = false, isHidden: boolean = false) {
  if (!label) {
    label = key;
  }

  return  {
    key,
    type: 'input',
    className: 'flex-grow-1 mr-1',
    defaultValue,
    templateOptions: {
      label,
      placeholder: label,
      required: isReq
    },
    hide: isHidden
  };
}

export function getValueFromPath(obj: any, paths: string): any {
  const pathOptions = paths.split(' > '); // Split multiple paths by '>'
  
  for (let path of pathOptions) {
    const pathArray = path.split('.');
    let result = obj;
    
    for (let key of pathArray) {
      if (result && typeof result === 'object' && key in result) {
        result = result[key];
      } else {
        result = ''; 
        break;
      }
    }
    
    if (result) {
      return result;
    }
  }

  return '';
}
