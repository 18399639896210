import {
  Observable,
  Subject,
  filter,
  map,
  takeUntil,
  BehaviorSubject,
} from 'rxjs';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventError,
  EventMessage,
  EventType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  SsoSilentRequest,
} from '@azure/msal-browser';

import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AuthUser, Profile, Role } from './../models';
import { AuthConfig } from './authentication.config';
import { ProfileService } from './profile.service';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

/** we store the user's mobile number in an extension property in our ADB2C tenant. **/
type AppIdTokenClaims = IdTokenClaims & {
  family_name?: string;
  given_name?: string;
  extension_MobileNumber?: string;
  email?: string;
  credentials: string;
};
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  private _authenticationContext$ = new BehaviorSubject<AuthUser>(null);
  private _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public msalService: MsalService,
    public msalBroadcastService: MsalBroadcastService,
    private authConfig: AuthConfig,
    public userService: ProfileService
  ) {
    this._destroying$ = new Subject<void>();
    this.msalService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        this.refreshAuthUser();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idToken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (this.hasEditProfilePolicyId(idToken)) {
          this.onEditProfileSuccess(idToken);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         */
        if (this.hasPasswordResetPolicyId(idToken)) {
          this.onPasswordReset();
        }

        if (this.hasSignInPolicyId(idToken)) {
          this.onSignInSuccess(payload);
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (this.hasForgotPasswordErrorCode(result.error)) {
          this.handleForgotPassword();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe((_) => {
        this.refreshAuthUser();
      });
  }

  handleForgotPassword() {
    let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
      authority:
        this.authConfig.b2cPolicies.authorities.resetPassword.authority,
      scopes: [],
    };

    this.login(resetPasswordFlowRequest);
  }

  hasForgotPasswordErrorCode(error: EventError) {
    const ForgotPasswordCode = 'AADB2C90118';
    return error && error.message.indexOf(ForgotPasswordCode) > -1;
  }

  hasPasswordResetPolicyId(idToken: IdTokenClaimsWithPolicyId) {
    return (
      idToken.acr ===
        this.authConfig.b2cPolicies.names.resetPassword.toLowerCase() ||
      idToken.tfp ===
        this.authConfig.b2cPolicies.names.resetPassword.toLowerCase()
    );
  }

  onPasswordReset() {
    let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
      authority: this.authConfig.b2cPolicies.authorities.signUpSignIn.authority,
      scopes: this.authConfig.apiConfig.scopes,
      prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
    };

    this.login(signUpSignInFlowRequest);
  }

  onEditProfileSuccess(idToken: IdTokenClaimsWithPolicyId) {
    // retrieve the account from initial sing-in to the app
    const originalSignInAccount = this.msalService.instance
      .getAllAccounts()
      .find(
        (account: AccountInfo) =>
          account.idTokenClaims?.sub === idToken.sub &&
          ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr ===
            this.authConfig.b2cPolicies.names.signUpSignIn ||
            (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp ===
              this.authConfig.b2cPolicies.names.signUpSignIn)
      );

    let signUpSignInFlowRequest: SsoSilentRequest = {
      authority: this.authConfig.b2cPolicies.authorities.signUpSignIn.authority,
      account: originalSignInAccount,
    };

    // silently login again with the signUpSignIn policy
    this.msalService.ssoSilent(signUpSignInFlowRequest);
  }

  onSignInSuccess(authenticationResult: AuthenticationResult) {
    this.msalService.instance.setActiveAccount(authenticationResult.account);
    this.refreshAuthUser();
  }

  hasSignInPolicyId(idToken: IdTokenClaimsWithPolicyId) {
    return (
      idToken.acr ===
        this.authConfig.b2cPolicies.names.signUpSignIn.toLowerCase() ||
      idToken.tfp ===
        this.authConfig.b2cPolicies.names.signUpSignIn.toLowerCase()
    );
  }

  hasEditProfilePolicyId(idToken: IdTokenClaimsWithPolicyId) {
    return (
      idToken.acr ===
        this.authConfig.b2cPolicies.names.editProfile.toLowerCase() ||
      idToken.tfp ===
        this.authConfig.b2cPolicies.names.editProfile.toLowerCase()
    );
  }

  get currentAuthUser(): AuthUser {
    return this.buildUser(this.getActiveAccount());
  }

  get isLoggedIn$(): Observable<boolean> {
    return this._isLoggedIn$.asObservable();
  }

  get user$(): Observable<AuthUser> {
    return this._authenticationContext$.asObservable();
  }
  get profile$(): Observable<Profile> {
    return this.userService.getMe();
  }

  updateLoginStatus(isLoggedIn: boolean) {
    this._isLoggedIn$.next(isLoggedIn);
  }
  // A method to update the user profile
  updateUserProfile(profile: any) {
    this._authenticationContext$.next(profile);
  }
  login(userFlowRequest?: RedirectRequest | PopupRequest): Observable<void> {
    if (this.msalGuardConfig.authRequest) {
      return this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        ...userFlowRequest,
      } as RedirectRequest);
    } else {
      return this.msalService.loginRedirect(userFlowRequest);
    }
  }

  logout() {
    return this.msalService.logoutRedirect();
  }

  editProfile() {
    let editProfileFlowRequest: RedirectRequest = {
      authority: this.authConfig.b2cPolicies.authorities.editProfile.authority,
      scopes: [],
    };
    this.login(editProfileFlowRequest);
  }

  private buildUser(accountInfo: AccountInfo): AuthUser {
    const claims = accountInfo.idTokenClaims as AppIdTokenClaims;
    var user: AuthUser = {
      firstName: claims.given_name,
      lastName: claims.family_name,
      name: accountInfo.name,
      id: claims.sub,
      email: claims.email,
      picture: '/assets/images/online-doctor-answers.png',
      roles: claims.roles as Role[],
      credentials: '',
    };
    return user;
  }

  private refreshAuthUser() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    this.updateLoginStatus(
      this.msalService.instance.getAllAccounts().length > 0
    );
    const activeAccount = this.getActiveAccount();
    if (activeAccount) {
      this.msalService.instance.setActiveAccount(activeAccount);
      this.profile$
        .pipe(
          map((profile) => {
            const mprofile = { ...this.buildUser(activeAccount), ...profile };
            this.updateUserProfile(mprofile);
          })
        )
        .subscribe();
    } else {
      this.updateUserProfile(null); // Clear the profile if no active account
    }
  }

  private getActiveAccount(): AccountInfo | null {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      activeAccount = accounts[0];
      return activeAccount;
    }
    return activeAccount;
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
