import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Component,Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'formly-selected-protocols-radio',
  template: `
   <div  class="bool-choice-container"  fxLayout="row" fxLayoutAlign="space-between center" >
     <mat-label [matTooltip]="props.toolTip ?? props.description" matTooltipPosition="after">Selected Protocols</mat-label>
     <mat-radio-group [formControl]="formControl" [formlyAttributes]="field" style="display: flex;">

      <mat-radio-button (change)="loadSelectedProtocol($event)" style="margin-right: 12px;" *ngFor="let protocol of seletedProtocols" [value]="protocol.value">{{protocol.label}}</mat-radio-button>

     </mat-radio-group>
     <button mat-flat-button color="primary" (click)="openDialogWithTemplateRef(myDialog)">+ Add More</button>
     <!-- Dialog template -->
    <ng-template #myDialog>
      <h2 matDialogTitle>Select Protocols</h2>
      <mat-dialog-content>
        <div  class="bool-choice-container dbgOuter"  fxLayout="row" fxLayoutAlign="space-between center" >
        <div name="multichckboxlist" ngDefaultControl class="dbgCont" [formlyAttributes]="field" [formControl]="formControl">
            <mat-checkbox  class="dbgCheck"
            (change)="onProtocolSelect(opt)" *ngFor="let opt of props.options | formlySelectOptions: field | async; let i = index"
            [value]="opt.value">{{opt.label}}
        </mat-checkbox>
        </div>
      </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button matDialogClose color="primary">Close</button>
      </mat-dialog-actions>
    </ng-template>
   </div>
 `,
  styles: [
    `.bool-choice-container {
        margin: 1%
      }
     .selected {
      outline: 4px dashed darkorange;
      background: transparent;
    }
    .dbgOuter{
            border: solid 1px #888;
            border-radius: 4px;
            padding: 3px 8px 0px 14px;
            width: auto;
            margin: 0 auto;
            font-size: 10.5pt;
        }
        .dbgCont{
            display: inline-block;
            height: auto;
            margin-left: 6px;
        }
        /* Base for label styling */
        .dbgCheck:not(:checked),
        .dbgCheck:checked {
            /* position: absolute;
            left: -9999px; */
        }
        .dbgCheck:not(:checked) + label,
        .dbgCheck:checked + label {
            display:inline-block;
            position: relative;
            padding-left: 25px;
            cursor: pointer;
        }

        /* checkbox aspect */
        .dbgCheck:not(:checked) + label:before,
        .dbgCheck:checked + label:before {
            content: '';
            position: absolute;
            left:0; top: 1px;
            width: 17px; height: 17px;
            border: 1px solid #aaa;
            background: #f8f8f8;
            border-radius: 3px;
            box-shadow: inset 0 1px 3px   rgba(0,0,0,.3)
        }
        /* checkmark aspect */
        .dbgCheck:not(:checked) + label:after,
        .dbgCheck:checked + label:after {
            content: '✔';
            position: absolute;
            top: 2px; left: 5px;
            font-size: 14px;
            color: #09ad7e;
            transition: all .2s;
        }
        /* checked mark aspect changes */
        .dbgCheck:not(:checked) + label:after {
            opacity: 0;
            transform: scale(0);
        }
        .dbgCheck:checked + label:after {
            opacity: 1;
            transform: scale(1);
        }
        /* disabled checkbox */
        .dbgCheck:disabled:not(:checked) + label:before,
        .dbgCheck:disabled:checked + label:before {
            box-shadow: none;
            border-color: #bbb;
            background-color: #ddd;
        }
        .dbgCheck:disabled:checked + label:after {
            color: #999;
        }
        .dbgCheck:disabled + label {
            color: #aaa;
        }
        /* accessibility */
        .dbgCheck:checked:focus + label:before,
        .dbgCheck:not(:checked):focus + label:before {
            border: 1px dotted blue;
        }

        .dbgCheck{
            display:inline-block;
            width:150px;
            height:24px;
            margin:0.5em;
        }
  `]
})
export class SelectedProtocolsType extends FieldType<FieldTypeConfig> {
    seletedProtocols : { label : string , value : number}[] = []
    maxProtocols = 4;
    constructor(private dialog: MatDialog){
      super();
    }

    openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
      this.dialog.open(templateRef);
    }

    ngOnInit() {}

    onProtocolSelect(selectedOption: any){
      if(selectedOption != null){
        this.seletedProtocols.push({label: selectedOption.label, value: selectedOption.value});
      }
      console.log(this.seletedProtocols);
    }

    loadSelectedProtocol(selected: any){

    }

    addMore(){

    }
}

export const radioSelectedProtocols = {
  name: 'radio-selected-protocols',
  component: SelectedProtocolsType
};
