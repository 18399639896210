import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HorizontalMenuModule } from '@autobot/layout';
import { PushModule } from '@ngrx/component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [LandingComponent],
  imports: [
    MatCardModule,
    CommonModule,
    MatListModule,
    MatToolbarModule,
    HorizontalMenuModule,
    MatButtonModule,
    PushModule,
  ],
})
export class LandingModule {}
