import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, concatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { VersionActions } from '../actions';
import { IVersion } from '../models/version.models';

@Injectable()
export class VersionEffects {
  loadVersion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VersionActions.updateVersion),
      concatMap(() => this.http.get<IVersion>('./assets/version.json')),
      map((data) => VersionActions.updateVersionSuccess({ info: data })),
      catchError((error) => of(VersionActions.updateVersionFailure({ error })))
    );
  });
  private http: HttpClient;
  constructor(private actions$: Actions,private readonly httpHandler: HttpBackend,) {
    this.http = new HttpClient(httpHandler);
  }
}
