import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormlyModule} from "@ngx-formly/core";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {ChipsAutocompleteComponent} from "./chips-autocomplete";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [ChipsAutocompleteComponent],
  imports: [
    CommonModule, FormlyModule.forChild({
      types: [{
        name: 'chips-lookup',
        component: ChipsAutocompleteComponent,
        wrappers: ['form-field'],
      }],
    }), MatAutocompleteModule, ReactiveFormsModule, MatInputModule, MatChipsModule, MatIconModule
  ],
  exports: [ChipsAutocompleteComponent]
})
export class FormlyChipsAutocompleteModule { }
