export function flattenObject(ob:any) {

  // The object which contains the
  // final result
  let result = {};

  // loop through the object "ob"
  for (const i in ob) {

    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
      
      if(ob[i] instanceof Date)
      {
        result[i] = ob[i].toString() ;
      }
      const temp = flattenObject(ob[i]);
      for (const j in temp) {

        // Store temp in result
        result[i + '.' + j] = temp[j];
      }
    }

    // Else store ob[i] in result directly
    else {
      result[i] = ob[i];
    }
  }
  return result;
};
