import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'formly-autocomplete-type',
  template: `
    <input matInput
      [matAutocomplete]="auto"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder ?? ''"
      [errorStateMatcher]="errorStateMatcher">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let opt of filter | async" [value]="opt.value">
        {{ opt.label }}
      </mat-option>
    </mat-autocomplete>
  `,
})
export class AutocompleteTypeComponent extends FieldType<FieldTypeConfig> implements OnInit, AfterViewInit {
  // @ts-ignore
  @ViewChild(MatInput) override formFieldControl: MatInput;
  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  // @ts-ignore
  filter: Observable<any>;

   ngOnInit() {
     this.filter = this.formControl.valueChanges
       .pipe(
         startWith(''),
         switchMap(term => this.props.filter(term)))
  }

   ngAfterViewInit() {


    (<any> this.autocomplete)._formField = this.formField;
  }
}
