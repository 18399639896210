import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";

import {
  BehaviorSubject,
  catchError,
  first,
  merge,
  Observable,
  of,
  ReplaySubject,
  startWith,
  Subject,
  switchMap
} from "rxjs";
import { DataSource } from '@angular/cdk/collections';
import {MatSort, SortDirection} from "@angular/material/sort";
import {combineLatest, map} from "rxjs/operators";
import {Employee} from "../../../../models/fake.models";
import {HttpClient} from "@angular/common/http";
import {FormControl} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {SearchService} from"../../../../services/search.service";


@Component({
  selector: 'lib-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.css']
})
export class SearchDialogComponent implements OnInit,AfterViewInit {
  _resultsLength  = new BehaviorSubject<number>(0);
  resultsLength$ = this._resultsLength.asObservable();
  _loadingResults = new BehaviorSubject<boolean>(false);
  loadingResults$ = this._loadingResults.asObservable();

  isRateLimitReached = false;
  data: Employee[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selected: any = false;

  _fields: any[];
  displayedColumns: string[] = ['firstName', 'lastName', 'company', 'city','state'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  searchControl: FormControl = new FormControl('');
  search$ = this.searchControl.valueChanges.pipe(startWith(''));
  constructor(private service: SearchService, private dialogRef: MatDialogRef<SearchDialogComponent>) {

  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // If the user changes the sort order, reset back to the first page.
   // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    setTimeout(() => {
    this.search$.pipe(
      switchMap(() => {
        this._loadingResults.next(true);
        return this.service.search(
          this.searchControl.value,
          // this.sort.active,
          // this.sort.direction,
          this.paginator.pageIndex,
        ).pipe(catchError(() => of(null)));
      }),
      map((data:any) => {
        // Flip flag to show that loading has finished.

        this.isRateLimitReached = data === null;

        if (data === null) {
          return [];
        }

        // Only refresh the result length if there is new data. In case of rate
        // limit errors, we do not want to reset the paginator to zero, as that
        // would prevent users from re-triggering requests.
        this._resultsLength.next(data.total);
        return data.items;
      }),
      first()
    ).subscribe(data => {
      this._loadingResults.next(false);

      this.dataSource.data = data;
    })
    });





  }

  ngOnInit(): void {
    this._loadingResults.next(false);
    this._resultsLength.next(0);
  }

  loadSelectedUser(row){
    this.dialogRef.close(row);
  }
  select(row){
    this.selected = row;

  }
}

export class EmployeeHttpDatabase {
  constructor(private _httpClient: HttpClient) {}

  getEmployees(sort: string, order: SortDirection, page: number): Observable<Employee> {
    const href = 'assets/data/fakes/fake.employees.json';
    const requestUrl = `${href}?q=repo:angular/components&sort=${sort}&order=${order}&page=${
      page + 1
    }`;

    return this._httpClient.get<Employee>(requestUrl);
  }
}
class ExampleDataSource extends DataSource<Employee> {
  private _dataStream = new ReplaySubject<Employee[]>();

  constructor(initialData: Employee[], private service: SearchService) {
    super();
    this.setData(initialData);
  }

  connect(): Observable<Employee[]> {
    return this._dataStream;
  }

  disconnect() {}

  setData(data: Employee[]) {
    this._dataStream.next(data);
  }
}
