import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import {
  CallActions,
  CallApiActions,
  CaseDialogActions,
  FormEditorActions,
} from '../actions';
import { CaseApiService } from '../services/case-api.service';
import { MatDialog } from '@angular/material/dialog';
import { faker } from '@faker-js/faker';
import { CloseEditorComponent } from '@autobot/shared';
import { RouterActions } from '@autobot/core';
import { getCaseId } from '../selectors';
import { getAccounts } from '@nfr/data/dashboard';
import { NewCallDialog } from '../modules/call';
import { CallForm } from '@nfr/common';
import { of } from 'rxjs';

@Injectable()
export class CallEffects {
  initCall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CallActions.initCall),
      concatLatestFrom(() => this.store.select(getAccounts)),
      exhaustMap((_) => {
        let dialogRef = this.dialog.open(NewCallDialog, {
          width: '60vw',

          backdropClass: 'dark-backdrop',
          disableClose: true,

          data: {
            accounts: _[1],
          },
        });
        return dialogRef.afterClosed();
      }),
      map((result: CallForm) => {
        /* (result == undefined) --> Call Dialog was cancelled aka no Call
         *  (result && result.newCase) --> New Case created --> Load new case
         *  (result && !result.newCase) --> Existing Case Selected --> Load Case
         * */

        if (result == undefined) return CallActions.callDialogCancelled();

        if (result.newCase) {
          const to = {
            path: [`nfr/case/${result.caseId}`],
          };
          return RouterActions.go({ to });
        }
        if (!result.newCase) {
          const to = {
            path: [`nfr/case/${result.caseId}`],
          };
          return RouterActions.go({ to });
        }
      })
    );
  });

  createCall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CallActions.createCall),
      switchMap(({ call }) => {
        return this.caseApi.createCall(call).pipe(
          map((call) => {
            return CallApiActions.createCallSuccess({ call });
          }),
          catchError((error: unknown) =>
            of(CallApiActions.createCallFailure({ err: error }))
          )
        );
      })
    );
  });

  /*  exitEditor$ =  createEffect(() => {
    return this.actions$.pipe(
      ofType(FormEditorActions.exitEditor),

      exhaustMap(() => {

        const actions = [
          {
            title: 'Save & Exit',
            value: 'saveExit',
            description : faker.lorem.paragraph(3),

          },
          {
            title: 'Exit',
            value: 'exit',
            description : faker.lorem.paragraph(3),

          },
          {
            title: 'Cancel',
            value: 'cancel',
            description : faker.lorem.paragraph(3),

          }
        ]





        let dialogRef = this.dialog.open(CloseEditorComponent,{minWidth: '750px', data: {
          actions
          }});
        return dialogRef.afterClosed();
      }),
      concatLatestFrom(() => this.store.select(getCaseId)),
      map((_) => {
        const result = _[0];
        const caseId = _[1];


        if(!result) return CaseDialogActions.exitEditorCancelled()
        switch(result){


          case 'exit':
            const to = {
              path: [`nfr/case/${caseId}`]
            }
            return RouterActions.go({to})

            break;


          case 'cancel':
            return CaseDialogActions.exitEditorCancelled()
            break;

          default:
            return CaseDialogActions.exitEditorCancelled()
            return;
        }

      })
    )
  })*/

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store,
    private caseApi: CaseApiService,
    private dialog: MatDialog
  ) {}
}
