import { ConfirmDialogParameters } from "@forms/common";
import {createAction, props} from "@ngrx/store";


export enum TemplatesDialogActionTypes {
  OPEN_TEMPLATE_WIZARD = '[New Template Menu] Open Template Wizard',
  TEMPLATE_WIZARD_CANCELLED = '[Template Wizard] Cancelled',
  CONFIRM_DIALOG = '[Templates General] Open Confirm Dialog',
  CONFIRM_DIALOG_CANCELLED = '[Template Dialogs] Confirm Dialog Cancelled',
  OPEN_FILE_IMPORT = '[New Template Menu] Open File Import',
  START_NEW_TEMPLATE_CANCELLED = '[New Template Menu] Cancelled'
}


export const confirmDialog = createAction(TemplatesDialogActionTypes.CONFIRM_DIALOG, props<{params:ConfirmDialogParameters}>())

export const openTemplateWizard = createAction(TemplatesDialogActionTypes.OPEN_TEMPLATE_WIZARD);
export const openFileImport = createAction(TemplatesDialogActionTypes.OPEN_FILE_IMPORT);
export const confirmDialogCancelled = createAction(TemplatesDialogActionTypes.CONFIRM_DIALOG_CANCELLED)
export const templateWizardCancelled = createAction(TemplatesDialogActionTypes.TEMPLATE_WIZARD_CANCELLED)
export const startNewTemplateCancelled = createAction(TemplatesDialogActionTypes.START_NEW_TEMPLATE_CANCELLED)
