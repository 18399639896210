import { AppRole, Customer } from '@accounting/common';
import { createAction, props } from '@ngrx/store';

export enum ManagementListActionTypes {
  LOAD_CUSTOMER = '[Customer/API] Load Customers',
  LOAD_CUSTOMER_SUCCESS = '[Customer/API] Load Customers Success',
  LOAD_CUSTOMER_FAILURE = '[Customer/API] Load Customers Failure',

  LOAD_ROLE = '[Role/API] Load Roles',
  LOAD_ROLE_SUCCESS = '[Role/API] Load Roles Success',
  LOAD_ROLE_FAILURE = '[Role/API] Load Roles Failure',

  SELECT_USER = '[User/API] Select User',
  SELECT_CUSTOMER = '[Customer/API] Select Customer',

  ASSIGN_ROLE = '[User/API] Assign Role',
  ASSIGN_ROLE_SUCCESS = '[User/API] Assign Role Success',
  ASSIGN_ROLE_FAILURE = '[User/API] Assign Role Failure',
}

export const selectUser = createAction(
  ManagementListActionTypes.SELECT_USER,
  props<{ id: string }>()
);

export const selectCustomer = createAction(
  ManagementListActionTypes.SELECT_CUSTOMER,
  props<{ id: string }>()
);

export const loadCustomers = createAction(
  ManagementListActionTypes.LOAD_CUSTOMER
);
export const loadUCustomersSuccess = createAction(
  ManagementListActionTypes.LOAD_CUSTOMER_SUCCESS,
  props<{ accounts: Customer[] }>()
);
export const loadCustomersFailure = createAction(
  ManagementListActionTypes.LOAD_CUSTOMER_FAILURE,
  props<{ err: any }>()
);

export const loadRoles = createAction(ManagementListActionTypes.LOAD_ROLE);
export const loadURolesSuccess = createAction(
  ManagementListActionTypes.LOAD_ROLE_SUCCESS,
  props<{ accounts: AppRole[] }>()
);
export const loadRolesFailure = createAction(
  ManagementListActionTypes.LOAD_ROLE_FAILURE,
  props<{ err: any }>()
);

export const assignRole = createAction(
  ManagementListActionTypes.ASSIGN_ROLE,
  props<{ id: string }>()
);
export const assignRoleSuccess = createAction(
  ManagementListActionTypes.ASSIGN_ROLE_SUCCESS,
  props<{ id: string }>()
);
export const assignRoleFailure = createAction(
  ManagementListActionTypes.ASSIGN_ROLE_FAILURE,
  props<{ err: any }>()
);
