import { createReducer, on } from '@ngrx/store';
import { DesktopActions } from '../actions';

export const desktopFeatureKey = 'desktop';

export interface State {
  navs: any[];
  leftNavStatus: string;
  rightNavOpen: boolean;
  loaded: boolean;
}

export const initialState: State = {
  navs: [],
  leftNavStatus: 'close',
  rightNavOpen: true,
  loaded: false,
};

export const reducer = createReducer(
  initialState,
  on(DesktopActions.loadDesktopSuccess, (state, action) => ({
    ...state,
    navs: action.navs,
    leftNavHidden: false,
    loaded: true,
  })),
  on(DesktopActions.toggleRightNav, (state) => ({
    ...state,
    rightNavOpen: !state.rightNavOpen,
  })),
  on(DesktopActions.setLeftNav, (state, action) => ({
    ...state,
    leftNavStatus: action.status,
  })),
  on(DesktopActions.setRightNav, (state, action) => ({
    ...state,
    rightNavOpen: action.isOpen,
  })),
  on(DesktopActions.clearDesktop, (state) => ({ ...initialState }))
);
