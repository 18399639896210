import { IForm, Template } from "@forms/common";
import { FollowUp, FollowUpStatus, FollowUpTab, FollowUpTabType } from "@nfr/common";
import { createReducer, on } from "@ngrx/store";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { FollowUpActions } from "../actions";



export const followUpFeatureKey = 'followup';

export interface SelectedEditor{
  template?: Template;
  iForm?: IForm;
  fields: FormlyFieldConfig[];
  selectedTabIndex: number;
  initialModel?: any;
  model?: any;
}
export interface State {
  templates:Template[];
  datasets?: { [key: string]: any };
  formEditor?:SelectedEditor;
  dialogEditor?:SelectedEditor;
  followUps: FollowUp[];
  currentFollowUp?: FollowUp;
  // followupMode:FollowUpMode;
  maxAllowedFollowUp:number;
  selectedTab?:FollowUpTab;
  followUpTabs:FollowUpTab[];
  loaded: boolean;
  loading: boolean;
  saved: boolean;
  saving: boolean;
  error?: any;
}

export const initialState: State = {
  templates:[],
  formEditor:{
    fields:null,
    selectedTabIndex:0
  },
  dialogEditor:{
    fields:null,
    selectedTabIndex:0
  },
  followUps:[],
  followUpTabs:[],
  currentFollowUp: null,
  maxAllowedFollowUp: 5,
  selectedTab:null,
  loaded: false,
  loading: true,
  saved: false,
  saving: false  
};

export const reducer = createReducer(
  initialState,
  on(FollowUpActions.init, (state) => state=initialState),
  on(FollowUpActions.getFollowUps, (state, action) => state),
  on(FollowUpActions.getFollowUpsSuccess, (state, action) => ({ 
  ...state,
    followUps:action.followUpData,
    followUpTabs:action.followUpTabs??state.followUpTabs,
    selectedTab:action.followUpTabs.length>0?action.followUpTabs.find(x => x.tabIndex === 0):{
      tabIndex:0, tabType:FollowUpTabType.Detail,tabDescription:"Case Details"
    },
    loading: false})),
  on(FollowUpActions.getFollowUpsFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.error,
  })),
  on(FollowUpActions.loadFollowUpForms, (state, action) => ({
    ...state,
    loaded: false,
    loading: true
  })),
  on(FollowUpActions.loadFollowUpFormSuccess, (state, action) => ({ 
  ...state,
    templates:action.templates,
    datasets:action.datasets,
    loaded: true,
    loading: false})),
    on(FollowUpActions.loadFollowUpFormFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.error,
  })),
  
  on(FollowUpActions.onFollowUpTabSelectSuccess, (state, action) => ({
    ...state,
    selectedTab:action?.selectedTab??state.selectedTab,
    formEditor:{
      template:action.template,
      fields:action.fields,
      selectedTabIndex:0,
      model:action.caseDetailData,

    },
    currentFollowUp:action?.currentFollowUp,
    followUps: action?.followUps?.length>0?action?.followUps:state.followUps,
    loaded: true,
    loading: false
  })),
  on(FollowUpActions.selectFollowUpFormlyTab, (state, action) => ({
    ...state,
    formEditor:{
      ...state.formEditor,
      selectedTabIndex:action.selectedTabIndex??state?.formEditor?.selectedTabIndex,
    },
  })),
  on(FollowUpActions.updateFollowUpModel, (state, action) => ({
   
    ...state,
    currentFollowUp: {
      ...state.currentFollowUp,
      data:action?.data,
    },
    followUps:      
     state.followUps.map((item:FollowUp)=> 
        (item?.followUpNumber === state.currentFollowUp?.followUpNumber) && (state.currentFollowUp?.status!=FollowUpStatus.Complete)
         ? {
          ...item, 
          data:action?.data
        }
        : item)           
    
  })),

  on(FollowUpActions.saveFollowUp, (state) => ({
    ...state,
    saved: false,
    saving: true,
  })),
  on(FollowUpActions.saveFollowUpSuccess, (state, action) => ({
    ...state,
    currentFollowUp:null,
    saved: true,
    saving: false,
  })),
  on(FollowUpActions.saveFollowUpFail, (state, action) => ({
    ...state,
    saved: false,
    saving: false,
    error: action.error,
  })),
  on(FollowUpActions.getFollowUpDialogFields, (state) => ({
    ...state
  })),
  on(FollowUpActions.loadFollowUpDialogFields, (state, action) => ({
    ...state,
    dialogEditor:{
      template:action.template,
      fields:action.fields,
      selectedTabIndex:0,
      model:action.caseDetailData,

    },
    
  })),
  
)