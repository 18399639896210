import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@autobot/shared';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import * as moment from 'moment-timezone';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { IForm } from '../models/assembly.model';
import { MedicalProtocol } from '../models/medical.protocol.models';
import { Area, Field, Template } from '../models/template.model';

@Injectable({
  providedIn: 'root',
})
export class MappingService {
  _activeTab = new BehaviorSubject<number>(0);
  active$ = this._activeTab.asObservable();
  constructor(private dialog: MatDialog, private store: Store) {}

  stringValidations: Object = {
    noSpecialChars: {
      expression: (c) => /^[a-zA-Z0-9\s]*$/.test(c.value),
      message: 'No special characters allowed.',
    },
    email: {
      expression: (c) =>{
        return(
          _.isEmpty(c.value) ||
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(c.value)
        );
      },
      message: 'Incorrect email format.',
    },
    phone: {
      expression: (c) => {
        return (
          _.isEmpty(c.value) ||
          /^\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/.test(c.value)
        );
      },
      message:
        'Incorrect phone format, only this format is allowed: XXX-XXX-XXXX.',
    },
    ssn: {
      expression: (c) => /^\d{3}-\d{2}-\d{4}$/.test(c.value),
      message: 'Incorrect ssn format, follow ths format: XXX-XX-XXXX.',
    },
    url: {
      expression: (c) =>
        /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(
          c.value
        ),
      message: 'Incorrect url format.',
    },
    time: {
      expression: (c) => /^([01]\d|2[0-3]):[0-5]\d$/.test(c.value),
      message: 'Incorrect time format, only this format is allowed: XX:XX',
    },
    integer: {
      expression: (c) => {
        if (c.value) {
          return /^[0-9]*$/.test(c.value);
        }
        return true;
      },
      message: 'Incorrect integer format.',
    },
    decimal: {
      expression: (c) => /^(?=.*[1-9])\d*(?:\.\d{0,2}0{0,1})?$/.test(c.value),
      message:
        'Incorrect decimal format, only 2 digits allowed in decimal part.',
    },
    addressFieldValidation: {
      expression: (c) =>/^[a-zA-Z0-9~!@#$%^&*()_ +`\-={}[\]|\\:;<>,".?\/]*$/.test(c.value),
      message: 'Apostrophe is not allowed',
    },
    default: {
      expression: (c) => true,
      message: '',
    },
  };

  calculateDefaultValue(fld: Field): boolean | string {
    let result: boolean | string;
    if (
      fld.control == 'datepicker' ||
      fld.control == 'text' ||
      fld.control == 'textarea' ||
      fld.control == 'select' ||
      fld.control == 'radio-three' ||
      fld.control === 'lookup' ||
      fld.control === 'radio'
    ) {
      result = fld.props?.defaultValue;
    } else if (fld.control === 'autocomplete' || fld.control === 'radioProtocol') {
      result = '';
    } else if (fld.control == 'radio-yes-no') {
      result = null;
    } else {
      result = fld.defaultValue;
    }
    return result;
  }

  isNumericControl(fld: Field): boolean {
    let isInputNumber = false;

    if (fld.control == 'text' && fld.subControl == 'number') {
      isInputNumber = true;
    }
    return isInputNumber;
  }

  onChangeInput(value: any, field: any, regExp: any) {
    if (!Number(value)) {
      field.formControl.setValue(null, { onlySelf: true, emitEvent: false });
    }
  }

  validateNumberInRange(
    input: string,
    min: number,
    max: number,
    regExp: any
  ): boolean {
    return regExp.test(input);
  }

  getValidationArray(field: Field) {
    const validators = [];
    if (field.key === 'eligibility.triage.dateOfDeath') {
      validators.push('dateofdeath-validation');
      return validators;
    }
    if (field.key === 'intake.supervisor.demographics.dateManagerNotified') {
      validators.push('dateofmanagernotified-validation');
      return validators;
    }

    if (
      field.key === 'intake.supervisor.accidentDescription.dateTimeOfInjury'
    ) {
      validators.push('dateofinjuryreport-validation');
      validators.push('dateofinjury-validation');

      return validators;
    }

    if (field.key === 'intake.supervisor.accidentDescription.injuryTimeZone') {
      validators.push('dateofinjuryreport-validation');
      return validators;
    }

    if (field.control == 'datepicker' || field.subControl == 'datetime-local')
      validators.push('dateValidator');

    if (
      (field.control == 'datepicker' ||
        (field.control == 'text' && field.subControl == 'date') ||
        (field.control == 'text' && field.subControl == 'datetime-local')) &&
      field.props?.noFutureDates == true
    ) {
      validators.push('future-date');
    }
    if(field.key ==='followup.calldetails.outboundPhoneSystemID') {
      validators.push('phonesystemid-validation')
    }

    if (field.key == 'intake.employee.demographics.dob') {
      validators.push('parental-consent');
    }
    return validators;
  }

  getAsyncValidationArray(field: Field) {
    const asyncValidators = {};
    //if (field.control == 'datepicker') asyncValidators.push('dateValidator');
    // if ((field.control == 'datepicker' ||
    //       (field.control == 'text' && field.subControl == 'date')) &&
    //     field.props?.noFutureDates == true
    // ){
    //     _.set(asyncValidators,"noFutureDate",{
    //         expression: (control: AbstractControl) =>{
    //           const date = new Date(Date.parse(control.value))
    //           return of(!(date > new Date()));
    //         },
    //         message: 'The selected date cannot be a future date.'
    //       });
    // }
    return asyncValidators;
  }

  //it generates the json (formly format) for the agent portal
  convertToNoWrapPropFields(template: Partial<Template>, dict?: any) {
    /// Has to be at least 1 Area to hold fields.
    const fieldGroup: any[] = [];
    const tabs = template.tabs;
    const areas = template.areas;
    const fields = template.fields;

    //todo: add property for template
    const isIntake = template.props?.key?.toLowerCase() == 'eligibility';

    if (tabs.length > 0) {
      _.forEach(tabs, (tab) => {
        const newTab = {
          id: tab.id,
          props: {
            label: tab.label,
          },
          fieldGroup: [],
          ...(isIntake && {
            validators: {
              validation: ['validation-triage'],
            },
          }),
        };

        fieldGroup.push(newTab);
      });
    }

    if (areas.length == 0)
      throw Error('Must be at least 1 area to map to form');

    if (areas.length > 0) {
      _.forEach(areas, (area) => {
        const hasHideExpression =
          area.props?.hideExpression != ('' || undefined);
        const newArea = {
          id: area.id,
          wrappers: [area.props?.wrapper],
          props: {
            label: area.label,
            showHeader: area.props?.showHeader,
            fxLayout: area.style?.fxLayout ?? 'row wrap',
          },
          className: 'w-100',
          type: 'flex-layout',
          ...(hasHideExpression && {
            hideExpression: area.props?.hideExpression,
          }),
          fieldGroup: [],
        };
        if (area.tabId !== null) {
          const tabIndex = fieldGroup.findIndex((o) => o.id == area.tabId);
          fieldGroup[tabIndex].fieldGroup.push(newArea);
        } else {
          fieldGroup.push(newArea);
        }
      });
    }

    if (fields.length > 0) {
      _.forEach(fields, (field) => {
        const hasDefaultValue = (field.props?.defaultValue ?? '') !== '';
        const alwaysCalculateDefault = field.key.includes(".abcd.");
        const area = areas.filter((o) => o.id == field.areaId)[0];
        const hasParser = field.props?.parser?.format !== '';
        const isTextArea = field.control == 'textarea';
        const isButton = field.control == 'button';
        const isDynamiclabel = field.control == 'formly-dynamiclabel';
        const isCustomlink = field.control == 'formly-custom-link';
        const isSelectionList = field.control == 'selection-list';
        let isMultiCheckboxList = false;
        if (field.control == 'multi-checkbox-list') {
          isMultiCheckboxList = true;
        }

        const hasDescription = field.props?.description !== '';
        const updateOnBlur =
          field.control == 'datepicker' || field.control == 'text';
        const hasMinLengthValidation = field.props?.minLength > 0;
        const hasMaxLengthValidation = field.props?.maxLength > 0;
        const hasFutureDateValidation =
          (field.control == 'datepicker' ||
            (field.control == 'text' &&
              (field.subControl == 'date' ||
                field.subControl == 'datetime-local'))) &&
          field.props?.noFutureDates == true;
        const dateFormatValidation =
          field.control == 'datepicker' || field.subControl == 'datetime-local';
        const validators = this.getValidationArray(field);
        const asyncValidators = this.getAsyncValidationArray(field);
        let resultField;
        const isNumber = this.isNumericControl(field);
        // Retrieve the minimum value from the field props, defaulting to 0 if it's not a number
        let minVal = Number.isNaN(field.props.min) ? 0 : field.props.min;
        // Retrieve the maximum value from the field props, defaulting to 100 if it's not a number or 0
        let maxVal =
          Number.isNaN(field.props.max) || field.props.max === 0
            ? 100
            : field.props.max;
        let resultExp = this.getExpression(field, minVal, maxVal);
        let maxDate =
          field.subControl == 'datetime-local' &&
          field.props?.noFutureDates == true
            ? this.getTomorrowDate()
            : null;
        let clearDisableValue = field.props?.clearDisableValue ?? 'true';

        const draftField = {
          id: field.id,
          key: field.key,
          label: field.label,
          ...(field.props?.disableExpression && {
            expressions: {
              'props.disabled': field.props?.disableExpression,
              'props.clearIfNull': (field) => {
                if (!field.form.disabled && field.formControl.disabled && clearDisableValue == 'true') {
                  this.setInitialValueField(field);
                }
              },
            },
          }),
          type: isMultiCheckboxList
            ? 'multicheckbox'
            : field.control == 'radioProtocol'
            ? 'radio'
            : field.control,
          className: this.mapStylingClasses(area, field),
          ...((hasDefaultValue || alwaysCalculateDefault) && {
            defaultValue: this.calculateDefaultValue(field),
          }),

          ...(isNumber
            ? {
                templateOptions: {
                  label: field.label,
                  type: 'numeric',
                  min: minVal,
                  max: maxVal,
                  required: field.required,
                  //pattern: resultExp,
                },
                hideExpression: field.hideExpression,
                ...(updateOnBlur && {
                  modelOptions: {
                    updateOn: 'blur',
                  },
                }),
                hooks: {
                  onInit: (field: any) => {
                    // Set the label of the field templateOptions to match the provided 'fld.label'
                    field.templateOptions.label = field.label;
                  },
                },
              }
            : {
                ...(dateFormatValidation && {
                  templateOptions: {
                    readonly: true,
                  },
                }),
                // ...(isButton && {
                //   templateOptions: {
                //     onClick: function($event) { this.alertme($event) },
                //   },
                // }),
                hideExpression: field.hideExpression,
                ...(updateOnBlur && {
                  modelOptions: {
                    updateOn: 'blur',
                  },
                }),
                hooks:
                  field.props?.hookName == undefined ||
                  field.props?.hookName === ''
                    ? {
                        onInit: (field: any) => {},
                      }
                    : {
                        onInit: (field: any) => {
                          field.formControl.valueChanges
                            .pipe(debounceTime(1000), distinctUntilChanged())
                            .subscribe((value) => {
                              this.applyHook(
                                field.props?.hookName,
                                value,
                                field
                              );
                            });
                        },
                      },
                props: {
                  label: field.label,
                  type: field.subControl,
                  disabled: field.props?.disabled,
                  isLabelHidden: field.props?.isLabelHidden,
                  cssClassName: field.props?.fieldClasses,
                  clearDisableValue: field.props?.clearDisableValue,
                  hookName: field.props?.hookName,
                  popupMessage: field.props?.popUpMessage,
                  max: maxDate,
                  format: field.props?.format,
                  options: this.bindDataset(field, dict),
                  ...(hasDefaultValue && {
                    defaultValue: this.calculateDefaultValue(field),
                  }),
                  required: field.required,
                  hooks: {
                    onInit: (field: any) => {
                      field.templateOptions.label = field.label;
                    },
                  },
                  tooltip: this.mapTooltip(field),
                  pasteFromField: this.pasteFromField(field),
                  pasteButtonProps: this.getPasteButtonProps(field),
                  addonRight: this.mapAddons(field),
                  dataHint: field.props?.dataHint,
                  ...(hasDescription && {
                    description: field.props?.description,
                  }),
                  ...(isButton && {
                    //onClick: () => function($event) {  },
                    callbackName: field.props?.callbackName,
                  }),
                  ...(isDynamiclabel && {
                    dynamicLabel: field.props?.dynamicLabel,
                  }),
                  ...(isCustomlink && {
                    dynamicLink: field.props?.dynamicLink,
                  }),

                  ...(isTextArea && { rows: 10, appearance: 'fill' }),
                  ...(isSelectionList && {
                    listItems: [
                      { label: 'First', value: 1 },
                      { label: 'Second', value: 2 },
                      { label: 'Third', value: 3 },
                    ],
                  }),
                  ...(hasParser && { parser: field.props?.parser }),
                  ...(hasMinLengthValidation && {
                    minLength: field.props?.minLength,
                  }),
                  ...(hasMaxLengthValidation && {
                    maxLength: field.props?.maxLength,
                  }),
                  ...(dateFormatValidation && {
                    datepickerOptions: {
                      startView: 'month' as const,
                      datepickerTogglePosition: 'suffix' as const,
                      ...(hasFutureDateValidation && { max: new Date() }),
                      disabled: false,
                      opened: false,
                      dateInput: () => {},
                      dateChange: (c, e) => {
                        if (e.value == undefined || e.value == null) {
                          e.targetElement.value = '';
                        }
                      },
                      monthSelected: () => {},
                      yearSelected: () => {},
                    },
                  }),
                },
                asyncValidators: asyncValidators,
              }),
          ...{
            validators: {
              ...(validators &&
                validators.length > 0 && {
                  validation: validators,
                }),
              ...(field &&
                field.validator && {
                  textValidation: this.getTextValidation(field.validator),
                }),
            },
          },
        };
        // if(draftField.templateOptions && draftField.templateOptions.onClick) {
        //   draftField.templateOptions.onClick = draftField.templateOptions.onClick.bind(this.callEventByCallbackName);
        // }
        const newField = { ...draftField };

        if (field.tabId !== null) {
          const _tabIndex = fieldGroup.findIndex((o) => o.id == field.tabId);
          const _areaIndex = fieldGroup[_tabIndex].fieldGroup.findIndex(
            (a: Area) => a.id == field.areaId
          );
          const areaArr =
            fieldGroup[_tabIndex].fieldGroup[_areaIndex].fieldGroup;

          if (areaArr == undefined) return;
          areaArr.push(newField);
        } else {
          const _areaIndex = fieldGroup.findIndex((a) => a.id == field.areaId);
          const areaArr = fieldGroup[_areaIndex].fieldGroup;

          if (areaArr == undefined) return;
          areaArr.push(newField);
        }
      });
    }

    const tabVersion = {
      type: 'nowrap-tabs',
      fieldGroup: fieldGroup,
    };

    const noTabsVersion = fieldGroup;
    // const result = tabs.length > 0 ? [tabVersion] : noTabsVersion;
    const result = noTabsVersion;
    return result;
  }
  convertToFormlyFields(template: Template, dict?: any) {
    const result = {
      type: 'noblock-tabs',
      fieldGroup: template.tabs.map((tab) => {
        return {
          id: tab.id,
          templateOptions: {
            label: tab.label,
          },
          fieldGroup: template.areas
            .filter((o) => o.tabId == tab.id)
            .map((area) => {
              return {
                id: area.id,
                wrappers: [area.props?.wrapper],
                templateOptions: {
                  label: area.label,
                  showHeader: area.props?.showHeader,
                  fxLayout: area.style?.fxLayout ?? 'row wrap',
                },
                className: 'w-100',
                type: 'flex-layout',
                fieldGroup: template.fields
                  .filter((f) => f.areaId == area.id)
                  .map((fld) => {
                    const hasDefaultValue =
                      (fld.props?.defaultValue ?? '') !== '';
                    return {
                      id: fld.id,
                      key: fld.key,
                      type: fld.control,
                      className: area.style?.childClasses ?? 'testing',
                      ...(hasDefaultValue && {
                        defaultValue: this.calculateDefaultValue(fld),
                      }),
                      hideExpression:
                        fld.props?.isHidden ?? this.mapHideExpressions(fld.key),
                      templateOptions: {
                        label: fld.label,
                        type: fld.subControl,
                        parser: fld.props?.parser,
                        disabled: fld.props?.disabled,
                        isLabelHidden: fld.props?.isLabelHidden,
                        format: fld.props?.format,
                        options:
                          fld.options ??
                          (fld.props?.dataHint
                            ? this.mapOptions(fld.props?.dataHint, dict)
                            : []),
                        required: fld.required,
                        hooks: {
                          onInit: (field: any) => {
                            field.templateOptions.label = fld.label;
                          },
                        },
                        addonRight: this.mapAddons(fld),
                        dataHint: fld.props?.dataHint,
                      },
                    };
                  }),
              };
            }),
        };
      }),
    };

    return [result];
  }
  convertToFlatFormlyFields(template: Template, pocket?, dict?) {
    /// Has to be at least 1 Area to hold fields.

    const fieldGroup = [];
    const tabs = template.tabs;
    const areas = template.areas;
    const fields = template.fields;
    const flatFields = [];
    if (tabs.length > 0) {
      _.forEach(tabs, (tab) => {
        const newTab = {
          id: tab.id,
          templateOptions: {
            label: tab.label,
          },
          fieldGroup: [],
        };

        fieldGroup.push(newTab);
      });
    }

    if (areas.length == 0)
      throw Error('Must be at least 1 area to map to form');

    if (areas.length > 0) {
      _.forEach(areas, (area) => {
        const hasHideExpression =
          area.props.hideExpression != ('' || undefined);

        const newArea = {
          id: area.id,
          wrappers: [area.props?.wrapper],
          templateOptions: {
            label: area.label,
            showHeader: area.props?.showHeader,
            fxLayout: area.style?.fxLayout ?? 'row wrap',
          },
          className: 'w-100',
          type: 'flex-layout',
          ...(hasHideExpression && {
            hideExpression: area.props.hideExpression,
          }),
          fieldGroup: [],
        };
        if (area.tabId !== null) {
          const tabIndex = fieldGroup.findIndex((o) => o.id == area.tabId);
          fieldGroup[tabIndex].fieldGroup.push(newArea);
        } else {
          fieldGroup.push(newArea);
        }
      });
    }

    if (fields.length > 0) {
      _.forEach(fields, (field) => {
        const hasDefaultValue = (field.props?.defaultValue ?? '') !== '';
        const area = areas.filter((o) => o.id == field.areaId)[0];
        const tab = tabs.filter((t) => t.id == area.tabId);
        const hasParser = field.props?.parser.format !== '';
        const isTextArea = field.control == 'textarea';
        /// TODO add the rest of the object builders.
        const draftField = {
          id: field.id,
          key: field.key,
          type: field.control,
          className: area.style?.childClasses ?? 'testing',
          ...(hasDefaultValue && {
            defaultValue: this.calculateDefaultValue(field),
          }),
          hideExpression: field.hideExpression,
          templateOptions: {
            label: field.label,
            type: field.subControl,
            disabled: field.props?.disabled,
            isLabelHidden: field.props?.isLabelHidden,
            cssClassName: field.props?.fieldClasses,
            clearDisableValue: field.props?.clearDisableValue,
            format: field.props?.format,
            options: this.bindDataset(field, dict),

            required: field.required,
            hooks: {
              onInit: (field) => {
                field.templateOptions.label = field.label;
              },
            },
            addonRight: this.mapAddons(field),
            dataHint: field.props?.dataHint,
            ...(isTextArea && { rows: 10 }),
            ...(hasParser && { parser: field.props.parser }),
          },
        };

        const flatField = {
          value: draftField.key,
          label: draftField.templateOptions.label,
          valueField: {
            type: draftField.type,
            templateOptions: {
              label: field.label,
              type: field.subControl,
              options: this.bindDataset(field, dict),
              dataHint: field.props?.dataHint,
            },
          },
        };

        flatFields.push(flatField);

        const newField = { ...draftField };

        if (field.tabId !== null) {
          const _tabIndex = fieldGroup.findIndex((o) => o.id == field.tabId);
          const _areaIndex = fieldGroup[_tabIndex].fieldGroup.findIndex(
            (a) => a.id == field.areaId
          );
          const areaArr =
            fieldGroup[_tabIndex].fieldGroup[_areaIndex].fieldGroup;

          if (areaArr == undefined) return;
          areaArr.push(newField);
        } else {
          const _areaIndex = fieldGroup.findIndex((a) => a.id == field.areaId);
          const areaArr = fieldGroup[_areaIndex].fieldGroup;

          if (areaArr == undefined) return;
          areaArr.push(newField);
        }
      });
    }

    const tabVersion = {
      type: 'noblock-tabs',
      fieldGroup: fieldGroup,
    };

    const noTabsVersion = fieldGroup;

    const result = tabs.length > 0 ? [tabVersion] : noTabsVersion;

    return { fields: flatFields };
  }
  convertToFields(template: Partial<Template>, dict?: any) {
    /// Has to be at least 1 Area to hold fields.

    const fieldGroup: any[] = [];
    const tabs = template.tabs;
    const areas = template.areas;
    const fields = template.fields;

    if (tabs.length > 0) {
      _.forEach(tabs, (tab) => {
        const newTab = {
          id: tab.id,
          templateOptions: {
            label: tab.label,
          },
          fieldGroup: [],
        };

        fieldGroup.push(newTab);
      });
    }

    if (areas.length == 0)
      throw Error('Must be at least 1 area to map to form');

    if (areas.length > 0) {
      _.forEach(areas, (area) => {
        const hasHideExpression =
          area.props?.hideExpression != ('' || undefined);

        const newArea = {
          id: area.id,
          wrappers: [area.props?.wrapper],
          templateOptions: {
            label: area.label,
            showHeader: area.props?.showHeader,
            fxLayout: area.style?.fxLayout ?? 'row wrap',
          },
          className: 'w-100',
          type: 'flex-layout',
          ...(hasHideExpression && {
            hideExpression: area.props?.hideExpression,
          }),
          fieldGroup: [],
        };
        if (area.tabId !== null) {
          const tabIndex = fieldGroup.findIndex((o) => o.id == area.tabId);
          fieldGroup[tabIndex].fieldGroup.push(newArea);
        } else {
          fieldGroup.push(newArea);
        }
      });
    }

    if (fields.length > 0) {
      _.forEach(fields, (field) => {
        const hasDefaultValue = (field.props?.defaultValue ?? '') !== '';
        const area = areas.filter((o) => o.id == field.areaId)[0];
        const hasParser = field.props?.parser?.format !== '';
        const isTextArea = field.control == 'textarea';
        const isSelectionList = field.control == 'selection-list';
        const hasDescription = field.props?.description !== '';
        const noFutureDates =
          field.control == 'custom-date-picker' &&
          field.props?.noFutureDates != '';

        const draftField = {
          id: field.id,
          key: field.key,
          type: field.control,
          className: this.mapStylingClasses(area, field),
          ...(hasDefaultValue && {
            defaultValue: this.calculateDefaultValue(field),
          }),
          hideExpression: field.hideExpression,
          templateOptions: {
            label: field.label,
            type: field.subControl,
            disabled: field.props?.disabled,
            isLabelHidden: field.props?.isLabelHidden,
            cssClassName: field.props?.fieldClasses,
            clearDisableValue: field.props?.clearDisableValue,
            format: field.props?.format,
            options: this.bindDataset(field, dict),
            required: field.required,
            hooks: {
              onInit: (field: any) => {
                field.templateOptions.label = field.label;
              },
            },
            addonRight: this.mapAddons(field),
            dataHint: field.props?.dataHint,
            ...(hasDescription && { description: field.props?.description }),
            ...(isTextArea && { rows: 10, appearance: 'fill' }),
            ...(isSelectionList && {
              listItems: [
                { label: 'First', value: 1 },
                { label: 'Second', value: 2 },
                { label: 'Third', value: 3 },
              ],
            }),
            ...(hasParser && { parser: field.props?.parser }),
            ...(noFutureDates && { noFutureDates: field.props?.noFutureDates }),
          },
        };

        const newField = { ...draftField };

        if (field.tabId !== null) {
          const _tabIndex = fieldGroup.findIndex((o) => o.id == field.tabId);
          const _areaIndex = fieldGroup[_tabIndex].fieldGroup.findIndex(
            (a: Area) => a.id == field.areaId
          );
          const areaArr =
            fieldGroup[_tabIndex].fieldGroup[_areaIndex].fieldGroup;

          if (areaArr == undefined) return;
          areaArr.push(newField);
        } else {
          const _areaIndex = fieldGroup.findIndex((a) => a.id == field.areaId);
          const areaArr = fieldGroup[_areaIndex].fieldGroup;

          if (areaArr == undefined) return;
          areaArr.push(newField);
        }
      });
    }

    const tabVersion = {
      type: 'info-tabs',
      fieldGroup: fieldGroup,
    };

    const noTabsVersion = fieldGroup;

    const result = tabs.length > 0 ? [tabVersion] : noTabsVersion;

    return result;
  }
  //generate json for admin portal
  convertToPropFields(template: Partial<Template>, dict?: any) {
    /// Has to be at least 1 Area to hold fields.

    const fieldGroup: any[] = [];
    const tabs = template.tabs;
    const areas = template.areas;
    const fields = template.fields;

    if (tabs.length > 0) {
      _.forEach(tabs, (tab) => {
        const newTab = {
          id: tab.id,
          props: {
            label: tab.label,
          },
          fieldGroup: [],
        };

        fieldGroup.push(newTab);
      });
    }

    if (areas.length == 0)
      throw Error('Must be at least 1 area to map to form');

    if (areas.length > 0) {
      _.forEach(areas, (area) => {
        const hasHideExpression =
          area.props?.hideExpression != ('' || undefined);

        const newArea = {
          id: area.id,
          wrappers: [area.props?.wrapper],
          props: {
            label: area.label,
            showHeader: area.props?.showHeader,
            fxLayout: area.style?.fxLayout ?? 'row wrap',
          },
          className: 'w-100',
          type: 'flex-layout',
          ...(hasHideExpression && {
            hideExpression: area.props?.hideExpression,
          }),
          fieldGroup: [],
        };
        if (area.tabId !== null) {
          const tabIndex = fieldGroup.findIndex((o) => o.id == area.tabId);
          fieldGroup[tabIndex].fieldGroup.push(newArea);
        } else {
          fieldGroup.push(newArea);
        }
      });
    }

    if (fields.length > 0) {
      _.forEach(fields, (field) => {
        const hasDefaultValue = (field.props?.defaultValue ?? '') !== '';
        const area = areas.filter((o) => o.id == field.areaId)[0];
        const hasParser = field.props?.parser?.format !== '';
        const isTextArea = field.control == 'textarea';
        const isSelectionList = field.control == 'selection-list';
        const hasDescription = field.props?.description !== '';
        const updateOnBlur = field.control == 'text';
        const hasMinLengthValidation = field.props?.minLength > 0;
        const hasMaxLengthValidation = field.props?.maxLength > 0;
        const hasFutureDateValidation =
          (field.control == 'datepicker' ||
            (field.control == 'text' &&
              (field.subControl == 'date' ||
                field.subControl == 'datetime-local'))) &&
          field.props?.noFutureDates == true;
        const dateFormatValidation =
          field.control == 'datepicker' || field.subControl == 'datetime-local';
        const validators = this.getValidationArray(field);
        const asyncValidators = this.getAsyncValidationArray(field);
        const isNumber = this.isNumericControl(field);
        // Retrieve the minimum value from the field props, defaulting to 0 if it's not a number
        let minVal = Number.isNaN(field.props.min) ? 0 : field.props.min;
        // Retrieve the maximum value from the field props, defaulting to 100 if it's not a number or 0
        let maxVal =
          Number.isNaN(field.props.max) || field.props.max === 0
            ? 100
            : field.props.max;
        let resultExp = this.getExpression(field, minVal, maxVal);

        let resultField;

        const draftField = {
          id: field.id,
          key: field.key,
          label: field.label,
          type: field.control == 'radioProtocol' ? 'radio' : field.control,
          className: this.mapStylingClasses(area, field),
          ...(hasDefaultValue && {
            defaultValue: this.calculateDefaultValue(field),
          }),
          ...(isNumber
            ? {
                templateOptions: {
                  label: field.label,
                  type: 'numeric',
                  min: minVal,
                  max: maxVal,
                  required: field.required,
                  //pattern: resultExp,
                },
                hideExpression: field.hideExpression,
                ...(updateOnBlur && {
                  modelOptions: {
                    updateOn: 'blur',
                  },
                }),
                hooks: {
                  onInit: (field: any) => {
                    // Set the label of the field templateOptions to match the provided 'fld.label'
                    field.templateOptions.label = field.label;
                  },
                },
              }
            : {
                hideExpression: field.hideExpression,
                ...(updateOnBlur && {
                  modelOptions: {
                    updateOn: 'blur',
                  },
                }),
                props: {
                  label: field.label,
                  type: field.subControl,
                  disabled: field.props?.disabled,
                  isLabelHidden: field.props?.isLabelHidden,
                  cssClassName: field.props?.fieldClasses,
                  clearDisableValue: field.props?.clearDisableValue,
                  format: field.props?.format,
                  options: this.bindDataset(field, dict),
                  required: field.required,
                  hooks: {
                    onInit: (field: any) => {
                      field.templateOptions.label = field.label;
                    },
                  },
                  tooltip: this.mapTooltip(field),
                  addonRight: this.mapAddons(field),
                  dataHint: field.props?.dataHint,
                  ...(hasDescription && {
                    description: field.props?.description,
                  }),
                  ...(isTextArea && { rows: 10, appearance: 'fill' }),
                  ...(isSelectionList && {
                    listItems: [
                      { label: 'First', value: 1 },
                      { label: 'Second', value: 2 },
                      { label: 'Third', value: 3 },
                    ],
                  }),
                  ...(hasParser && { parser: field.props?.parser }),
                  ...(hasMinLengthValidation && {
                    minLength: field.props?.minLength,
                  }),
                  ...(hasMaxLengthValidation && {
                    maxLength: field.props?.maxLength,
                  }),
                  ...(hasFutureDateValidation && {
                    max: this.getTomorrowDate(),
                  }),
                  ...(dateFormatValidation && {
                    datepickerOptions: {
                      startView: 'month' as const,
                      datepickerTogglePosition: 'suffix' as const,
                      ...(hasFutureDateValidation && { max: new Date() }),
                      disabled: false,
                      opened: false,
                      dateInput: () => {},
                      dateChange: () => {},
                      monthSelected: () => {},
                      yearSelected: () => {},
                    },
                  }),
                },
                asyncValidators: asyncValidators,
              }),
          ...{
            validators: {
              ...(validators &&
                validators.length > 0 && {
                  validation: validators,
                }),
              ...(field &&
                field.validator && {
                  textValidation: this.getTextValidation(field.validator),
                }),
            },
          },
        };

        const newField = { ...draftField };

        if (field.tabId !== null) {
          const _tabIndex = fieldGroup.findIndex((o) => o.id == field.tabId);
          const _areaIndex = fieldGroup[_tabIndex].fieldGroup.findIndex(
            (a: Area) => a.id == field.areaId
          );
          const areaArr =
            fieldGroup[_tabIndex].fieldGroup[_areaIndex].fieldGroup;

          if (areaArr == undefined) return;
          areaArr.push(newField);
        } else {
          const _areaIndex = fieldGroup.findIndex((a) => a.id == field.areaId);
          const areaArr = fieldGroup[_areaIndex].fieldGroup;

          if (areaArr == undefined) return;
          areaArr.push(newField);
        }
      });
    }

    const tabVersion = {
      type: 'info-tabs',
      fieldGroup: fieldGroup,
    };

    const noTabsVersion = fieldGroup;

    const result = tabs.length > 0 ? [tabVersion] : noTabsVersion;

    return result;
  }
  getExpression(fld: any, minVal: number, maxVal: number): any {
    let resultExp;
    const integerExpression = new RegExp(`^[0-9]{${minVal},${maxVal}}$`);
    const decimalExp = new RegExp(
      `^[0-9]{${minVal},${maxVal}}(\.00)?|[0-9]{1,2}(\.[0-9]{1,2})?$`
    );

    if (fld.validator === 'decimal') {
      resultExp = decimalExp;
    } else if (fld.validator === 'integer') {
      resultExp = integerExpression;
    } else {
      // by default
      resultExp = integerExpression;
    }
    return resultExp;
  }
  convertTabsToForms(template: Partial<Template>, dict?: any) {
    const fields = this.convertToPropFields(template, dict);
    const tabs = _.forEach(fields[0].fieldGroup, (tab) => {});
  }

  mapHideExpressions(key: string) {
    if (key == 'caller.isSupervisorPresent') {
      return "model.caller?.whoIsCalling == 'supervisor'";
    }
    return '';
  }
  mapOptions(hint: string, dict: any) {
    const foundArr = dict[hint];

    const result = foundArr
      ? dict[hint].map((e: any) => {
          return {
            label: e.label ?? e.value,
            value: e.label ?? e.value,
          };
        })
      : [];

    return result;
  }

  mapAddons(field: Field) {
    // if(field.control == 'phone') return {icon: 'phone'};
    if (field.subControl == 'email') return { icon: 'email' };
    return;
  }
  mapTooltip(field: Field) {
    if (
      (field.control == 'text' || field.control == 'textarea') &&
      field.props?.textTooltip
    )
      return {
        icon: 'info',
        text: field.props?.textTooltip,
      };
    return;
  }

  pasteFromField(field: Field) {
    if ((field.control == 'text' || field.control == 'textarea') && field.props?.pasteFromField)
      return {
        text: field.props?.pasteFromField,
      };
    return;
  }

  getPasteButtonProps(field: Field) {
    if ((field.control == 'text' || field.control == 'textarea') && field.props?.pasteButtonProps)
      return {
        text: field.props?.pasteButtonProps
      };
    return;
  }

  mapStylingClasses(area, field) {
    const hasFieldStyling = field.props?.fieldClasses !== '';
    const defaultClass = area?.style?.childClasses;
    if (hasFieldStyling) return defaultClass + ' ' + field.props?.fieldClasses;
    return defaultClass;
  }
  bindDataset(field: Field, dict: any) {
    if (field.options == undefined) return [];
    if (field.options.length > 0) return field.options;
    else {
      return field.props?.dataHint
        ? this.mapOptions(field.props?.dataHint, dict)
        : [];
    }
  }
  callEventByCallbackName(field: Field) {
    if (field.props?.callbackName == 'protocoldetails') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '900px';
    }
    if (field.props?.callbackName == 'generalmedicalhistory') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '500px';

      const data = {
        title: 'Attention',
        message: 'message',
        acceptButtonText: 'Ok',
      };

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '600px',
        data,
        disableClose: true,
      });

      dialogRef
        .afterClosed()
        .pipe()
        .subscribe((result) => {
          console.log('secondary modal dialog closed');
        });
    }
  }
  private _getPath(tabKey: string, areaKey: string, segments: string[]) {
    return [tabKey, areaKey, segments[segments.length - 1]].join('.');
  }

  mapMedicalProtocolTemplate(protocol: MedicalProtocol[], dict?: any) {
    const pforms = protocol.map((f) => {
      const interventiontemplate = f.interventionForm;
      const primarytemplate = f.primaryForm;
      const secondarytemplate = f.secondaryForm;

      f.primaryFormFields = this.convertToNoWrapPropFields(
        primarytemplate,
        dict
      );
      f.secondaryFormFields = this.convertToNoWrapPropFields(
        secondarytemplate,
        dict
      );
      f.interventionFormFields = this.convertToNoWrapPropFields(
        interventiontemplate,
        dict
      );
      return {
        ...f,
      };
    });
    return {
      ...pforms,
    };
  }
  mapMedicalProtocolTemplate1(protocol: MedicalProtocol[], dict?: any) {
    const result = {
      type: '',
      temp: protocol.map((f) => {
        const interventiontemplate = f.interventionForm;
        const primarytemplate = f.primaryForm;
        const secondarytemplate = f.secondaryForm;

        f.primaryFormFields = this.convertToNoWrapPropFields(
          primarytemplate,
          dict
        );
        f.secondaryFormFields = this.convertToNoWrapPropFields(
          secondarytemplate,
          dict
        );
        f.interventionFormFields = this.convertToNoWrapPropFields(
          interventiontemplate,
          dict
        );
        return {
          ...f,
        };
      }),
    };
    return [result.temp];
  }
  mapMenuItems(form: IForm, currentStateData: any) {
    var subMenu = form.menuItems;
    const menuKey = form.key;

    switch (menuKey.toLowerCase()) {
      case 'triage':
        const isProtocolSelected =
          currentStateData?.case?.protocols.length > 0 ? true : false;

        const newList = subMenu.map((p) => {
          if (
            p.menuLink === '/protocol/abcd' ||
            p.menuLink === '/protocol/secondaryassessment'
          ) {
            return { ...p, disabled: !isProtocolSelected };
          }
          return { ...p };
        });
        return newList;
        break;
      default:
        return subMenu; //no change
    }
  }
  applyHook(hookType: string, control: any, field: any) {
    console.log('Calling Hook: ' + hookType);
    switch (hookType) {
      case 'dateOfDeathHook': {
        this.dateOfDeathHook(control, field);
        break;
      }
      case 'injuryResultInDeathHook': {
        this.injuryResultInDeathHook(control, field);
        break;
      }
      case 'isReportOnlyHook': {
        this.isReportOnlyHook(control, field);
        break;
      }
      case 'employeeSoughtMedicalCareHook': {
        this.employeeSoughtMedicalCareHook(control, field);
        break;
      }
      case 'ssnHook': {
        this.ssnHook(control, field);
        break;
      }
      case 'isAlternateLocationSearch': {
        this.isAlternateLocationSearch(field);
        break;
      }
      case 'lagTimeHoursCalcHook': {
        this.lagTimeHoursCalcHook(control, field);
        break;
      }
      case 'stateofjurisdiction': {
        this.stateOfJurisdictionHook(control, field);
        break;
      }
      default: {
        console.log('Hook not found.');
        break;
      }
    }
  }

  injuryResultInDeathHook(control: any, field: any) {
    if (field.formControl.value == 'true'
    && (field['form'].get('eligibility.triage.dateOfDeath').value == null  || field['form'].get('eligibility.triage.dateOfDeath').value == undefined )
    ) {

      const dataPopUp = {
        message:
          'Please confirm the employee has died as the result of the reported incident.<br><br>If YES, Read account special handling instructions for a death report.',
        acceptButtonText: 'YES',
        cancelButtonText: 'NO',
        title: 'NFR Confirm',
        showCancelButton: true,
      };

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '450px',
        height: '250px',
        backdropClass: 'dark-backdrop',
        disableClose: true,
        data: dataPopUp,
      });

      dialogRef
        .afterClosed()
        .pipe()
        .subscribe((result) => {
          if (!result) {
            setTimeout(() => {
              field.formControl.setValue('false', {
                onlySelf: true,
                emitEvent: true,
              });
              field['form']
                .get('eligibility.triage.isReportOnly')
                .setValue('false', { onlySelf: true, emitEvent: false });
            }, 100);
          }
        });

      return false;
    }
    field['form']
      .get('eligibility.triage.isReportOnly')
      .setValue('false');
  }
  dateOfDeathHook(control: any, field: any) {
    if (
      field.formControl.value === undefined ||
      field.formControl.value === null
    ) {
      field['form']
        .get('eligibility.triage.deathReportedOn')
        .setValue(null, { onlySelf: true, emitEvent: false });
      if (
        field['form'].get('eligibility.triage.isReportOnly').value == 'true'
      ) {
        field['form']
          .get('planofcare.disposition.dispositionRecommended')
          .setValue('FNOL - Report Only', { onlySelf: true, emitEvent: false });
        field['form']
          .get('eligibility.triage.caseFlag')
          .setValue('FNOL - Report Only', { onlySelf: true, emitEvent: false });
      } else if (
        field['form'].get('eligibility.triage.employeeSoughtMedicalCare')
          .value == 'true'
      ) {
        field['form']
          .get('planofcare.disposition.dispositionRecommended')
          .setValue('FNOL', { onlySelf: true, emitEvent: false });
        field['form']
          .get('eligibility.triage.caseFlag')
          .setValue('FNOL', { onlySelf: true, emitEvent: false });
      } else {
        field['form']
          .get('planofcare.disposition.dispositionRecommended')
          .setValue(null, { onlySelf: true, emitEvent: false });
        field['form']
          .get('eligibility.triage.caseFlag')
          .setValue(null, { onlySelf: true, emitEvent: false });
      }

      return;
    }

    field['form']
      .get('planofcare.disposition.dispositionRecommended')
      .setValue('FNOL - Report Only', { onlySelf: true, emitEvent: false });
    field['form']
      .get('eligibility.triage.deathReportedOn')
      .setValue(new Date(), { onlySelf: true, emitEvent: false });
    field['form']
      .get('eligibility.triage.caseFlag')
      .setValue('Death Case', { onlySelf: true, emitEvent: false });
    field['form']
      .get('eligibility.triage.isReportOnly')
      .setValue('true', { onlySelf: true, emitEvent: false });
    field['form']
      .get('eligibility.triage.isEmployeeAvailable')
      .setValue('false', { onlySelf: true, emitEvent: false });
  }
  isAlternateLocationSearch(field: any) {
    if (field.formControl.value == false) {
      let ctrlAlternate = field['form'].get('intake.location.alternate');
      if (ctrlAlternate) {
        ctrlAlternate.controls['address1'].enable();
        ctrlAlternate.controls['address1'].setValue('', {
          onlySelf: true,
          emitEvent: false,
        });
        ctrlAlternate.controls['code'].enable();
        ctrlAlternate.controls['code'].setValue('', {
          onlySelf: true,
          emitEvent: false,
        });
        ctrlAlternate.controls['city'].enable();
        ctrlAlternate.controls['city'].setValue('', {
          onlySelf: true,
          emitEvent: false,
        });
        ctrlAlternate.controls['state'].enable();
        ctrlAlternate.controls['state'].setValue('', {
          onlySelf: true,
          emitEvent: false,
        });
        ctrlAlternate.controls['name'].enable();
        ctrlAlternate.controls['name'].setValue('', {
          onlySelf: true,
          emitEvent: false,
        });
        ctrlAlternate.controls['zip'].enable();
        ctrlAlternate.controls['zip'].setValue('', {
          onlySelf: true,
          emitEvent: false,
        });
        ctrlAlternate.controls['address2'].enable();
        ctrlAlternate.controls['address2'].setValue('', {
          onlySelf: true,
          emitEvent: false,
        });
        ctrlAlternate.controls['localPhone'].enable();
        ctrlAlternate.controls['localPhone'].setValue('', {
          onlySelf: true,
          emitEvent: false,
        });
      }
    }
  }
  ssnHook(control: any, field: any) {
    if (field.formControl.value != 'FullSSN') {
      let ctrl = field['form'].get('intake.employee.demographics.ssnValue');
      if (ctrl) {
        field['form']
          .get('intake.employee.demographics.ssnValue')
          .setValue(null, { onlySelf: true, emitEvent: false });
        return;
      }
    }
  }
  isReportOnlyHook(control: any, field: any) {
    if (
      field['form'].get('eligibility.triage.injuryResultInDeath').value ==
      'true'
    )
      return;

    if (field.formControl.value == 'true') {
      field['form']
        .get('eligibility.triage.employeeSoughtMedicalCare')
        .setValue('false', { onlySelf: true, emitEvent: false });
      field['form']
        .get('eligibility.triage.caseFlag')
        .setValue('FNOL - Report Only', { onlySelf: true, emitEvent: false });
      field['form']
        .get('planofcare.disposition.dispositionRecommended')
        .setValue('FNOL - Report Only', { onlySelf: true, emitEvent: false });
    } else if (
      field.formControl.value == 'false' &&
      field['form'].get('eligibility.triage.employeeSoughtMedicalCare').value ==
        'false'
    ) {
      field['form']
        .get('eligibility.triage.caseFlag')
        .setValue('', { onlySelf: true, emitEvent: false });
      field['form']
        .get('planofcare.disposition.dispositionRecommended')
        .setValue('', { onlySelf: true, emitEvent: false });
    }
  }
  employeeSoughtMedicalCareHook(control: any, field: any) {
    if (
      field['form'].get('eligibility.triage.injuryResultInDeath').value ==
      'true'
    )
      return;

    if (field.formControl.value == 'true') {
      field['form']
        .get('eligibility.triage.isReportOnly')
        .setValue('false', { onlySelf: true, emitEvent: false });
      field['form']
        .get('eligibility.triage.caseFlag')
        .setValue('FNOL', { onlySelf: true, emitEvent: false });
      field['form']
        .get('planofcare.disposition.dispositionRecommended')
        .setValue('FNOL', { onlySelf: true, emitEvent: false });
    } else if (
      field.formControl.value == 'false' &&
      field['form'].get('eligibility.triage.isReportOnly').value == 'false'
    ) {
      field['form']
        .get('eligibility.triage.caseFlag')
        .setValue('', { onlySelf: true, emitEvent: false });
      field['form']
        .get('planofcare.disposition.dispositionRecommended')
        .setValue('', { onlySelf: true, emitEvent: false });
    }
  }

  lagTimeHoursCalcHook(control: any, field: any) {
    let doi = field['form'].get(
      'intake.supervisor.accidentDescription.dateTimeOfInjury'
    ).value;
    let doiTz = field['form'].get(
      'intake.supervisor.accidentDescription.injuryTimeZone'
    ).value;
    let callTime = field['form'].get(
      'intake.supervisor.accidentDescription.dateReported'
    ).value;
    var lagTimeDiffhour = this.getLagTimeHours(doi, callTime, doiTz);

    field['form']
      .get('intake.supervisor.accidentDescription.timeFromInjuryToReporting')
      .setValue(lagTimeDiffhour, { onlySelf: true, emitEvent: true });
    if (lagTimeDiffhour < 24) {
      field['form']
        .get('intake.supervisor.accidentDescription.lagTimeRole')
        .setValue(null, { onlySelf: true, emitEvent: false });
      field['form']
        .get('intake.supervisor.accidentDescription.lagTimeRole')
        .disable();
    } else {
      field['form']
        .get('intake.supervisor.accidentDescription.lagTimeRole')
        .enable();
    }
  }

  getTextValidation(code: string): Object {
    let current = { expression: (c) => true };
    if (code == '') return current;
    for (let key in this.stringValidations) {
      if (key == code) {
        current = this.stringValidations[key];
        break;
      }
    }
    return current;
  }
  setInitialValueField(field: any) {
    if (['checkbox', 'multicheckbox'].includes(field.type)) {
      field.formControl.setValue(false, { emitEvent: false });
    } else if (['text'].includes(field.type)) {
      field.formControl.setValue(null, { onlySelf: true, emitEvent: false });
    }
  }

  stateOfJurisdictionHook(control: any, field: any) {
    //field._elementRefs[0].nativeElement.getAttribute('data-codeme')
    const _stateOfJurisdiction = field['form'].get(
      'intake.accident.details.stateOfJurisdiction'
    )?.value;
    if (_stateOfJurisdiction) {
      this.store.dispatch({
        type: '[Case] Validate Telemedicine',
        data: { stateOfJurisdiction: _stateOfJurisdiction },
      });
    }
    return;
  }
  getLagTimeHours(doi, callStartTime, timeZone) {
    let doiTz = 'America/New_York';
    switch (timeZone) {
      case 'cst':
        doiTz = 'America/Chicago';
        break;
      case 'est':
        doiTz = 'America/New_York';
        break;
      case 'mst':
        doiTz = 'America/Denver';
        break;
      case 'pst':
        doiTz = 'America/Los_Angeles';
        break;
      case 'ast':
        doiTz = 'US/Alaska';
        break;
      case 'hst':
        doiTz = 'US/Hawaii';
        break;
    }
    // Parse the input dates and apply the separate timezone offset to 'doi'
    const doiDate = moment.tz(doi, doiTz);
    const utcDateParsed = moment.utc(callStartTime);
    const reportDateinTz = moment.tz(utcDateParsed, doiTz);
    // Calculate the difference in hours
    let differenceInHours = Math.floor(reportDateinTz.diff(doiDate, 'hours'));
    if (differenceInHours < 0) differenceInHours = 0;
    return differenceInHours;
  }

  getTomorrowDate() {
    return moment().format('YYYY-MM-DD') + 'T00:00';
  }
}
