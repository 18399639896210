import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { Observable, of } from 'rxjs';
import {
  startWith,
  switchMap,
} from 'rxjs/operators';
import { LookupService } from '../../services/lookup.service';
import { AutocompleteSelectionDirective } from '@autobot/shared';
import { MatInput } from '@angular/material/input';
import {
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'formly-autocomplete-type',
  template: `
    <input
      matInput
      autobotForceSelection
      [matAutocomplete]="auto"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder"
      [errorStateMatcher]="errorStateMatcher"
      (click)="onClick($event)"
      (keyup)="loadRecords($event)"
      [name]="props.dataHint"
      autocomplete="no"
    />

    <a *ngIf="value && !disabled" matSuffix aria-label="Clear" (click)="clear()">
      <mat-icon style="font-size:12px; height:12px">close</mat-icon>
    </a>

    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
      <mat-option *ngIf="(filter | async)?.length === 0" disabled>
        No items
      </mat-option>
      <mat-option
        *ngFor="let opt of filter | async"
        [value]="opt.label"
        (onSelectionChange)="onSelectionChange(opt, $event)"
        [title]="opt.label"
      >
        {{ opt.label }}
      </mat-option>
    </mat-autocomplete>
  `,
  styles: [
    `
      .mat-input-element {
        width: 90%;
      }
      .error {
        font-size: 75%;
        position: absolute;
        margin-top: 8px;
      }
    `,
  ],
})
export class LookupAutocompleteComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, AfterViewInit
{
  @ViewChild(AutocompleteSelectionDirective, { static: true })
  directive: AutocompleteSelectionDirective;

  @ViewChild(MatInput) formFieldControl: MatInput;

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  svc = inject(LookupService);

  filter!: Observable<any>;

  isLoading = false;
  errorMsg!: string;
  minLengthTerm = 3;
  
  constructor(private dialog: MatDialog) {
    super();
  }

  ngAfterViewInit(): void {
    (<any>this.autocomplete)._formField = this.formField;
  }

  ngOnInit() {
    const hint = this.props.dataHint;

    if (hint != 'location') {
      this.filter = this.formControl.valueChanges.pipe(
        startWith(''),
        switchMap((term) => {
          const result = this.svc.filter(term, hint);
          return result;
        })
      );
    }

    if (hint == 'location' && this.formControl.value?.length > 0) {
      let controlLocation =
        this.form.controls['intake']?.controls['location']?.controls['work'];
      if (controlLocation) {
        this.disablecontrol(controlLocation);
      }
      this.formControl.enable();
    }
    if (hint == 'alternatelocation' && this.formControl.value?.length > 0) {
      let ctrlAlternate =
        this.form.controls['intake']?.controls['location']?.controls[
          'alternate'
        ];
      if (ctrlAlternate) {
        this.disablecontrol(ctrlAlternate);
      }
      this.formControl.enable();
    }
  }

  onClick(event: any) {
    let searchItem = '';
    let dataHint = this.props.dataHint;
    searchItem += event.target.value;
    if (dataHint == 'location' || dataHint == 'alternatelocation') {
      if (searchItem.length == 0) {
        this.filter = of(null);
      }
    }
  }
  loadRecords(event: any) {
    let searchItem = '';
    let dataHint = this.props.dataHint;
    searchItem += event.target.value;
    if (
      event.key == 'ArrowDown' ||
      event.key == 'ArrowUp' ||
      event.key == 'Enter'
    ) {
      return;
    }
    if (dataHint == 'location' || dataHint == 'alternatelocation') {
      if (searchItem.length >= 3) {
        const result = this.svc.filterLocation(searchItem, dataHint);
        this.filter = result;
        this.filter.subscribe((x) => {
          if (x.length === 0) {
          }
        });
      } else {
        this.filter = of(null);
      }
    }
  }

  clear() {
    if (this.props.dataHint == 'location') {
      let ctrl =
        this.form.controls['intake']?.controls['location']?.controls['work'];
      if (ctrl) {
        ctrl.controls['address1'].setValue('');
        ctrl.controls['code'].setValue('');
        ctrl.controls['city'].setValue('');
        ctrl.controls['state'].setValue('');
        ctrl.controls['name'].setValue('');
        ctrl.controls['zip'].setValue('');
        ctrl.controls['division']?.setValue('');
        ctrl.controls['localPhone']?.setValue('');
        ctrl.enable();
      }
      this.formControl.setValue('');
      this.filter = of(null);
    } else if (this.props.dataHint == 'alternatelocation') {
      let ctrlAlternate =
        this.form.controls['intake']?.controls['location']?.controls[
          'alternate'
        ];
      if (ctrlAlternate) {
        if (ctrlAlternate.controls['isInjuredInAlternateLocation'].value) {
          ctrlAlternate.controls['address1'].setValue('');
          ctrlAlternate.controls['code'].setValue('');
          ctrlAlternate.controls['city'].setValue('');
          ctrlAlternate.controls['state'].setValue('');
          ctrlAlternate.controls['name'].setValue('');
          ctrlAlternate.controls['zip'].setValue('');

          ctrlAlternate.enable();
        }
      }
      this.formControl.setValue('');

      this.filter = of(null);
    } else {
      this.formControl.setValue('');
      //this.filter = null;
    }
  }
  disablecontrol(controlLocation: any) {
    controlLocation.controls['address1']?.disable();
    controlLocation.controls['code']?.disable();
    controlLocation.controls['city']?.disable();
    controlLocation.controls['state']?.disable();
    controlLocation.controls['name']?.disable();
    controlLocation.controls['zip']?.disable();
   
  }
  onSelectionChange(event, evt: any) {
    if (evt.isUserInput) {
      if (event.value && this.props.dataHint == 'location') {
        let stateLabel = '';
        this.svc.getItemByfieldID('states', event.state).subscribe((data) => {
          if (data?.length > 0) {
            stateLabel = data[0].label;
          }
        });

        let ctrl =
          this.form.controls['intake']?.controls['location']?.controls['work'];
        if (ctrl) {
          ctrl.controls['address1'].setValue(event.address);
          ctrl.controls['code'].setValue(event.code);
          ctrl.controls['city'].setValue(event.city);
          ctrl.controls['state'].setValue(stateLabel);
          ctrl.controls['name'].setValue(event.address);
          ctrl.controls['zip'].setValue(event.Zip);
          ctrl.controls['division']?.setValue(event.division);
          
          this.disablecontrol(ctrl);
          ctrl.controls['division']?.disable();
        }
      }
      if (event.value && this.props.dataHint == 'alternatelocation') {
        let ctrlAlternate =
          this.form.controls['intake']?.controls['location']?.controls[
            'alternate'
          ];

        if (ctrlAlternate) {
          // if (ctrlAlternate.controls['isInjuredInAlternateLocation'].value) {
          ctrlAlternate.controls['address1'].setValue(event.address);
          ctrlAlternate.controls['code'].setValue(event.code);
          ctrlAlternate.controls['city'].setValue(event.city);
          ctrlAlternate.controls['state'].setValue(event.state);
          ctrlAlternate.controls['name'].setValue(event.address);
          ctrlAlternate.controls['zip'].setValue(event.Zip);

          this.disablecontrol(ctrlAlternate);
          //}
        }
      }
      if(event.value.toLowerCase() != "no" && this.props.dataHint.toLowerCase() == "escalationcriteria"){
        this.showEscalationCriteriaPopupMsg(event.value);
      }
    }
    this.formControl.enable();
  }

  showEscalationCriteriaPopupMsg(selectedCriteria){
    const data = {
      title: 'Attention',
      content: "You have selected <b>"+ selectedCriteria +"<b> Criterion. </br> Are you sure you want to continue?</br>",
      confirmBtnText: 'Yes',
      cancelBtnText: 'No'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data,
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe()
      .subscribe((res) => {
        if(!res){
          this.formControl.setValue(this.formControl.defaultValue);
        }
      });
  }
}
