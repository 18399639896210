import { Component, Input } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';


@Component({
  selector: 'formly-info',
  template: `
  
  <div class="w-100" >
    <div [class]="className" [innerHTML]="to.label">
     
    </div>
</div>
 `,
 styles: [
  `
  .messageClass
  {
    width:100%;
    height:75px;
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
    margin: 0 auto; 
    border-radius: 4px;
     text-align: center; 
     width:450px;
     font-size: 20px;
    font-weight: 500;
    padding: 1.5rem;
     
  }
  .messageClassfullwidth
  {
    width:100%;
  
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
    margin: 0 auto; 
    border-radius: 4px;
     text-align: center; 
   
     font-size: 15px;
    font-weight: 500;
    padding: 1.5rem;
     
  }
    `,
],
})
export class FormlyInfoType extends FieldType<FieldTypeConfig> {

  get className() {
    return this.props.cssClassName || 'messageClass';
  }
}

export const formlyInfo = {
  name: 'formly-info',
  component: FormlyInfoType

};
