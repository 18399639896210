import {Case, CaseView} from "./case";
import { Guid } from '@autobot/core';
export class Session implements ISession {
  constructor(agent:string) {
    this.id  = Guid.newGuid();
    this.agent = agent;

    this.events = [];
  }
  id: string
  start!: Date
  account: any;
  agent: string;
  events: IEvent[];
  inContactId!: string;
  case?: CaseView;
  end?: Date;
  translator: any;

  setTranslation(){

  }
  setAccount(account:any){
    this.account = account;
    this.addEvent(new SessionEvent('Account Set',account))
  }
  startSession(){
    this.start  = new Date();
    this.addEvent(new SessionEvent('Call Started'))

  }
  endSession(){
    this.end  = new Date();
    this.events = [
      new SessionEvent('Call Ended')
    ];
  }
  createCase(data?: any){

    this.events.push(new SessionEvent('Case Created', data))
    this.case = new CaseView(this.account.id, this.agent);
  }
  loadCase(caseId:string){
    this.addEvent(new SessionEvent('Case Loaded', {caseId}))

  }
  addEvent(e:IEvent){
    this.events.push(e);
  }

}


export function runSession(){
  const session = new Session('andrej')
  session.endSession();
  session.loadCase('andrej-case');
  console.log(session)
}


export interface ISession {
  id: string;
  start: Date;
  end?: Date;
  events: SessionEvent[];
}

export class SessionEvent implements IEvent {
  constructor(name:string,payload?:any) {
    this.id = Guid.newGuid();
    this.name = name;
    this.payload = payload;
    this.timestamp = new Date();
    this.type = 'Session';
  }
  id: string;
  name: string;
  payload?: any;
  timestamp: Date;
  type: string;
}

export enum SessionType {
   new = 'new',
   existing = 'existing',
 }

export enum CaseMode {
  new = 'new',
  edit = 'edit',
  view = 'view'
}

export interface IEvent {
  id: string;
  name: string;
  payload?: any;
  timestamp: Date;
  type: string;
}
