import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormlyFieldTabsetComponent} from "./tabset.type";
import {VisiblePipe} from "./visible.pipe";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatTabsModule} from "@angular/material/tabs";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FormlyModule} from "@ngx-formly/core";



@NgModule({
  declarations: [FormlyFieldTabsetComponent,VisiblePipe],
  imports: [
    CommonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatTabsModule,
    FormlyModule.forChild({
      types: [
        {
          name: "tabset",
          component: FormlyFieldTabsetComponent
        }
      ]
    })
  ]
})
export class FormlyMatTabsetModule { }
