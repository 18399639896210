import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthenticationService } from '../services/authentication.service';
import * as fromAuthActions from './authentication.actions';
import { filter, switchMap, tap } from 'rxjs/operators';
import { combineLatest, forkJoin, of } from 'rxjs';
import { Role } from './../models';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthenticationService
  ) {}
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.login),
        tap(() => this.authService.login())
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.logout),
      tap(() => this.authService.logout()),
      switchMap(() => of(fromAuthActions.logoutComplete()))
    )
  );
  checkAuth$ = createEffect(() =>
    this.actions$.pipe(
      // If an action with the type 'checkAuth' occurs in the actions$ stream...
      ofType(fromAuthActions.checkAuth),
      // return an observable including the latest info from 'isLoggedIn' and 'userProfile'
      switchMap(() =>
        combineLatest([
          this.authService.isLoggedIn$,
          this.authService.user$,
        ]).pipe(
          filter(([_, profile]) => profile !== null), // Optional: Filter out the initial null value if needed
          // Take it out and return the appropriate action based on if logged in or not
          switchMap(([isLoggedIn, profile]) => {
            if (isLoggedIn) {
              const roles = profile['roles'];
              var isSupervisor = roles.some(
                (r: string) => r == Role.Supervisor
              );
              var isTestUser = roles.some(
                (r: string) => r == Role.Test
              );
              return of(
                fromAuthActions.loginComplete({
                  profile,
                  isLoggedIn,
                  roles,
                  isSupervisor,
                  isTestUser
                })
              );
            }

            return of(fromAuthActions.logoutComplete());
          })
        )
      )
    )
  );
  //effects go here
}
