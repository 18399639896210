import {DatapointSummary, Guid, IDataPoint, Series} from "@autobot/core";
import {ISparkChart} from "./chart.models";


export interface Dashboard {
  filterDates: {
    start: Date,
    end: Date
  },

  series: IDataPoint[]
}

export interface ChartModels {
  sparks?: ISparkChart[];
  widgets?: any[];
  radials?: any[];
  options: any;

}

export enum DashboardScope {
  today,
  week,
  month,
  all
}

export interface DashboardDataView {
  totals: {
    citations: DatapointSummary;
    distance: DatapointSummary;
    paused: DatapointSummary;
    totalCovered: DatapointSummary;
    totalSkipped: DatapointSummary;
  }
  users: UserView[];
  days: string[];
  isEmpty?: boolean
}

export interface UserView {
  info: UserInfo;
  totals: {
    citations: number;
    distance: number;
    paused: number;
    timeTravelled: number;
    totalCovered: number;
    totalSkipped: number;
  }
  series: {
    citations: Series[];
    distance: Series[];
    paused: Series[];
    timeTravelled: Series[];
    totalCovered:  Series[];
    totalSkipped: Series[];
  }
}

export interface UserInfo {
  id: string;
  name: string;
  employeeCode: string;
  email: string;
  oauthId?: string;
  userName: string;
  role: string;

}

export interface UnAssignedFollowUps {
  caseRef: string;
  caseOrigin: string;
  accountName: string;
  name: string;
  date: Date;
  createdBy: string;
  dateCreated?: Date;
  dateCompleted?: Date;
  followUpDueDate?: Date;
  status: string;
  caseType: string;
  disposition: string;
  mobilePhone: string;
  workPhone: string;
  supervisorName: string;
  accidentDescription: string;
  bodyPartInjured: string;
  subjectiveAssessment: string;
  notes: string;
}

export interface AssignedFollowUps {
  id: Guid;
  caseRef: string;
  caseOrigin: string;
  accountName: string;
  name: string;
  date: Date;
  createdBy: string;
  dateCreated?: Date;
  dateCompleted?: Date;
  followUpDueDate?: Date;
}

export interface UnAssignedCases {
  accountId: Guid;
  accountName: string;
  caseRef: string;
  caseOrigin: string;
  created: string;
  name: string;
  mobilePhone: string;
  workPhone: string;
  supervisorName: string;
  accidentDescription: string;
  bodyPartInjured: string;
}

export interface AssignedCases {
  id: Guid;
  accountId: Guid;
  created: string;
  name: string;
  accountName: string;
  caseRef: string;
  caseOrigin: string;
  caseType: string;
  bodyPartInjured: string;
  createdBy: string;
  dateCreated: Date;
}

export interface CaseAssignment {
  caseId:string;
  assignedTo:string;
  assignDate:Date;
  followUpAction:string;
  lastAssignDate:Date;
  caseStatus: string;
}

export enum CaseOriginDisplay {
  NfrStandAlone = 'NFR Stand Alone',
  LrmTransfer = 'LRM Transfer',
}