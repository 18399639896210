import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';


@Component({
  selector: 'formly-field-button',
  template: `
    <div>
      <button [type]="props.type"  (click)="onClick($event)" [class]="className">
        {{ props.label }}
      </button>
    </div>
  `,
})

export class CustomButtonType extends FieldType<FieldTypeConfig> {

  get className() {
    return this.props.cssClassName || 'mat-focus-indicator mat-raised-button mat-button-base mat-primary';
  }
  onClick($event: Event) {
    if (this.props.onClick) {
      this.props.onClick(this.props,$event);
    }
   
  }
}


