import { Component, Inject } from '@angular/core';
import { AuthenticationFacade } from '@autobot/authentication';
import { IEnvironmentConfig } from '@autobot/core';

@Component({
  selector: 'autobot-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent {
  siteName = 'Agent Portal';
  loggedIn$ = this.auth.loggedIn$;
  items: any[];
  constructor(
    private auth: AuthenticationFacade,
    @Inject('app-menu') public cfg: any,
    @Inject('ENVIRONMENT') private environment: IEnvironmentConfig
  ) {
    //this.items = cfg.items.filter((e) => e.props?.horizontalEnabled);
    this.items = [
      {
        link: '/nfr',
        icon: 'home',
        label: 'Agent Portal',
        props: {
          horizontalEnabled: true,
          reqAuth: false
        }
      }
    ];
  }

  login() {
    this.auth.login();
  }
}
