import { Injectable } from '@angular/core';
import { LookupService } from '@forms/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { LookUpActions } from '../actions';
import { error } from 'console';

@Injectable()
export class LookUpEffects {
  loadLookup = createEffect(() => {
    return this.actions$.pipe(
      ofType(LookUpActions.loadLookup),
      switchMap(() => {
        return  this.lookupService.init();
      }),
      map((looksups) => {
        return LookUpActions.loadLookupSuccess({ looksups });
      }),
      catchError((error: unknown)=>{
        return of(LookUpActions.loadLookupFailure({error}));
      })
    );
  });

  constructor(
    private actions$: Actions,
    private lookupService: LookupService
  ) {}
}
