import { Assembly } from '@forms/common';
import { Case, CaseSearchResult, Note, Protocols } from '@nfr/common';
import { createAction, props } from '@ngrx/store';

export enum CaseApiActionTypes {
  LOAD_CASE_SUCCESS = '[Case/API] Load Case Success',
  LOAD_CASE_FAILURE = '[Case/API] Load Case Failure',
  SAVE_CASE_SUCCESS = '[Case/API] Save Case Success',
  SAVE_CASE_FAILURE = '[Case/API] Save Case Failure',
  SEARCH_CASES_SUCCESS = '[Case/API] Search Cases Success',
  SEARCH_CASES_FAILURE = '[Case/API] Search Cases Failure',
  CREATE_CASE_SUCCESS = '[Case/API] Create Case Success',
  CREATE_CASE_FAILURE = '[Case/API] Create Case Failure',
  LOAD_SEARCH_CASES_SUCCESS = '[Case/API] Load Search Case Success',
  LOAD_SEARCH_CASES_FAILURE = '[Case/API] Load search Case Failure',

  SAVE_NOTE_API = '[Case] Save Note API',
  SAVE_NOTE_API_SUCCESS = '[Case] Save Note API Success',
  SAVE_NOTE_API_FAILURE = '[Case] Save Note API Failure',
}

export const createCaseSuccess = createAction(
  CaseApiActionTypes.CREATE_CASE_SUCCESS,
  props<{
    case: Case;
  }>()
);

export const createCaseFailure = createAction(
  CaseApiActionTypes.CREATE_CASE_FAILURE,
  props<{ error: any }>()
);

export const loadCaseSuccess = createAction(
  CaseApiActionTypes.LOAD_CASE_SUCCESS,
  props<{
    model: any;
    obj: Case;
    assembly: Assembly;
    notes: Note[];
    protocols: Protocols[];
  }>()
);

export const loadCaseFailure = createAction(
  CaseApiActionTypes.LOAD_CASE_FAILURE,
  props<{ error: any }>()
);

export const saveCaseSuccess = createAction(
  CaseApiActionTypes.SAVE_CASE_SUCCESS
);

export const saveCaseFailure = createAction(
  CaseApiActionTypes.SAVE_CASE_FAILURE,
  props<{ error: any }>()
);

export const searchCasesSuccess = createAction(
  CaseApiActionTypes.SEARCH_CASES_SUCCESS,
  props<{ results: CaseSearchResult[] }>()
);

export const searchCasesFailure = createAction(
  CaseApiActionTypes.SEARCH_CASES_FAILURE,
  props<{ error: any }>()
);
export const loadSearchCaseSuccess = createAction(
  CaseApiActionTypes.LOAD_SEARCH_CASES_SUCCESS,
  props<{
    results: CaseSearchResult[];
  }>()
);

export const loadSearchCaseFailure = createAction(
  CaseApiActionTypes.LOAD_SEARCH_CASES_FAILURE,
  props<{ error: any }>()
);
export const saveNoteAPI = createAction(CaseApiActionTypes.SAVE_NOTE_API);
export const saveNoteAPISuccess = createAction(
  CaseApiActionTypes.SAVE_NOTE_API_SUCCESS
);
export const saveNoteAPIFailure = createAction(
  CaseApiActionTypes.SAVE_NOTE_API_FAILURE,
  props<{ error: any }>()
);
