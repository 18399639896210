import {Component, Inject, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Params} from "@angular/router";
import {IMultiMenuConfig} from "../sidenav/main-nav/main-nav.component";
import {MenuItem} from "./horizontal-menu-item.component";


@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss']
})
export class HorizontalMenuComponent implements OnInit {
  @Input() params: Observable<Params> | undefined;
  currentItemId: string | undefined;
  items: MenuItem[]
  constructor( @Inject('app-menu') public cfg: IMultiMenuConfig) {
    this.items = cfg.items.filter(e => e.props?.horizontalEnabled)
  }

  ngOnInit(): void {
  }

}
