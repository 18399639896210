import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { getFlatCaseModel, selectCaseState } from './case.selectors';

export const getSupervisor = createSelector(
  selectCaseState,
  getFlatCaseModel,
  (state, model) => {
    const firstName = model.find((o) =>
      hasWordsSearch(o.key, ['supervisor', 'firstName'])
    )?.value ?? '' ;
    const lastName = model.find((o) =>
      hasWordsSearch(o.key, ['supervisor', 'lastName'])
    )?.value ?? '' ;
    const firstNameAnother = model.find((o) =>
      hasWordsSearch(o.key, ['another', 'firstName'])
    )?.value ?? '' ;
    const lastNameAnother = model.find((o) =>
      hasWordsSearch(o.key, ['another', 'lastName'])
    )?.value ?? '' ;
    let fullName = firstName + ' ' + lastName;
    let nameLabel='Supervisor'
    if(firstNameAnother)
    {
      nameLabel='Another Person'
      fullName = firstNameAnother + ' ' + lastNameAnother;
    }
    
    return {
      firstName,
      lastName,
      fullName,
      nameLabel,
    };
  }
);
export const getAnotherPerson = createSelector(
  selectCaseState,
  getFlatCaseModel,
  (state, model) => {
    const firstName = model.find((o) =>
      hasWordsSearch(o.key, ['another', 'firstName'])
    )?.value ?? '' ;
    const lastName = model.find((o) =>
      hasWordsSearch(o.key, ['another', 'lastName'])
    )?.value ?? '' ;
    const fullName = firstName + ' ' + lastName;
    return {
      firstName,
      lastName,
      fullName,
    };
  }
);

export const getEmployee = createSelector(
  selectCaseState,
  getFlatCaseModel,
  (state, model) => {
    const firstName = model.find((o) =>
      hasWordsSearch(o.key, ['employee', 'firstName'])
    )?.value;
    const lastName = model.find((o) =>
      hasWordsSearch(o.key, ['employee', 'lastName'])
    )?.value;
    const fullName = firstName + ' ' + lastName;
    return {
      firstName,
      lastName,
      fullName,
    };
  }
);

const hasWordsSearch = (text, searchWords) =>
  searchWords.every((el) => {
    return text.match(new RegExp(el, 'i'));
  });
