import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {TemplateCreatorActions, TemplateEditorActions} from "../actions";
import {switchMap} from "rxjs/operators";

import {of} from "rxjs";

import * as fileSaver from 'file-saver';
import {getDateString} from "@forms/common";


@Injectable()
export class TemplateCreatorEffects {
  constructor(private actions$: Actions
 ) {

  }


  exportTemplateClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplateCreatorActions.exportTemplateClicked, TemplateEditorActions.exportTemplateClicked),

      switchMap(({template}) => {


        const json = JSON.stringify(template, null, 2);
        const blob = new Blob([json], {type: 'application/json'});

        const fileName = 'JSON_' + getDateString() + '.json';

        fileSaver.saveAs(blob, fileName);

        return of(true);
      }),

    )
  },{dispatch: false})
}
