import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Assembly, IForm } from '../models/assembly.model';
import { ICaseRule } from '../models/caserules.model';
import { Rule } from '../models/rule.models';

@Injectable({ providedIn: 'root' })
export class RuleService {
  constructor(private http: HttpClient) {}

  getSampleRules() {
    return this.http.get<Rule[]>('assets/data/rules/intake.eligibility.json');
  }

  getCaseNavigationByCaseBasedRule(assembly: Assembly, rules: ICaseRule, caseMetaData: any,caseData:any) {
    const isCaseCompleted= caseData?.status?.toUpperCase() == 'COMPLETED'
    const result = {
      type: '',
      newForm: assembly.forms.map((f) => {
        let isMenuDisable = null;
        let isChildMenuDisabled = false;
        let mappedMenu = null;

        if (caseMetaData && caseMetaData.caseType == 'Emergency') {
          isMenuDisable = false;
        } else if (f.key.toLowerCase() == 'triage') {
          isMenuDisable = rules.ApplyNurseTriageRule;
        } else if (f.key.toLowerCase() == 'planofcare') {
          isMenuDisable = rules.ApplyNurseTriageRule;
          isMenuDisable = rules.ApplyPlanOfCareExceptionRule ? false : isMenuDisable;
          mappedMenu = f.menuItems.map((p) => {
            isChildMenuDisabled = rules.ApplyPlanOfCareExceptionRule ? (p.menuDescription.toLocaleLowerCase() != ('provider')) : false;
            return {
              ...p,
              disabled: isChildMenuDisabled,
            };
          });
        }
        if (f.key.toLowerCase() === 'followup') {
          if(isCaseCompleted &&  (caseMetaData.caseType == 'NurseTriage' || caseMetaData.caseType == 'Emergency')){
            isMenuDisable = false;
          }
          else{
            isMenuDisable = true;
          }
          
        }
        return {
          ...f,
          disabled: isMenuDisable ?? f.disabled,
          menuItems: mappedMenu ?? f.menuItems,
        };
      }),
    };

    return result.newForm as IForm[];
  }
}
