<button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="onCancelClick()">
  <mat-icon class="close-icon" color="black">close</mat-icon>
</button>

<h2 mat-dialog-title>Existing Similar Cases</h2>

<mat-dialog-content class="mat-typography">
  <div class="example-table-container">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="created" matSortDisableClear
      matSortDirection="desc" class="rule-table ">

      <ng-container matColumnDef="primary">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element;">
          <mat-radio-button (change)="primaryClick(element.id)"
            [checked]="selectedSimilarCase === element.id"></mat-radio-button>
        </td>
      </ng-container>

      <ng-container matColumnDef="caseRef">
        <th mat-header-cell *matHeaderCellDef> Case Reference ID</th>
        <td mat-cell *matCellDef="let element"> {{element.caseRef | uppercase}} </td>
      </ng-container>
      <!-- Symbol Column -->
      <ng-container matColumnDef="account">
        <th mat-header-cell *matHeaderCellDef> Account </th>
        <td mat-cell *matCellDef="let element"> {{element.accountName}} </td>
      </ng-container>
      <ng-container matColumnDef="employee">
        <th mat-header-cell *matHeaderCellDef> Employee Name</th>
        <td mat-cell *matCellDef="let element"> {{element.claimantFirstName}} {{element.claimantLastName}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="dateofinjury">
        <th mat-header-cell *matHeaderCellDef> Date of Injury </th>
        <td mat-cell *matCellDef="let element"> {{element.doi | date}} </td>
      </ng-container>
      <ng-container matColumnDef="bodypart">
        <th mat-header-cell *matHeaderCellDef> Body Part </th>
        <td mat-cell *matCellDef="let element"> {{element.bodyPartDesc}} </td>
      </ng-container>
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef> Created Date </th>
        <td mat-cell *matCellDef="let element"> {{element.created | date}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Case Status </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>
      <ng-container matColumnDef="followup">
        <th mat-header-cell *matHeaderCellDef> Follow-Up </th>
        <td mat-cell *matCellDef="let element"> {{element.isFollowUp}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [length]="resultsLength$|async" [pageSize]="5"></mat-paginator>

  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <div fxLayout="row" style="text-align:center;width:100%">
    <button mat-raised-button color="primary" (click)="newCase()">
      Create New Case
    </button>
    <button mat-raised-button color="primary" mat-dialog-close [disabled]="selectedSimilarCase === ''"
      (click)="openCase()">Open
      Case
    </button>
  </div>
</mat-dialog-actions>
