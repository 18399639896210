import { createReducer, on } from '@ngrx/store';
import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Update,
} from '@ngrx/entity';

import { AccountApiActions, AccountListActions } from '../actions';
import { Account } from '@accounting/common';

export const accountsFeatureKey = 'accounts';

export interface State extends EntityState<Account> {
  loaded: boolean;
  loading: boolean;
  ids: string[];
  selectedAccountId?: string | null;
  lastUpdateDate: Date;
  error?: string | null; // last known error (if any)
}

export const adapter: EntityAdapter<Account> = createEntityAdapter<Account>({
  selectId: (model: Account) => model.id,
});

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  ids: [],
  selectedAccountId: null,
  lastUpdateDate: new Date('1971-01-01'),
});

export const reducer = createReducer(
  initialState,
  on(AccountApiActions.loadAccountsSuccess, (state, action) => {
    console.log(action);
    return adapter.setAll(action.accounts, {
      ...state,
      lastUpdateDate: action.accounts.reduce((max, a) => {
        const objMaxDate =
          a.created > a.updated ? new Date(a.created) : new Date(a.updated);
        return objMaxDate > max ? objMaxDate : new Date(max);
      }, new Date('1970-01-01')),
      loading: false,
      loaded: true,
    });
  }),
  on(AccountApiActions.loadAccountConfigsSuccess, (state, action) => {
    const account = state.entities[action.id];
    if (account) {
      const update: Update<Account> = {
        id: action.id,
        changes: {
          configs: action.configs.map((o) => {
            return {
              name: o.keyName,
              value: o.value,
            };
          }),
        },
      };
      return adapter.updateOne(update, { ...state });
    }
  }),
  on(AccountApiActions.createAccountSuccess, (state, action) =>
    adapter.addOne(action.account, { ...state })
  ),
  on(AccountApiActions.deleteAccountSuccess, (state, action) =>
    adapter.removeOne(action.id, { ...state })
  ),
  on(AccountApiActions.updateAccountSuccess, (state, action) =>
    adapter.updateOne(action.account, { ...state })
  ),
  on(AccountApiActions.activateAccountSuccess, (state, action) =>
    adapter.updateOne(action.account, { ...state })
  ),
  on(AccountListActions.selectAccount, (state, action) => ({
    ...state,
    selectedAccountId: action.id,
  }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
