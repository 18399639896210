import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationFacade } from '@autobot/authentication';
import { IEnvironmentConfig } from '@autobot/core';
import {
  AngularPlugin,
  ApplicationinsightsAngularpluginErrorService,
} from '@microsoft/applicationinsights-angularplugin-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import {
  ApplicationInsights,
  ICustomProperties,
  IDependencyTelemetry,
  IEventTelemetry,
  IExceptionTelemetry,
  IMetricTelemetry,
  IPageViewTelemetry,
  ITraceTelemetry,
} from '@microsoft/applicationinsights-web';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  myinjector = Injector.create({
    providers: [
      {
        provide: ApplicationinsightsAngularpluginErrorService,
        useClass: ApplicationinsightsAngularpluginErrorService,
      },
    ],
  });

  private angularPlugin = new AngularPlugin(this.myinjector);
  private clickPluginInstance = new ClickAnalyticsPlugin();
  private clickPluginConfig = {
    autoCapture: true,
    dataTags: {
      useDefaultContentNameOrId: true,
    },
    dropInvalidEvents: true,
  };
  private appInsights = new ApplicationInsights({
    config: {
      connectionString: this.environment.telemetry.connectionString,
      extensions: [this.angularPlugin, this.clickPluginInstance],
      // auto router tracking, default pageview duration will be set to 0
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          useInjector: true,
          errorServices: [new ErrorHandler()]
        },
        [this.clickPluginInstance.identifier]: this.clickPluginConfig,
      },
    },
  });

  constructor(
    private router: Router,
    private auth: AuthenticationFacade,
    @Inject('ENVIRONMENT') private environment: IEnvironmentConfig
  ) {
    this.appInsights.loadAppInsights();
    combineLatest([this.auth.loggedIn$, this.auth.profile$])
      .pipe(
        filter(([_, profile]) => profile !== null),
        map(([isLoggedIn, profile]) => {
          if (isLoggedIn) {
            this.appInsights.setAuthenticatedUserContext(profile.email);
          } else {
            this.appInsights.clearAuthenticatedUserContext();
          }
        })
      )
      .subscribe({
        error: (err) => {
          console.error('Error updating user context in Application Insights', err);
        },
      });
    this.appInsights.addTelemetryInitializer((envelope) => {
      envelope.tags = envelope.tags || [];
      //envelope.tags['ai.cloud.role'] = 'testTag';
    });
  }

  // expose tracking methods
  trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
    this.appInsights.trackEvent(event, customProperties);
  }

  startTrackEvent(name?: string) {
    this.appInsights.startTrackEvent(name);
  }

  stopTrackEvent(
    name: string,
    properties?: { [p: string]: string },
    measurements?: { [p: string]: number }
  ): any {
    this.appInsights.stopTrackEvent(name, properties, measurements);
  }

  trackPageView(pageView?: IPageViewTelemetry) {
    this.appInsights.trackPageView(pageView);
  }

  startTrackPage(name?: string) {
    this.appInsights.startTrackPage(name);
  }

  stopTrackPage(
    name?: string,
    url?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number }
  ) {
    this.appInsights.stopTrackPage(name, url, properties, measurements);
  }

  trackMetric(metric: IMetricTelemetry, properties?: ICustomProperties) {
    this.appInsights.trackMetric(metric, properties);
  }

  trackException(
    exception: IExceptionTelemetry,
    properties?: ICustomProperties
  ) {
    this.appInsights.trackException(exception, properties);
  }

  trackTrace(message: ITraceTelemetry, properties?: ICustomProperties) {
    this.appInsights.trackTrace(message, properties);
  }

  trackDependencyData(dependency: IDependencyTelemetry) {
    this.appInsights.trackDependencyData(dependency);
  }

  flush() {
    this.appInsights.flush();
  }

  setUserContext(userId: string, accountId?: string) {
    this.appInsights.setAuthenticatedUserContext(userId, accountId);
  }

  clearUserContext() {
    this.appInsights.clearAuthenticatedUserContext();
  }
}
