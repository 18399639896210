import { Guid } from '@autobot/core';
import { CaseMetaData, CaseOrigin, CaseType, CaseView, CallType } from './case';
import { IEvent, ISession, SessionEvent } from './session';

export interface Call {
  id: string;
  accountId: string;
  caseId?: string;
  phoneSystemId: string;
  participants: Participant[];
  newCase: boolean;
  receivedBy: string;
  action: string;
  props: { [key: string]: any };
  data: { [key: string]: any };
  startTime: Date;
  endTime?: Date;
}

/**
 * @description Definition for New Call POST
 */
export interface CreateCallDTO {
  id: string;
  accountId: string;
  tenant?: string;
  caseId?: string;
  phoneSystemId: string;
  participants: Participant[];
  newCase: boolean;
  receivedBy: string;
  action: string;
  callType: string;
  props: { [key: string]: any };
  data: { [key: string]: any };
  startTime: any;
  endTime?: any;
}
export interface CallForm {
  id: string;
  account: any;
  caseId?: string;
  phoneSystemId: string;
  participants: Participant[];
  newCase: boolean;
  receivedBy: string;
  action: CallAction;
  props?: { [key: string]: any };
  data: { [key: string]: any };
  startTime: any;
  endTime?: any;
  caseType?: CaseType;
  callType?: CallType;
  caseOrigin?: CaseOrigin;
  metaData?: CaseMetaData;
}

export enum CallAction {
  NewCase,
  LoadCase,
  FailedEligibility,
  EndCall,
  FollowUp,
  ReadOnly,
  Edit
}

export class CallView implements ISession {
  constructor(agent: string) {
    this.id = Guid.newGuid();
    this.agent = agent;
    this.participants = [];
    this.events = [];
    this.start = new Date();
  }
  id: string;
  account: any;
  agent: string;
  events: IEvent[];
  phoneSystemId!: string;
  case?: CaseView;
  participants: Participant[];
  start!: Date;
  end?: Date;
  translator: any;
  
  setTranslation() {}
  setAccount(account: any) {
    this.account = account;
    this.addEvent(new SessionEvent('Account Set', account));
  }
  startCall() {
    this.start = new Date();
    this.addEvent(new SessionEvent('Call Started'));
  }
  endCall() {
    this.end = new Date();
    this.events = [new SessionEvent('Call Ended')];
  }
  createCase(data?: any) {
    this.events.push(new SessionEvent('Case Created', data));
    this.case = new CaseView(this.account.id, this.agent);
  }
  loadCase(caseId: string) {
    this.addEvent(new SessionEvent('Case Loaded', { caseId }));
  }
  addParticipant(participant: Participant) {
    this.participants.push(participant);
  }
  addEvent(e: IEvent) {
    this.events.push(e);
  }
}
export interface Participant {
  firstName: string;
  lastName: string;
  role: string; //  Supervisor , Employee, Translator, Other
  employeeId: string;
  isCaller: boolean;
}
export interface SimilarCaseDTO {
  accountId: string;
  employeeFirstName?: string;
  employeeLastName?: string;
  doi?: string;
  withinDays: number;
}
