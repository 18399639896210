import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogAction } from '../actions.model';
@Component({
  selector: 'nfr-close-editor',
  templateUrl: './close-editor.component.html',
  styleUrls: ['./close-editor.component.scss'],
})
export class CloseEditorComponent implements OnInit {
  actions: DialogAction[];

  title: string;
  constructor(
    private dialogRef: MatDialogRef<CloseEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title ?? 'Close Form';
    let actions: DialogAction[] = data.actions;
    this.actions = actions.map((a) => {
      return {
        ...a,
        command: () => {
          this.dialogRef.close(a.value);
        },
      };
    });
  }

  ngOnInit(): void {}
}
