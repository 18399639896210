import { Guid } from '@autobot/core';
import { Template } from '@forms/common';
import { Call } from './call';
import { Note } from './note';
import { Protocols } from './protocols';

/*

1. <Call Starts>
    Create Session (Local)
    @Input Account
    @Input Existing
2. <Load || New Case>
    @Attach CaseId to Session
         POST Session - Partial<Session>
    if(new Case)
         POST Partial<Case>

3. <Case Stuff Happens>
        PUT Case whenever
4. if(call ends)
        PUT SESSION

 */

export enum CaseType {
  Undefined = 'Undefined',
  NurseTriage = 'NurseTriage',
  FNOL = 'FNOL',
  FNOLReportOnly = 'FNOLReportOnly',
  Emergency = 'Emergency',
  Death = 'Death',
}

export enum CallType {
  Inbound = 'Inbound',
  Outbound = 'Outbound'
}
export enum LabelChanged {
  Associate = 'Associate',
  Manager = 'Manager',
  Employee='Employee',
  Supervisor='Supervisor'
}
export const CaseTypeDescriptions: Record<CaseType, string> = {
  [CaseType.Undefined]: 'Undefined',
  [CaseType.NurseTriage]: 'Nurse Triage',
  [CaseType.FNOL]: 'FNOL',
  [CaseType.FNOLReportOnly]: 'FNOL Report Only',
  [CaseType.Emergency]: '911 Emergency',
  [CaseType.Death]: 'Death',
};
export enum CaseOrigin {
  NFRStandalone = 'NFR Standalone',
  LRMTransfer = 'LRM Transfer',
}
export enum SelectedDisposition {
  SelfCare = 'SelfCare',
  TeleMedicine = 'TeleMedicine',
  Other = 'Other'
}
export interface DbCase {
  id: string;
  claimant: {
    employeeKey: string;
    firstName: string;
    lastName: string;
  };
  caseRef?: string;
  status: string;
  accountId: string;
  data: { [key: string]: any };
  assembly?: any;
  notes: any[];
  protocols: any[];
  updatedBy: string;
  created: Date;
  updated: Date;
}

export interface CaseMetaData {
  transferredBy: any;
  caseType?: CaseType;
  caseOrigin?: CaseOrigin;
  disableReportOnlyAndSoughtMedical?: boolean;
  isTelemedicineDispositionAvailable?: boolean;
  selectedDisposition?: SelectedDisposition;
  completedBy?: any;
  createdBy?: any;
  secondaryGaugeData: any;
}
export interface CreateCaseDTO {
  id: string;
  claimant: {
    employeeKey?: string;
    firstName: string;
    lastName: string;
  };
  caseRef: string;
  accountId: string;
  accountName: string;
  createdBy: string;
  updatedBy: string;
  created: Date;
  updated: Date;
  caseType: CaseType;
  data?: { [key: string]: any };
  props: { [key: string]: any };
}
export interface Case {
  id: string;
  caseRef?: string;
  status: string;
  accountId: string;
  createdBy: string;
  updatedBy: string;
  created: Date;
  updated: Date;
  caseType?: CaseType;
  metaData?: CaseMetaData;
  caseOrigin?: string;
  claimant: {
    employeeKey?: string;
    firstName: string;
    lastName: string;
  };

  props: { [key: string]: any };
  data?: { [key: string]: any }; //case data
  assembly?: any; //template structure
  notes?: any[];
  protocols?: any[];
  calls: Call[];
  events: CaseEvent[];
}
export interface CaseEvent {
  id: string;
  name: string;
  payload: any;
  created: Date;
}

export interface CaseSearchResult {
  id: string;
  claimant: {
    employeeKey: string;
    firstName: string;
    lastName: string;
  };
  caseRef: string;
  accountId: string;
  status: string;
  caseType?: string; //NFR Standalone?
  created: Date;
  updated: Date;
  lastCall?: Call;
}
export interface CaseAdvanceSearchResult {
  id: string;
  AccountId: string;
  AccountName: string;
  CreatedDate: string;
  UpdatedDate: string;
  CaseRef: string;
  SSN: string;
  EmployeeDOB: string;
  LocationCode: string;
  ClaimantFirstName: string;
  ClaimantLastName: string;
  EmployeeName: string;
  DateOfInjury: string;
  Disposition: string;
  Protocol: string;
  BodyPart: string;
  CaseStatus: string;
  CaseType: string;
  CaseOrigin: string;
  IsFollowUpCompleted: string;
  IsLocked: string;
  createdBy: string;
  createdByName: string;
  totalRecords: number;
}
export class CaseView {
  constructor(account: any, agent: string) {
    this.id = Guid.newGuid();
    this.createdBy = agent;
    this.account = account;
    this.created = new Date();
    this.updated = new Date();
    this.notes = [];
    this.protocols = [];
  }

  id: string;
  account: any;
  assembly?: Template;
  employee: any;
  notes: Note[];
  protocols: Protocols[];
  props?: { [key: string]: any };
  createdBy: string;
  created: Date;
  updated: Date;

  addNote(note: Note) {
    this.notes.push(note);
  }
  addProtocols(protocol: Protocols) {
    this.protocols.push(protocol);
  }
  removeProtocols(protocol: Protocols) {
    this.protocols.splice(
      this.protocols.findIndex((a) => a.id === protocol.id),
      1
    );
  }
}
export interface CreateCaseSerchDTO {
  caseRef: string;
  accountId: string;
  status?: string;
  caseType?: string;
  firstName?: string;
  lastName?: string;
  empID?: string;
  dateInjury?: Date;
  ssn?: string;
  createDateFrom?: Date;
  createDateTo?: Date;
  dateOfBirth?: Date;
  locationCode?: string;
  createdBy?: string;
  tenant: string;
  pageNumber: Number;
  pageSize: Number;
  sortField?: string;
  sortDirection?: string;
}
export interface CaseSearchForm {
  caseRef: string;
  account: any;
  status?: string;
  caseType?: string;
  firstName?: string;
  lastName?: string;
  empID?: string;
  dateInjury?: Date;
  ssn?: string;
  createDateFrom?: Date;
  createDateTo?: Date;
  dateOfBirth?: Date;
  locationCode?: string;
  createdBy?: string;
}
