import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material/input';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {Observable, tap} from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import {MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import { LookupService } from '../../services/lookup.service';

@Component({
  selector: 'formly-autocomplete-type',
  template: `

    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let choice of choices"
        (removed)="remove(choice)">
        {{choice.label}}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    <input matInput
      [matAutocomplete]="auto"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder ?? ''"
      [errorStateMatcher]="errorStateMatcher"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let opt of filter | async" [value]="opt">
        {{ opt.label }}
      </mat-option>
    </mat-autocomplete>
  `,
})
export class ChipsAutocompleteComponent extends FieldType<FieldTypeConfig> implements OnInit, AfterViewInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild(MatInput) formFieldControl!: MatInput;
  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;
  choices: any[] = [];
  constructor(private lookupService: LookupService) {
    super();
  }

  filter!: Observable<any>;

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    console.log(event);
    if (value) {
      this.choices.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.formControl.setValue(null);
  }

  remove(choice: any): void {
    const index = this.choices.indexOf(choice);

    if (index >= 0) {
      this.choices.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.choices.push(event.option.value);
    this.formFieldControl.value = '';
    this.formControl.setValue(null);
  }


   ngOnInit() {

    const hint = this.props.dataHint;
    this.filter = this.formControl.valueChanges
      .pipe(
        startWith(''),
        tap((e) => console.log(e)),
        switchMap(term => this.lookupService.filter(term,hint)),
      )
  }

   ngAfterViewInit() {


    (<any> this.autocomplete)._formField = this.formField;
  }
}

