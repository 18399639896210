<ng-container>
  <nav class="navbar-header" aria-label="Top Toolbar">
    <button
      *ngIf="sideNavButtonEnabled"
      mat-icon-button
      (click)="toggleSideNav()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <ng-content select="[title]"></ng-content>
    <div *ngIf="sideNavButtonEnabled" class="flex-spacer"></div>
    <app-horizontal-menu></app-horizontal-menu>
    <!-- <div class="flex-spacer"></div> -->
    <ng-container *ngIf="auth.loggedIn$ |ngrxPush as loggedIn; else notAuth">
      <button
        [matMenuTriggerFor]="userMenu"
        [matTooltip]="'User options'"
        mat-icon-button
        class="ml-xs"
        style="outline: none"
      >
        <ng-container *ngIf="auth.profile$ | ngrxPush as user; else icon">
          <span class="material-icons"><mat-icon PersonIcon>person</mat-icon></span>
          <span class="ml-5"> {{ user.name | titlecase }}</span>
        </ng-container>
        <ng-template #icon>
          <mat-icon>person</mat-icon>
        </ng-template>
      </button>
      <mat-menu #userMenu="matMenu" x-position="before">
        <button mat-menu-item (click)="openVersion.emit()">
          <mat-icon>build_circle</mat-icon>
          App Version
        </button>
        <button mat-menu-item (click)="openProfile.emit()">
          <mat-icon>account_box</mat-icon>
          Profile
        </button>
        <button mat-menu-item (click)="confirmLogout()">
          <mat-icon>exit_to_app</mat-icon>
          Sign Out
        </button>
      </mat-menu>
    </ng-container>
    <ng-template #notAuth>
      <button
        mat-icon-button
        class="ml-xs"
        style="outline: none"
        matTooltip="Login"
        (click)="login()"
        matTooltipPosition="left"
      >
        <mat-icon>login</mat-icon>
      </button>
    </ng-template>
  </nav>
</ng-container>
