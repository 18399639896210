import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { Guid, MappingService, Template } from '@forms/common';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { FollowUpActions } from '../actions';

import {
  CaseMetaData,
  FollowUp,
  FollowUpStatus,
  FollowUpTab,
  FollowUpTabType,
} from '@nfr/common';
import * as moment from 'moment';
import {
  getAllTemplates,
  getCaseId,
  getFollowUpDatasets,
  getFollowUpObjectforSave,
  getFollowUpPageTabs,
  getSelectedFollowUpTabAfterSave,
  selectCaseDetailModel,
  selectCaseState,
  selectFollowUpsData,
} from '../selectors';
import { CaseApiService } from '../services/case-api.service';
import { getLookUpDatasets } from '../selectors/lookup.selectors';
import { FormsApiService } from '../services/forms-api.service';

@Injectable()
export class FollowUpEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private caseApi: CaseApiService,
    private mapping: MappingService,
    private forms: FormsApiService
  ) {}

  getDialogTab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FollowUpActions.getFollowUpDialogFields),

      concatLatestFrom(() => [
        this.store.select(selectCaseDetailModel),
        this.store.select(getAllTemplates),
      ]),
      map((_) => {
        const caseDetailModel = _[1];
        const templates = _[2] as Template[];

        let dialogTemplate = null;
        dialogTemplate = templates.find(
          (x) => x.props.key === 'generalMedical'
        );
        const fields = this.mapping.convertToNoWrapPropFields(dialogTemplate);
        return FollowUpActions.loadFollowUpDialogFields({
          template: dialogTemplate,

          fields: fields,
          caseDetailData: caseDetailModel,
        });
      })
    );
  });
  getSelectedTab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FollowUpActions.onFollowUpTabSelect),
      switchMap(({ selectedTabIndex }) => {
        return of(selectedTabIndex);
      }),
      concatLatestFrom(() => [
        this.store.select(getFollowUpPageTabs),
        this.store.select(getAllTemplates),
        this.store.select(getFollowUpDatasets),
        this.store.select(selectFollowUpsData),
        this.store.select(selectCaseDetailModel),
        this.store.select(getCaseId),
      ]),
      map((_) => {
        const selectedTabIndex = _[0];
        const tabs = _[1] as FollowUpTab[];
        const templates = _[2] as Template[];
        let datasets = _[3];
        let followUps = _[4] as FollowUp[];
        const caseDetailModel = _[5];
        const metaData = caseDetailModel.metaData as CaseMetaData;
        //modify selected datasets if TM disposition is not enabled.
        if (!metaData?.isTelemedicineDispositionAvailable) {
          let _disposition = datasets.dispositions.filter(
            (item) => item.code !== 'TM'
          );
          let _dispositionRecommended = datasets.dispositionRecommended.filter(
            (item) => item.code !== 'TM'
          );
          datasets = {
            ...datasets,
            dispositions: _disposition,
            dispositionRecommended: _dispositionRecommended,
          };
        }
        const caseId = _[6];
        let followUpState = [];
        let currentFollowUp: FollowUp = null;
        const selectedTab = tabs.find((x) => x.tabIndex === selectedTabIndex);
        let currentTemplate = null;
        if (selectedTab.tabType === FollowUpTabType.Detail) {
          currentTemplate = templates.find(
            (x) => x.props.key === 'followupcasedetails'
          );
        } else {
          currentTemplate = templates.find(
            (x) => x.props.key === 'dynamicfollowup'
          );
          currentFollowUp = followUps.find(
            (x) => x.followUpNumber == selectedTab.tabIndex
          );

          const currentDate = moment(new Date())
            .tz('America/New_York')
            .format('MM/DD/yyy HH:mm:ss');
          const calldetails = {
            dateTime: currentDate,
            followupNumber: selectedTab.tabIndex
          };

          currentFollowUp = currentFollowUp ?? {
            id: Guid.newGuid(),
            caseId: caseId,
            followUpNumber: selectedTabIndex,
            data: {
              followup: {
                calldetails: {
                  ...calldetails,
                },
              },
            },
            status: FollowUpStatus.New,
          };

          //if current followup does not exists, then add it to followups list
          followUpState = this.getFollowUpData(followUps, currentFollowUp);
        }

        const fields = this.mapping.convertToNoWrapPropFields(
          currentTemplate,
          datasets
        );
        return FollowUpActions.onFollowUpTabSelectSuccess({
          selectedTab: selectedTab,
          template: currentTemplate,
          currentFollowUp: currentFollowUp,
          followUps: followUpState,
          fields: fields,
          caseDetailData: caseDetailModel,
          selectedTabIndex: selectedTabIndex,
          caseId: caseId,
        });
      })
    );
  });
  getFollowUps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FollowUpActions.getFollowUps),
      concatLatestFrom(({ isRefreshFollowUp, status }) => [
        this.store.select(getCaseId),
        this.store.select(getSelectedFollowUpTabAfterSave(status)),
      ]),
      switchMap((_) => {
        const isRefresh = _[0].isRefreshFollowUp;
        const caseId = _[1];
        const selectTabIfRefresh = _[2];
        const followups = this.caseApi.loadFollowUps(caseId);
        return forkJoin([followups]).pipe(
          first(),
          map((o) => {
            return {
              followups: o[0],
              isRefreshFollowUp: isRefresh,
              selectTab: selectTabIfRefresh,
              // lookupsResult: o[1],
            };
          })
        );
      }),
      concatLatestFrom(() => this.store.select(selectCaseState)),
      mergeMap((_) => {
        const followUps = _[0].followups;
        const tabs = this.getFollowUpTabs(followUps);
        const isRefresh = _[0].isRefreshFollowUp;
        const goToTab = _[0].selectTab;
        const model = _[1];
        if (isRefresh) {
          return [
            FollowUpActions.getFollowUpsSuccess({
              followUpData: followUps,
              followUpTabs: tabs,
            }),
            FollowUpActions.onFollowUpTabSelect({ selectedTabIndex: goToTab }),
          ];
        } else {
          return [
            FollowUpActions.getFollowUpsSuccess({
              followUpData: followUps,
              followUpTabs: tabs,
            }),
          ];
        }
      }),
      catchError((error: unknown) => {
        return of(
          FollowUpActions.getFollowUpsFailure({
            error,
          })
        );
      })
    );
  });
  getFollowUpsEditor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FollowUpActions.loadFollowUpForms),
      switchMap(
        ({
          caseDetailTemplateId,
          followUpTemplateId,
          generalmedicalTemplateId,
        }) => {
          const caseDetail = this.forms.loadTemplate(caseDetailTemplateId);
          const followUpTemplate = this.forms.loadTemplate(followUpTemplateId);
          const dialogTemplate = this.forms.loadTemplate(
            generalmedicalTemplateId
          );
          return forkJoin([caseDetail, followUpTemplate, dialogTemplate]).pipe(
            first(),
            map((o) => {
              let templates: Template[] = [];
              templates.push(o[0]);
              templates.push(o[1]);
              templates.push(o[2]);
              return {
                templates: templates,
              };
            })
          );
        }
      ),
      concatLatestFrom(() => this.store.select(getLookUpDatasets)),
      map((_) => {
        const templates = _[0].templates;
        const datasets = _[1];

        return FollowUpActions.loadFollowUpFormSuccess({
          templates: templates,
          datasets: datasets,
        });
      }),
      catchError((error: unknown) => {
        return of(
          FollowUpActions.loadFollowUpFormFailure({
            error,
          })
        );
      })
    );
  });
  loadDefaultTab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FollowUpActions.loadFollowUpFormSuccess),
      map((_) => {
        return FollowUpActions.onFollowUpTabSelect({
          selectedTabIndex: 0,
        });
      })
    );
  });

  saveFollowUp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FollowUpActions.saveFollowUp),
      switchMap(({ status }) => {
        return of(status);
      }),
      concatLatestFrom(() => this.store.select(getFollowUpObjectforSave)),
      mergeMap((_) => {
        const fs = _[0] as FollowUpStatus;
        const followUpData = _[1] as FollowUp;

        //decesion: Save or Update
        if (followUpData.status === FollowUpStatus.New) {
          followUpData.status = fs;
          return this.caseApi.saveFollowUp(followUpData).pipe(
            mergeMap((_) => {
              return [
                FollowUpActions.saveFollowUpSuccess(),
                FollowUpActions.getFollowUps({
                  isRefreshFollowUp: true,
                  status: followUpData.status,
                }),
              ];
            }),
            catchError((error: unknown) => {
              return of(FollowUpActions.saveFollowUpFail({ error }));
            })
          );
        } else {
          followUpData.status = fs;
          return this.caseApi
            .updateFollowUp(followUpData.id, followUpData)
            .pipe(
              mergeMap((_) => {
                return [
                  FollowUpActions.saveFollowUpSuccess(),
                  FollowUpActions.getFollowUps({
                    isRefreshFollowUp: true,
                    status: followUpData.status,
                  }),
                ];
              }),
              catchError((error: unknown) => {
                return of(FollowUpActions.saveFollowUpFail({ error }));
              })
            );
        }
      })
    );
  });

  private getFollowUpTabs(
    followUps: FollowUp[],
    maxFollowUpTab: number = 5
  ): FollowUpTab[] {
    const i = followUps.length;
    const defaultTab: FollowUpTab = {
      tabIndex: 0,
      tabType: FollowUpTabType.Detail,
      tabDescription: 'Case Details',
    };
    var list: FollowUpTab[] = [];
    list.push(defaultTab);
    const completedFollowup = followUps.filter(
      (x) => x.status == FollowUpStatus.Complete
    );
    const m =
      i == 0 || completedFollowup?.length == 0
        ? 1
        : completedFollowup.length < maxFollowUpTab
        ? completedFollowup.length + 1
        : maxFollowUpTab;

    for (let index = 1; index <= m; index++) {
      let tab: FollowUpTab = {
        tabIndex: index,
        tabType: FollowUpTabType.FollowUp,
        tabDescription: `Follow Up #${index}`,
      };
      list.push(tab);
    }
    return list;
  }
  private getFollowUpData(followUps: FollowUp[], currentFollowUp: FollowUp) {
    if (currentFollowUp && currentFollowUp?.status != FollowUpStatus.Complete) {
      //check if exists
      let index = followUps.findIndex(
        (x) => x.followUpNumber == currentFollowUp.followUpNumber
      );
      if (index >= 0) {
        //exists, update it
        const fdata = followUps.map((f) => {
          if (f.followUpNumber == currentFollowUp.followUpNumber) {
            return {
              ...f,
              data: currentFollowUp.data,
            };
          }
          return { ...f };
        });
        return fdata;
      } else {
        return [...followUps, currentFollowUp];
      }
    }
    return followUps;
  }
}
