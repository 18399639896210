import {InjectionToken} from "@angular/core";


export interface IMenuItem {
  label?: string;
  icon: string;
  externalRedirect?: boolean;
  link?: string;
  items?: IMenuItem[];
  authenticatedOnly?: boolean;
  adminOnly?: boolean;
}
export interface IMenuConfig {
  items: IMenuItem[]
}
export const APP_MENU = new InjectionToken<IMenuConfig>('APP_MENU');


