import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CloseEditorComponent } from './close-editor/close-editor.component';
import { MatListModule } from '@angular/material/list';
import { LetModule } from '@ngrx/component';
import { MatTableModule } from '@angular/material/table';
import { VersionDisplayComponent } from './version-display/version-display.component';
import { ConfirmDialogSearchComponent } from './confirmPhoneSystem-dialog.component';
import { SoftDeleteDialogComponent } from './softdelete-dialog.component';
import { ConfirmReopenCaseDialogComponent } from './confirm-reopenCase-dialog.component';
import { MaterialModule } from '../material';
import { ConfirmOVDispositionComponent } from './confirmOVDisposition-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    CloseEditorComponent,
    VersionDisplayComponent,
    ConfirmDialogSearchComponent,
    SoftDeleteDialogComponent,
    ConfirmReopenCaseDialogComponent,
    ConfirmOVDispositionComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    LetModule,
    MatListModule,
    MatTableModule,
    MatButtonModule,
    MaterialModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    FlexLayoutModule,
    NgxMaskModule,
  ],
  exports: [
    ConfirmDialogComponent,
    CloseEditorComponent,
    VersionDisplayComponent,
    ConfirmDialogSearchComponent,
    SoftDeleteDialogComponent,
    ConfirmReopenCaseDialogComponent,
    ConfirmOVDispositionComponent
  ],
})
export class CommonDialogsModule {}
