import { Inject, Injectable } from '@angular/core';
import { IEnvironmentConfig } from '@autobot/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CommonFunctionService {
  constructor(
    @Inject('ENVIRONMENT') private environmentConfig: IEnvironmentConfig
  ) {}

  public getCurrentDisplayName() {
    return this.environmentConfig.displayName;
  }
  flattenObject(obj: any) {
    let result = {};
    for (const i in obj) {
      if (typeof obj[i] === 'object' && !Array.isArray(obj[i])) {
        const temp = this.flattenObject(obj[i]);
        for (const j in temp) {
          result[i + '.' + j] = temp[j];
        }
      } else {
        result[i] = obj[i];
      }
    }
    return result;
  }
  //
  //source:https://stackoverflow.com/questions/19098797/fastest-way-to-flatten-un-flatten-nested-javascript-objects
  //
  unFlattenObject(data: any) {
    if (Object(data) !== data || Array.isArray(data)) return data;
    var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
      resultholder = {};
    for (var p in data) {
      var cur = resultholder,
        prop = '',
        m;
      while ((m = regex.exec(p))) {
        cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
        prop = m[2] || m[1];
      }
      cur[prop] = data[p];
    }
    return resultholder[''] || resultholder;
  }

  /**
   * https://medium.com/@srajas02/merge-two-objects-deeply-nested-in-javascript-8e8515b4f8d3
   * This function will accept the two objects as arguments and return the object of deeply
   * merged with nested properties.
   * @param {object} targetObject objects containing the properties to be merged with source.
   * @param {object} sourceObject objects containing the properties you want to apply.
   * @return {object} return the deeply merged objects
   */
  public deepMergeObject(targetObject = {}, sourceObject = {}) {
    let momentLib = moment;
    let replacer = (key: string, value: any) => {
      try {
        if (key == 'dob' || key == 'dateManagerNotified' || key == 'dateOfDeath') {
          if (value instanceof Date) {
            const formatedDate = value;
            formatedDate.setHours(0, 0, 0, 0);
            return momentLib(formatedDate).format('YYYY-MM-DDTHH:MM:SS');
          } else if (value && value != '') {
            const formatedDate = new Date(value);
            formatedDate.setHours(0, 0, 0, 0);
            return momentLib(formatedDate).format('YYYY-MM-DDTHH:MM:SS');
          }
        }
        return value;
      } catch (e) {
        return value;
      }
    };
    // clone the source and target objects to avoid the mutation
    const copyTargetObject = JSON.parse(JSON.stringify(targetObject, replacer));
    const copySourceObject = JSON.parse(JSON.stringify(sourceObject, replacer));
    // Iterating through all the keys of source object
    if (copySourceObject) {
      Object.keys(copySourceObject).forEach((key) => {
        if (
          typeof copySourceObject[key] === 'object' &&
          !Array.isArray(copySourceObject[key])
        ) {
          if (copySourceObject[key] == null) {
            copyTargetObject[key] = '';
          } else {
            if (
              copyTargetObject[key] == undefined ||
              copyTargetObject[key] == ''
            ) {
              copyTargetObject[key] = {};
            }
            // If property has nested object, call the function recursively
            copyTargetObject[key] = this.deepMergeObject(
              copyTargetObject[key],
              copySourceObject[key]
            );
          }
        } else {
          // else merge the object source to target
          copyTargetObject[key] = copySourceObject[key];
        }
      });
    }

    return copyTargetObject;
  }
}
