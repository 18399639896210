import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

//import { accountsFeatureReducersMap } from './reducers';
import {usersFeatureReducersMap} from './reducers'
import { EffectsModule } from '@ngrx/effects';
import { UserApiEffects } from './effects';

import { AlertService } from '@forms/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UserFacade, UserFacadeBase } from './services/user.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSnackBarModule,
    StoreModule.forFeature('users',usersFeatureReducersMap),
  //  StoreModule.forFeature('accounts', accountsFeatureReducersMap),
    EffectsModule.forFeature([UserApiEffects]),
  ],
  providers: [
    AlertService,
    { provide: UserFacadeBase, useClass: UserFacade },
  ],
})
export class UsersStoreModule {}
