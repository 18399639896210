import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { LocalStorageService } from './local-storage.service';

export const EXTRA_SMALL_WIDTH_BREAKPOINT = 720;
export const SMALL_WIDTH_BREAKPOINT = 959;

const state = {
  loading: false,
  view: null,
  lastUpdate: null,
  siteName: null,
  siteKey: null,
  options: {
    enabled: false,
    pagination: true,
    tooltips: false,
    changeThemes: true,
  },
};

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  refreshSubject: Subject<any> = new Subject<any>();

  isScreenSmall: Observable<boolean>;
  _state: BehaviorSubject<any> = new BehaviorSubject<any>(state);
  store$: Observable<any> = this._state.asObservable();
  code: string | undefined;
  override: true | undefined;

  private headers = new HttpHeaders();

  constructor(
    private storage: LocalStorageService,
    breakpoints: BreakpointObserver,
    private router: Router,
    private notifications: NotificationService
  ) {
    this.isScreenSmall = breakpoints
      .observe(`(max-width:  ${SMALL_WIDTH_BREAKPOINT}px)`)
      .pipe(
        map((breakpoint) => {
          return breakpoint.matches;
        })
      );
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const options = this.storage.getItem('SITE-MANAGER-OPTIONS');
    const value = this._state.getValue();
    if (value.options != options && options != null) value.options = options;
  }

  changeOptions(opts: any) {
    const value = this._state.getValue();
    value.options = opts;

    this._state.next(value);
    this.storage.setItem('SITE-MANAGER-OPTIONS', opts);
  }
}
