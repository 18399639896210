import { Inject, Injectable } from '@angular/core';
// @ts-ignore
import * as SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import { IEnvironmentConfig } from '../environment';

@Injectable({
  providedIn: 'root',
})
export class AddressVerificationService {
  constructor(@Inject('ENVIRONMENT') private environment: IEnvironmentConfig) {}

  AutocompleteAddress(term: string): any {
    const SmartyStreetsCore = SmartyStreetsSDK.core;
    const websiteKey = this.environment.smartyStreetKey; // Your Website Key

    const Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup;
    const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey);
    const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials);
    const client = clientBuilder.buildUsAutocompleteProClient();

    let lookup = new Lookup(term);
   //lookup.search=term;

    return client.send(lookup);
  }
}
