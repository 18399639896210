import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HorizontalMenuComponent} from "./horizontal-menu.component";
import {HorizontalMenuItemComponent} from "./horizontal-menu-item.component";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {RouterModule} from "@angular/router";
import {MatListModule} from "@angular/material/list";


@NgModule({
  declarations: [ HorizontalMenuComponent,
    HorizontalMenuItemComponent],
  imports: [
    CommonModule,
    MatIconModule, MatListModule,
    MatMenuModule, RouterModule
  ],
  exports:[ HorizontalMenuComponent,
    HorizontalMenuItemComponent]
})
export class HorizontalMenuModule { }
