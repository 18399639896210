import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { DesktopActions, SessionActions } from '../actions';
import { CaseApiService } from '../services/case-api.service';

@Injectable()
export class DesktopEffects {
  loadDesktop$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DesktopActions.loadDesktop),
      switchMap(() => {
        return this.caseApi.loadDesktop();
      }),
      map(({ navs }) => {
        return DesktopActions.loadDesktopSuccess({ navs });
      })
    );
  });

  sessionStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.sessionStarted),
      map(() => DesktopActions.clearDesktop())
    )
  );

  exitDesktop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DesktopActions.exitDesktop),
      exhaustMap((_) => [
        DesktopActions.clearDesktop(),
        SessionActions.sessionEnded()
      ]),
      tap(() => {
        this.router.navigate(['/nfr']);
      })
    )
  );

  constructor(
    private actions$: Actions,
    private caseApi: CaseApiService,
    private router: Router
  ) {}
}
