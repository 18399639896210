import {createReducer, on} from '@ngrx/store';
import {VersionActions} from '../actions';
import {IVersion} from "../models/version.models";


export const versionFeatureKey = 'version';

export interface VersionState {
  info?: IVersion,
  error?: any,
  loading: boolean,
  loaded: boolean

}

const initialState: VersionState = {

  loading: true,
  loaded: false,


};

export const versionReducer = createReducer(
  initialState,
  // Even thought the `state` is unused, it helps infer the return type
  on(VersionActions.updateVersion, state => state),
  on(VersionActions.updateVersionSuccess, (state,action) => ({...state, info: action.info, loading: false, loaded: true})),
  on(VersionActions.updateVersionFailure, (state,action) => ({...state, error: action.error, loading: false, loaded: false}))
);



export const selectVersionLoadError = (state: VersionState) => state.error
