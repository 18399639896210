import { IForm, Template } from "@forms/common";
import { FollowUp, FollowUpStatus, FollowUpTab } from "@nfr/common";
import { createAction, props } from "@ngrx/store";
import { FormlyFieldConfig } from "@ngx-formly/core";

export enum FollowUpActionTypes {
  INIT = '[Follow Up] Init',
  LOAD_CASE_DETAIL = '[Follow Up] Load Case Detail Form',
  LOAD_CASE_DETAIL_SUCCESS = '[Follow Up] Load Case Detail Success',
  LOAD_CASE_DETAIL_FAILURE = '[Follow Up] Load Case Detail Failure',
  GET_FOLLOWUP = '[Follow Up] Get Follow Up Data',
  GET_FOLLOWUP_SUCCESS = '[Follow Up] Get Follow Up Data Success',
  GET_FOLLOWUP_FAILURE = '[Follow Up] Get Follow Up Data Failure',
  LOAD_FOLLOWUP_FORM = '[Follow Up] Load Selected Follow Up Form',
  LOAD_FOLLOWUP_FORM_SUCCESS = '[Follow Up] Load Selected Follow Up Form Success',
  LOAD_FOLLOWUP_FORM_FAILURE = '[Follow Up] Load Selected Follow Up Form Failure',
  
  UPDATE_FORM_STATE = '[Editor] Update Form State',
  FOLLOWUP_TAB_SELECT = '[Follow Up] Tab Select',
  FOLLOWUP_TAB_SELECT_SUCCESS = '[Follow Up] Tab Select Success',

  SELECT_EDITOR_TAB = '[Follow Up] Select Editor Tab',
  SELECT_EDITOR_AREA = '[Follow Up] Select Editor Area',

  EDITOR_TAB_SELECT = '[Follow Up] Editor Tab Select',
  EDITOR_TAB_SELECT_SUCCESS = '[Follow Up]Editor Tab Select Success',
  SELECT_FOLLOWUP_FORMLY_TAB= '[Follow Up] Editor Tab Select Success',
  UPDATE_FOLLOWUP_MODEL = '[Follow Up] Update Model',

  SAVE_FOLLOWUP= '[Follow Up] Save/Update Follow Up',
  SAVE_FOLLOWUP_SUCCESS= '[Follow Up] Save/Update Follow Up Success',
  SAVE_FOLLOWUP_FAIL= '[Follow Up] Save/Update Follow Up Fail',

  GET_FOLLOWUP_DIALOG_FIELDS = '[Follow Up] Get Follow Up Dialog Fields',
  LOAD_DIALOG_FIELDS = '[Follow Up] Load Dialog Field',
  REFRESH_FOLLOWUP_TAB = '[Follow Up] Refresh Follow Up Tab',
  REFRESH_FOLLOWUP_TAB_SUCCESS = '[Follow Up] Refresh Follow Up Tab Success',
}
export const init = createAction(FollowUpActionTypes.INIT);
export const getFollowUps = createAction(
  FollowUpActionTypes.GET_FOLLOWUP,
  props<{
    isRefreshFollowUp?: boolean,
    status?: FollowUpStatus
   }>()
);
export const getFollowUpsSuccess = createAction(
  FollowUpActionTypes.GET_FOLLOWUP_SUCCESS,
  props<{
   followUpData: FollowUp[],
   followUpTabs:FollowUpTab[]
  }>()
);
export const getFollowUpsFailure = createAction(
  FollowUpActionTypes.GET_FOLLOWUP_FAILURE,
  props<{ error: any }>()
);

// export const loadCaseDetail = createAction(
//   FollowUpActionTypes.LOAD_CASE_DETAIL,
//   props<{ templateKey }>()
// );
export const loadCaseDetailSuccess = createAction(
  FollowUpActionTypes.LOAD_CASE_DETAIL_SUCCESS,
  props<{
    template: Template;
    form: IForm;
    datasets: any;
    fields: FormlyFieldConfig[];
    data?: any;
  }>()
);
// export const loadCaseDetailFailure = createAction(
//   FollowUpActionTypes.LOAD_CASE_DETAIL_FAILURE,
//   props<{ error: any }>()
// );

export const onFollowUpTabSelect = createAction(
  FollowUpActionTypes.FOLLOWUP_TAB_SELECT,
  props<{
    selectedTabIndex?:Number;
    data?: any;
  }>()
);
export const refreshFollowUpTab = createAction(
  FollowUpActionTypes.REFRESH_FOLLOWUP_TAB,
  props<{
    status?:FollowUpStatus;
    data?: any;
  }>()
);
export const refreshFollowUpTabSuccess = createAction(
  FollowUpActionTypes.REFRESH_FOLLOWUP_TAB_SUCCESS,
  props<{
    data?: any;
  }>()
);
//
export const onFollowUpTabSelectSuccess = createAction(
  FollowUpActionTypes.FOLLOWUP_TAB_SELECT_SUCCESS,
  props<{
    selectedTab?:FollowUpTab;
    template?: Template;
    fields: FormlyFieldConfig[];
    currentFollowUp?:FollowUp,
    followUps?:FollowUp[],
    caseDetailData?: any;
    selectedTabIndex?:Number,
    caseId:string
  }>()
);

export const loadFollowUpForms = createAction(
  FollowUpActionTypes.LOAD_FOLLOWUP_FORM,
  props<{ caseDetailTemplateId,followUpTemplateId ,generalmedicalTemplateId}>()
);

export const loadFollowUpFormSuccess = createAction(
  FollowUpActionTypes.LOAD_FOLLOWUP_FORM_SUCCESS,
  props<{
    templates: Template[];
    datasets: any;
    data?: any;
  }>()
);
export const loadFollowUpFormFailure = createAction(
  FollowUpActionTypes.LOAD_FOLLOWUP_FORM_FAILURE,
  props<{ error: any }>()
);
export const selectFollowUpFormlyTab = createAction(
  FollowUpActionTypes.SELECT_FOLLOWUP_FORMLY_TAB,
  props<{ selectedTabIndex: number }>()
);
export const updateFollowUpModel = createAction(
  FollowUpActionTypes.UPDATE_FOLLOWUP_MODEL,
  props<{ data: any }>()
);
export const saveFollowUp = createAction(FollowUpActionTypes.SAVE_FOLLOWUP,
  props<{
    status: FollowUpStatus;
  }>());
export const saveFollowUpSuccess = createAction(
  FollowUpActionTypes.SAVE_FOLLOWUP_SUCCESS
  );
export const saveFollowUpFail = createAction(
  FollowUpActionTypes.SAVE_FOLLOWUP_FAIL,
  props<{ error: any }>()
  );
  export const getFollowUpDialogFields = createAction(
    FollowUpActionTypes.GET_FOLLOWUP_DIALOG_FIELDS 
  );
  export const loadFollowUpDialogFields = createAction(
    FollowUpActionTypes.LOAD_DIALOG_FIELDS,
    props<{
      template?: Template;
      fields?: FormlyFieldConfig[];
      caseDetailData?: any;
    
    }>()
  );