import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  getAccountMinimalView,
  getAllAccounts,
  getContractAccountViews,
  getSelectedAccountDetailView,
  getSelectedAccountExpandedView,
  getStandardAccountViews,
  getSubAccountViews,
} from '../selectors';
import {
  AccountApiActions,
  AccountListActions,
  AccountEditorActions,
} from '../actions';
import {
  Account,
  AccountActivation,
  AccountApiService,
  AccountDetailView,
  AccountExpandedView,
  AccountMinimalView,
  AccountView,
} from '@accounting/common';
import * as fromAccounts from '../reducers/account-entity.reducer';

@Injectable({ providedIn: 'root' })
export class AccountFacade implements AccountFacadeBase {
  constructor(
    private store: Store<fromAccounts.State>,
    private service: AccountApiService
  ) {}
  accounts$: Observable<Account[]> = this.store.select(getAllAccounts);

  selectedAccountExpandedView$: Observable<any> = this.store.select(
    getSelectedAccountExpandedView
  );
  selectedAccountDetailView$: Observable<AccountDetailView> = this.store.select(
    getSelectedAccountDetailView
  );
  accountMinimalView$: Observable<AccountMinimalView[]> = this.store.select(
    getAccountMinimalView
  );
  accountViews$: Observable<Partial<AccountView>[]> = this.store.select(
    getStandardAccountViews
  );
  contractViews$: Observable<Partial<AccountView>[]> = this.store.select(
    getContractAccountViews
  );
  subViews$: Observable<Partial<AccountView>[]> =
    this.store.select(getSubAccountViews);

  getLastUpdatedDate(): Observable<Date> {
    return this.service.getLastUpdateDate();
  }
  loadAccounts(): void {
    this.store.dispatch(AccountApiActions.loadAccounts());
  }
  loadAccountConfig(id: string): void {
    this.store.dispatch(AccountApiActions.loadAccountConfigs({ id }));
  }
  selectAccount(id: string): void {
    this.store.dispatch(AccountListActions.selectAccount({ id }));
  }
  createAccount(account: Account): void {
    this.store.dispatch(AccountApiActions.createAccount({ account }));
  }
  saveAccountClicked(account: Account) {
    this.store.dispatch(AccountEditorActions.saveAccount({ account }));
  }
  updateAccount(account: Account) {
    this.store.dispatch(AccountApiActions.updateAccount({ account }));
  }
  activateAccount(account: AccountActivation) {
    this.store.dispatch(AccountApiActions.activateAccount({ account }));
  }
  removeAccount(id: string) {}
}

export abstract class AccountFacadeBase {
  abstract accounts$: Observable<Account[]>;

  abstract selectedAccountExpandedView$: Observable<AccountExpandedView>;
  abstract contractViews$: Observable<Partial<AccountView>[]>;
  abstract accountViews$: Observable<Partial<AccountView>[]>;
  abstract subViews$: Observable<Partial<AccountView>[]>;
  abstract accountMinimalView$: Observable<AccountMinimalView[]>;
  abstract selectedAccountDetailView$: Observable<AccountDetailView>;

  abstract selectAccount(id: string): void;
  abstract loadAccountConfig(id: string): void;

  abstract removeAccount(id: string): void;
  abstract createAccount(account: Account): void;
  abstract saveAccountClicked(account: Account): void;
  abstract updateAccount(account: Account): void;
  abstract activateAccount(account: AccountActivation): void;
}
