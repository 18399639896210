import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmDialog } from './ConfirmDialog';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { HorizontalMenuModule } from '../horizontal-menu/horizontal-menu.module';

import { PushModule } from '@ngrx/component';
import { ThemePickerModule } from '@autobot/themes';

@NgModule({
  declarations: [NavbarComponent, ConfirmDialog],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    HorizontalMenuModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    MatTooltipModule,
    MatListModule,
    ThemePickerModule,
    PushModule,
  ],
  exports: [NavbarComponent],
})
export class NavbarModule {}
