import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  template: `
    <h1 mat-dialog-title>An error has occurred!</h1>
    <div mat-dialog-content>
      <p *ngIf="data.status">
        <b>Status Code: {{ data.status }}</b>
      </p>
      <p>Something went wrong!</p>
      <p class="error-message">
        {{ data?.message }}
      </p>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button [mat-dialog-close] color="primary">Close</button>
    </div>
  `,
  styles: [`
  .error-message {

  padding: 1rem;
  font-family: monospace;
  word-break: break-all;
}
  `],
})
export class ErrorDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string; status?: number }
  ) {}
}
