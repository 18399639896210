import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";

import {filter, finalize, first, tap} from "rxjs/operators";
import { templatesLoaded } from "../selectors";
import {TemplateFacade} from "./templates.facade";

@Injectable({
  providedIn: 'root'
})
export class TemplateResolver implements Resolve<any> {

  useLocal: boolean = false;
  routeParams: any;
  Editor: any;
  loading: boolean = false;
  baseUrl(){
    if(this.useLocal) return '';
    return "http://localhost";
  }
  constructor(private store: Store, private facade: TemplateFacade) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;

    return this.loadTemplates();

  }


  loadTemplates(){
    return this.store
      .pipe(
        select(templatesLoaded),
        tap(TemplatesLoaded => {
          if (!this.loading && !TemplatesLoaded) {
            this.loading = true;
            this.facade.loadTemplates()
          }
        }),
        filter(TemplatesLoaded => TemplatesLoaded),
        first(),
        finalize(() => this.loading = false)
      );
  }





}
