import { createReducer, on } from '@ngrx/store';
import { UserActions } from '../actions/user.actions';
import { AuthUser } from '@autobot/authentication';
import { cloneDeep } from 'lodash-es';

export interface UserState {
  userDetails?: AuthUser;
  props?: { [key: string]: any };
  favorites: string[];
}
const initialState: UserState = {
  userDetails: undefined,
  favorites: [],
};

export const userReducer = createReducer(
  initialState,
  // on(UserActions.loginComplete, (state, { profile}) => {
  //   return {
  //     ...state,
  //     userDetails: profile,

  //   };
  // }),
  on(UserActions.addFavorite, (state, action) => {
    const favorites = state.favorites;
    return {
      ...state,
      favorites,
    };
  }),
  on(UserActions.updateProps, (state, action) => {
    const props = cloneDeep(state.props);
    const data = action.data;
    const updated = { ...props, ...data };

    return {
      ...state,
      props: updated,
    };
  }),
  on(UserActions.toggleFavorite, (state, action) => {
    const favorites = cloneDeep(state.favorites);

    const favSet = new Set(state.favorites.map((fav) => fav));

    !favSet.has(action.id) ? favSet.add(action.id) : favSet.delete(action.id);
    let newFavorites = [...favSet];
    return {
      ...state,
      favorites: newFavorites,
    };
  })
);
