import {Component, Input, OnInit} from '@angular/core';
export interface MenuItem {
  link: string;
  icon: string;
  label: string;
  disabled?: boolean;
  reqAuth?: boolean;
  props?: { [key: string]: any }
  children?: MenuItem[]
}
@Component({
  selector: 'app-horizontal-menu-item',
  template: `
    <ng-container *ngIf="menu">
      <ng-container *ngIf="!menu.children; else multi">
      <!--  <mat-list-item style="border:1px solid red" [routerLink]="menu.link"
                       routerLinkActive="is-active"
                       [routerLinkActiveOptions]="{exact:true}">{{menu.label}}
        </mat-list-item>-->
      </ng-container>
      <ng-template #multi>
        <div mat-menu-item [matMenuTriggerFor]="menuItem">{{menu.label}}</div>
      </ng-template>

<!--    <mat-list-item [routerLink]="menu.link"-->
<!--                   routerLinkActive="is-active"-->
<!--                   [routerLinkActiveOptions]="{exact:true}">-->
<!--      <ng-container *ngIf="menu.children; else single">-->
<!--        <div mat-menu-item [matMenuTriggerFor]="menuItem">{{menu.label}}</div>-->
<!--      </ng-container>-->
<!--       <ng-template #single>-->
<!--          <div>{{menu.label}}</div>-->
<!--        </ng-template>-->

    <mat-menu #menuItem="matMenu" [overlapTrigger]="false">
      <button mat-menu-item *ngFor="let child of menu.children" [routerLink]="child.link">
        <mat-icon>{{child.icon}}</mat-icon>
        <span>{{child.label}}</span>
      </button>
    </mat-menu>
    </ng-container>
  `,
  styles: [
  ]
})
export class HorizontalMenuItemComponent implements OnInit {
  @Input() menu!: MenuItem
  constructor() { }

  ngOnInit(): void {
  }

}
