import {
  APP_INITIALIZER,
  ErrorHandler,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule, EntityMetadataMap } from '@ngrx/data';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSyncReducer, reducers } from './state/core.state';
import { RouterEffects, SettingsEffects } from './state/effects';
import { CoreEnvironment, CoreEnvironmentService } from './state/core.helpers';
import { LoadingDialogComponent } from './loading/loading-dialog.component';
import { ErrorDialogComponent } from './errors/error-dialog.component';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpLoadingInterceptor, RoutingInterceptor } from './errors/';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorDialogService } from './errors/error-dialog.service';
import { LoadingDialogService } from './loading/loading.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { VersionEffects } from './state/effects/version.effects';
import { ApplicationFacade, ApplicationFacadeBase } from './state';
import { UserFacade } from './state/user-facade.service';
import { ThemePickerModule, ThemeStorage } from '@autobot/themes';
import { ConfigurationService, configFactory } from '../../services/src';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

const entityMetadata: EntityMetadataMap = {
  User: {},
};

const pluralNames = { User: 'Users' };

const MATERIAL = [MatProgressSpinnerModule, MatButtonModule, MatDialogModule];

const CONFIG_URL_TOKEN = new InjectionToken<string>('CONFIG_URL');

export function appInitializerFactory(
  themeStorage: ThemeStorage,
  app: ApplicationFacade
) {
  return () => {
    app.loadVersion();

    const themeName = themeStorage.getStoredThemeName();
    console.log(themeName);
    if (themeName) {
      app.changeThemeByName(themeName);
    } else {
      app.changeThemeByName('conduent-theme');
    }
  };
}

@NgModule({
  declarations: [LoadingDialogComponent, ErrorDialogComponent],
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers: [localStorageSyncReducer],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([RouterEffects, VersionEffects, SettingsEffects]),
    EntityDataModule.forRoot({
      entityMetadata,
      pluralNames,
    }),
    StoreDevtoolsModule.instrument(),
    ThemePickerModule,
    ...MATERIAL,
  ],
  exports: [],
  providers: [
    ApplicationFacade,
    UserFacade,
    ErrorDialogService,
    LoadingDialogService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [ThemeStorage, ApplicationFacade],
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpLoadingInterceptor,
    //   multi: true,
    // },

  ],
})
export class CoreModule {
  static forRoot(config: CoreEnvironment): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: CoreEnvironmentService,
          useValue: config,
        },
        ConfigurationService,
        { provide: CONFIG_URL_TOKEN, useValue: config.configUrl + "/app/" + config.environment},
        {
          provide: APP_INITIALIZER,
          useFactory: configFactory,
          deps: [ConfigurationService, CONFIG_URL_TOKEN],
          multi: true,
        },
        {
          provide: 'ENVIRONMENT',
          useFactory: (env: ConfigurationService) => env.getConfig,
          deps: [ConfigurationService],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RoutingInterceptor,
          deps:[CoreEnvironmentService],
          multi: true,
        },
        {
          provide: ErrorHandler,
          useClass: ApplicationinsightsAngularpluginErrorService
        }
      ],
    };
  }
  environment: any;
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import StateModule in the AppModule only.`
      );
    }
  }
}
