import { Injectable } from '@angular/core';
import { MedicalProtocol } from '@forms/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadABCDProtocol, loadProtocol } from '../actions/protocol.action';
import {
  getAbcdProtocolForms_Sorted,
  getInterventionAssmtForms_Sorted,
  getProtocolExtendedProperties,
  getSecondaryAssmtForms_Sorted,
  isProtocolFormLoaded,
  isProtocolLoading,
} from '../selectors';

@Injectable({ providedIn: 'root' })
export class ProtocolFacade {
  isProtocolLoaded$: Observable<boolean> =
    this.store.select(isProtocolFormLoaded);
  isProtocolLoading$: Observable<boolean> =
    this.store.select(isProtocolLoading);

  abcdProtocols$: Observable<MedicalProtocol[]> = this.store.select(
    getAbcdProtocolForms_Sorted
  );

  secondaryAssmtForms$: Observable<MedicalProtocol[]> = this.store.select(
    getSecondaryAssmtForms_Sorted
  );
  intervetionAssmtForms$: Observable<MedicalProtocol[]> = this.store.select(
    getInterventionAssmtForms_Sorted
  );

  extendedProperties$: Observable<MedicalProtocol[]> = this.store.select(
    getProtocolExtendedProperties
  );
 
  //
  constructor(private store: Store) {}

  loadABCDProtocolForm(
    protocolTemplateIds: string[],
    protocolType: string
  ): void {
    this.store.dispatch(
      loadABCDProtocol({ templateIds: protocolTemplateIds, protocolType })
    );
  }
  loadSecondaryAssmtProtocolForm(
    protocolTemplateIds: string[],
    protocolType: string
  ): void {
    this.store.dispatch(
      loadProtocol({ templateIds: protocolTemplateIds, protocolType })
    );
  }
  loadIntervetionAssmtProtocolForm(
    protocolTemplateIds: string[],
    protocolType: string
  ): void {
    this.store.dispatch(
      loadProtocol({ templateIds: protocolTemplateIds, protocolType })
    );
  }
}
