import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { delay, map, switchMap } from 'rxjs/operators';
import { MappingService } from '@forms/common';
import { Store } from '@ngrx/store';
import { CaseActions, FormEditorActions, ProtocolActions } from '../actions';
import {
  getProtocols,
  selectAssembly,
  selectCurrentState,
  selectModel,
} from '../selectors';
import { CaseApiService } from '../services/case-api.service';
import { LookUpFacade } from '../services/lookup.facade';

@Injectable()
export class EditorEffects {
  loadEditor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormEditorActions.loadForm),
      switchMap(({ templateId }) => {
        return this.lookup.lookUpDataSet$.pipe(
          map((r) => {
            return {
              templateId,
              datasets: r,
            };
          })
        );
      }),
      concatLatestFrom((_) =>
        this.store.select(selectAssembly).pipe(
          map((r) => {
            const template = r?.forms?.filter(
              (e) => e.templateId == _.templateId
            )[0];

            return {
              template,
              datasets: _.datasets,
            };
          })
        )
      ),
      concatLatestFrom(() => this.store.select(selectModel)),

      map((_) => {
        const data = _[0][1];
        const model = _[1];
        const form = data.template;
        const datasets = data.datasets;

        const fields = this.mapping.convertToNoWrapPropFields(
          form.template,
          datasets
        );

        return FormEditorActions.loadFormSuccess({
          template: form.template,
          form,
          fields,
          data: model,
          datasets,
        });
      })
    );
  });
  loadCustomFormEditor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormEditorActions.loadCustomForm),
      switchMap(({ key }) => {
        return this.lookup.lookUpDataSet$.pipe(
          map((r) => {
            return {
              key,
              datasets: r,
            };
          })
        );
      }),
      concatLatestFrom((_) =>
        this.store.select(selectAssembly).pipe(
          map((r) => {
            const template = r?.forms?.filter((e) => e.key == _.key)[0];

            return {
              template,
              datasets: _.datasets,
            };
          })
        )
      ),
      concatLatestFrom(() => [
        this.store.select(selectModel),
        this.store.select(selectCurrentState),
      ]),
      switchMap((_) => {
        const data = _[0][1];
        const model = _[1];
        const caseStateData = _[2];
        const form = data.template;
        const datasets = data.datasets;

        const fields = this.mapping.convertToNoWrapPropFields(
          form.template,
          datasets
        );
        const subMenus = this.mapping.mapMenuItems(form, caseStateData);

        return [
          FormEditorActions.loadFormSuccess({
            template: form.template,
            form,
            fields,
            data: model,
            datasets,
          }),
          FormEditorActions.enableDisableSubMenu({
            form,
            data: caseStateData,
            subMenu: subMenus,
          }),
        ];
      })
    );
  });
  enableDisableSubMenu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.addProtocols, CaseActions.removeProtocols),
      delay(1000),
      concatLatestFrom(() => [
        this.store.select(selectCurrentState),
        this.store.select(getProtocols),
      ]),
      switchMap((_) => {
        const currentProtocol = _[0];
        const data = _[1];
        const selectedProtocols = _[2];
        let protocolIds = [];

        selectedProtocols.map((f) => {
          let id = f.id;
          protocolIds.push(id);
        });
        // const protocols = data.case.protocols;
        const form = data.editor.iForm;
        const subMenus = this.mapping.mapMenuItems(form, data);

        return [
          FormEditorActions.enableDisableSubMenu({
            form,
            data: data,
            subMenu: subMenus,
          }),
          ProtocolActions.loadProtocol({
            templateIds: protocolIds,
            protocolType: '',
          }),
        ];
      })
    );
  });
  constructor(
    private actions$: Actions,
    private store: Store,
    private mapping: MappingService,
    private lookup: LookUpFacade
  ) {}
}
