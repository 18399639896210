import {Component} from '@angular/core';
import {FieldType, FieldTypeConfig} from '@ngx-formly/core';

@Component({
  selector: 'formly-field-file',
  template: `
    <mat-form-field>
      <button mat-icon-button matPrefix (click)="f_input.click()">
        <mat-icon>attach_file</mat-icon>
      </button>
      <input type="text" readonly matInput/>
      <input
        type="file"
        multiple
        hidden
        [formControl]="formControl" [formlyAttributes]="field"
        #f_input
  />

    </mat-form-field>

  `,
})
export class MatFormlyFieldFile extends FieldType<FieldTypeConfig> {}




