import { Observable } from 'rxjs';
import { SettingsState } from './models';

import { Store } from '@ngrx/store';
import { AppState, selectVersionState } from './core.state';
import { selectSettings, selectTheme } from './selectors';
import { actionSettingsChangeHeaderVisibility, changeTheme } from './actions';
import { Injectable } from '@angular/core';
import { VersionState } from './reducers';
import { updateVersion } from './actions/version.actions';
import { SiteTheme } from '@autobot/themes';

const themes: SiteTheme[] = [
  {
    primary: '#303f9f',
    accent: '#ff5722',
    displayName: 'Conduent Blue & Orange',
    name: 'conduent-blue-orange',
    isDark: false,
  },
  {
    primary: '#607d8b',
    accent: '#FDD835',
    displayName: 'Grey & Yellow',
    name: 'grey-yellow',
    isDark: false,
  },
  {
    primary: '#673AB7',
    accent: '#FFC107',
    displayName: 'Deep Purple & Amber',
    name: 'deeppurple-amber',
    isDark: false,
  },
  {
    primary: '#3F51B5',
    accent: '#E91E63',
    displayName: 'Indigo & Pink',
    name: 'indigo-pink',
    isDark: false,
    isDefault: true,
  },
  {
    primary: '#E91E63',
    accent: '#607D8B',
    displayName: 'Pink & Blue-grey',
    name: 'pink-bluegrey',
    isDark: true,
  },
  {
    primary: '#9C27B0',
    accent: '#4CAF50',
    displayName: 'Purple & Green',
    name: 'purple-green',
    isDark: true,
  },
  {
    primary: '#214563',
    accent: '#E8E8E8',
    displayName: 'Conduent Theme',
    name: 'conduent theme',
    isDark: true,
  },
];
export abstract class ApplicationFacadeBase {
  abstract settings$: Observable<SettingsState>;
  abstract version$: Observable<VersionState>;
  abstract theme$: Observable<SiteTheme>;
  abstract changeTheme(theme: SiteTheme): void;
  abstract loadVersion(): void;
  abstract changeHeaderVisibility(showPageHeader: boolean): void;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationFacade {
  settings$: Observable<SettingsState> = this.store.select(selectSettings);
  theme$: Observable<SiteTheme | undefined> = this.store.select(selectTheme);
  version$: Observable<VersionState> = this.store.select(selectVersionState);

  changeTheme(theme: SiteTheme): void {
    this.store.dispatch(changeTheme({ theme }));
  }

  loadVersion() {
    this.store.dispatch(updateVersion());
  }

  changeThemeByName(name: string): void {
    const theme = themes.find((currentTheme) => currentTheme.name === name);
    console.log(theme, name);
    if (theme) this.changeTheme(theme);
  }

  changeHeaderVisibility(showPageHeader: boolean): void {
    this.store.dispatch(
      actionSettingsChangeHeaderVisibility({ showPageHeader })
    );
  }

  constructor(private store: Store<AppState>) {}
}
