import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmDialogService,
  MappingService,
  MedicalProtocol,
} from '@forms/common';
import { Protocols } from '@nfr/common';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { forkJoin, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { CustomFormActions } from '../actions';

import {
  getCustomFormsModel,
  getIsEmergencyCase,
  selectAssembly,
  selectCurrentState,
  selectModel,
} from '../selectors';
import { LookUpFacade } from '../services/lookup.facade';
import { getLookUpDatasets } from '../selectors/lookup.selectors';
import { TemplateApiService } from '@forms/data/templates';

@Injectable()
export class CustomFormEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: TemplateApiService,
    private lookup: LookUpFacade,
    private mapping: MappingService,
    private confirmDialog: ConfirmDialogService
  ) {}

  loadFormFromCustomPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomFormActions.loadFormFromCustomPage),
      switchMap(({ templateKey }) => {
        return this.lookup.lookUpDataSet$.pipe(
          map((r) => {
            return {
              templateKey,
              datasets: r,
            };
          })
        );
      }),
      concatLatestFrom((_) =>
        this.store.select(selectAssembly).pipe(
          map((r) => {
            const template = r.forms.filter((e) => e.key.toLowerCase() == _.templateKey.toLowerCase())[0];
            return {
              template,
              datasets: _.datasets,
            };
          })
        )
      ),
      // concatLatestFrom(() => this.store.select(getCustomFomrsModel)),
      concatLatestFrom(() => this.store.select(selectModel)),
      map((_) => {
        const data = _[0][1];
        const model = _[1];
        const form = data.template;
        const datasets = data.datasets;
        const fields = this.mapping.convertToNoWrapPropFields(
          form.template,
          datasets
        );
        return CustomFormActions.loadFormFromCustomPageSuccess({
          template: form.template,
          form,
          fields,
          data: model,
          datasets,
        });
      }),
      catchError((error: unknown) => {
        return of(
          CustomFormActions.loadFormFromCustomPageFailure({
            err: error,
          })
        );
      })
    );
  });

  loadProtocolForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomFormActions.loadProtocol),
      concatLatestFrom(()=>this.store.select(getLookUpDatasets)),
      switchMap((data) => {
        const protocol = this.service.loadProtocolDetails(
          data[0].templateIds,
          data[0].protocolType
        );
        return forkJoin([protocol, of(data[1])]).pipe(
          first(),
          map((o) => {
            return {
              protocolResult: o[0],
              lookupsResult: o[1],
            };
          })
        );
      }),
      concatLatestFrom(() => this.store.select(getCustomFormsModel)),
      map((_) => {
        const data = _[0].protocolResult;
        const datasetResult = _[0].lookupsResult;
        const model = _[1];
        var mappedTemplate = this.mapping.mapMedicalProtocolTemplate1(
          data,
          datasetResult
        )[0];
        return CustomFormActions.loadProtocolSuccess({
          medicalProtocol: mappedTemplate,
          data: model,
          datasets: datasetResult,
        });
      }),
      catchError((error: unknown) => {
        return of(
          CustomFormActions.loadProtocolFailure({
            err: error,
          })
        );
      })
    );
  });

  validateRedirectToLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomFormActions.validateRedirectToLink),
        switchMap(({ selectedMenu, relativeTo }) => {
          return of([selectedMenu, relativeTo]);
        }),
        concatLatestFrom(() => [
          this.store.select(selectCurrentState),
          this.store.select(getIsEmergencyCase),
        ]),
        tap((_) => {
          const menu = _[0][0];
          const aRoute = _[0][1];
          const data = _[1];
          const isEmergencyCase = _[2];

          //To DO: Inject rules here to get pass failed results along with rule name
          if (
            menu.menuLink === '/protocol/secondaryassessment' &&
            !isEmergencyCase
          ) {
            const selectedProtocol = data?.case?.protocols;
            const model = data?.case?.model;
            const primaryProtocols = data?.protocol?.abcdForms;
            let isValid = this.checkSecondaryAssmtRule(
              selectedProtocol,
              model,
              primaryProtocols
            );
            if (!isValid) {
              this.confirmDialog.openConfirmDialog(
                'Attention',
                `<div><p>Before proceeding to the Secondary Assessment, please answer all questions in the ABCD/911 screen.</p></div>`,
                null,
                this.callBack,
                null,
                'OK',
                null
              );
            }
            this.store.dispatch(
              CustomFormActions.validateRedirectToLinkSuccess({
                callBackData: { redirectTo: isValid, selectedMenu: menu },
              })
            );
          } else {
            this.store.dispatch(
              CustomFormActions.validateRedirectToLinkSuccess({
                callBackData: { redirectTo: true, selectedMenu: menu },
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  validateRedirectToLinkSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomFormActions.validateRedirectToLinkSuccess),
        switchMap(({ callBackData }) => {
          return of([callBackData]);
        }),
        tap((_) => {})
      ),
    { dispatch: false }
  );

  callBack() {
    // alert('called');
  }

  /*
  To move from ABCD/911 to Secondary Assessment, the system must
  validate that all questions (that will trigger 911) in the ABCD/911 from all selected medical protocols are
  answered as ‘No’.
  */
  checkSecondaryAssmtRule(
    selectedProtocol: Protocols[],
    model: any,
    protocols: MedicalProtocol[]
  ) {
    let result = true;
    const triage = model?.triage;
    if (selectedProtocol.length == 0) return false;

    for (var protocol of protocols) {
      const fields = protocol?.primaryForm?.fields;
      var filteredFields = fields.filter(function (el) {
        return (
          (el.control == 'radioProtocol' || el.control == 'radio') &&
          (el.isTrigger911 == undefined || el.isTrigger911 == true)
        );
      });
      let isAllAnsweredNO = filteredFields.every(function (e) {
        let modelVal = _.get(triage, e.key);
        if (modelVal == 'N') {
          return true;
        }
      });
      if (!isAllAnsweredNO) {
        result = false;
        break;
      }
    }
    return result;
  }
}
