import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationInsightsService, CoreModule } from '@autobot/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationModule, RoleGuard } from '@autobot/authentication';
import { AutobotFormlyModule, DynamicFormModule } from '@autobot/formly';
import { environment } from '../environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { HighlightModule } from 'ngx-highlightjs';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { DefaultConfig } from '../environments/default.config';

export const DEFAULT_APP_MENU: any = {
  items: [
    // {
    //   link: '/nfr',
    //   icon: 'home',
    //   label: 'HOME',
    //   props: {
    //     horizontalEnabled: true,
    //     reqAuth: false
    //   }
    // }
  ],
};

export const CORE_MODULES = [
  HttpClientModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
  HighlightModule,
  FlexLayoutModule,
  NgxUiLoaderModule, // import NgxUiLoaderModule
  NgxUiLoaderRouterModule, // import NgxUiLoaderRouterModule. By default, it will show foreground loader.
  // If you need to show background spinner, do as follow:
  // NgxUiLoaderRouterModule.forRoot({ showForeground: false })
  NgxSpinnerModule,
  TranslateModule.forRoot({
    defaultLanguage: 'en',
  }),
  NgxMaskModule.forRoot(),
  DynamicFormModule,
  AuthenticationModule.forRoot(),
  AutobotFormlyModule.forRoot(),
  CoreModule.forRoot(environment),
];

export const PROVIDERS = [
  {
    provide: 'DEFAULTCONFIG',
    useValue: DefaultConfig
  },
  {
    provide: 'app-menu',
    useValue: DEFAULT_APP_MENU,
  },
  { provide: Window, useValue: window },
  {
    provide: 'CoreEnvironment',
    useValue: environment
  },
  RoleGuard,
  ApplicationInsightsService
];
