import { createAction, props } from '@ngrx/store';

export enum DesktopActionTypes {
  TOGGLE_LEFT_NAV = '[Desktop] Toggle Left Nav',
  TOGGLE_RIGHT_NAV = '[Desktop] Toggle Right Nav',

  SET_LEFT_NAV = '[Desktop] Set Left Nav',

  SET_HIDE_LEFT_NAV = '[Desktop] Hide Left Nav',
  SET_RIGHT_NAV = '[Desktop] Set Right Nav',

  CLEAR_DESKTOP = '[Desktop] Clear Desktop',
  LOAD_DESKTOP = '[Desktop] Load Desktop',
  LOAD_DESKTOP_SUCCESS = '[Desktop] Load Desktop Success',
  EXIT_DESKTOP = '[Desktop] Exit Desktop',
}

export const loadDesktop = createAction(DesktopActionTypes.LOAD_DESKTOP);

export const loadDesktopSuccess = createAction(
  DesktopActionTypes.LOAD_DESKTOP_SUCCESS,
  props<{ navs: any[] }>()
);

export const toggleRightNav = createAction(DesktopActionTypes.TOGGLE_RIGHT_NAV);

export const setRightNav = createAction(
  DesktopActionTypes.SET_RIGHT_NAV,
  props<{ isOpen: boolean }>()
);
export const setLeftNav = createAction(
  DesktopActionTypes.SET_LEFT_NAV,
  props<{ status: string }>()
);

export const clearDesktop = createAction(DesktopActionTypes.CLEAR_DESKTOP);
export const exitDesktop = createAction(DesktopActionTypes.EXIT_DESKTOP);
