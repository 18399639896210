import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { AuthenticationFacade } from '../../services/authentication.facade';

type ProfileType = {
  name?: string;
  picture?: string;
  email?: string;
};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  profile!: ProfileType;
  constructor(private http: HttpClient, private auth: AuthenticationFacade) {}

  ngOnInit() {
    this.getProfile();
  }

  getProfile() {
    return this.auth.profile$;
  }
}
