import { FormlyFieldConfig } from '@ngx-formly/core';

export function tooltipExtension(field: FormlyFieldConfig) {
  if (!field.props || (field.wrappers && field.wrappers.indexOf('tooltip') !== -1)) {
    return;
  }

  if (field.props && field.props.tooltip) {
    field.wrappers = [...(field.wrappers || []), 'tooltip'];
  }
}
