import {createSelector} from "@ngrx/store";
import {selectSessionFeatureState} from "./session.selectors";

export const selectDesktopState =  createSelector(selectSessionFeatureState, (state) => state.desktop)
export const getDesktopNavs = createSelector(selectDesktopState, (state) => state.navs)
export const selectDesktopLoaded = createSelector(selectDesktopState, (state) => state.loaded)
export const isRightNavOpen = createSelector(selectDesktopState, (state) => state.rightNavOpen)


export const getLeftNavStatus =  createSelector(selectDesktopState, (state) =>  {
  return state.leftNavStatus
})


export const isLeftNavHidden =  createSelector(selectDesktopState, (state) =>  {
  return state.leftNavStatus == 'hidden'
})
export const isLeftNavOpen = createSelector(selectDesktopState, (state) => state.leftNavStatus !== 'hidden')

