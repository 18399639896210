import { Component } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FieldType } from '@ngx-formly/core';
import {RepeatTypeComponent} from "./repeat-type.component";

@Component({
  selector: 'formly-drag-drop-horizontal',
  template: `
    <div class="formly-drag-drop" cdkDropListOrientation="horizontal"  (cdkDropListDropped)="drop($event)" cdkDropList>
     <div *ngFor="let field of field.fieldGroup;" cdkDrag>
       <div class="example-handle" cdkDragHandle>
         <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
           <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
           <path d="M0 0h24v24H0z" fill="none"></path>
         </svg>
       </div>
       <formly-field [field]="field"></formly-field>
     </div>

    </div>
  `,
  styles:[`
    .example-handle {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #ccc;
      cursor: move;
      width: 24px;
      height: 24px;
    }
  `]

})
export class DragDropHorizontalFieldType extends FieldType {
  drop(event: CdkDragDrop<string[]>) {
    // @ts-ignore
    moveItemInArray(this.field.fieldGroup, event.previousIndex, event.currentIndex);
  }
}


export const dragDropHorizontalType = {
  name: 'drag-drop-horizontal',
  component: DragDropHorizontalFieldType

};
