import {
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { LookupService } from '../../services/lookup.service';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

@Component({
  selector: 'smartystreet-search-formly',
  template: `
 <address-verification  style="width: 100%;" (SearchValue)="receiveSearchValue($event)"
  (clearSearchValue)="clearValue()"></address-verification>
  `,
  styles: [
    `
      .mat-input-element {
        width: 90%;
      }
      .error {
        font-size: 75%;
        position: absolute;
        margin-top: 8px;
      }
    `,
  ],
})
export class AddressVerificationFormlyComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit {
    svc = inject(LookupService);


  receiveSearchValue(event) {

    let ctrl= this.form.controls['intake']?.controls['employee']?.controls['address']?.controls['home'];
    if (ctrl) {
       // Update the regex to keep comma, underscore, hash, and ampersand
   
    
      ctrl.controls['address1'].setValue(event.address1);
      ctrl.controls['address2'].setValue(event.address2);
      ctrl.controls['city'].setValue(event.city);
      ctrl.controls['state'].setValue(event.state);
      ctrl.controls['zip'].setValue(event.zipCode);
      
    }

    
  }

  clearValue() {

    let ctrl= this.form.controls['intake']?.controls['employee']?.controls['address']?.controls['home'];
    if (ctrl) {
      ctrl.controls['address1'].setValue('');
      ctrl.controls['address2'].setValue('');
      ctrl.controls['city'].setValue('');
      ctrl.controls['state'].setValue('');
      ctrl.controls['zip'].setValue('');
    }


  }

  ngOnInit() {
     
    }
}
