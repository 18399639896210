import {createSelector} from "@ngrx/store";
import {selectSessionFeatureState} from "./session.selectors";

export const getLookUp = createSelector(
  selectSessionFeatureState,
  (state) => state?.lookUp
);

export const getLookUpDatasets = createSelector(
  selectSessionFeatureState,
  (state) => state?.lookUp?.lookups
);


export const isLookUpLoaded = createSelector(
  getLookUp,
  (state) => state?.loaded
)
