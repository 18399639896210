import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { LoadingComponent } from './components';
import { ErrorComponent, ProfileComponent } from './pages';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { authFeatureKey, authReducer } from './store/authentication.reducer';
import { AuthEffects } from './store/authentication.effects';
import { HighlightModule } from 'ngx-highlightjs';
import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthConfig } from './services/authentication.config';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(
  logLevel: LogLevel,
  message: string,
  containsPii: boolean
) {
  if (containsPii) {
    return;
  }
  switch (logLevel) {
    case LogLevel.Error:
      console.error(message);
      return;
    case LogLevel.Info:
      console.info(message);
      return;
    case LogLevel.Verbose:
      console.debug(message);
      return;
    case LogLevel.Warning:
      console.warn(message);
      return;
  }
}

export function MSALInstanceFactory(
  config: AuthConfig
): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: config.auth.clientId,
      authority: config.b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: config.auth.redirectUri,
      postLogoutRedirectUri: config.auth.postLogoutRedirectUri,
      knownAuthorities: [config.b2cPolicies.authorityDomain],
      navigateToLoginRequestUrl: config.auth.navigateToLoginRequestUrl,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: config.loggerOptions.logLevel,
        piiLoggingEnabled: config.loggerOptions.piiLoggingEnabled,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(
  config: AuthConfig
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>(
    config.interceptor.protectedResourceMap
  );
  return {
    interactionType: config.interceptor.interactionType,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(
  config: AuthConfig
): MsalGuardConfiguration {
  return {
    interactionType: config.guard.interactionType,
    authRequest: config.guard.authRequest,
    loginFailedRoute: config.guard.loginFailedRoute,
  };
}

@NgModule({
  declarations: [LoadingComponent, ErrorComponent, ProfileComponent],
  imports: [
    MsalModule,
    CommonModule,
    HighlightModule,
    StoreModule.forFeature(authFeatureKey, authReducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  exports: [LoadingComponent, ErrorComponent, ProfileComponent],
  providers: [],
})
export class AuthenticationModule {
  static forRoot() {
    return {
      ngModule: AuthenticationModule,
      providers: [
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [AuthConfig],
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
          deps: [AuthConfig],
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [AuthConfig],
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true,
        },
      ],
    };
  }
}
