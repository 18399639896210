import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './authentication.reducer';

// get the `auth` property from the state object
export const getAuthFeatureState = createFeatureSelector<AuthState>('auth');

// get the userProfile from the auth state
export const selectUserProfile = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.userProfile
);

export const selectUserRoles = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.roles
);

// get the isLoggedIn from the auth state
export const selectIsLoggedIn = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.isLoggedIn
);

// get the isLoggedIn from the auth state
export const selectIsSupervisor = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.isSupervisor
);

// get the isLoggedIn from the auth state
export const selectIsTestUser = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.isTestUser
);
