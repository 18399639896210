import {  LogLevel } from "@azure/msal-browser";

export class AuthConfig {
  auth: {
    clientId: string,
    redirectUri:string,
    postLogoutRedirectUri: string,
    navigateToLoginRequestUrl: boolean
  };
  b2cPolicies: {
    names: { signUpSignIn: string; resetPassword: string; editProfile: string };
    authorities: {
      signUpSignIn: { authority: string };
      editProfile: { authority: string };
      resetPassword: { authority: string };
    };
    authorityDomain: string;
  };
  guard: any;
  apiConfig: {
    profileUri:string;
    scopes: string[];
  };
  interceptor: { interactionType: any; protectedResourceMap: any };
  isServed: boolean;
  withError: boolean;
  loggerOptions:{
    logLevel: LogLevel,
    piiLoggingEnabled:boolean
  }
}

export const AppRoles = {
  Nurse: "Nurse",
  Supervisor: "Supervisor",
  Admin: "Admin"
}
