import { NgrxRoute } from '@autobot/core';
import { ICaseRule, IForm } from '@forms/common';
import {
  CallForm,
  CaseType,
  CreateCaseSerchDTO,
  Note,
  Protocols,
} from '@nfr/common';
import { createAction, props } from '@ngrx/store';

export enum CaseActionTypes {
  CREATE_CASE = '[Case] Create Case',
  LOAD_CASE = '[Case] Load Case',
  LOAD_SEARCH_CASES = '[Case] Search Existing Cases',
  SAVE_CASE = '[Case] Save Case',
  SAVE_CASE_AND_EXIT = '[Case] Save Case and exit',
  SAVE_CASE_AND_NAVIGATE = '[Case] Save Case and navigate',
  UPDATE_CASE_MODEL = '[Case] Update Case Model',
  UPDATE_CASE_MODEL_CUSTOM = '[Case] Update Case Model From Custom Page',
  UPDATE_CASE_MODEL_CUSTOM_SUCCESS = '[Case] Update Case Model From Custom Page Success',
  UPDATE_TRIAGE_PROTOCOL_MODEL = '[Case] Update Triage Model From Custom Page',
  SEARCH_CASES = '[Case/New Call Dialog] Search Existing Cases',

  ADD_NOTE = '[Case/Form Editor] Add Note',
  ADD_PROTOCOLS = '[Case/Form Editor] Add Protocols',
  REMOVE_PROTOCOLS = '[Case/Form Editor] Remove Protocols',
  REMOVE_PROTOCOLS_SUCCESS = '[Case/Form Editor] Remove Protocols Success',
  COMPLETE_CASE = '[Case] Complete Case',
  COMPLETE_CASE_SUCCESS = '[Case] Complete Case Success',
  COMPLETE_CASE_FAILURE = '[Case] Complete Case Failure',
  //COMPLETE_CASE_FAILURE_NOTIFY = '[Case] Complete Case Failure Notify',
  HANDLE_911_EMERGENCY_CLICK = '[Case] Handle 911 Emergency Case',
  ACTIVATE_911_EMERGENCY = '[Case] Activate 911 Emergency',
  ACTIVATE_911_EMERGENCY_SUCCESS = '[Case] Activate 911 Emergency Success',
  ACTIVATE_911_EMERGENCY_FAILURE = '[Case] Activate 911 Emergency Failure',

  DEACTIVATE_911_EMERGENCY = '[Case] De-Activate 911 Emergency',
  DEACTIVATE_911_EMERGENCY_SUCCESS = '[Case] De-Activate 911 Emergency Success',
  DEACTIVATE_911_EMERGENCY_FAILURE = '[Case] De-Activate 911 Emergency Failure',

  APPLY_CASE_RULE = '[Case] Apply Case Rules(s)',
  ENABLE_DISABLE_MENU = '[Editor] Enable Disable menu',
  ENABLE_DISABLE_MENU_SUCCESS = '[Editor] Enable Disable menu Success',
  ENABLE_DISABLE_MENU_FAILURE = '[Editor] Enable Disable menu Failure',
  UPDATE_STANDARD_PROTOCOL_ANSWER = '[CASE] Update Standard Protocol(s) Question Response ',
  UPDATE_STANDARD_PROTOCOL_ANSWER_SUCCESS = '[CASE] Update Standard Protocol(s) Question Response Success',
  UPDATE_STANDARD_PROTOCOL_ANSWER_FAILURE = '[CASE] Update Standard Protocol(s) Question Response failure',
  EVALUATE_CASE_TYPE = '[Case] Evaluate Case Type',
  EVALUATE_CASE_TYPE_SUCCESS = '[Case] Evaluate Case Type Success',
  EVALUATE_CASE_TYPE_FAILURE = '[Case] Evaluate Case Type Failure',
  UPDATE_VALID_CASE_FORM_LIST = 'Update Valid Case Form List',
  UPDATE_SECONDARY_GAUGES_DATA = 'UPDATE SECONDARY GAUGES DATA',
  UPDATE_COMPLETING = 'Update Completing',
  CLEAR_TRIAGE_DATA = '[Case] CLEAR TRIAGE DATA',

  GET_TELEMEDICINE_CONFIG = '[Case] Get Telemedicine Config',
  GET_TELEMEDICINE_CONFIG_SUCCESS = '[Case] Get Telemedicine Config Success',

  VALIDATE_TELEMEDICINE = '[Case] Validate Telemedicine',
  ACTIVATE_TELEMEDICINE = '[Case] Activate Telemedicine',
  DEACTIVATE_TELEMEDICINE = '[Case] De-Activate Telemedicine',
  APPLY_TELEMEDICINE_WORKFLOW = '[Case] Apply Telemedicine Workflow',
  APPLY_TELEMEDICINE_WORKFLOW_SUCCESS = '[Case] Apply Telemedicine Workflow Success',
  NOOP_ACTION = 'No Action',
}

export const createCase = createAction(
  CaseActionTypes.CREATE_CASE,
  props<{
    call: CallForm;
    account: any;
    agent: string;
    user: any;
  }>()
);

export const searchCases = createAction(
  CaseActionTypes.SEARCH_CASES,
  props<{
    searchText: string;
    tenant: string;
  }>()
);

export const loadCase = createAction(
  CaseActionTypes.LOAD_CASE,
  props<{ id: string }>()
);

export const loadSearchCases = createAction(
  CaseActionTypes.LOAD_SEARCH_CASES,
  props<{ call: CreateCaseSerchDTO }>()
);

export const saveCase = createAction(CaseActionTypes.SAVE_CASE);

export const saveCaseAndExit = createAction(CaseActionTypes.SAVE_CASE_AND_EXIT);

export const saveCaseAndNavigate = createAction(
  CaseActionTypes.SAVE_CASE_AND_NAVIGATE,
  props<{ to: NgrxRoute }>()
);

export const updateCaseModel = createAction(
  CaseActionTypes.UPDATE_CASE_MODEL,
  props<{ data: any }>()
);

export const updateCaseModelCustom = createAction(
  CaseActionTypes.UPDATE_CASE_MODEL_CUSTOM,
  props<{ data: any }>()
);

export const updateTriageProtocolModel = createAction(
  CaseActionTypes.UPDATE_TRIAGE_PROTOCOL_MODEL,
  props<{ data: any }>()
);
export const addNote = createAction(
  CaseActionTypes.ADD_NOTE,
  props<{ note: Note }>()
);
export const addProtocols = createAction(
  CaseActionTypes.ADD_PROTOCOLS,
  props<{ protocols: Protocols; protocolspecialInstruction: boolean }>()
);
export const removeProtocols = createAction(
  CaseActionTypes.REMOVE_PROTOCOLS,
  props<{ protocols: Protocols }>()
);

export const removeProtocolsSuccess = createAction(
  CaseActionTypes.REMOVE_PROTOCOLS_SUCCESS,
  props<{ protocols: Protocols; protocolspecialInstruction: boolean }>()
);
export const completeCase = createAction(
  CaseActionTypes.COMPLETE_CASE,
  props<{ completedBy: any }>()
);
export const completeCaseSuccess = createAction(
  CaseActionTypes.COMPLETE_CASE_SUCCESS
);
export const completeCaseFailure = createAction(
  CaseActionTypes.COMPLETE_CASE_FAILURE,
  props<{ error: any }>()
);
// export const completeCaseFailureNotify = createAction(
//   CaseActionTypes.COMPLETE_CASE_FAILURE_NOTIFY
// );

//#region 911 workflow

export const handle911EmergencyClick = createAction(
  CaseActionTypes.HANDLE_911_EMERGENCY_CLICK
);
export const activateEmergencyCase = createAction(
  CaseActionTypes.ACTIVATE_911_EMERGENCY
);
export const activateEmergencyCaseSuccess = createAction(
  CaseActionTypes.ACTIVATE_911_EMERGENCY_SUCCESS
);

export const activateEmergencyCaseFailure = createAction(
  CaseActionTypes.ACTIVATE_911_EMERGENCY_FAILURE,
  props<{ error: any }>()
);
export const deActivateEmergencyCase = createAction(
  CaseActionTypes.DEACTIVATE_911_EMERGENCY
);
export const deActivateEmergencyCaseSuccess = createAction(
  CaseActionTypes.DEACTIVATE_911_EMERGENCY_SUCCESS,
  props<{ caseType: CaseType }>()
);

export const deActivateEmergencyCaseFailure = createAction(
  CaseActionTypes.DEACTIVATE_911_EMERGENCY_FAILURE,
  props<{ error: any }>()
);

export const applyCaseRules = createAction(
  CaseActionTypes.APPLY_CASE_RULE,
  props<{ rules: ICaseRule }>()
);

/**
 * Pass IForm array with {Key and disabled flag) lists.
 * We are keeping list because we may have to enable/disable multiple form(s)
 * in one event
 */
export const enableDisableMenu = createAction(
  CaseActionTypes.ENABLE_DISABLE_MENU,
  props<{
    forms: IForm[];
    data?: any;
  }>()
);
export const enableDisableMenuSuccess = createAction(
  CaseActionTypes.ENABLE_DISABLE_MENU_SUCCESS,
  props<{
    forms: IForm[];
  }>()
);
export const enableDisableMenuFailure = createAction(
  CaseActionTypes.ENABLE_DISABLE_MENU_FAILURE,
  props<{
    error: any;
  }>()
);

//#endregion

export const updateStandardProtocolAnswer = createAction(
  CaseActionTypes.UPDATE_STANDARD_PROTOCOL_ANSWER,
  props<{ data: any }>()
);
export const updateStandardProtocolAnswerSuccess = createAction(
  CaseActionTypes.UPDATE_STANDARD_PROTOCOL_ANSWER_SUCCESS,
  props<{ data: any }>()
);
export const updateStandardProtocolAnswerError = createAction(
  CaseActionTypes.UPDATE_STANDARD_PROTOCOL_ANSWER_FAILURE,
  props<{ error: any }>()
);

export const evaluateCaseType = createAction(
  CaseActionTypes.EVALUATE_CASE_TYPE,
  props<{ data: any }>()
);
export const evaluateCaseTypeSuccess = createAction(
  CaseActionTypes.EVALUATE_CASE_TYPE_SUCCESS,
  props<{ caseType: CaseType }>()
);
export const evaluateCaseTypeFailure = createAction(
  CaseActionTypes.EVALUATE_CASE_TYPE_FAILURE,
  props<{ error: any }>()
);
export const updateCompleting = createAction(CaseActionTypes.UPDATE_COMPLETING);
export const updateValidCaseFormList = createAction(
  CaseActionTypes.UPDATE_VALID_CASE_FORM_LIST,
  props<{ data: any }>()
);

export const loadTelemedicineConfig = createAction(
  CaseActionTypes.GET_TELEMEDICINE_CONFIG,
  props<{ accountId: string }>()
);

export const loadTelemedicineConfigSuccess = createAction(
  CaseActionTypes.GET_TELEMEDICINE_CONFIG_SUCCESS,
  props<{ data?: any }>()
);
export const validateTelemedicine = createAction(
  CaseActionTypes.VALIDATE_TELEMEDICINE,
  props<{ data?: any }>()
);
export const activateTelemedicine = createAction(
  CaseActionTypes.ACTIVATE_TELEMEDICINE
);
export const deActivateTelemedicine = createAction(
  CaseActionTypes.DEACTIVATE_TELEMEDICINE
);
export const applyTelemedicineWorkflow = createAction(
  CaseActionTypes.APPLY_TELEMEDICINE_WORKFLOW
);
export const applyTelemedicineWorkflowSuccess = createAction(
  CaseActionTypes.APPLY_TELEMEDICINE_WORKFLOW_SUCCESS,
  props<{ provider?: any; providersearch?: any; metaData?: any }>()
);
export const noopAction = createAction(CaseActionTypes.NOOP_ACTION);
export const updateSecondaryGaugesData = createAction(
  CaseActionTypes.UPDATE_SECONDARY_GAUGES_DATA,
  props<{ data: any }>()
);
export const clearTriageData = createAction(CaseActionTypes.CLEAR_TRIAGE_DATA);
