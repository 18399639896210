import { get } from 'lodash';
import { CaseType, SelectedDisposition } from '../models/case';
import { CaseOriginDisplay } from '../models/dashboard';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root' // This ensures the service is a singleton and available everywhere
})
export class Helpers {
  static evaluateCaseType(data: any) {
    const {
      injuryResultInDeath,
      isReportOnly,
      employeeSoughtMedicalCare,
      isEmployeeAvailable,
      isEmployeeUnder18,
      parentalConsent,
    } = data;

    if (
      injuryResultInDeath === 'true' &&
      isReportOnly === 'true' &&
      isEmployeeAvailable !== 'true'
    ) {
      return CaseType.Death;
    }

    else if (injuryResultInDeath === 'false' && isReportOnly === 'true') {
      return CaseType.FNOLReportOnly;
    }
    else if (
      (injuryResultInDeath == 'false' &&
        isReportOnly == 'false' &&
        employeeSoughtMedicalCare == 'true') ||
      (injuryResultInDeath == 'false' &&
        isReportOnly == 'false' &&
       employeeSoughtMedicalCare == 'false' &&
       isEmployeeAvailable == 'false')
    ){
      return CaseType.FNOL;
    }
    else if (
      injuryResultInDeath == 'false' &&
      isReportOnly == 'false' &&
      employeeSoughtMedicalCare == 'false' &&
      isEmployeeAvailable == 'true' &&
      (isEmployeeUnder18 == 'false' ||
      (isEmployeeUnder18 == 'true' 
      && parentalConsent == 'true')
      )){
          return CaseType.NurseTriage;
        }

    return CaseType.Undefined;
  }

  static checkFormsAreValid(validCaseFormListData: any, formNames: string[]) {
    return formNames.every((formName) => {
      const selectedForm = validCaseFormListData.find(
        (p: { key: string }) => p.key.toUpperCase() === formName.toUpperCase()
      );
      return selectedForm && selectedForm.valid;
    });
  }
   
  static checkFormValid(validCaseFormListData: any, formName: string) {
    const selectedForm = validCaseFormListData.find(
      (p: { key: string }) => p.key.toUpperCase() === formName.toUpperCase()
    );
    return selectedForm && selectedForm.valid;
  }

  static checkEmergencyCaseValid(data: any) {
    const requiredFields = [
      'model.intake.supervisor.accidentDescription.dateTimeOfInjury',
      'model.intake.supervisor.accidentDescription.injuryTimeZone',
      'model.intake.employee.demographics.firstName',
      'model.intake.employee.demographics.lastName',
      'model.intake.employee.demographics.dob',
      'model.intake.location.work.city',
      'model.intake.location.work.state',
      'model.intake.location.work.code',
      'model.intake.location.work.address1',
    ];

    return requiredFields.every(
      (field) => !this.checkIsNullEmpty(get(data, field))
    );
  }

  static checkIsNullEmpty(value: any) {
    return value === null || value === undefined || value === '';
  }

  static getEmergencyCaseValidationMsg(data: any) {
    const { intake } = data.model;
    const { supervisor, employee, location } = intake;
    const { accidentDescription } = supervisor;
    const { demographics } = employee;

    const missingFields = [];

    if (this.checkIsNullEmpty(accidentDescription.dateTimeOfInjury)) {
      missingFields.push('Date of Injury');
    }

    if (this.checkIsNullEmpty(accidentDescription.injuryTimeZone)) {
      missingFields.push('Time Zone');
    }

    if (this.checkIsNullEmpty(demographics.firstName)) {
      missingFields.push('Employee First Name');
    }

    if (this.checkIsNullEmpty(demographics.lastName)) {
      missingFields.push('Employee Last Name');
    }

    if (this.checkIsNullEmpty(demographics.dob)) {
      missingFields.push('Employee DOB');
    }

    if (this.checkIsNullEmpty(location?.work?.city)) {
      missingFields.push('Work Location City');
    }

    if (this.checkIsNullEmpty(location?.work?.state)) {
      missingFields.push('Work Location State');
    }

    if (this.checkIsNullEmpty(location?.work?.code)) {
      missingFields.push('Work Location Code');
    }

    if (this.checkIsNullEmpty(location?.work?.address1)) {
      missingFields.push('Work Location Address 1');
    }

    const missingFieldsStr = missingFields.join(', ');

    return `Please fill ${missingFieldsStr} to report Emergency Case.`;
  }

  static removeCommaFromEnd(inputString: string): string {
    return inputString.endsWith(', ') ? inputString.slice(0, -2) : inputString;
  }

  static getValidCaseFormList(data: any) {
    const validCaseFormList = data?.model?.metaData?.validCaseFormList;

    if (validCaseFormList) {
     return validCaseFormList;
    }

    const formList = data.assembly.forms.flatMap((p: any) => {
      if (p.menuItems && p.menuItems.length > 0) {
        return p.menuItems.map((v: any) => ({
          key: v.menuDescription,
          visited: false,
          valid: v.valid,
        }));
      }

      return { key: p.key, visited: false, valid: p.valid };
    });

    return formList;
  }

  static getCaseFormList(data: any,arrForm:any) {
    
    const formList = data.assembly.forms.flatMap((p: any) => {
      if (p.menuItems && p.menuItems.length > 0) {
        return p.menuItems.map((v: any) => v.menuDescription);
      }
      return  p.key;
    }).filter((item : any) => arrForm.includes(item.toLowerCase()));
    return formList;
  }
  
  static isTrueSet(inputString: string): boolean {
    return (String(inputString).toLowerCase() == 'true');
  }
  static isFalseSet(inputString: string): boolean {
    return (String(inputString).toLowerCase() == 'false');
  }
  static isABCDSectionInComplete(data: any) {
    let result = false;
    if (data.protocols.length == 0) return true;

    for (var protocol of data.protocols) {
      let modelProtocol = null;
      modelProtocol = eval('data?.model?.triage?.protocol?.' + protocol.code.toLowerCase() + '?.abcd');
      // Bloodbody is special protocol case where ABCD has no question
      if (modelProtocol === undefined && protocol.code.toLowerCase() != 'bloodbody') {
        return true;
      }
      result = this.checkAnyNodeNullOrEmpty(modelProtocol);
      if (result) {
        return true;
      }
    }
    return result;
  }

  static checkAnyNodeNullOrEmpty(obj: any): boolean {
    if (typeof obj !== 'object') {
      return obj === null || obj === '';
    }
    if (obj === null) {
      return true;
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (this.checkAnyNodeNullOrEmpty(value)) {
          return true;
        }
      }
    }
    return false;
  }

  static getSelectedDisposition(data: any): SelectedDisposition {
    const disposition=data?.planofcare?.disposition;
    const {
      dispositionRecommended,
      isEEAgrees,
      eeDisagrees,
      typeOfTreatment
    } = disposition;
    if((dispositionRecommended=='Telemedicine' && isEEAgrees=='yes') 
    ||(dispositionRecommended=='Telemedicine' && isEEAgrees=='no' && eeDisagrees=='Timeframe')
    ||(isEEAgrees=='no' && (eeDisagrees=='Disposition'|| eeDisagrees=='Both') && typeOfTreatment=='Telemedicine' )){
      return SelectedDisposition.TeleMedicine;
   }
   else if((dispositionRecommended=='Self-Care' && isEEAgrees=='yes')
    ||(dispositionRecommended=='Self-Care' && isEEAgrees=='no' && eeDisagrees=='Timeframe')
    ||(isEEAgrees=='no' && (eeDisagrees=='Disposition'|| eeDisagrees=='Both') && typeOfTreatment=='Self-Care')){
      return SelectedDisposition.SelfCare;
   }
    return SelectedDisposition.Other;
  }

  getCaseOriginDisplay(caseOrigin: string): string {
    switch (caseOrigin) {
      case 'NfrStandAlone':
        return CaseOriginDisplay.NfrStandAlone;
      case 'LrmTransfer':
        return CaseOriginDisplay.LrmTransfer;
      default:
        return caseOrigin; // Return the original value if no match is found
    }
  }
  
}
