import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertService, ConfirmDialogService } from '@forms/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CommonDialogsModule } from '@autobot/shared';
import { CaseEffects } from './effects/case.effects';
import { EditorEffects } from './effects/editor.effects';
import { sessionFeatureReducersMap } from './reducers';
import { CaseFacade } from './services/case.facade';
import { DesktopFacade } from './services/desktop.facade';
import { EditorFacade } from './services/editor.facade';
import { LookUpFacade } from './services/lookup.facade';

import { CallEffects } from './effects/call.effects';
import { CustomFormEffects } from './effects/custom-form.effects';
import { DesktopEffects } from './effects/desktop.effects';
import { LookUpEffects } from './effects/lookup.effects';
import { FollowUpEffects } from './effects/followup.effects';
import { ProtocolEffects } from './effects/protocol.effects';
import { CallModule } from './modules/call';
import { FollowUpFacade } from './services/followup.facade';
import { ProtocolFacade } from './services/protocol.facade';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    CommonDialogsModule,
    CallModule,
    StoreModule.forFeature('session', sessionFeatureReducersMap),
    EffectsModule.forFeature([
      CaseEffects,
      EditorEffects,
      CallEffects,
      DesktopEffects,
      CustomFormEffects,
      ProtocolEffects,
      FollowUpEffects,
      LookUpEffects
    ]),
  ],
  providers: [
    AlertService,
    CaseFacade,
    DesktopFacade,
    LookUpFacade,
    EditorFacade,
    ProtocolFacade,
    FollowUpFacade,
    ConfirmDialogService,
  ],
})
export class SessionStoreModule {}
