<div class="landing-container">
  <ng-container *ngIf="loggedIn$ | ngrxPush; else notAuth">
    <mat-nav-list class="list-horizontal">
      <ng-container *ngFor="let menu of items; index as i">
        <app-horizontal-menu-item
          *ngIf="!menu.props?.reqAuth"
          mat-list-item
          [menu]="menu"
        ></app-horizontal-menu-item>
      </ng-container>
    </mat-nav-list>
  </ng-container>
</div>
<ng-template #notAuth>
  <div class="arow">
    <div class="coll">
    <div class="panel">
      <div class="sitename">{{siteName}}</div>
      <div class="panel-body">
        <img class="companyLogo"  src="assets/images/conduentlogo.jpg" alt="Company Logo">
        <div class="buttons">
        <button mat-raised-button (click)="login()">Login</button>
      </div>
      </div>
    </div>
  </div>
  </div>
</ng-template>
