import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Account, AccountActivation, Config } from '@accounting/common';

export enum AccountsApiActionTypes {
  LOAD_ACCOUNTS = '[Account/API] Load Accounts',
  LOAD_ACCOUNTS_SUCCESS = '[Account/API] Load Accounts Success',
  LOAD_ACCOUNTS_FAILURE = '[Account/API] Load Accounts Failure',

  LOAD_ALL_ACCOUNTS = '[Account/API] Load Active and Inactive Accounts',
  LOAD_ALL_ACCOUNTS_SUCCESS = '[Account/API] Load All Accounts Success',
  LOAD_ALL_ACCOUNTS_FAILURE = '[Account/API] Load All Accounts Failure',

  LOAD_ACCOUNT_CONFIGS = '[Account/API] Load Account Configs',
  LOAD_ACCOUNT_CONFIG_COMPLETE = '[Account/API] Load Account Configs Complete',
  LOAD_ACCOUNT_CONFIGS_SUCCESS = '[Account/API] Load Account Configs Success',
  LOAD_ACCOUNT_CONFIGS_FAILURE = '[Account/API] Load Account Configs Failure',

  CREATE_ACCOUNT = '[Account/API] Add Account',
  CREATE_ACCOUNT_SUCCESS = '[Account/API] Add Account Success',
  CREATE_ACCOUNT_FAILURE = '[Account/API] Add Account Failure',

  CREATE_ACCOUNT_DRAFT = '[Account/API] Create Account Draft',
  CREATE_ACCOUNT_DRAFT_SUCCESS = '[Account/API] Create Account Draft Success',
  ADD_ACCOUNTS_SUCCESS = '[Account/API] Add Accounts Success',

  UPDATE_ACCOUNT = '[Account/API] Update Account',
  UPDATE_ACCOUNT_SUCCESS = '[Account/API] Update Account Success',
  UPDATE_ACCOUNT_FAILURE = '[Account/API] Update Account Failure',

  ACTIVATE_ACCOUNT = '[Account/API] Activate Account',
  ACTIVATE_ACCOUNT_SUCCESS = '[Account/API] Activate Account Success',
  ACTIVATE_ACCOUNT_FAILURE = '[Account/API] Activate Account Failure',

  DELETE_ACCOUNT = '[Account/API] Delete Account',
  DELETE_ACCOUNT_SUCCESS = '[Account/API] Delete Account Success',
  DELETE_ACCOUNT_FAILURE = '[Account/API] Delete Account Failure',
}

export const loadAccounts = createAction(AccountsApiActionTypes.LOAD_ACCOUNTS);
export const loadAccountsSuccess = createAction(
  AccountsApiActionTypes.LOAD_ACCOUNTS_SUCCESS,
  props<{ accounts: Account[] }>()
);
export const loadAccountsFailure = createAction(
  AccountsApiActionTypes.LOAD_ACCOUNTS_FAILURE,
  props<{ err: any }>()
);
export const loadAccountConfigs = createAction(AccountsApiActionTypes.LOAD_ACCOUNT_CONFIGS,
  props<{ id: string }>()
);
export const loadAccountConfigsSuccess = createAction(
  AccountsApiActionTypes.LOAD_ACCOUNT_CONFIGS_SUCCESS,
  props<{id: string , configs: Config[] }>()
);

export const loadAccountConfigsFailure = createAction(
  AccountsApiActionTypes.LOAD_ACCOUNT_CONFIGS_FAILURE,
  props<{ err: any }>()
);

export const createAccount = createAction(
  AccountsApiActionTypes.CREATE_ACCOUNT,
  props<{ account: Account }>()
);
export const createAccountSuccess = createAction(
  AccountsApiActionTypes.CREATE_ACCOUNT_SUCCESS,
  props<{ account: Account }>()
);
export const createAccountFailure = createAction(
  AccountsApiActionTypes.CREATE_ACCOUNT_FAILURE,
  props<{ err: any }>()
);

export const createAccountDraft = createAction(
  AccountsApiActionTypes.CREATE_ACCOUNT_DRAFT,
  props<{ id: string }>()
);
export const createAccountDraftSuccess = createAction(
  AccountsApiActionTypes.CREATE_ACCOUNT_SUCCESS,
  props<{ account: Account }>()
);

export const addAccountsSuccess = createAction(
  AccountsApiActionTypes.ADD_ACCOUNTS_SUCCESS,
  props<{ accounts: Account[] }>()
);

export const updateAccount = createAction(
  AccountsApiActionTypes.UPDATE_ACCOUNT,
  props<{ account: Account }>()
);
export const updateAccountSuccess = createAction(
  AccountsApiActionTypes.UPDATE_ACCOUNT_SUCCESS,
  props<{ account: Update<Account> }>()
);
export const updateAccountFailure = createAction(
  AccountsApiActionTypes.UPDATE_ACCOUNT_FAILURE,
  props<{ err: any }>()
);

export const activateAccount = createAction(
  AccountsApiActionTypes.ACTIVATE_ACCOUNT,
  props<{ account: AccountActivation }>()
);
export const activateAccountSuccess = createAction(
  AccountsApiActionTypes.ACTIVATE_ACCOUNT_SUCCESS,
  props<{ account: Update<Account> }>()
);
export const activateAccountFailure = createAction(
  AccountsApiActionTypes.ACTIVATE_ACCOUNT_FAILURE,
  props<{ err: any }>()
);

export const deleteAccount = createAction(
  AccountsApiActionTypes.DELETE_ACCOUNT,
  props<{ id: string }>()
);
export const deleteAccountSuccess = createAction(
  AccountsApiActionTypes.DELETE_ACCOUNT_SUCCESS,
  props<{ id: string }>()
);
export const deleteAccountFailure = createAction(
  AccountsApiActionTypes.DELETE_ACCOUNT_FAILURE,
  props<{ err: any }>()
);

export const updateAccounts = createAction(
  '[Account/API] Update Accounts',
  props<{ accounts: Update<Account>[] }>()
);
export const deleteAccounts = createAction(
  '[Account/API] Delete Accounts',
  props<{ ids: string[] }>()
);
export const clearAccounts = createAction('[Account/API] Clear Accounts');

export const upsertAccount = createAction(
  '[Account/API] Upsert Account',
  props<{ account: Account }>()
);
export const upsertAccounts = createAction(
  '[Account/API] Upsert Accounts',
  props<{ accounts: Account[] }>()
);
