import {
  AbstractControl, ValidationErrors
} from '@angular/forms';

export class FormControlValidators {
  /**
   * @description
   * Checks the form control value has only blank spaces.
   */
  public static spaceOnly(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const regExp = /^\s+$/;
    const isSpaceOnly = control.valid && regExp.test(control.value);
    return isSpaceOnly ? { spaceOnly: true } : null;
  }
  public static textOnly(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    
    const regExp = /^[a-zA-Z-.\s]+$/;
    const isTextOnly = regExp.test(control.value);
    return isTextOnly ? null: { textOnly: true } ;
  }
}
