import { IForm, MedicalProtocol, Template } from '@forms/common';
import { createReducer, on } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ProtocolActions } from '../actions';

export interface State {
  caseId?: string;
  selectedProtocols: SelectedProtocol[];

  subjectiveAssmt: SubjectiveAssmtState;
  abcdForms: MedicalProtocol[];
  abcd: AbcdState[];
  secondaryAssmtForms: MedicalProtocol[];
  secondaryAssmt: SecondaryAssessment[];
  interventionAssmtForms: MedicalProtocol[];
  interventionAssmt: InterventionAssessment[];
  initialModel?: any;
  model?: any;
  loaded: boolean;
  loading: boolean;
  saved: boolean;
  saving: boolean;
  error?: any;
  extendedProperties?: any;
}
export interface SelectedProtocol {
  Id?: string;
  Name?: string;
  Code?: string;
}

export interface SubjectiveAssmtState {
  iForm?: IForm;
  template?: Template;
  datasets?: { [key: string]: any };
  fields: FormlyFieldConfig[];
  initialModel?: any;
  model?: any;
  loaded: boolean;
  loading: boolean;
  saved: boolean;
  saving: boolean;
  selectedTabIndex: number;
  selectedAreaIndex: number;
  lastSaved?: Date;
  lastUpdate: Date;
  error?: any;
}
export interface AbcdState {
  protocolId: string;
  template?: Template;
  datasets?: { [key: string]: any };
  fields: FormlyFieldConfig[];
  model?: any;
  loaded: boolean;
  loading: boolean;
  saved: boolean;
  saving: boolean;
  selectedTabIndex: number;
  selectedAreaIndex: number;
  lastSaved?: Date;
  lastUpdate: Date;
  error?: any;
}
export interface SecondaryAssessment {
  template?: Template;
  iForm?: IForm;
  datasets?: { [key: string]: any };
  fields: FormlyFieldConfig[];
  initialModel?: any;
  model?: any;
  loaded: boolean;
  loading: boolean;
  saved: boolean;
  saving: boolean;
  selectedTabIndex: number;
  selectedAreaIndex: number;
  lastSaved?: Date;
  lastUpdate: Date;
  error?: any;
}
export interface InterventionAssessment {
  template?: Template;
  iForm?: IForm;
  datasets?: { [key: string]: any };
  fields: FormlyFieldConfig[];
  initialModel?: any;
  model?: any;
  loaded: boolean;
  loading: boolean;
  saved: boolean;
  saving: boolean;
  selectedTabIndex: number;
  selectedAreaIndex: number;
  lastSaved?: Date;
  lastUpdate: Date;
  error?: any;
}
export interface FormState {
  template?: Template;
  iForm?: IForm;
  datasets?: { [key: string]: any };
  fields: FormlyFieldConfig[];
  initialModel?: any;
  model?: any;
}

export const initialProtocolState: State = {
  caseId: undefined,
  selectedProtocols: [],
  subjectiveAssmt: undefined,
  abcdForms: [],
  secondaryAssmtForms: [],
  interventionAssmtForms: [],
  abcd: [],
  secondaryAssmt: [],
  interventionAssmt: [],
  initialModel: undefined,
  model: undefined,
  loaded: false,
  loading: false,
  saved: false,
  saving: false,
  error: undefined,
  extendedProperties: undefined,
};

export const reducer = createReducer(
  initialProtocolState,
  on(ProtocolActions.loadProtocol, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(ProtocolActions.loadProtocolSuccess, (state, action) => ({
    ...state,
    model: action.data,
    abcdForms: action.medicalProtocol,
    secondaryAssmtForms: action.medicalProtocol,
    interventionAssmtForms: action.medicalProtocol,
    extendedProperties: action.medicalProtocol,
    loaded: true,
    loading: false,
  })),
  on(ProtocolActions.loadProtocolFailure, (state, action) => ({
    ...state,
    abcdForms: [],
    secondaryAssmtForms: [],
    interventionAssmtForms: [],
    extendedProperties: undefined,
    loaded: false,
    loading: false,
    error: action.err,
  })),
  on(ProtocolActions.loadABCDProtocol, (state) => ({
    ...state,

    loading: true,
    loaded: false,
  })),
  on(ProtocolActions.loadABCDProtocolSuccess, (state, action) => ({
    ...state,
    abcdForms: action.medicalProtocol,
    //abcdForms=action.medicalProtocol,
    initialModel: action.data,
    model: action.data,
    loaded: true,
    loading: false,
  })),
  on(ProtocolActions.loadABCDProtocolFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.err,
  })),

  //#region Subjective Assmt
  on(ProtocolActions.loadFormFromProtocol, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(ProtocolActions.loadFormFromProtocolSuccess, (state, action) => ({
    ...state,
    subjectiveAssmt: {
      ...state.subjectiveAssmt,
      template: action.template,
      iForm: action.form,
      datasets: action.datasets,
      fields: action.fields,
      initialModel: action.data,
      model: action.data,
    },
    loaded: true,
    loading: false,
  })),
  on(ProtocolActions.loadABCDProtocolFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    error: action.err,
  })),
  //#endregion

  on(ProtocolActions.exitProtocolPage, (state) => ({ ...initialProtocolState }))
);
