import {createAction, props} from "@ngrx/store";
import {Template} from "@forms/common";

export enum TemplateEditorPageActionTypes {
  INIT = '[Template/Editor Page] Init',
  SAVE_TEMPLATE = '[Template/Editor Page] Save Template',
  SAVE_TEMPLATE_CANCELLED = '[Template/Editor Page] Save Template Cancelled',
  SAVE_TEMPLATE_SUCCESS = '[Template/Editor Page] Save Template Success',
  SAVE_TEMPLATE_FAILURE = '[Template/Editor Page] Save Template Failure',
  EXPORT_TEMPLATE_CLICKED = '[Template/Editor Page] Export Template Clicked',
}
export const init = createAction(TemplateEditorPageActionTypes.INIT,  props<{template: Template}>());
export const saveTemplate = createAction(TemplateEditorPageActionTypes.SAVE_TEMPLATE, props<{template: Template}>());
export const saveTemplateSuccess = createAction(TemplateEditorPageActionTypes.SAVE_TEMPLATE_SUCCESS);
export const saveTemplateCancelled = createAction(TemplateEditorPageActionTypes.SAVE_TEMPLATE_CANCELLED);
export const saveTemplateFailure = createAction(TemplateEditorPageActionTypes.SAVE_TEMPLATE_FAILURE,props<{err: any}>());
export const exportTemplateClicked = createAction(TemplateEditorPageActionTypes.EXPORT_TEMPLATE_CLICKED, props<{template: Template}>());
