import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of, retry, tap, throwError } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { IEnvironmentConfig } from '@autobot/core';
import { Account, AccountActivation, AccountMinimalView, Config } from '../models/account';
import { AccountFakerService } from './account-faker.service';

@Injectable({ providedIn: 'root' })
export class AccountApiService {
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private environment: IEnvironmentConfig,
    private faker: AccountFakerService
  ) {}
  useLocal = false;
  apiURL = this.environment.apiConfig.accountsUri + '/api';

  getAll(): Observable<Account[]> {
    return this.http
      .get<Account[]>(this.apiURL + '/accounts')
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }
  getActiveAccounts(): Observable<Account[]> {
    return this.http
      .get<Account[]>(this.apiURL + '/accounts/active')
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  getLastUpdateDate(): Observable<Date> {
    return this.http
      .get<Date>(this.apiURL + '/accounts/cachebusting')
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  getMinimal(): Observable<AccountMinimalView[]> {
    return this.http
      .get<Account[]>(this.apiURL + '/accounts/search')
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  getById(id: string): Observable<Account> {
    const _url = `${this.apiURL} + '/accounts/' + ${id}`;

    return this.http
      .get<Account>(_url)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  getConfigs(id: string): Observable<Config[]> {
    const _url = `${this.apiURL}/config/${id}/module/portal`;

    return this.http
      .get<Config[]>(_url)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  create(account: Account) {
    return this.http
      .post<Account>(this.apiURL + '/accounts', account)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  update(id: string, account: Account) {
    const _url = this.apiURL + '/accounts/' + id;
    return this.http
      .put<Account>(_url, account)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }


  activateAccount(id: string, account: AccountActivation) {
    const _url = this.apiURL + '/accounts/' + id + '/activate';
    return this.http
      .put<AccountActivation>(_url, account)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  delete(id: string): Observable<boolean> {
    const _url = this.apiURL + '/accounts/' + id;
    return this.http
      .delete<boolean>(_url)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(msg);
  }
}
