import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from './ConfirmDialog';

import { AppState, changeTheme } from '@autobot/core';
import { Store } from '@ngrx/store';
import { IMultiMenuConfig } from '../sidenav/main-nav/main-nav.component';
import { defaultDialogConfig } from '../configs';
import {
  AuthenticationFacade
} from '@autobot/authentication';
import { SidenavService } from '../sidenav/sidenav.service';
import { LayoutFacadeService } from '../state/layout-facade.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Output() openSettings = new EventEmitter();
  @Output() openVersion = new EventEmitter();
  @Output() openProfile = new EventEmitter();

  @Output()
  toggleSidenav = new EventEmitter<void>();
  @Output()
  toggleNotificationSidenav = new EventEmitter<void>();
  @Output()
  updateSiteOptions = new EventEmitter<any>();
  name = 'good stuff';
  value = '';
  @Input() sideNavButtonEnabled = true;
  @Output() onLogin = new EventEmitter();
  @Output() onLogout = new EventEmitter();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public auth: AuthenticationFacade,
    private state: Store<AppState>,
    private sideNavService: SidenavService,
    private layout: LayoutFacadeService,
    @Inject('app-menu') private cfg: IMultiMenuConfig
  ) {}
  toggleSideNav() {
    this.layout.toggleSideNav();
  }

  themeUpdated(e) {
    this.state.dispatch(changeTheme({ theme: e }));
  }
  updateOptions(e: any) {
    this.updateSiteOptions.emit(e);
  }

  confirmLogout(): void {
    const dialogRef = this.dialog.open(ConfirmDialog, defaultDialogConfig());

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.logout();
      }
    });
  }
  returnToStart() {}

  login() {
    this.onLogin.emit();
  }
  logout() {
    this.onLogout.emit();
  }
  ngOnInit(): void {}
}
