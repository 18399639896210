import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import * as RouterActions from '../actions/router.actions';
import {filter, map, mergeMap, tap} from 'rxjs/operators';
import {Location} from '@angular/common';
import {Actions, createEffect, ofType} from '@ngrx/effects';

@Injectable()
export class RouterEffects {
  updateTitle$ = createEffect(
    () =>
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap(route => route.data),
        map(data => {

          return data['title']
        }),
        tap(title => this.titleService.setTitle(title))
      ),
    {
      dispatch: false,
    }
  );
  navigate$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(RouterActions.go),
        map(action => action.to),
        tap(({ path, query: queryParams, extras }) => this.router.navigate(path, { queryParams, ...extras })),
      ),
    { dispatch: false },
  );

  navigateBack$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(RouterActions.back),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  navigateForward$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(RouterActions.forward),
        tap(() => this.location.forward()),
      ),
    { dispatch: false },
  );
  constructor(

    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private action$: Actions, private router: Router, private location: Location
  ) {}
}
