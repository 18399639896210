import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
export class ConditionalValidators {
  
  /**
   * @description
   * Checks the condition before setting required validator ON or OFF.
   */
  public static RequiredIf(masterControlLabel: string, operator: string, conditionalValue: any, slaveControlLabel: string) {
    return (group: FormGroup): {[key: string]: any} => {
      const masterControl = group.controls[masterControlLabel];
      const slaveControl = group.controls[slaveControlLabel];     
      if (eval(`'${masterControl.value}' ${operator} '${conditionalValue}'`)) { 
        return Validators.required(slaveControl)
      }
      slaveControl.setErrors(null); 
      return null;
    }
  }

  public static RequiredIfTrue(condition: any) {
      if (eval(`${condition}`)) { 
        return Validators.required
      }
      return Validators.nullValidator;
   
  }

}
