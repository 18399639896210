import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MsalService} from '@azure/msal-angular';
import {Observable, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
})
export class UnAuthorizedComponent {

  //public error$: Observable<any> = this.auth.;

  constructor(private auth: MsalService, private router: Router) {}

  ngOnInit() {
    // timer(0).pipe(takeUntil(this.error$)).subscribe(() => {
    //   this.router.navigateByUrl('/');
    // });
  }
}

