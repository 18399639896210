import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-dialog-formly',
  template: `
    <div class="dialog-header">
    <h2 mat-dialog-title *ngIf="title">{{ title }}</h2>
    <button *ngIf="showXButton" class="close-button" mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
    
    <mat-dialog-content class="mat-typography center-contents" [innerHTML]="message">
      
    </mat-dialog-content>
    <div mat-dialog-actions fxLayoutAlign="end center">
      <button
        mat-raised-button
        *ngIf="showCancelButton"
        [mat-dialog-close]="false"
        cdkFocusInitial
      >
        {{ cancelButtonText }}
      </button>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">
        {{ acceptButtonText }}
      </button>
    </div>
  `,
  styles: [`
  .center-align{
    text-align:center;
  }
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dialog-header h2 {
    margin: 0;
  }
  .close-button:focus {
    outline: none;
  }
  .mat-dialog-content {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  `],
})
export class ConfirmDialogControlComponent {
  showCancelButton = true;
  title!: string;
  message!: string;
  acceptButtonText!: string;
  cancelButtonText!: string;
  showXButton!: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.title !== undefined) this.title = data.title;
    if (data.message !== undefined) this.message = data.message;
    if (data.acceptButtonText !== undefined)
      this.acceptButtonText = data.acceptButtonText;
    if (data.cancelButtonText !== undefined)
      this.cancelButtonText = data.cancelButtonText;
    if (data.showCancelButton !== undefined)
      this.showCancelButton = data.showCancelButton;
    if (data.showXButton !== undefined)
      this.showXButton = data.showXButton;
  }
}
