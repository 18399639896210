
import * as _ from 'lodash';
import {pairwise, startWith} from "rxjs";
import {filter, map} from "rxjs/operators";
import {FormGroup} from "@angular/forms";
export const flattenObject = (obj:any) => {
  const flattened = {}

  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value))
    } else {
      flattened[key] = value
    }
  })

  return flattened
}

export function flatten(arr:any[]): any {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}
export function getDateString() {
  const date = new Date();
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  return `${year}${month}${day}`
}

export function isNullUndefinedOrEmpty(value:any) {
  if (value !== undefined && value !== "" && value !== "null" && value !== null) {
    return false;
  } else {
    return true;
  }
}

export interface IGroupedData {
  field: string;
  value: string;
  rows: [];
  count: number;
}

export function groupBy2(arr:any[], fields:string[]): IGroupedData[] {
  const field = fields[0];               // one field at a time
  if (!field) { return arr; }
  const retArr: IGroupedData[] = Object.values(
    arr.reduce((obj:Array<any>, current) => {
      if (!obj[current[field]]) { obj[current[field]] = {field, value: current[field],rows: []}; }
      obj[current[field]].rows.push(current);
      return obj;
    }, {}));

  // recurse for each child's rows if there are remaining fields
  if (fields.length){
    retArr.forEach((obj:any) => {
      obj.count = obj.rows.length;
      obj.rows = groupBy(obj.rows, fields.slice(1));
    });
  }




  return retArr
}

function isString(val){
  return val instanceof String;
}

function groupBy(getKey, array) {

  return _.reduce(
    (acc, v) => {
      acc.push(v);
      return acc;
    },
    () => [],
    getKey,
    // @ts-ignore
    array
  );
}
export function watchFormUpdates(form: FormGroup): void {
  form.valueChanges.pipe(
    startWith(this.form.value),
    pairwise(),
    map(([oldState, newState]) => {
      let changes = {};
      for (const key in newState) {
        if (oldState[key] !== newState[key] &&
          oldState[key] !== undefined) {
          changes[key] = newState[key];
        }
      }
      return changes;
    }),
    filter(changes => Object.keys(changes).length !== 0)
  ).subscribe(
    value => {
      console.log("Form has changed:", value);
    }
  );
}
export function MapContentProps(type){
  switch (type) {
    case 'Template':  {
      return {
        icon: {
          src: 'assets/images/icons/form-dark-ico.png',
          mat: 'grid_view'
        }
      }
    }
    case 'Rule':  {
      return {
        icon: {
          src: 'assets/images/icons/rules.png',
          mat: 'rule'
        }
      }
    }
    case 'Dataset':  {
      return {
        icon: {
          src: 'assets/images/icons/lookup-set.png',
          mat: 'dataset'
        }
      }
    }
    case 'Supplement':  {
      return {
        icon: {
          src: 'assets/images/icons/supplement-peach.png',
          mat: 'dataset'
        }
      }
    }
  }
}
