import { createSelector } from '@ngrx/store';
import { selectSessionFeatureState } from './session.selectors';

export const selectCustomFormState = createSelector(
  selectSessionFeatureState,
  (state) => state.customFormState
);

export const getSubjectiveAssmtIForm = createSelector(
  selectCustomFormState,
  (state) => {
    return state?.triage?.subjectiveAssmtFormInfo?.iForm;
  }
);

export const getSubjectiveAssmtFormlyFields = createSelector(
  selectCustomFormState,
  (state) => state?.triage?.subjectiveAssmtFormInfo?.fields
);

export const getCustomFormsModel = createSelector(
  selectCustomFormState,
  (state) => state?.model
);

export const selectSelectedForm = createSelector(
  selectSessionFeatureState,
  (state) =>
    state.customFormState?.loading ? null : state.customFormState?.model
);

export const getSelectedProtocols = createSelector(
  selectSessionFeatureState,
  (state) => {
    return state?.case?.protocols;
  }
);

export const selectEnabledChildMenu = (subMenu: string) =>
  createSelector(selectSessionFeatureState, (state) => {
    const selectedProtocol = state?.case?.protocols;
    const iform = state?.editor?.iForm;
    return false;
  });
  export const getfollowUpIForm = createSelector(
    selectCustomFormState,
    (state) => {
      return state?.followUp?.followUpFormInfo?.iForm;
    }
  );
  export const getfollowUpFormlyFields = createSelector(
    selectCustomFormState,
    (state) => state?.followUp?.followUpFormInfo?.fields
  );