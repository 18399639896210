/* tslint:disable:typedef */

export class DaraUtils {
  /**
   * Filter array by string
   *
   * @param mainArr
   * @param searchText
   * @returns {any}
   */
  public static filterArrayByString(mainArr, searchText): any {
    if (searchText === '') {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter(itemObj => {
      return this.searchInObj(itemObj, searchText);
    });
  }

  /**
   * Search in object
   *
   * @param itemObj
   * @param searchText
   * @returns {boolean}
   */
  public static searchInObj(itemObj, searchText): boolean {
    for (const prop in itemObj) {
      if (!itemObj.hasOwnProperty(prop)) {
        continue;
      }

      const value = itemObj[prop];

      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }

      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }


  public static getDaysArray(start, end) {
    const result = [];
    // tslint:disable-next-line:no-shadowed-variable
    for (const arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)){
      arr.push(new Date(dt));
      result.push(new Date(dt));
    }
    return result;
  }
  /**
   * Search in array
   *
   * @param arr
   * @param searchText
   * @returns {boolean}
   */
  public static searchInArray(arr, searchText): boolean {
    for (const value of arr) {
      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }

      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  /**
   * Search in string
   *
   * @param value
   * @param searchText
   * @returns {any}
   */
  public static searchInString(value, searchText): any {
    return value.toLowerCase().includes(searchText);
  }

  /**
   * Generate a unique GUID
   *
   * @returns {string}
   */
  public static generateGUID(): string {
    function S4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  }

  /**
   * Toggle in array
   *
   * @param item
   * @param array
   */
  public static toggleInArray(item, array): void {
    if (array.indexOf(item) === -1) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  /**
   * Handleize
   *
   * @param text
   * @returns {string}
   */
  public static handleize(text): string {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from landing of text
      .replace(/-+$/, '');            // Trim - from end of text
  }
}
export function getPropertiesByKey(object, key) {

  const paths = [
  ];

  iterate(
    object,
    '');

  return paths;

  /**
   * Single object iteration. Accumulates to an outer 'paths' array.
   */
  function iterate(object, path) {
    let chainedPath;

    for (const property in object) {
      if (object.hasOwnProperty(property)) {

        chainedPath =
          path.length > 0 ?
            path + '.' + property :
            path + property;

        if (typeof object[property] == 'object') {

          iterate(
            object[property],
            chainedPath);
        } else if (
          property === key ||
          key.length === 0) {

          paths.push(
            chainedPath);
        }
      }
    }

    return paths;
  }
}

export  class Guid {
  // tslint:disable-next-line:typedef
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8 );
      return v.toString(16);
    });
  }
}

// tslint:disable-next-line:max-line-length
export const randomColor = () => 'rgb(' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ')';

export function getDirtyValues(form: any) {
  const dirtyValues = {};

  Object.keys(form.controls)
    .forEach(key => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = this.getDirtyValues(currentControl);
        }
        else {
          dirtyValues[key] = currentControl.value;
        }
      }
    });

  return dirtyValues;
}

function isDebug(){
  return true;
}

export class DebugData {
  constructor(desc, value) {
    this.desc = desc;
    this.value = value;
  }

  desc: string;
  value: any;
}

export function logGroup(groupName: string, data: DebugData[]){

  if (isDebug()){
    console.group(groupName);
    data.forEach((d) => {
      console.log(d.desc + ':' , d.value);
    });
    console.groupEnd();
  }

}

import * as _ from 'lodash';

const cssRule =
  'color: rgb(249, 162, 34);' +
  'font-size: 60px;' +
  'font-weight: bold;' +
  'text-shadow: 1px 1px 5px rgb(249, 162, 34);' +
  'filter: dropshadow(color=rgb(249, 162, 34), offx=1, offy=1);';

export function shouldLog(){
  return true;
}

export function CustLog(message: string, data: any){
  if (shouldLog()){
    const val = JSON.parse(JSON.stringify(data));
    console.log(message, data);
  }

}

export interface IGroupedData {
  field: string;
  value: string;
  rows: [];
  count: number;
}

export function groupBy2(arr, fields) {
  const field = fields[0];               // one field at a time
  if (!field) { return arr; }
  const retArr = Object.values(
    arr.reduce((obj: Array<any>, current) => {
      if (!obj[current[field]]) { obj[current[field]] = {field, value: current[field], rows: []}; }
      obj[current[field]].rows.push(current);
      return obj;
    }, {}));

  // recurse for each child's rows if there are remaining fields
  if (fields.length){
    retArr.forEach((obj: any) => {
      obj.count = obj.rows.length;
      obj.rows = groupBy2(obj.rows, fields.slice(1));
    });
  }
  return retArr;
}

function isString(val){
  return val instanceof String;
}

// Method to format createdBy
export function formatCreatedBy(firstName: string, lastName: string): string {
  if (firstName === 'System' && lastName === 'System') {
    return 'System';
  }
  return `${firstName} ${lastName}`.trim();
}





