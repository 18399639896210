import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { faker } from '@faker-js/faker';
import { SortDirection } from '@angular/material/sort';

import { select } from '@ngrx/store';
import { Employee } from '../models/fake.models';

export interface SearchResponse<T> {
  items: T[];
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  _state: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  state$ = this._state.asObservable();
  props?: { [key: string]: any };

  constructor(private http: HttpClient) {
    http.get('assets/data/fakes/fake.employees.json').subscribe((data) => {
      this._state.next({
        employees: data,
      });
    });
  }

  search(term: string, page: number): Observable<SearchResponse<Employee>> {
    return this.state$.pipe(
      select('employees'),
      map((emps: Employee[]) => {
        const result = emps.filter(
          (emp) =>
            emp.firstName.toLowerCase().indexOf(term.toLowerCase()) !== -1
        );

        return result;
      }),
      map((results) => {
        return {
          items: results,
          total: results.length,
        };
      })
    );
  }
  searchFake(
    sort: string,
    order: SortDirection,
    page: number
  ): Observable<SearchResponse<Employee>> {
    const numResults = faker.datatype.number({ min: 25, max: 30 });
    const results = [];
    for (let i = 0; i < numResults; i++) {
      const result = this.createRandomUser();
      results.push(result);
    }
    const lag = faker.datatype.number({ min: 2000, max: 5000 });

    const response = {
      items: results,
      total: results.length,
    };
    return of(response).pipe(debounceTime(lag));
  }

  createRandomUser(): any {
    const homeAddressState = faker.address.state();

    return {
      id: faker.datatype.uuid(),
      username: faker.internet.userName(),
      employeeId: faker.datatype.uuid(),
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),

      contactNumbers: {
        home: faker.phone.phoneNumber(),
        cell: faker.phone.phoneNumber(),
        work: faker.phone.phoneNumber(),
      },
      address: {
        address1: faker.address.streetAddress(),
        address2: '',
        city: faker.address.city(),
        state: homeAddressState,
        zip: faker.address.zipCodeByState(homeAddressState),
        country: faker.address.country(),
      },
      location: {
        address1: faker.address.streetAddress(),
        address2: '',
        city: faker.address.city(),
        state: homeAddressState,
        zip: faker.address.zipCodeByState(homeAddressState),
        country: faker.address.country(),
      },
      jobTitle: faker.name.jobTitle(),
      company: faker.company.companyName(),
      email: faker.internet.email(),
      avatar: faker.image.avatar(),
      password: faker.internet.password(),
      birthdate: faker.date.birthdate(),
      registeredAt: faker.date.past(),
    };
  }
}
