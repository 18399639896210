import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index;" fxLayout="row">

      <formly-field  fxLayout="row" [field]="field"></formly-field>
      <div class="ml-3">
        <button mat-icon-button color="warn" (click)="remove(i)"><mat-icon>delete</mat-icon></button>
      </div>
    </div>
    <div style="margin:30px 0;">
      <button class="btn btn-primary" type="button" (click)="add()">{{ props?.['addText'] }}</button>
    </div>
  `,
})
export class RepeatTypeComponent extends FieldArrayType {}

export const repeatType = {
  name: 'repeat',
  component: RepeatTypeComponent

};
