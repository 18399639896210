import { AfterViewInit, Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-tooltip',
  template: `
   <ng-container #fieldComponent></ng-container>

   <ng-template #matSuffix>
    <span
      *ngIf="props.tooltip"
      matTooltip="{{ props.tooltip.text }}" matTooltipPosition="above" matTooltipHideDelay="1000">
      &nbsp;<mat-icon *ngIf="props.tooltip.icon">{{ props.tooltip.icon }}</mat-icon>
    </span>
  </ng-template>
  `,
})
export class FormlyTooltipWrapper extends FieldWrapper implements AfterViewInit  {
  @ViewChild('matSuffix') matSuffix: TemplateRef<any> | undefined;

  ngAfterViewInit() {
    if (this.matSuffix) {
      Promise.resolve().then(() => this.props.suffix = this.matSuffix);
    }
  }

}
