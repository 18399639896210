import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  first,
  tap,
  take,
} from 'rxjs';
import { IEnvironmentConfig } from '../../src/lib/environment';
import * as _ from 'lodash';
import { CoreEnvironment } from '@autobot/core';

export const configFactory =
  (env: ConfigurationService, configUrl: string) => () =>
    env.loadAppConfig(configUrl);

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(
    private http: HttpClient,
    @Inject('DEFAULTCONFIG') private defaultConfig: IEnvironmentConfig,
    @Inject('CoreEnvironment') private environment: CoreEnvironment
  ) {
    // Set the default config from the users provided config (from forRoot)
    this._config = defaultConfig;
    this._env = environment;
  }

  private _configSubject: BehaviorSubject<IEnvironmentConfig> =
    new BehaviorSubject<any>(false);
  private readonly _config: any;
  private readonly _env: any;

  get config(): Observable<IEnvironmentConfig> {
    return this._configSubject.asObservable();
  }

  get getConfig(): IEnvironmentConfig {
    return this._configSubject.value;
  }

  private _init(): void {
    this._configSubject = new BehaviorSubject(_.cloneDeep(this._config));
  }

  private _createConfig(config: any, withError: boolean): void {
    // cast all keys as are
    let localConfig = this._configSubject.getValue();
    // Merge the new config
    localConfig = _.merge({}, localConfig, config);
    // is severd
    localConfig.isServed = true;
    if (this._env?.environment !== 'prod' || this._env?.environment !== 'production') {
      localConfig.displayName = `${
        localConfig.displayName
      } - ${this.getDisplayName(this._env.environment)}`;
    }
    // with error
    localConfig.withError = withError;

    this._configSubject.next(localConfig);
  }

  loadAppConfig(endpoint: string): Observable<boolean> {
    // Initialize the service
    this._init();
    return this.http.get(endpoint).pipe(
      map((response) => {
        this._createConfig(response, false);
        return true;
      }),
      catchError((error) => {
        // if in error, return set fall back from environment
        this._createConfig(this.defaultConfig, true);
        console.log(error);
        return of(false);
      })
    );
  }
  getDisplayName(env: string) {
    switch (env) {
      case 'dev':
        return 'QA';
      case 'btat':
        return 'Pre-UAT';
      case 'uat':
      case 'cert':
        return env.toUpperCase();
      default:
        env;
    }

    return env;
  }
}