import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IEnvironmentConfig } from '@autobot/core';
import {
  catchError,
  Observable,
  retry,
  throwError,
} from 'rxjs';
import {  Template } from '@forms/common';
import { Case } from '@nfr/common';

@Injectable({ providedIn: 'root' })
export class CaseApiService {
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private environment: IEnvironmentConfig
  ) {}
  caseURL = this.environment.apiConfig.casesUri + '/api';
  formsURL = this.environment.apiConfig.formsUri + '/api';

  saveCase(id: string, data: any) {
    const _url = `${this.caseURL}/cases/${id}`;
    return this.http
      .put<Case>(_url, { data })
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  loadTemplate(id: string): Observable<Template> {
    const _url = `${this.formsURL}/templates/${id}`;

    return this.http
      .get<Template>(_url)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(msg);
  }
}
