import { createAction, props } from '@ngrx/store';

export enum SessionActionTypes {
  INIT = '[Session] Session Init',
  SESSION_STARTED = '[Session] Session Started',
  SESSION_ENDED = '[Session] Session Ended',
  CASE_LOADED = '[Session] Case Loaded',
}

export const sessionStarted = createAction(SessionActionTypes.SESSION_STARTED);
export const sessionEnded = createAction(SessionActionTypes.SESSION_ENDED);
