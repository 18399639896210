import {Injectable} from "@angular/core";
import {BreakpointObserver} from "@angular/cdk/layout";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
const EXTRA_SMALL_WIDTH_BREAKPOINT = 720;
const SMALL_WIDTH_BREAKPOINT = 959;
@Injectable({providedIn: "root"})
export class EditorToolbarService {
  isExtraScreenSmall: Observable<boolean>;
  isScreenSmall: Observable<boolean>;

  constructor(breakpoints: BreakpointObserver) {
    this.isExtraScreenSmall =
      breakpoints.observe(`(max-width: ${EXTRA_SMALL_WIDTH_BREAKPOINT}px)`)
        .pipe(map(breakpoint => breakpoint.matches));
    this.isScreenSmall = breakpoints.observe(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`)
      .pipe(map(breakpoint => breakpoint.matches));
  }
}
