import { Account } from "@accounting/common";
import { selectUserProfile } from "@autobot/authentication";
import { FollowUp, FollowUpStatus, FollowUpTabType, Protocols } from "@nfr/common";
import { createSelector } from "@ngrx/store";
import * as _ from "lodash";
import { selectCaseState } from "./case.selectors";
import { selectSessionFeatureState } from "./session.selectors";

export const selectFollowUpState = createSelector(
  selectSessionFeatureState,
  (state) => state?.followUpState
);

export const selectCaseDetailModel = createSelector(
  selectCaseState,
  (state) => {
    let _casemodel = state.model ?? {};
    let _caseMetaData = state.metaData ?? {};
    let protocols= state.protocols as Protocols[];  
    let account= state.calls[0].data.account  as Account[]
    let protocolSelected=protocols?.map((item)=>{return item.name}).join(", "); 
    let followup={
      caseSummary:{
        selectedProtocol:protocolSelected
      }
    }
    const _caseDetailModel = {
      ..._casemodel,
      metaData:_caseMetaData,
      followup: followup,
      account:account,
    };
    return _caseDetailModel;
  }
);

export const selectFollowUpsData = createSelector(
  selectFollowUpState,
  (state) => state?.followUps??[]
);
export const isFollowUpFormSaved = createSelector(
  selectFollowUpState,
  (state) => state.saved
);
export const isFollowUpFormSaving = createSelector(
  selectFollowUpState,
  (state) => state.saving
);
export const isFollowUpFormLoading = createSelector(
  selectFollowUpState,
  (state) => state.loading
);
export const isFollowUpFormLoaded = createSelector(
  selectFollowUpState,
  (state) => state.loaded
);

export const getFollowUpPageTabs = createSelector(
  selectFollowUpState,
  (state) => state?.followUpTabs
);

export const getFollowUpDatasets = createSelector(
  selectFollowUpState,
  (state) => state?.datasets
);
export const getSelectedFollowUpTab = createSelector(
  selectFollowUpState,
  (state) => state?.selectedTab
);
export const getSelectedFollowUpTabIndex = createSelector(
  selectFollowUpState,
  (state) => state?.selectedTab?.tabIndex
);
export const getmaxAllowedFollowUp = createSelector(
  selectFollowUpState,
  (state) => state?.maxAllowedFollowUp
);

export const getSelectedFollowUpTabAfterSave = (status?: FollowUpStatus) =>
  createSelector(getSelectedFollowUpTab,getmaxAllowedFollowUp,
    (selectedTab,maxTabAllowed) => { 
      //if draft, retain on same tab
      //if complete, move to next tab 
      //if next tab is max, then stay on it.
      if (!selectedTab ) return 1;
      if (!status ) return 1;
      else if (status== FollowUpStatus.Draft) return selectedTab.tabIndex;
      else if (selectedTab.tabIndex==maxTabAllowed) return selectedTab.tabIndex;
      else if(selectedTab.tabIndex < maxTabAllowed) return selectedTab.tabIndex+1;
      else  return 0;
    }
  )
export const getSelectedFollowUpTabType = createSelector(
  getSelectedFollowUpTab,
  (state) => state?.tabType
);
export const getAllTemplates = createSelector(
  selectFollowUpState,
  (state) => state?.templates
);
export const getFollowUpFormlyTabs = createSelector(
  selectFollowUpState,
  (state) => state?.formEditor?.template?.tabs
);
export const getFollowUpFormlySelectedTabIndex = createSelector(
  selectFollowUpState,
  (state) => state.formEditor?.selectedTabIndex
);

export const getFollowUpFormlyFields1 = createSelector(
  selectFollowUpState,
  (state) => state?.formEditor?.fields
);

export const getFormlyDilogFields = createSelector(
  selectFollowUpState,
  (state) => state?.dialogEditor?.fields
);
export const getFollowUpFormlyFields = createSelector(
  selectFollowUpState,
  getSelectedFollowUpTabType,
  getFollowUpFormlyTabs,
  getFollowUpFormlySelectedTabIndex,
  (state, tabType, tabs, tabIndex) => {
    
    if (!state?.formEditor?.fields) return;
    else if(tabType===FollowUpTabType.Detail) return state?.formEditor?.fields
    else if(tabType===FollowUpTabType.FollowUp){
      const tabId=tabs[tabIndex].id;
      //return state?.formEditor?.fields
      return state?.formEditor?.fields.filter((a) => a.id == tabId);
    } 
    else return state?.formEditor?.fields
  
   // return state?.formEditor?.fields
  }
);
export const getCurrentFollowUp = createSelector(
  selectFollowUpState,
  (state) => state.currentFollowUp
);
export const isCompleteFollowUpButtonVisible = createSelector(
  getCurrentFollowUp,
  getSelectedFollowUpTabType,
  getFollowUpFormlyTabs,
  getFollowUpFormlySelectedTabIndex,
  (currentFollowUp, tabType,tabs,selectedTabIndex) => {
    if (!tabType ||!tabs || tabType===FollowUpTabType.Detail) return false;
    if(tabType===FollowUpTabType.FollowUp){
      //show only is current follow up does not have any value or 
      //if current follow up is in draft mode.
      const isDraftOrNew=(!currentFollowUp || (currentFollowUp?.status===FollowUpStatus.Draft ||currentFollowUp?.status===FollowUpStatus.New));
      if(selectedTabIndex==tabs.length-1 && isDraftOrNew)
       return true;
      else
      return false;
    } 
  }
);
export const getFollowUpModel = createSelector(
  getCurrentFollowUp,selectCaseDetailModel,
  (state,caseDetail) => {
    const followUpData=state?.data??{};
    const _model = {
      ...caseDetail,
      ...followUpData,
    };
    return _model;
  }
);
export const getFollowUpObjectforSave = createSelector(
  getCurrentFollowUp,selectCaseDetailModel,selectUserProfile,
  (state,caseDetail,profile) => {
    const _followUpData = {
      followup:state.data?.followup,
      metaData: state.data?.metaData,
    };
    return {
      id: state.id,
      caseId:state.caseId,
      followUpNumber:state.followUpNumber,
      status: state.status,
      data: _followUpData,
      createdBy:profile.email,
      updatedBy: profile.email,
      created:new Date(),
      updated:new Date()
    };
  }
);
export const getIsViewOnlyForm = createSelector(getCurrentFollowUp,  getSelectedFollowUpTabType,
  (state:FollowUp,tabType) => {
    if (!tabType || tabType===FollowUpTabType.Detail) return true;
    return state?.status==FollowUpStatus.Complete
  });

  export const getPrevRecomendation = createSelector(
    selectCaseState,selectFollowUpsData,getCurrentFollowUp,getIsViewOnlyForm,
    (state,followups,currentFollowUp,isViewOnlyForm) => {
      let _casemodel = state.model ?? {};
      let prevRecomendation=_casemodel?.planofcare?.disposition?.dispositionRecommended;
      if(isViewOnlyForm || !currentFollowUp || followups?.length==0 || !_casemodel)
         return prevRecomendation;
      const completedFollowup = followups
            .filter( (x) => x.status == FollowUpStatus.Complete);
       const soretdByLatest=_.orderBy(completedFollowup, 'followUpNumber', ['desc']);   
       const result = soretdByLatest.find(function (val) {
        return (val?.data.followup?.medicalAssessment
        ?.dispositionReview?.disposition!=null && val?.data.followup?.medicalAssessment
        ?.dispositionReview?.disposition!='');
        });
        if(result){
          return result?.data?.followup?.medicalAssessment
          ?.dispositionReview?.disposition
        }
        else
        return prevRecomendation
      
    }
  );

/*
export const getFollowUpFormlyTabs = createSelector(selectFollowUpState, (state) => 
   state?.followupEditor?.template?.tabs
);

export const getFollowUpTemplate = createSelector(
  selectFollowUpState,
  (state) => state?.followupEditor?.template
);
export const getFollowUpFormlyFields = createSelector(selectFollowUpState, (state) => {
  return state?.followupEditor?.fields;
});
export const getFollowUpFormlySelectedTabIndex = createSelector(
  selectFollowUpState,
  (state) => state.followupEditor?.selectedTabIndex
);
export const getFollowUpFormlySelectedAreaIndex = createSelector(
  selectFollowUpState,
  (state) => state.followupEditor?.selectedAreaIndex
);
export const getFollowUpFormlySelectedTab = createSelector(
  selectFollowUpState,
  getFollowUpFormlyTabs,
  getFollowUpFormlySelectedTabIndex,
  (state, tabs, tabIndex) => {
    if (!tabs) return;
    return tabs[tabIndex];
  }
);

export const getFormlyAreas = createSelector(selectFollowUpState,getFollowUpTemplate,getFollowUpFormlySelectedTab,
  (state, template, tab) => {
    if (!template?.areas) return;
    return template.areas.filter((a) => a.tabId == tab.id);
  });
  export const getFormlyCombinedMenu = createSelector(
    selectFollowUpState,
    getFollowUpFormlyTabs,
    getFormlyAreas,
    (state, tabs, areas) => {
      const result = [];
      _.forEach(tabs, (t) => {
        const tabAreas = areas.filter((o) => o.tabId == t.id);
        const final = {
          id: t.id,
          text: t.label,
          routerLink: '',
          children: tabAreas.map((a) => {
            return {
              id: a.id,
              text: a.label,
              routerLink: a.id,
            };
          }),
        };
        result.push(final);
      });
  
      return result;
    }
  );
  */


