import { createSelector } from '@ngrx/store';

import { selectUserState } from '../core.state';
import { UserState } from '../reducers/user.reducer';
export const selectUserDetails = createSelector(
  selectUserState,
  (state: UserState) => state.userDetails
);

export const selectUserRoles = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails ? userDetails[`roles`] : "";
  }
);
export const selectUserFavorites = createSelector(selectUserState, (state) => {
  return Object.values(state.favorites);
});
export const selectCurrentUser = createSelector(
  selectUserState,
  (state) => state
);
