import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironmentConfig } from '@autobot/core';
import { Assembly, Template } from '@forms/common';

import {
  Observable,
  catchError,
  forkJoin,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FormsApiService {
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT')
    private environment: IEnvironmentConfig
  ) {}

  apiURL = this.environment.apiConfig.formsUri + '/api';

  loadAssembly(id: string): Observable<any> {
    const _url = `${this.apiURL}/assemblies/${id}/detail`;

    return this.http.get<Assembly>(_url).pipe(
      switchMap((assembly) => {
        const forms = assembly.forms;
        const calls = forms.map((e) => this.loadTemplate(e.templateId));

        return forkJoin(calls).pipe(
          first(),
          map((o) => {
            return {
              templates: o,
              assembly,
            };
          })
        );
      }),
      map((e) => {
        const assembly = e.assembly;

        const forms = assembly.forms.map((f) => {
          const template = e.templates.filter((t) => t.id == f.templateId)[0];

          return {
            ...f,
            template,
          };
        });
        return {
          ...assembly,
          forms,
        };
      }),
      retry(1),
      catchError(this.httpError.bind(this))
    );
  }

  loadTemplate(id: string): Observable<Template> {
    const _url = `${this.apiURL}/templates/${id}`;

    return this.http
      .get<Template>(_url)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
}
