import {InjectionToken} from '@angular/core';

export interface CoreEnvironment {
  isProduction: boolean;
  configUrl: string,
  environment:string,
  ngrx: {
    hydrate: string[]
  }
}

export const CoreEnvironmentService = new InjectionToken<CoreEnvironment>('CoreEnvironment');
