export abstract class BaseContent {
  id!: string;
  name!: string;
  props?: { [key: string]: any };
  updated!: Date;
}

export interface IContent<T> {
  id: string;
  name: string;
  props?: { [key: string]: any };
  updated: Date;
  data: T;
}

export class Content<T extends BaseContent> implements IContent<T> {
  constructor(input: T, typeHint?: string) {
    this.data = input;
    this.id = input.id;
    this.name = input.name;
    this.owner = input.props?.owner;
    this.updated = input.updated;
    this.props = { ...input.props };
    this.type = typeHint ?? typeof input;
  }
  data: T;
  id: string;
  name: string;
  owner: string;
  updated: Date;
  type: string;
  props?: { [key: string]: any };
}

export interface ContentFilter {
  id: string;
  icon: string;
  label: string;
  group: string;
  type?: FilterType;
  value: any;
}

export enum FilterType {
  search = 'search',
  category = 'category',
  attribute = 'attribute',
}
