import {Component, Input} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';


@Component({
  selector: 'formly-group-radio',
  template: `

   <div class="w-100" [class.selected]="props?.selected" fxLayout="column">
     <mat-label>{{props.label}}</mat-label>
     <mat-radio-group [formControl]="formControl" [formlyAttributes]="field" fxLayout="row wrap" >
       <mat-radio-button fxFlex="33" class="py-1" *ngFor="let number of [0,1,2,3,4,5,6,7,8,9]" [value]="number">{{number}}</mat-radio-button>



     </mat-radio-group>
   </div>
 `,
  styles:[
    `.selected {
      outline: 4px dashed darkorange;
      background: transparent;
    }`]
})
export class GroupRadioType extends FieldType<FieldTypeConfig> {

}

export const radiogroup = {
  name: 'radio-group',
  component: GroupRadioType

};
