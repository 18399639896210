import {StoreModule} from '@ngrx/store';

import {EffectsModule} from '@ngrx/effects';
import {NgModule} from '@angular/core';
import * as fromDashboard from './reducers/dashboard.reducer';
import {reducer as dashboardReducers} from './reducers/dashboard.reducer';
import {DashboardEffects} from './effects/dashboard.effects';
import {DashboardFacade, DashboardFacadeImpl} from "./services";



@NgModule({
  imports: [
    StoreModule.forFeature(fromDashboard.dashboardFeatureKey, dashboardReducers ),
    EffectsModule.forFeature([DashboardEffects]),
  ],
  providers: [
    {
      provide:  DashboardFacade,
      useClass: DashboardFacadeImpl

    }
  ]
})
export class DashboardStoreModule {}
