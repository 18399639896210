import {createAction, props} from "@ngrx/store";
import {Call} from "@nfr/common";


export enum CallApiActionTypes {
  CALL_CREATED_SUCCESS = '[Call/Api] Call Created Success',
  CALL_CREATED_FAILURE = '[Call/Api] Call Created Failure',

  CALL_UPDATED_SUCCESS = '[Call/Api] Call Updated Success',
  CALL_UPDATED_FAILURE = '[Call/Api] Call Updated Failure',

}


export const createCallSuccess = createAction(CallApiActionTypes.CALL_CREATED_SUCCESS ,props<{call: Call}>());
export const updateCallSuccess = createAction(CallApiActionTypes.CALL_UPDATED_SUCCESS ,props<{call: any}>());

export const createCallFailure = createAction(CallApiActionTypes.CALL_CREATED_FAILURE ,props<{ err: any }>());
export const updateCallFailure = createAction(CallApiActionTypes.CALL_UPDATED_FAILURE ,props<{ err: any }>());
