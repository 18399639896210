export enum Role {
  Agent = 'Agent',
  Admin = 'Admin',
  Supervisor  = 'Supervisor',
  SuperAdmin = 'SuperAdmin',
   Test = 'TestSupervisor'
}
export interface AuthUser {
  name?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  picture?:string|undefined;
  id: string;
  email: string;
  roles:Role[];
  credentials: string;
}

export interface Profile {
  id: string;
  email: string;
  firstName?: string | undefined;
  lastName?: string | undefined;
  organizationId?: string | undefined;
  credentials: string;
}
