import {Router} from '@angular/router';
import {Injectable, NgZone} from '@angular/core';
import {OverlayContainer} from '@angular/cdk/overlay';
import {select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {combineLatest, merge, of} from 'rxjs';
import {tap, withLatestFrom} from 'rxjs/operators';


import {
  actionSettingsChangeAnimationsElements,
  actionSettingsChangeAnimationsPage,
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeAutoNightMode,
  actionSettingsChangeHour,
  actionSettingsChangeLanguage,
  actionSettingsChangeStickyHeader,
  changeTheme
} from '../actions';
import {selectElementsAnimations, selectPageAnimations} from '../selectors/settings.selectors';
import {SettingsAppState} from '../models/settings.model';

import {selectSettingsState} from '../core.state';
import {AnimationsService, LocalStorageService} from "@autobot/core/services";


export const SETTINGS_KEY = 'SETTINGS';

const INIT = of('anms-init-effect-trigger');

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SettingsAppState>,
    private router: Router,
    private overlayContainer: OverlayContainer,
    private localStorageService: LocalStorageService,
    private animationsService: AnimationsService,

    private ngZone: NgZone
  ) {}

  // hour = 0;
  // changeHour = this.ngZone.runOutsideAngular(() =>
  //   setInterval(() => {
  //     const hour = new Date().getHours();
  //     if (hour !== this.hour) {
  //       this.hour = hour;
  //       this.ngZone.run(() =>
  //         this.store.dispatch(actionSettingsChangeHour({ hour }))
  //       );
  //     }
  //   }, 60_000)
  // );

  persistSettings = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          actionSettingsChangeAnimationsElements,
          actionSettingsChangeAnimationsPage,
          actionSettingsChangeAnimationsPageDisabled,
          actionSettingsChangeAutoNightMode,
          actionSettingsChangeLanguage,
          actionSettingsChangeStickyHeader,
          changeTheme
        ),
        withLatestFrom(this.store.pipe(select(selectSettingsState))),
        // tap(([action, settings]) =>
        //   this.localStorageService.setItem(SETTINGS_KEY, settings)
        // )
      ),
    { dispatch: false }
  );

  updateRouteAnimationType = createEffect(
    () =>
      merge(
        INIT,
        this.actions$.pipe(
          ofType(
            actionSettingsChangeAnimationsElements,
            actionSettingsChangeAnimationsPage
          )
        )
      ).pipe(
        withLatestFrom(
          combineLatest([
            this.store.pipe(select(selectPageAnimations)),
            this.store.pipe(select(selectElementsAnimations))
          ])
        ),
        tap(([action, [pageAnimations, elementsAnimations]]) =>
          this.animationsService.updateRouteAnimationType(
            pageAnimations,
            elementsAnimations
          )
        )
      ),
    { dispatch: false }
  );




}
