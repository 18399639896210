import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeedDialComponent } from './speed-dial-fab.component';


import {FormsModule} from '@angular/forms';
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [SpeedDialComponent],
  imports: [
    CommonModule, FormsModule, MatMenuModule, MatButtonModule, MatTooltipModule, DragDropModule,MatIconModule

  ],
  exports: [SpeedDialComponent]
})
export class SpeedDialFabModule { }
