import { Action, createReducer, on } from '@ngrx/store';
import * as authActions from './authentication.actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  userProfile: any;
  isLoggedIn: boolean;
  roles: string[];
  isSupervisor: boolean;
  isTestUser:boolean;
}

export const initialState: AuthState = {
  userProfile: null,
  isLoggedIn: false,
  roles: [],
  isSupervisor:false,
  isTestUser:false
};

const authReducerInternal = createReducer(
  initialState,

  on(authActions.loginComplete, (state, { profile, isLoggedIn, roles, isSupervisor, isTestUser }) => {
    return {
      ...state,
      userProfile: profile,
      isLoggedIn,
      roles,
      isSupervisor,
      isTestUser
    };
  }),

  on(authActions.logoutComplete, (state, {}) => {
    return {
      ...state,
      userProfile: null,
      isLoggedIn: false,
      roles:[],
      isSupervisor:false,
      isTestUser:false
    };
  })
);

export function authReducer(
  state: AuthState | undefined,
  action: Action
): AuthState {
  return authReducerInternal(state, action);
}
