import {Component, Input} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ConfirmDialogControlComponent } from './confirm-dialog-control';


@Component({
  selector: 'formly-yesno-radio',
  template: `

   <div  class="{{props.cssClassName != '' ? props.cssClassName : 'flexRow'}}"   >
     <mat-label [matTooltip]="props.toolTip ?? props.description" matTooltipPosition="after">{{props.label}}</mat-label>
     <mat-radio-group [formControl]="formControl" [formlyAttributes]="field" >
       <mat-radio-button style="margin-right: 12px;" [value]="true">Yes</mat-radio-button>
       <mat-radio-button [value]="false">No</mat-radio-button>
     </mat-radio-group>
   </div>
 `,
  styles:[
    ` .flexRow {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      place-content: center space-between;
      align-items: center;
      margin: 1%;
      }
      .flexColumn {
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      place-content: center space-between;
      margin: 1%;
      }
      .bool-choice-container {
        margin: 1%
      }
      .selected {
      outline: 4px dashed darkorange;
      background: transparent;
    }`]
})
export class YesnoRadioType extends FieldType<FieldTypeConfig> {}

export const radioyesno = {
  name: 'radio-yes-no',
  component: YesnoRadioType

};
