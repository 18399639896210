import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material/form-field';
import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { MaskService } from 'ngx-mask';
import {FieldTypeConfig} from "@ngx-formly/core";

@Component({
  template: `
    <input
      matInput
      [mask]="mask"
      [prefix]="prefix"
      [id]="id"
      [type]="type || 'text'"
      [readonly]="props.readonly"
      [required]="props.required ?? false"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [tabIndex]="props.tabindex"
      [placeholder]="props.placeholder"
    />
  `
})
export class PhoneMaskTypeComponent extends FieldType<FieldTypeConfig> {
  @ViewChild(MatInput) formFieldControl!: MatInput;



  get type() {
    return this.props.type ?? 'text';
  }

  get mask() {
    return this.props.mask ?? '(000) 000-0000';
  }

  get prefix() {
    const pre = this.props.prefix ?? '';

    return pre;
  }
}




@Directive({
  selector: 'input[mask]',
})
export class PhoneMaskDirective {
  constructor(
    private readonly elementRef: ElementRef,
    // private ngControl: NgControl,
    private readonly renderer: Renderer2,
    private readonly maskService: MaskService
  ) {}

  @HostListener('keydown', ['$event']) private onKeyDown(
    e: KeyboardEvent
  ): void {
    const el: HTMLInputElement = e.target as HTMLInputElement;
    const position = this.maskService.maskExpression.length;
    const key = e.key || e.keyCode;
    if (key === 'Backspace' || key === 8) {
    } else {
      if (position !== el.value.length) {
        el.setSelectionRange(position, position);
      }
    }
  }
}
