import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

@Injectable()
export class ConfirmDialogService {
  constructor(private dialog: MatDialog) {}

  openConfirmDialog(
    title: string | null,
    content: string | null,
    contentComponent: any | null = null,
    onConfirm: OnConfirm | null = null,
    onCancel: OnCancel | null = null,
    confirmBtnText: string = 'Confirm',
    cancelBtnText: string = 'Cancel',
    callBackData: any | null = null,
    fireAfterClose: boolean | null = true,
    model:any|null=null
  ) {
    const data: ConfirmDialogData = {
      title,
      content,
      contentComponent,
      confirmBtnText,
      cancelBtnText,
      callBackData,
      model
    };

    const instance = this.dialog.open(ConfirmDialogComponent, {
      data,
      minWidth: '250px',
      maxWidth: '60dvw',
      maxHeight: '90dvh',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'confirm-dialog',
    });

    if (fireAfterClose) {
      instance.afterClosed().subscribe((result) => {
        console.log(result);
        console.log(instance);
        if (result) {
          if (!!onConfirm) onConfirm(data.callBackData,data.model);
        } else {
          if (!!onCancel) onCancel();
        }
      });
    }
    return instance;
  }
}

export interface OnCancel {
  (): void;
}

// export interface OnConfirm {
//   ({ ...arg }): void;
// }

export interface OnConfirm {
  ( ...arg ): void;
}
export interface ConfirmDialogData {
  title: string | null;
  content: string | null;
  contentComponent: any | null;
  confirmBtnText: string;
  cancelBtnText: string;
  callBackData: any | null;
  model:any|null;
}
