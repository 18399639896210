import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, startWith } from "rxjs";
import { FormControl } from "@angular/forms";
import { Employee } from "@forms/common";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AccountMinimalView } from "@accounting/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseApiService } from '@nfr/data';
import { Router } from '@angular/router';
@Component({
  selector: 'similar-call-case-search',
  templateUrl: './similar-case-search.component.html',
  styleUrls: ['./similar-case-search.component.scss'],
})

export class SimilarCaseSearchComponent implements OnInit {
  _resultsLength = new BehaviorSubject<number>(0);
  resultsLength$ = this._resultsLength.asObservable();

  @Input() selectedAccount: AccountMinimalView;
  @Input() accounts: AccountMinimalView[];

  isRateLimitReached = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  _fields: any[];
  displayedColumns: string[] = ['primary', 'caseRef', 'account', 'employee', 'dateofinjury', 'bodypart', 'created', 'status', 'followup'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  selectedSimilarCase = '';
  searchControl: FormControl = new FormControl('');
  search$ = this.searchControl.valueChanges.pipe(startWith(''));
  constructor(private service: CaseApiService,private dialogRef: MatDialogRef<SimilarCaseSearchComponent>,private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.data = this.data.duplicateCaseData;
  }

  ngOnInit(): void {
    this._resultsLength.next(0);
  }

  primaryClick(id) {
    this.selectedSimilarCase = id;
    console.log('Select Case Id' + id);
  }

  openCase(){
    this.dialogRef.close({ closeType: 'ExistingCase', caseId: this.selectedSimilarCase});
    this.router.navigateByUrl('nfr/case/' + this.selectedSimilarCase);
  }

  newCase(){
    this.dialogRef.close({ closeType: 'NewCase', caseId: null});
  }

  onCancelClick(){
    this.dialogRef.close({ closeType: 'Cancel', caseId: null});
  }

}
