import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { UserApiActions, ManagementListActions } from '../actions';
import { User } from '@accounting/common';

export interface State extends EntityState<User> {
  loaded: boolean;
  loading: boolean;
  ids: string[];
  selectedUserId?: string | null;
  error?: string | null; // last known error (if any)
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (model: User) => model.id,
});

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  ids: [],
  selectedUserId: null,
});

export const reducer = createReducer(
  initialState,
  on(UserApiActions.loadUsers, (state, action) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(UserApiActions.loadUsersSuccess, (state, action) => {
    console.log(action);
    return adapter.setAll(action.users, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(UserApiActions.updateUser, (state) => ({ ...state })),
  on(UserApiActions.createUserSuccess, (state, action) =>
    adapter.addOne(action.user, { ...state })
  ),
  on(UserApiActions.deleteUserSuccess, (state, action) =>
    adapter.removeOne(action.id, { ...state })
  ),

  on(UserApiActions.updateUserSuccess, (state, action) =>
    adapter.updateOne(action.user, { ...state })
  ),
  on(ManagementListActions.selectUser, (state, action) => ({
    ...state,
    selectedUserId: action.id,
  }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
