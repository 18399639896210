import { flattenObject } from '@autobot/core';
import { createSelector } from '@ngrx/store';
import * as _ from 'lodash-es';
import { selectAssembly } from './case.selectors';
import { selectSessionFeatureState } from './session.selectors';

export const selectEditorState = createSelector(
  selectSessionFeatureState,
  (state) => state?.editor
);

export const getModel = createSelector(
  selectEditorState,
  (state) => state.model
);
export const getTemplate = createSelector(
  selectEditorState,
  (state) => state.template
);
export const isFormSaved = createSelector(
  selectEditorState,
  (state) => state.saved
);
export const isFormSaving = createSelector(
  selectEditorState,
  (state) => state.saving
);
export const isFormLoading = createSelector(
  selectEditorState,
  (state) => state.loading
);
export const isFormLoaded = createSelector(
  selectEditorState,
  (state) => state.loaded
);
export const selectFormKeys = createSelector(
  selectEditorState,
  getTemplate,
  (state, template) => {
    if (!template) return [];
    const keys: string[] = template?.fields.map((f) => f.key?.toString());
    return keys ?? [];
  }
);
export const getTemplateFields = createSelector(
  selectEditorState,
  getTemplate,
  (state, template) => {
    if (!template) return [];

    return template?.fields;
  }
);

export const getCurrentIForm = createSelector(selectEditorState, (state) => {
  return state?.iForm;
});

export const getNextForm = createSelector(
  selectEditorState,
  selectAssembly,
  (state, assembly) => {
    const currentForm = state.iForm;
    const forms = assembly.forms;

    const filteredForm = forms.filter((f) => f.key == currentForm?.key)[0];
    const currentIndex = filteredForm?.index;
    if (currentIndex == undefined) return;

    var i = currentIndex + 1;
    i %= forms.length;

    if (i == 0) return;
    while (forms[i].disabled) {
      i++;
      i %= forms.length;
    }
    let nextForm = forms[i];
    const next = nextForm;
    return next;
  }
);
export const getPrevForm = createSelector(
  selectEditorState,
  selectAssembly,
  (state, assembly) => {
    const currentForm = state.iForm;
    const forms = assembly.forms;

    if (currentForm == undefined) return;
    if (forms == undefined) return;

    const filteredForm = forms.filter((f) => f.key == currentForm?.key)[0];
    const currentIndex = filteredForm?.index;
    if (currentIndex == undefined) return;
    var i = currentIndex - 1;
    if (i == -1) return;
    i %= forms.length;
    while (forms[i].disabled) {
      i--;
      i %= forms.length;
    }
    let prevForm = forms[i];
    const next = prevForm;
    return next;
  }
);

export const getFormTabs = createSelector(selectEditorState, (state) => {
  return state.template?.tabs;
});

export const getFlatModel = createSelector(
  selectEditorState,
  getTemplate,
  (state, template) => {
    const model = state.model;
    const fields = template.fields;
    const obj = flattenObject(model);
    return Object.entries(obj).map((e) => {
      const key = e[0];
      const value = e[1];
      const field = fields.filter((f) => f.key == key)[0];
      return {
        key,
        value,
        field,
      };
    });
  }
);

export const getDatasets = createSelector(
  selectEditorState,
  (state) => state.datasets
);
export const getSelectedTabIndex = createSelector(
  selectEditorState,
  (state) => state.selectedTabIndex
);
export const getSelectedAreaIndex = createSelector(
  selectEditorState,
  (state) => state.selectedAreaIndex
);
export const getSelectedTab = createSelector(
  selectEditorState,
  getFormTabs,
  getSelectedTabIndex,
  (state, tabs, tabIndex) => {
    if (!tabs) return;
    return tabs[tabIndex];
  }
);
export const getFormAreas = createSelector(
  selectEditorState,
  getTemplate,
  getSelectedTab,
  (state, template, tab) => {
    if (!template?.areas) return;
    return template.areas.filter((a) => a.tabId == tab.id && a.props?.showHeader);
  }
);
export const getSelectedArea = createSelector(
  selectEditorState,
  getFormAreas,
  getSelectedAreaIndex,
  (state, areas, areaIndex) => {
    return areas[areaIndex];
  }
);

export const getCombinedMenu = createSelector(
  selectEditorState,
  getFormTabs,
  getFormAreas,
  (state, tabs, areas) => {
    const result = [];
    _.forEach(tabs, (t) => {
      const tabAreas = areas.filter((o) => o.tabId == t.id);
      const final = {
        id: t.id,
        text: t.label,
        routerLink: '',
        children: tabAreas.map((a) => {
          return {
            id: a.id,
            text: a.label,
            routerLink: a.id,
          };
        }),
      };
      result.push(final);
    });

    return result;
  }
);

export const getFormlyFields = createSelector(
  selectEditorState,
  (state) => state.fields
);
export const getNoWrapFormlyFields = createSelector(
  selectEditorState,
  (state) => {
    return [
      {
        type: 'nowrap-tabs',
        fieldGroup: state.fields[0].fieldGroup,
      },
    ];
  }
);

function getNextFormIndex() {
  var i = this.currPhotoId + 1;
  i %= this.album.length;
  while (this.album[i].disabled) {
    i++;
    i %= this.album.length;
  }
  return this.album[i];
}

function getPrev() {
  var i = this.currPhotoId - 1;
  i %= this.album.length;
  while (this.album[i].disabled) {
    i--;
    i %= this.album.length;
  }
  return this.album[i];
}
