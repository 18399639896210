import { createAction, props } from '@ngrx/store';

export enum DashboardActionsTypes {
  NEW_CALL = '[Dashboard] New Call',
  LOAD_DRAFT_CASES = '[Dashboard] Load My Draft Cases',
  LOAD_COMPLETED_CASES = '[Dashboard] Load My Completed Cases',
  LOAD_ACCOUNTS_TREE = '[Dashboard] Load Dashboard Accounts Tree',
  LOAD_UNASSIGNED_CASES = '[Pending Assignments] Load Cases',
  LOAD_UNASSIGNED_FOLLOWUPS = '[Pending Assignments] Load UnAssigned FollowUps',
  LOAD_ASSIGNED_FOLLOWUPS = '[My Cases Follow Ups] Load Assigned FollowUps', 
}

export const newCall = createAction(DashboardActionsTypes.NEW_CALL);

export const loadAccountsTree = createAction(DashboardActionsTypes.LOAD_ACCOUNTS_TREE);

export const loadDraftCases = createAction(DashboardActionsTypes.LOAD_DRAFT_CASES);

export const loadCompletedCases = createAction(DashboardActionsTypes.LOAD_COMPLETED_CASES);

export const loadUnAssignedCases = createAction(DashboardActionsTypes.LOAD_UNASSIGNED_CASES);

export const loadUnAssignedFollowUps = createAction(DashboardActionsTypes.LOAD_UNASSIGNED_FOLLOWUPS);

export const loadAssignedFollowUps = createAction(DashboardActionsTypes.LOAD_ASSIGNED_FOLLOWUPS);
