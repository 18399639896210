import { Action, createReducer, on } from '@ngrx/store';

import { ManagementEditorActions } from '../actions';
import { User } from '@accounting/common';

export const editorFeatureKey = 'editor';

export interface State {
  saving: boolean;
  saved: boolean;
  initialUser?: User;
  currentUser?: User;
  isDraft?: boolean;
}

export const initialState: State = {
  saving: false,
  saved: false,
};

export const reducer = createReducer(
  initialState,
  on(ManagementEditorActions.init_user, (state, action) => ({
    ...state,
    saved: true,
    initialUser: action.user,
    currentUser: action.user,
    isDraft: action.user.active?.['isDraft'] ?? false,
  })),
  on(ManagementEditorActions.saveUser, (state) => ({
    ...state,
    saving: true,
    saved: false,
  })),
  on(ManagementEditorActions.saveUserSuccess, (state) => ({
    ...state,
    saving: false,
    saved: true,
  }))
);
