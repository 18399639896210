import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {LetModule, PushModule} from "@ngrx/component";
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { AutoProviderSearchComponent } from './auto-provider-search.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import { NgxMaskModule } from 'ngx-mask';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [AutoProviderSearchComponent],
  imports: [
    MatRadioModule,
    MatCardModule,
    CommonModule,
    ScrollingModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    LetModule,
    MatExpansionModule,
    FlexLayoutModule,
    PushModule,
    NgxMaskModule,
    MatFormFieldModule 
  ],
  exports: [AutoProviderSearchComponent],
})
export class AutoProviderSearchModule {}
