import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ConfirmDialogControlComponent } from './confirm-dialog-control';

@Component({
  selector: 'formly-radio-three',
  template: `
    <div  class="{{props.cssClassName != '' ? props.cssClassName : 'flexRow'}}"   >
     <mat-label [matTooltip]="props.toolTip ?? props.description" matTooltipPosition="after">{{props.label}} {{props.required ? '*' : ''}}</mat-label>
      <mat-radio-group [formControl]="formControl" [required]="props.required" [formlyAttributes]="field">
         <mat-radio-button (change)="onRadioChange($event, props.options)"
          *ngFor="let opt of props.options | formlySelectOptions: field | async; let i = index" [value]="opt.value">{{opt.label}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="isValid" class="error-message">
        {{ getMessage }}
    </div>
 `,
  styles: [
    `
      .flexRow {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      place-content: center space-between;
      align-items: center;
      margin: 1%;
      }
      .flexColumn {
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      place-content: center space-between;
      margin: 1%;
      }
      .selected {
        outline: 4px dashed darkorange;
        background: transparent;
      }
      .error-message {
        color: #f44336;
        font-size: 12px;
        margin-top: 4px;
      }
    `,
  ],
})
export class RadioThreeChoiceType extends FieldType<FieldTypeConfig> {
  constructor(private dialog: MatDialog) {
    super();
  }

  get isValid() {
    return this.formControl.invalid && this.formControl.touched;
  }

  get getMessage() {
    if (this.field.props.required) {
      return 'This Field is required.';
    }
    return '';
  }

  onRadioChange(event: any, radioOptions: any) {
    let value = event.value;
    let message: string;
    if (radioOptions) {
      let data = radioOptions.filter(x => x.value.toLocaleLowerCase() === value.toLocaleLowerCase())[0];
      if (data) {
        message = data.popUpMessage;
        if (message) {
          this.showPopup(message);
        }
      }
    }
  }

  showPopup(message: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';

    const data = {
      title: "Attention",
      message: message,
      acceptButtonText: 'Ok'
    };

    const dialogRef = this.dialog.open(ConfirmDialogControlComponent, {
      width: '600px',
      data,
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe()
      .subscribe((result) => {
        console.log('custom radio modal dialog closed');
      });
  }
}
export const radioThreeChoice = {
  name: 'radio-three',
  component: RadioThreeChoiceType,
};
