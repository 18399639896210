import { Component } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ApplicationInsightsService } from '@autobot/core';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'agent-portal';
  constructor(
    private router: Router,
    private appInsights: ApplicationInsightsService
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter(
          (event): event is ResolveEnd => event instanceof ResolveEnd
        )
      )
      .subscribe((event) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.appInsights.trackPageView({
            name: activatedComponent.name,
            uri: event.urlAfterRedirects,
          });
        }
      });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }
}

