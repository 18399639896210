import {createAction, createActionGroup, emptyProps, props} from "@ngrx/store";
import {Template} from "@forms/common";



export enum TemplatesPageActionTypes {
  INIT = '[Templates Page] Enter Templates Page',


  SELECT_TEMPLATE = '[Templates Page] Select Template',

  IMPORT_TEMPLATE = '[Template Page] Import Template',

  PREVIEW_TEMPLATE = '[Template Page] Preview Template',

  SAVE_TEMPLATE_CLICKED = '[Template Header] Save Template Clicked',


}


export const init = createAction(TemplatesPageActionTypes.INIT);




export const importTemplate = createAction(TemplatesPageActionTypes.IMPORT_TEMPLATE);




export const saveTemplateClicked = createAction(TemplatesPageActionTypes.SAVE_TEMPLATE_CLICKED, props<{template: Template}>())



